import template from './dataAnalysis.html';
import modal from './filterOptionsModal.html';
import trackerModal from './filterTrackerOptionsModal.html';
import './dataAnalysis.less';
import urlModalTemplate from './dataAnalysisUrlModal.html';
import c3 from 'c3';
import c3styling from 'c3/c3.css';
import c3Tooltip from './c3-tooltip.html';
import moment from 'moment';
import { copyToClipboard } from "../dashboard";
import { alignDatasets, transformDataSet } from './helpers';

export default angular.module('eventix.dashboard.dataAnalysis',[])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.dataAnalysis', {
            url: '/dataAnalysis?config',
            params: {
                config: '',
            },
            views: {
                'dashboard@eventix.dashboard': {
                    component: 'comparePage'
                }
            },
            data: {
                crumb: 'common.menu.tools.dataAnalysis'
            }
        });
    })
    .component('filterTrackerOptionsModal', {
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&'
        },
        controller: FilterOptionsModalController,
        templateUrl: trackerModal
    })
    .component('filterOptionsModal', {
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&'
        },
        controller: FilterOptionsModalController,
        templateUrl: modal
    })
    .component('urlModal', {
        bindings: {
            // resolve: '<',
            close: '&',
            dismiss: '&'
        },
        controller: UrlModalController,
        templateUrl: urlModalTemplate
    })
    .component('comparePage', {
        bindings: { events: '<' },
        controller: DataAnalysisController,
        templateUrl: template
    }).name;

function DataAnalysisController($scope, $state, $http, $q, $uibModal, $filter, $templateCache, $translate, $timeout, $analytics, ElasticLoader, DateRangeHelper, UIMessages, CSVExport, ConfirmDelete, ConfirmDeleteSweet, Company, Role, MetaData, Tracker) {
    const $ctrl = this;
    $ctrl.addDataConfig = addDataConfig;
    $ctrl.addFilter = addFilter;
    $ctrl.getFilterOptions = getFilterOptions;
    $ctrl.copyFilterTemplate = copyFilterTemplate;
    $ctrl.deleteFilter = deleteFilter;
    $ctrl.removeDataConfig = removeDataConfig;
    $ctrl.makeCustomQuery = makeCustomQuery;
    $ctrl.setConfigDateRange = setConfigDateRange;
    $ctrl.getStatistics = getStatistics;
    $ctrl.bookmark = bookmark;
    $ctrl.resetDataConfigs = resetDataConfigs;
    $ctrl.settings = { timeunit: 'week', alignment: 'right', count_type: 'cumulative_count' };
    $ctrl.currency = Company.getMe().currency;
    $ctrl.download = download;
    $ctrl.formOptionsAvailable = [{
        key: 'dateRange',
        label: $translate.instant('models.dataAnalysis.dateRange')
    }, {
        key: 'visitorFilters',
        label: $translate.instant('models.dataAnalysis.visitorFilters')
    }];
    $ctrl.openURLModal = ($event) => {
        if ($event.currentTarget.getAttribute('disabled'))
            return;
        $uibModal.open({
            component: 'urlModal'
        }).result.catch(angular.noop);
    };

    // Prepare a nullObj for padding datapoints at unknown ends
    const nullObj = {
        key: null,
        key_as_string: null,
        doc_count: null,
        count: { value: null },
        cumulative_count: { value: null },
        sales: { value: null },
        cumulative_sales: { value: null }
    };
    const formatCurrency = $filter('formatCurrency', $ctrl.currency, $ctrl.currency);
    const c3TooltipFn = _.template($templateCache.get(c3Tooltip), { imports: { _: _, moment: moment }});

    let metaDataPromise = $q.reject('Not Initialized');

    $ctrl.$onInit = function() {
        $ctrl.dataConfigs = [];
        $ctrl.isAdmin = Role.isAuthorizedAs('Admin');
        $scope.$watch('$ctrl.dataConfigs', notifyUpdates, true);
        $scope.$watch('$ctrl.formOptions.visitorFilters', (n,o) => {
            if(n && !o) {
                _.forEach($ctrl.dataConfigs, config => {
                    if(config.tickets && config.tickets.length)
                        $ctrl.addFilter(config);
                });
            } else if(!n && o) {
                _.forEach($ctrl.dataConfigs, config => {
                    config.filters = [];
                });
            }
        });
        metaDataPromise = MetaData.query().then(m => {
            $ctrl.availableFilters = [{
                id: 'trackers',
                label: $filter('capitalize')($translate.instant('models.models.tracker', {count: 2})),
                type: 'trackers',
                key: null
            }];
            if($ctrl.isAdmin) {
                $ctrl.formOptionsAvailable.push({
                    label: $translate.instant('models.dataAnalysis.giveAll'),
                    key: 'giveAll'
                });
                $ctrl.availableFilters.push({
                    id: 'geo:country_code',
                    label: $translate.instant('models.dataAnalysis.filters.geo_country_code'),
                    level: 'order',
                    type: 'geo',
                    key: 'country_code'
                }, {
                    id: 'geo:locality',
                    label: $translate.instant('models.dataAnalysis.filters.geo_locality'),
                    level: 'order',
                    type: 'geo',
                    key: 'locality'
                }, {
                    id: 'geo:admin_level_1',
                    label: $translate.instant('models.dataAnalysis.filters.geo_admin_level_1'),
                    level: 'order',
                    type: 'geo',
                    key: 'admin_level_1'
                });
            }
            _.map(m, metaData => {
                $ctrl.availableFilters.push({
                    id: 'metadata:' + metaData.guid,
                    label: metaData.translatedName,
                    type: 'metadata',
                    key: metaData.guid
                });
            });
            $ctrl.availableFilters = _.sortBy($ctrl.availableFilters, 'label');
        });

        addDataConfig();
    };

    $ctrl.$postLink = function() {
        $ctrl.busy = true;

        let promises = _.map($ctrl.events, event =>  event.$queryEventDate(true).then(d => [event, d]));

        $q.all(promises).then(eventsWithDates => {
            $ctrl.busy = false;

            let events = _.map(eventsWithDates, eventWithDates => {
                let event = eventWithDates[0];
                let dates = eventWithDates[1];

                if(!_.keys(dates).length)
                    return event;

                event.start = _.minBy(dates, 'start').start;
                event.end = _.maxBy(_.filter(dates, d => !/proxy date/i.test(d.name)), 'end').end;
                event.daterange = DateRangeHelper.format(event.start, event.end);
                return event;
            });

            $ctrl.events = _.orderBy(events, 'start', 'desc');

            $q.all([metaDataPromise]).then(() => {
                if (!_.get($state.params, 'config'))
                    return;

                $ctrl.dataConfigs = [];

                let decoded = atob($state.params.config);
                let parsed = JSON.parse(decoded);

                let ticketPromises = [];
                _.forEach(parsed, (dataForConfig) => {
                    if (dataForConfig && dataForConfig.$settings) {
                        _.assign($ctrl.settings, dataForConfig.$settings);

                        return;
                    }

                    let config = newDataConfig();

                    config.label = dataForConfig.label;
                    config.filters = dataForConfig.filters;
                    config.start = dataForConfig.start;
                    config.end = dataForConfig.end;
                    config.includeFree = dataForConfig.includeFree;
                    config.isOpen = dataForConfig.isOpen;

                    addDataConfig(config);

                    let unresolved = $q.defer();
                    ticketPromises.push(unresolved.promise);

                    waitForDigest((config, dataForConfig) => {
                        config.event = _.find($ctrl.events, (event) => event.guid === dataForConfig.event);

                        waitForDigest((config, dataForConfig) => {
                            config.tickets = _.filter(config.tickets, (configTicket) => {
                                return _.findIndex(dataForConfig.tickets, (ticketId) => ticketId === configTicket.guid) >= 0;
                            });
                            unresolved.resolve();
                        }, config, dataForConfig);
                    }, config, dataForConfig);
                });

                $q.all(ticketPromises).then(() => {
                    // because of reasons
                    waitForDigest(() => getStatistics().then(q => q));
                });
            });
        }, () => {
            $ctrl.busy = false;
        });
    };

    function notifyUpdates() {
        if(!$ctrl.datasets || $ctrl.busy)
            return;
        $ctrl.updated = true;
        $timeout(() => $ctrl.updated = false, 1000);
    }

    function newDataConfig() {
        return {
            label: [
                $filter('capitalize')($translate.instant('models.models.event', {count: 1})),
                ($ctrl.dataConfigs.length + 1)
            ].join(' '),
            event: null,
            tickets: [],
            filters: [],
            start: moment().subtract(1,'year').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD'),
            includeFree: true,
            isOpen: true
        }
    }

    /**
     * Add a new dataset configuration
     */
    function addDataConfig(dataConfig = null) {
        if (!dataConfig)
            dataConfig = newDataConfig();

        $ctrl.dataConfigs.push(dataConfig);
    }

    /**
     * Remove a dataset configuration
     *
     * @param {Object} config The dataset configuration to remove
     */
    function removeDataConfig(config) {
        ConfirmDelete.open(config.label).then(() => {
            _.pull($ctrl.dataConfigs, config);
            _.last($ctrl.dataConfigs).isOpen = true;
        });
    }

    /**
     * Reset all dataset configurations
     */
    function resetDataConfigs() {
        ConfirmDeleteSweet.open('config').then(() => {
            $state.go($state.current.name, { config: undefined });
        });
    }

    /**
     * Set the start/end and label for a dataset configuration according to the selected event
     *
     * @param {Object} config Dataset configuration
     * @param {Event} event The selected event
     */
    function setConfigDateRange(config, event) {
        config.start = moment(event.start, 'YYYY-MM-DDTHH:mm:ssZ').subtract(1, 'year').format('YYYY-MM-DD');
        config.end = event.end.slice(0, 10);
        config.label = event.name.replace(/[^A-Za-z0-9\-\s]+/g,'');
    }

    /**
     * Add a (blank) data filter to a dataset configuration
     *
     * @param {Object} config Dataset configuration
     */
    function addFilter(config) {
        config.filters.push({
            id: null,
            type: null,
            key: null,
            level: 'order',
            values: []
        });
    }

    /**
     * Convert a filter a custom query where user types his/her query manually
     *
     * @param {Object} filter The filter to make custom
     */
    function makeCustomQuery(filter) {
        filter.custom = true;
        filter.query = (filter.values || []).join(' OR ');
        delete filter.values;
    }

    /**
     * Remove a filter from a dataset configuration
     *
     * @param {Object} config Dataset configuration the filter belongs to
     * @param {Object} filter The filter to remove
     */
    function deleteFilter(config, filter) {
        _.pull(config.filters, filter);
    }

    /**
     * Copy the filter configuration from one of the templates
     *
     * @param {Object} filter The filter to pre-configure
     */
    function copyFilterTemplate(filter) {
        let template = _.find($ctrl.availableFilters, { id: filter.id });
        delete filter.query;
        filter.values = [];
        filter.type = template.type;
        filter.key = template.key;
        filter.label = template.label;
    }

    /**
     * Query API for most popular filter values and offer them for selection in a modal
     *
     * @param {Object} config Dataset configuration the filter belongs to
     * @param {Object} filter The filter to remove
     * @return {Promise} Resolves when done
     */
    function getFilterOptions(config, filter) {
        if($ctrl.busy)
            return $q.reject('busy');
        $ctrl.busy = true;
        config.timeunit = $ctrl.settings.timeunit;
        config.giveAll = $ctrl.settings.giveAll;
        return ElasticLoader.getAdvancedFilterOptions(config, filter).then(buckets => {
            // Reselect any previous selected buckets
            _.forEach(filter.values, v => {
                let bucket = _.find(buckets, { key: v });
                if(bucket)
                    bucket.selected = true;
            });
            let resolves = _.set({}, 'buckets', () => buckets);
            if(filter.type === 'trackers')
                _.set(resolves, 'trackers', () => Tracker.query());
            return $uibModal.open({
                component: filter.type === 'trackers' ? 'filterTrackerOptionsModal' : 'filterOptionsModal',
                resolve: resolves
            }).result;
        }).then(buckets => {
            filter.values = _.map(_.filter(buckets, 'selected'), 'key');
            $ctrl.busy = false;
        }, e => $ctrl.busy = false);
    }

    /**
     * Query API for statistics for every dataset
     *
     * @return {Promise} Resolves when done
     */
    function getStatistics() {
        if($ctrl.busy)
            return $q.reject('busy');

        if(_.find($ctrl.dataConfigs, c => _.get(c, 'tickets.length', 0) === 0)){
            UIMessages.push({
                type: 'danger',
                message: 'models.dataAnalysis.notice.selectTickets'
            });
            return $q.reject('incomplete configuration');
        }

        $ctrl.busy = true;

        return $q.all(_.map($ctrl.dataConfigs, (config, iConfig) => {
            config.index = iConfig;
            config.timeunit = $ctrl.settings.timeunit;
            config.giveAll = $ctrl.settings.giveAll;
            config.isOpen = false;

            return ElasticLoader.getAdvancedStatistics(config).then((data) => transformDataSet(config, data));
        }))
            .then(transformedDatasets => {
                const alignedDataSets = alignDatasets(transformedDatasets, $ctrl.settings.alignment);

                if (!_.size(alignedDataSets)) {
                    $ctrl.alignedDataSets = [];
                    $ctrl.tableData = [];
                    $ctrl.chartData = [];
                    $ctrl.datasets = [];

                    return $q.resolve();
                }

                const firstDataset = alignedDataSets[0];
                const nrDatapoints = firstDataset.datapoints.length;

                // Prepare table data
                const timeunitLabel = $translate.instant('common.datetime.' + $ctrl.settings.timeunit, { count: 1 });
                let iOffset;

                switch ($ctrl.settings.alignment) {
                case 'right':
                    iOffset = (-1) * firstDataset.eventStartIndex;
                    break;
                case 'right-end':
                    iOffset = (-1) * firstDataset.eventEndIndex;
                    break;
                case 'left':
                default:
                    iOffset = (-1) * firstDataset.saleStartIndex;
                    break;
                }

                $ctrl.alignedDataSets = alignedDataSets;
                $ctrl.tableData = _.times(nrDatapoints, (i) => ({ diff: '', label: `${timeunitLabel} ${i + iOffset}` }));
                $ctrl.chartData = alignedDataSets.map((dataset, datasetIndex) => {
                    const chartRow = [ dataset.config.label ];

                    switch($ctrl.settings.count_type) {
                        case 'sales':
                            dataset.datapoints.forEach((datapoint, datapointIndex) => {
                                $ctrl.tableData[datapointIndex][datasetIndex] = datapoint.sales;
                                chartRow.push(_.isNull(datapoint.sales) ? null : datapoint.sales / 100);
                            });
                            break;
                        case 'cumulative_sales':
                            dataset.datapoints.forEach((datapoint, datapointIndex) => {
                                $ctrl.tableData[datapointIndex][datasetIndex] = datapoint.cumulative_sales;
                                chartRow.push(_.isNull(datapoint.cumulative_sales) ? null : datapoint.cumulative_sales / 100);
                            });
                            break;
                        case 'count':
                            dataset.datapoints.forEach((datapoint, datapointIndex) => {
                                $ctrl.tableData[datapointIndex][datasetIndex] = datapoint.count;
                                chartRow.push(datapoint.count);
                            });
                            break;
                        default:
                        case 'cumulative_count':
                            dataset.datapoints.forEach((datapoint, datapointIndex) => {
                                $ctrl.tableData[datapointIndex][datasetIndex] = datapoint.cumulative_count;
                                chartRow.push(datapoint.cumulative_count);
                            });
                            break;
                    }

                    return chartRow;
                });
                const xAxis = _.times(nrDatapoints, (i) => `${i + iOffset}`);
                xAxis.unshift('x');
                $ctrl.chartData.unshift(xAxis);
                $ctrl.datasets = alignedDataSets.map((dataset) => {
                    const filterSpec = _.fromPairs(_.map(dataset.config.filters, filter => [filter.label, filter.query || filter.values]));

                    return dataset.datapoints.map((datapoint) => {
                        return Object.assign({}, datapoint, {
                            config: dataset.config,
                            eventStartIndex: dataset.eventStartIndex,
                            eventEndIndex: dataset.eventEndIndex,
                            first: dataset.first,
                            last: dataset.last,
                            eventStart: dataset.eventStart,
                            eventEnd: dataset.eventEnd,
                            alignedOffsets: dataset.alignedOffsets,
                            dataset: dataset.config.label,
                            filters: filterSpec,
                        });
                    });
                });

                if (alignedDataSets.length === 2) {
                    _.forEach($ctrl.tableData, datapoint => {
                        if(!_.isNil(datapoint[0]) && !_.isNil(datapoint[1])) {
                            datapoint.diff = datapoint[1] - datapoint[0];
                        }
                    });
                }
            })
            .then(() => {
                renderGraph();

                $ctrl.busy = false;
            })
            .catch(e => {
                console.error('Error from advancedStatistics', e);

                UIMessages.push({
                    type: 'danger',
                    message: 'common.notice.error',
                });

                $ctrl.busy = false;
            });
    }

    function getGridLines() {
        if (!$ctrl.alignedDataSets || !$ctrl.alignedDataSets.length) {
            return [];
        }

        const gridLines = {};

        $ctrl.alignedDataSets.forEach((dataset, i) => {
            gridLines[dataset.saleStartIndex] = gridLines[dataset.saleStartIndex] || {
                left: [],
                right: [],
                'right-end': [],
            };
            gridLines[dataset.eventStartIndex] = gridLines[dataset.eventStartIndex] || {
                left: [],
                right: [],
                'right-end': [],
            };
            gridLines[dataset.eventEndIndex] = gridLines[dataset.eventEndIndex] || {
                left: [],
                right: [],
                'right-end': [],
            };

            gridLines[dataset.saleStartIndex]['left'].push(dataset.config.index + 1);
            gridLines[dataset.eventStartIndex]['right'].push(dataset.config.index + 1);
            gridLines[dataset.eventEndIndex]['right-end'].push(dataset.config.index + 1);
        })

        const firstDataset = $ctrl.alignedDataSets[0];
        let iOffset;

        switch ($ctrl.settings.alignment) {
        case 'right':
            iOffset = (-1) * firstDataset.eventStartIndex;
            break;
        case 'right-end':
            iOffset = (-1) * firstDataset.eventEndIndex;
            break;
        case 'left':
        default:
            iOffset = (-1) * firstDataset.saleStartIndex;
            break;
        }

        return _.map(gridLines, (values, key) => {
            const parts = [];

            if (values['left'].length) {
                parts.push(`sales start: (${values['left'].join(', ')})`);
            }

            if (values['right'].length) {
                parts.push(`event start: (${values['right'].join(', ')})`);
            }

            if (values['right-end'].length) {
                parts.push(`event end: (${values['right-end'].join(', ')})`);
            }

            return {
                value: parseInt(key) + iOffset,
                text: parts.join(', '),
            };
        });

    }

    /**
     * Render the datasets into a C3 graph
     */
    function renderGraph() {
        if($ctrl.chart)
            $ctrl.chart.destroy();

        $ctrl.chart = c3.generate({
            bindto: '#chart',
            data: { columns: $ctrl.chartData, type: 'line', x: 'x', },
            size: { width: $('#chart').width() * 0.95, height: 350 },
            legend: { show: true },
            tooltip: {
                contents: function(rows, defaultTitleFormat, defaultValueFormat, color) {
                    _.forEach(rows, (row, i) => row.source = $ctrl.datasets[i][row.index]);
                    const x = _.get(rows, '0.x', '');
                    return c3TooltipFn({
                        rows: rows,
                        $$: this,
                        x: x,
                        color: color,
                        formatCurrency: $filter('formatCurrency'),
                        currency: $ctrl.currency,
                    });
                },
            },
            color: { pattern: ['#00BEF6','#0079BF','#00A650','#F46B49','#BB2321'] },
            grid: { x: { lines: getGridLines() } },
        });

    }

    function download() {
        return CSVExport.convert(_.map(_.filter(_.flatten($ctrl.datasets), p => p.timestamp), datapoint => {
            return {
                dataset: datapoint.dataset,
                filters: datapoint.filters,
                timestamp: datapoint.timestamp,
                sales: datapoint.sales / 100,
                cumulative_sales: datapoint.cumulative_sales / 100,
                count: datapoint.count,
                cumulative_count: datapoint.cumulative_count,
            }
        }));
    }

    function bookmark() {
        const duplicateNames = _.keys(_.pickBy(_.countBy($ctrl.dataConfigs, 'label'), cnt => cnt > 1));

        if (_.size(duplicateNames) > 0) {
            UIMessages.push({
                type: 'error',
                message: 'common.compareTool.notice.unique',
                translateValues: {names: duplicateNames.join(', ')}
            });

            return;
        }

        let params = _.cloneDeep($state.params);

        let serializableConfig = _.map($ctrl.dataConfigs, config => {
            return {
                label: config.label.replace(/[^A-Za-z0-9\-\s]+/g,''),
                event: config.event.guid,
                tickets: _.map(config.tickets, ticket => ticket.guid),
                filters: config.filters,
                start: config.start,
                end: config.end,
                includeFree: config.includeFree,
                isOpen: config.isOpen
            }
        });

        serializableConfig.push({
            $settings: $ctrl.settings,
        });

        params.config = btoa(JSON.stringify(serializableConfig))

        $state.go($state.current.name, params);

        $analytics.eventTrack('compared-events', serializableConfig);
        // $state.go($state.current.name, params, { notify: false });
        // $location.search('config', query);
    }

    function waitForDigest(callback = angular.noop, ...args) {
        $timeout(() => {
            if($http.pendingRequests.length > 0) {
                args.unshift(callback);
                waitForDigest.apply(null, args);
            } else {
                callback.apply(null, args);
            }
        });
    }
}

function FilterOptionsModalController() {
    const $ctrl = this;
    $ctrl.$postLink = function() { _.assign($ctrl, $ctrl.resolve); };
    $ctrl.newBucket = {};
    $ctrl.addBucket = function() {
        $ctrl.buckets.push({ key: $ctrl.newBucket.key, doc_count: '?', selected: true });
        $ctrl.newBucket = {};
    };
}

function UrlModalController(UIMessages) {
    const $ctrl = this;
    $ctrl.url = window.location.href;
    $ctrl.copyToClipboard = () => UIMessages.push(copyToClipboard($ctrl.url));
}

// TODO Make analyse button dirty/clean
