import template from './styling.html';
import css from './styling.less';


export default angular.module('eventix.dashboard.shops.styling',['minicolors'])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.shops.styling', {
            url: '/:shop/styling',
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'StylingController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/{
                shop: function($transition$, Shop) {
                    // Prepare document domain for cross-domain frame injection
                    document.domain = location.host.split('.').slice(-2).join('.');
                    return Shop.queryUserShops().then(shops => {
                        return _.find(shops, { guid: $transition$.params().shop});
                    })
                }
            }
        });
    })
    .controller('StylingController', function($state, shop, $sce, $scope, $log, isAdmin, ShopStyler) {
        const vm = this;
        vm.isAdmin = isAdmin;
        vm.shopUrl = $sce.trustAsResourceUrl([FANCY_SHOP_ROOT, '/', shop.guid, '?preview'].join(''));
        vm.shop = shop;

        $scope.$watch('vm.styleSettings', function(settings) {
            if (!settings)
                return;
            vm.shop.css = ShopStyler.cssGenerator(settings);
        }, true);

        vm.quickStart = function() {
            if(!vm.styleSettings.primary || !vm.styleSettings.secondary)
                return;
            vm.styleSettings.css = ShopStyler.cssVariablesGenerator(vm.styleSettings);
        };

        $scope.$watch('vm.shop.css', function(css) {
            vm.styleSettings = ShopStyler.restoreVariables(css);
        });

        vm.save = function() {
            vm.shop.$save();
        };

        $scope.back = function() {
            window.history.back();
        };

    }).name;
