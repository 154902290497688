import template from './profile.html';

export default angular.module('eventix.dashboard.profile', [ ])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.profile', {
            url: '/profile',
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'ProfileController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/{
            }
        });
    })
    .controller('ProfileController', function($state, $http, UIMessages, user, $translate) {

        var vm = this;
        vm.user = user;

        vm.save = function() {
            if (!vm.user.$valid || vm.busy)
                return;
            vm.busy = true;
            vm.user.$save().finally(() => vm.busy = false);
        };

        vm.mailgun = (error, valid, suggestion) => {
            if (!valid)
                _.set(vm, 'errors.email', [$translate.instant('validation.email', { attribute: 'Email' })]);
            if (suggestion)
                vm.errors.email.push($translate.instant('common.notice.didYouMean', { suggestion: suggestion }));
        };

        vm.passwordChange = function() {

            if (vm.busy) {
                return;
            }

            vm.busy = true;

            $http.post(AUTH_API_ROOT + '/password/reset/password', {
                old_password: vm.oldPassword,
                new_password: vm.newPassword,
                new_password_confirmation: vm.newPassword
            }).then(() => {

                UIMessages.push('common.notice.saved');
                vm.busy = false;
                vm.oldPassword = '';
                vm.newPassword = '';

            }, error => {

                vm.busy = false;
                let resp = error.data;

                if(resp.status_code == 406){
                    UIMessages.push("Invalid credentials");
                }
                else {
                    if (_.get(error, 'data.error_description.length')) {
                        UIMessages.push(error.data.error_description);
                    } else {
                        UIMessages.push('common.notice.unknownError');
                    }
                }

            });

        };
    }).name;
