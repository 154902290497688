import templateUrl from './financialHeader.html';
import './financialHeader.less';

export default angular.module('eventix.dashboard.financials.reporting.financialHeader', [])
    .component('financialHeader', {
        controller: FinancialHeaderController,
        templateUrl: templateUrl
    }).name;

function FinancialHeaderController(Role, $state, $q, $http, $timeout, UIMessages, $attrs) {
    const $ctrl = this;
    const urlBase = '/finance';

    $ctrl.isAdmin = Role.isAuthorizedAs('Admin');
    $ctrl.busy = 0;

    $ctrl.last_updated_at = null;
    $ctrl.last_cleared_at = null;
    $ctrl.receivables = {};
    $ctrl.turnover = {};
    $ctrl.currencies = [];
    $ctrl.totals = {};
    $ctrl.target = _.get($attrs, 'target');

    $ctrl.updateData = updateData;

    $ctrl.$onInit = function() {
        loadData();
    };

    /*
     * Data methods
     */

    function loadData() {
        if ($ctrl.busy)
            return errorResponse('System busy, please try again later');

        busy();

        let promises = [];

        promises.push($http.get(urlBase + '/sum/receivable' + ($state.params.as ? ('/' + $state.params.as) : ''))
            .then(response => _.tap(response.data, data => $ctrl.receivables = _.get(data, 'sums', {})))
            .catch(errorResponse)
            .then($ctrl.updateData));

        promises.push($http.get(urlBase + '/sum/turnover' + ($state.params.as ? ('/' + $state.params.as) : ''))
            .then(response => _.tap(response.data, data => $ctrl.turnover = _.get(data, 'sums', {})))
            .catch(errorResponse)
            .then($ctrl.updateData));

        return $q.all(promises).finally(done);
    }

    function updateData(data) {
        $ctrl.totals = {};

        $ctrl.last_updated_at = _.max([$ctrl.last_updated_at, _.get(data, 'last_updated_at')]);
        $ctrl.last_cleared_at = _.max([$ctrl.last_cleared_at, _.get(data, 'last_cleared_at')]);

        $ctrl.currencies = _.sortBy(_.uniqBy(_.merge(_.keys($ctrl.receivables), _.keys($ctrl.turnover))));

        _.forEach($ctrl.currencies, function(currency) {
            let creditOpen = _.get($ctrl.turnover, [currency, 'credit', 'open'], 0);
            let creditClosed = _.get($ctrl.turnover, [currency, 'credit', 'closed'], 0);
            let debitOpen = _.get($ctrl.turnover, [currency, 'debit', 'open'], 0);
            let debitClosed = _.get($ctrl.turnover, [currency, 'debit', 'closed'], 0);
            let receivableOpen = _.get($ctrl.receivables, [currency, 'open'], 0);
            let receivableClosed = _.get($ctrl.receivables, [currency, 'closed'], 0);

            _.set($ctrl.totals, currency, {
                turnover: {
                    credit: {
                        open: creditOpen,
                        closed: creditClosed,
                        total: creditOpen + creditClosed
                    },
                    debit: {
                        open: debitOpen,
                        closed: debitClosed,
                        total: debitOpen + debitClosed
                    }
                },
                receivables: {
                    open: receivableOpen,
                    closed: receivableClosed,
                    total: receivableOpen + receivableClosed
                }
            });
        });
    }

    /*
     * Utility methods
     */

    function busy() {
        $ctrl.busy++;
    }

    function done() {
        $timeout(() => $ctrl.busy--, 500);
    }

    function errorResponse(error) {
        let message = 'Something went wrong, please try again later.';

        UIMessages.push(message);
        console.error(message, error);

        return $q.reject(error);
    }

}
