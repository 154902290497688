import template from './coupons.html';
import Codes from './codes/codes.js';
import './coupons.less';
import { copyToClipboard } from '../dashboard';

export default angular.module('eventix.dashboard.coupons', [Codes])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.coupons', {
            url: '/coupons',
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'CouponsController as vm',
                    templateUrl: template,
                },
            },
            data: {
                requiredRoles: ['Event Manager'],
            },
        });
    })
    .controller('CouponsController', function($state, $q, $http, $translate, $scope, CrudCtrl, ErrorRejector, Role, Coupon, Company) {
        const vm = this;

        vm.isAdmin = Role.isAuthorizedAs('Admin');
        vm.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');

        vm.pickProducts = pickProducts;
        vm.getCoupons = getCoupons;
        vm.copyToClipboard = copyToClipboard;

        vm.currency = Company.getMe().currency;
        vm.statii = {
            enabled: $translate.instant('models.coupon.statii.enabled'),
            disabled: $translate.instant('models.coupon.statii.disabled'),
        };
        vm.types = {
            'fixed-discount': $translate.instant('models.coupon.types.fixed-discount'),
            'percentage-discount': $translate.instant('models.coupon.types.percentage-discount'),
            'new-price': $translate.instant('models.coupon.types.new-price'),
        };
        if (vm.isAdmin) {
            vm.types.cheque = $translate.instant('models.coupon.types.cheque');
        }
        vm.totalRows = 0;

        vm.crud = CrudCtrl.new([], () => Coupon.new({amount: 1, type: 'fixed-discount'}), {determineInitState: false});
        vm.crud.addBelongsToMany('products', 'Product');
        vm.crud.afterSave(function(error, coupon, isNew) {
            if (!error && isNew) {
                vm.crud.options.persistState = false;
                coupon.belongsToMany.clear();
                $state.go('eventix.dashboard.coupons.codes', {couponGuid: coupon.guid, newCodes: true});
            }
        });

        vm.guids = {
            coupon: {
                guid: vm.crud && vm.crud.model ? vm.crud.model.guid : '',
                icon: 'fa-gift',
                name: 'models.models.coupon',
            },
        };

        $scope.$watch('vm.crud.model.guid', function(guid) {
            vm.guids.coupon.guid = guid || '';
            vm.products = _.filter(vm.crud.model.products, (product) => product.origin_type === 'product');
            vm.tickets = _.filter(vm.crud.model.products, (product) => product.origin_type === 'ticket');
        });
        vm.guids.coupon.guid = vm.crud && vm.crud.model ? vm.crud.model.guid : '';

        function getCoupons(offset, limit, sorting, filters) {
            let query = {
                offset,
                limit,
            };

            _.each(sorting, (direction, attribute) => {
                _.set(query, `sorting_${attribute}`, direction);
            });

            _.each(filters, (value, attribute) => {
                _.set(query, `filter_${attribute}`, value);
            });

            return $http.get('/coupon/normal', {params: query})
                .then(response => {
                    vm.totalRows = response.data.total;

                    return _.map(response.data.data, coupon => Coupon.new(coupon));
                })
                .then(coupons => {
                    vm.crud.models = coupons;

                    return coupons;
                })
                .catch(ErrorRejector.handle);
        }

        function pickProducts(picked, originType = 'product') {
            // Hacking in the origin type as it is not provided by default for ticket entities.
            for (const key in picked) {
                picked[key]['origin_type'] = originType;
            }

            let products = [];

            if (originType === 'product') {
                vm.products = picked;
                products = picked.concat(vm.tickets)
            }

            if (originType === 'ticket') {
                vm.tickets = picked;
                products = picked.concat(vm.products)
            }

            if (vm.crud.showNew()) {
                return $q.resolve(vm.crud.model.products = products);
            }

            if (!vm.crud.showEdit()) {
                return ErrorRejector.handle('Can not pick products, not editing a coupon campaign.');
            }

            let queue = _.reduce(vm.crud.model.products, (queue, product) => {
                return _.findIndex(products, {guid: product.guid}) >= 0 ? queue : queue.then(() => vm.crud.detachProduct(product));
            }, $q.resolve());

            return _.reduce(products, (queue, product) => queue.then(() => vm.crud.attachProduct(product)), queue);
        }
    }).name;

