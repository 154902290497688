import simpleEventForm from './simpleEventForm/simpleEventForm';
import advancedEventForm from './advancedEventForm/advancedEventForm';
import scannerForm from './scannerForm/scannerForm';
import simpleTicketForm from './simpleTicketForm/simpleTicketForm';
import advancedTicketForm from './advancedTicketForm/advancedTicketForm';

export default angular.module('eventix.dashboard.wizard.common.forms', [
    simpleEventForm,
    advancedEventForm,
    scannerForm,
    simpleTicketForm,
    advancedTicketForm
])
/**
 * @ngdoc service
 * @name eventix.common.services.FormInModal
 * @kind function
 * @description
 * Service to load a component inside a modal, complete with resolves.
 *
 * @param {Object} options An object with the following properties
 * ```
 * component: {String} Name of the component. Controller can request a `modal` injection to manipulate
 *     `resolve` & `reject` behaviour or adjust translation strings dynamically
 * [bindings]: {Object}  Dependencies that are to be bound when they resolve.
 *     It's an object that mimicks uiRouter's resolve paradigm `{name: fn}`
 *
 *     If you add a `formOptionsAvailable` object which can be fed into `<more-options>`, such an element will be
 *     added to the modal header and `form-options` will be bound to your component
 * [translations]: {Object} Custom (ngTranslate) strings for `title`, `cancel` and `close` button
 * [size='lg']: {String} Size of modal
 * ```
 * @returns {Promise} Resolves when the modal is closed
 */
    .factory('FormInModal', function($uibModal, $compile, $q, generateRandomString) {
        return { open: open };
        function open(options = {}) {
            options = _.defaults(options, {
                size: 'lg',
                translations: {},
                bindings: {},
                beforeClose: angular.noop,
                beforeDismiss: angular.noop
            });
            let tagName = _.kebabCase(options.component);
            if(!tagName || !tagName.length)
                return $q.reject('Invalid component');
            let attrs = _.keys(options.bindings).map(k => `${_.kebabCase(k)}="locals.${k}"`).join(' ');
            attrs = attrs.replace(
                'form-options-available="locals.formOptionsAvailable"',
                'form-options="locals.formOptions"'
            );
            let el = `<${ tagName } ${ attrs }></${ tagName }>`;
            let modal = $uibModal.open({
                controller: /*@ngInject*/function($scope, locals, translations) {
                    locals.modal = $scope.modal = {
                        resolve: () => $q.resolve(options.beforeClose()).then(() => $scope.$close()),
                        reject: () => $q.resolve(options.beforeDismiss()).then(() => $scope.$dismiss()),
                        translations: translations
                    };
                    $scope.locals = locals;
                    $scope.translations = _.defaults(translations, { close: 'common.action.close' });
                },
                size: options.size,
                resolve: {
                    translations: () => options.translations,
                    locals: function() {
                        var promises = _.map(options.bindings, (value,key) => {
                            return $q.resolve(value()).then(resolved => [key, resolved]);
                        });

                        return $q.all(promises).then( resolved => {
                            return $q.resolve( _.fromPairs(resolved) );
                        });
                    }
                },
                template: `<div class="modal-content">
    <div class="modal-header" ng-if="translations.title">
        <h3 class="modal-title">
            {{ translations.title | translate }}
            <more-options ng-if="locals.formOptionsAvailable"
                        class="pull-right"
                        name="${options.component}"
                        tx-options="option.key as option.label with icon option.icon for option in locals.formOptionsAvailable"
                        ng-model="locals.formOptions"></more-options>
        </h3>
    </div>
    <div class="modal-body">${ el }</div>
    <div class="modal-footer">
        <button class="btn btn-default" ng-click="modal.reject()" translate="{{translations.cancel}}" ng-if="translations.cancel"></button>
        <button class="btn btn-info" ng-click="modal.resolve()"><translate>{{translations.close}}</translate></button>
    </div>
    </div>`
            });

            return modal.result;
        }
    }).name;
