import templateUrl from './acceptTerm.html';

export default angular.module('eventix.dashboard.events.refundSettings.acceptTerm', [])
    .component('acceptTerm', {
        controller: AcceptTermController,
        templateUrl: templateUrl,
        bindings: {
            accepted: '=',
            showEnabled: '<', // bool whether to show enabled or not
            label: '@',
            translationValues: '<',
        },
    })
    .name;

function AcceptTermController() {
    const $ctrl = this;

    $ctrl.branding = {
        shop_brand_name: SHOP_BRAND_NAME || 'Eventix',
        show_help_url: SHOP_BRAND_NAME === 'Eventix',
    };

    $ctrl.translationValues = {
        whitelabel: $ctrl.branding.shop_brand_name,
    };

    $ctrl.$onInit = function() {
        $ctrl.translationValues = _.defaults({}, $ctrl.translationValues, {
            whitelabel: $ctrl.branding.shop_brand_name,
        });

        console.log($ctrl.showEnabled);
        console.log($ctrl.label);
        if($ctrl.showEnabled){
            $ctrl.accepted = true;
        }
    };
}
