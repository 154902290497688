export default angular.module('eventix.dashboard.wizard.simple.shops', [])
    .config(ModuleConfig)
    .name;

function ModuleConfig($stateProvider) {
    $stateProvider.state('eventix.dashboard.wizard.simple.shops', {
        url: '/shops',
        component: 'wizardShops',
        bindings: {
            event: 'currentEvent',
            company: 'company'
        }
    });
    $stateProvider.state('eventix.dashboard.wizard.simple.shops.collapses', {
        url: '/:shopGuid/collapses',
        views: {
            '@eventix.dashboard.wizard': {
                component: 'shopCollapses',
                bindings: {
                    event: 'currentEvent',
                    shop: 'shop'
                }
            }
        },
        resolve: {
            shop: function($transition$, Shop) {
                let params = $transition$.params();
                return Shop.get({ guid: params.shopGuid });
            }
        }
    });
}
