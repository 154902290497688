import templateUrl from './promote-form.html'
import promotePlatform from './promote-platform/promote-platform'
require('./promote-form.less')

/**
 * The promote form component
 * @author Stan Hurks, Eventix
 */
export default angular.module('eventix.dashboard.wizard.common.shop.promoteForm', [promotePlatform])
    .component('promoteForm', {
        controller: PromoteFormController,
        templateUrl,
        bindings: {
            shop: '<',
            event: '<',
            forms: '<'
        }
    }).name

function PromoteFormController ($http, $scope, UIMessages) {
    const $ctrl = this

    $ctrl.data = {
        // All platforms
        promotePlatforms: [],
    }

    /**
     * Initialize the controller
     */
    $ctrl.$onInit = () => {
        $ctrl.updateData()
        $ctrl.initializeWatches()
    }

    /**
     * Update all data
     * @param {Void} callback the callback on success
     */
    $ctrl.updateData = (callback) => {
        $ctrl.updateCalendars(callback)
    }

    /**
     * Update the list of calendars
     * @param {Void} callback callback on success
     */
    $ctrl.updateCalendars = (callback) => {
        if (!$ctrl.shop || !$ctrl.event) {
            return
        }
        $http.get('/event/' + $ctrl.event.guid + '/calendars/' + $ctrl.shop.guid).then((response) => {
            $ctrl.data.promotePlatforms = response.data.map(
                platform =>
                Object.assign(platform, {
                    promoted: platform.shop_id !== null && platform.event_id !== null
                })
            )

            if (callback) {
                callback()
            }
        }, (e) => {
            UIMessages.push({
                message: 'models.shop.promote.notice.getFailed',
                type: 'danger'
            })
        })
    }

    /**
     * Initializes all the watches
     */
    $ctrl.initializeWatches = () => {
        $scope.$watch('$ctrl.shop', (value) => {
            if (value && $ctrl.event) {
                $ctrl.updateData()
            }
        }, true)
        $scope.$watch('$ctrl.event', (value) => {
            if (value && $ctrl.shop) {
                $ctrl.updateData()
            }
        }, true)
    }
}