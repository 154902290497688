import template from './demographicsTool.html';
import './demographicsTool.less';
import moment from 'moment';

export default angular.module('eventix.dashboard.demographicsTool',[])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.demographicsTool', {
            url: '/demographics',
            views: {
                'dashboard@eventix.dashboard': {
                    component: 'demographicsToolPage'
                }
            },
            data: {
                crumb: 'common.menu.tools.demographicsTool'
            }
        });
    })
    .component('demographicsToolPage', {
        bindings: { events: '<' },
        controller: DemographicsToolController,
        templateUrl: template
    }).name;

function DemographicsToolController($scope, $analytics, $q, $uibModal, $translate, $filter, $timeout, ElasticLoader, UIMessages, store, Role, MetaData, Tracker) {
    // CONTROLLER
    const $ctrl = this;

    // CONSTANTS
    const LOCAL_STORAGE_OUTPUT_SELECTION_KEY = 'demographicsToolOutputSelection';
    const STD_OUTPUT_SELECTION = [
        'metadata:3e4aa5b0-a1b8-11e6-b2b5-735d381b6ca7', // City
        'metadata:3f7b72f0-a47f-11e6-a52d-21d369c3d816', // Gender
        'metadata:46cc9ac0-a1b8-11e6-830b-a1fc45b39e5b', // State
        'metadata:59032dd0-a1b8-11e6-ac9f-cd96651967ca' // Age
    ];

    // ACTIONS
    // $ctrl.download = (section) => downloadData(section);
    $ctrl.addDataConfig = addDataConfig;
    $ctrl.setConfigDateRange = setConfigDateRange;
    $ctrl.addFilter = addFilter;
    $ctrl.deleteFilter = deleteFilter;
    $ctrl.getFilterOptions = getFilterOptions;
    $ctrl.makeCustomQuery = makeCustomQuery;
    $ctrl.removeDataConfig = removeDataConfig;
    $ctrl.openFirstDataConfig = openFirstDataConfig;
    $ctrl.openLastDataConfig = openLastDataConfig;
    $ctrl.getStatistics = getStatistics;
    $ctrl.copyFilterTemplate = copyFilterTemplate;
    $ctrl.hasData = () => {return _.size($ctrl.data) > 0;};
    $ctrl.pickTickets = pickTickets;

    // FLAGS
    $ctrl.dataLoading = {
        scanning: true
    };

    // DATA CONTAINERS
    $ctrl.settings = { timeunit: 'week' };
    $ctrl.dataConfigs = [];
    $ctrl.outputSelection = [];
    $ctrl.availableFilters = {};
    $ctrl.dataRaw = {};
    $ctrl.data = {};
    $ctrl.tableData = {};

    // ITERATORS
    let dataConfigIterator = 0;

    //
    // INITIALIZATION
    //

    $ctrl.$onInit = function() {
        $ctrl.isAdmin = Role.isAuthorizedAs('Admin');

        resetData();

        getAvailableFilters().then(() => {
            getOutputSelectionFromStorage();

            if (_.size($ctrl.outputSelection) < 1)
                $ctrl.outputSelection = _.clone(STD_OUTPUT_SELECTION);

            watchOutputSelection();
        });

        openFirstDataConfig();
    };

    //
    // HELPERS
    //

    // TODO

    //
    // DATA CONFIG METHODS
    //

    function getAvailableFilters() {
        $ctrl.availableFilters = {};

        return MetaData.query().then(m => {
            $ctrl.availableFilters['tracker:trackers'] = {
                id: 'tracker:trackers',
                label: $filter('capitalize')($translate.instant('models.models.tracker', {count: 2})),
                type: 'trackers',
                key: null
            };
            // GEO LOCATION DOES NOT WORK AT THE MOMENT, NOT GOING TO BE FIXED SOON.
            // if($ctrl.isAdmin) {
            //     $ctrl.availableFilters['geo:country_code'] = {
            //         id: 'geo:country_code',
            //         label: $translate.instant('models.dataAnalysis.filters.geo_country_code'),
            //         level: 'order',
            //         type: 'geo',
            //         key: 'country_code'
            //     };
            //     $ctrl.availableFilters['geo:locality'] = {
            //         id: 'geo:locality',
            //         label: $translate.instant('models.dataAnalysis.filters.geo_locality'),
            //         level: 'order',
            //         type: 'geo',
            //         key: 'locality'
            //     };
            //     $ctrl.availableFilters['geo:admin_level_1'] = {
            //         id: 'geo:admin_level_1',
            //         label: $translate.instant('models.dataAnalysis.filters.geo_admin_level_1'),
            //         level: 'order',
            //         type: 'geo',
            //         key: 'admin_level_1'
            //     };
            // }
            _.each(m, metaData => {
                $ctrl.availableFilters['metadata:' + metaData.guid] = {
                    id: 'metadata:' + metaData.guid,
                    label: metaData.translatedName,
                    type: 'metadata',
                    key: metaData.guid
                };
            });
        });
    }

    function addDataConfig() {
        _.each($ctrl.dataConfigs, (config) => config.isOpen = false);

        dataConfigIterator++;

        let config = {
            id: dataConfigIterator,
            label: $translate.instant('models.demographicsTool.dataset') + ' ' + dataConfigIterator,
            event: null,
            tickets: [],
            filters: [],
            start: moment().subtract(1,'year').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD'),
            isOpen: true,
            includeFree: true
        };

        $ctrl.dataConfigs.push(config);
    }

    function removeDataConfig(config) {
        _.pull($ctrl.dataConfigs, config);

        openLastDataConfig();
    }

    function openFirstDataConfig() {
        if ($ctrl.dataConfigs.length < 1)
            addDataConfig();

        _.each($ctrl.dataConfigs, (config) => config.isOpen = false);

        _.first($ctrl.dataConfigs).isOpen = true;
    }

    function openLastDataConfig() {
        if ($ctrl.dataConfigs.length < 1)
            addDataConfig();

        _.each($ctrl.dataConfigs, (config) => config.isOpen = false);

        _.last($ctrl.dataConfigs).isOpen = true;
    }

    /**
     * Set the start/end and label for a dataset configuration according to the selected event
     *
     * @param {Object} config Dataset configuration
     * @param {Event} event The selected event
     */
    function setConfigDateRange(config, event) {
        config.start = moment(event.start, 'YYYY-MM-DDTHH:mm:ssZ').subtract(1, 'year').format('YYYY-MM-DD');
        config.end = event.start.slice(0,10);
    }

    /**
     * Add a (blank) data filter to a dataset configuration
     *
     * @param {Object} config Dataset configuration
     */
    function addFilter(config) {
        config.filters.push({
            id: null,
            type: null,
            key: null,
            level: 'order',
            values: []
        });
    }

    /**
     * Convert a filter a custom query where user types his/her query manually
     *
     * @param {Object} filter The filter to make custom
     */
    function makeCustomQuery(filter) {
        filter.custom = true;
        filter.query = (filter.values || []).join(' OR ');
        delete filter.values;
    }

    /**
     * Remove a filter from a dataset configuration
     *
     * @param {Object} config Dataset configuration the filter belongs to
     * @param {Object} filter The filter to remove
     */
    function deleteFilter(config, filter) {
        _.pull(config.filters, filter);
    }

    /**
     * Copy the filter configuration from one of the templates
     *
     * @param {Object} filter The filter to pre-configure
     */
    function copyFilterTemplate(filter) {
        let template = _.get($ctrl.availableFilters, filter.id );
        delete filter.query;
        filter.values = [];
        filter.type = template.type;
        filter.key = template.key;
        filter.label = template.label;
    }

    /**
     * Query API for most popular filter values and offer them for selection in a modal
     *
     * @param {Object} config Dataset configuration the filter belongs to
     * @param {Object} filter The filter to remove
     * @return {Promise} Resolves when done
     */
    function getFilterOptions(config, filter) {
        if($ctrl.busy)
            return $q.reject('busy');

        $ctrl.busy = true;

        config.timeunit = $ctrl.settings.timeunit;
        config.giveAll = $ctrl.settings.giveAll;

        return ElasticLoader.getAdvancedFilterOptions(config, filter).then(buckets => {
            // Reselect any previous selected buckets
            _.forEach(filter.values, v => {
                let bucket = _.find(buckets, { key: v });

                if(bucket)
                    bucket.selected = true;
            });

            let resolves = _.set({}, 'buckets', () => buckets);

            if(filter.type === 'trackers')
                _.set(resolves, 'trackers', () => Tracker.query());

            return $uibModal.open({
                component: filter.type === 'trackers' ? 'filterTrackerOptionsModal' : 'filterOptionsModal',
                resolve: resolves
            }).result;
        }).then(buckets => {
            filter.values = _.map(_.filter(buckets, 'selected'), 'key');

            $ctrl.busy = false;
        }, () => $ctrl.busy = false);
    }

    function pickTickets(config, event, tickets) {
        let eventChanged = !config.event || (config.event.guid !== event.guid);

        config.event = event;
        config.tickets = tickets;

        if (eventChanged)
            $ctrl.setConfigDateRange(config, event);
    }

    //
    // DATA METHODS
    //

    /**
     * Empty the data containers
     */
    function resetData() {
        $ctrl.dataRaw = {};
        $ctrl.data = {};
        $ctrl.tableData = {};
    }

    /**
     * Query API for statistics for every dataset
     *
     * @return {Promise} Resolves when done
     */
    function getStatistics() {
        if($ctrl.busy)
            return $q.reject('busy');

        let config = _.find($ctrl.dataConfigs, config => _.get(config, 'tickets.length', 0) === 0);

        if(config){
            config.isOpen = true;

            UIMessages.push({
                type: 'danger',
                message: 'models.demographicsTool.notice.selectTickets'
            });

            return $q.reject('incomplete configuration');
        }

        $ctrl.busy = true;

        resetData();

        $analytics.eventTrack('compared-demographics', $ctrl.dataConfigs);

        let promises = _.map($ctrl.dataConfigs, config => {
            config.timeunit = $ctrl.settings.timeunit;
            config.giveAll = $ctrl.settings.giveAll;
            config.isOpen = false;
            config.aggregate_metadata_for = [];
            config.aggregate_trackers = false;
            config.aggregate_geo = false;

            _.each($ctrl.outputSelection, (section) => {
                if (section.indexOf('geo:') === 0)
                    config.aggregate_geo = true;

                if (section.indexOf('tracker:') === 0)
                    config.aggregate_trackers = true;

                if (section.indexOf('metadata:') === 0)
                    config.aggregate_metadata_for.push(section.slice(9));
            });
            return ElasticLoader.getAdvancedStatistics(config, true).then((data) => {
                return {
                    config: config,
                    data: data
                };
            });
        });

        return $q.all(promises).then(data => {
            $ctrl.dataRaw = data;

            notifyUpdate();

            $ctrl.busy = false;
        }).catch( e => {
            console.error('AdvancedStatistics', e);

            UIMessages.push({
                type: 'danger',
                message: 'common.notice.error'
            });

            $ctrl.busy = false;
        });
    }

    function notifyUpdate() {
        $ctrl.tableData = {};

        _.each($ctrl.dataRaw, (data) => {
            let outputConfig = _.find($ctrl.dataConfigs, {id: data.config.id});
            let eventId = _.get(outputConfig, 'event.guid');
            let eventTicketTotal = _.get(data.data, ['stats', eventId, 'total_ticket_count', 'value']);

            if (outputConfig === undefined) {
                UIMessages.push('Got unexpected output back');
                console.warn('Got unexpected output back');

                return;
            }

            _.each($ctrl.outputSelection, (outputId) => {
                if (outputId.indexOf(':') === -1) {
                    UIMessages.push('Malformed outputId');
                    console.error('Malformed outputId', outputId);

                    return;
                }

                let filter = _.get($ctrl.availableFilters, outputId, null);

                if (filter === null) {
                    UIMessages.push('Unknown output ID');
                    console.warn('Unknown output ID', outputId);

                    return;
                }

                if (!$ctrl.data.hasOwnProperty(outputId))
                    $ctrl.data[outputId] = {};

                let valueId = outputId.split(':').slice(1).join(':');

                let values = [];
                let thisBuckets = [];

                switch (filter.type) {
                    case 'geo':
                        values = _.chain(data.data).get('geo_orders', {}).get(valueId, {});
                        break;
                    case 'trackers':
                        values = _.chain(data.data).get('trackers', {}).get(valueId, {}).get('trackers', {});
                        break;
                    case 'metadata':
                        thisBuckets = _.chain(data.data).get('orderMetaData', {});
                        values = thisBuckets.get(valueId, {}).get('statistics', {});
                        break;
                    default:
                        UIMessages.push('Unknown output type');
                        console.warn('Unknown output type');
                        break;
                }

                values = values.get('buckets', []).value();

                let foundTotal = 0;
                _.forEach(values, value => {
                    let key = _.get(value, 'key');
                    if (!$ctrl.data[outputId].hasOwnProperty(key))
                        $ctrl.data[outputId][key] = {key: key, total: 0};

                    let valueTotal = _.get(value, 'number_of_tickets.number_of_tickets.doc_count', 0);
                    $ctrl.data[outputId][key][data.config.id] = valueTotal;
                    $ctrl.data[outputId][key].total += valueTotal;
                    foundTotal += valueTotal;
                });


                // We want to show 'Others (not in size set) and tickets without data
                let totalTicketsWithData = 0;
                let missingCount = 0;
                if(filter.type  == 'metadata'){
                    totalTicketsWithData = thisBuckets.get(valueId + '-total.number_of_tickets.number_of_tickets.doc_count', 0).value();
                }

                if(totalTicketsWithData !== eventTicketTotal && (eventTicketTotal) > 0 && totalTicketsWithData > 0) {
                    missingCount = eventTicketTotal - totalTicketsWithData;
                    if(missingCount > 0 ) {
                        let key = 'Tickets without data';

                        if (!$ctrl.data[outputId].hasOwnProperty(key))
                            $ctrl.data[outputId][key] = {key: key, total: 0};

                        $ctrl.data[outputId][key][data.config.id] = missingCount;
                        $ctrl.data[outputId][key].total += missingCount;
                    }
                }

                if(foundTotal !== totalTicketsWithData && (totalTicketsWithData - foundTotal) > 0 && totalTicketsWithData > 0) {
                    let otherCount = totalTicketsWithData - foundTotal;
                    if(otherCount > 0 ) {
                        let key = 'Other';
                        if (!$ctrl.data[outputId].hasOwnProperty(key))
                            $ctrl.data[outputId][key] = {key: key, total: 0};

                        $ctrl.data[outputId][key][data.config.id] = otherCount;
                        $ctrl.data[outputId][key].total += otherCount;
                    }
                }
            });
        });

        updateTables();

        $ctrl.updated = true;
        $timeout(() => $ctrl.updated = false, 1000);
    }

    function updateTables() {
        $ctrl.tableData = {};

        _.forEach($ctrl.outputSelection, output => {
            let rows = _.orderBy(_.values(_.get($ctrl.data, output, {})), 'total', 'desc');

            let totals = {};

            _.forEach($ctrl.dataConfigs, config => {
                totals[config.id] = _.sumBy(rows, config.id);
            });

            $ctrl.tableData[output] = {
                rows: _.orderBy(_.values(_.get($ctrl.data, output, {})), 'total', 'desc'),
                totals: totals
            };
        });
    }

    //
    // STORAGE
    //

    function watchOutputSelection() {
        $scope.$watch('$ctrl.outputSelection', () => {
            saveOutputSelectionToStorage();

            let newTableData = {};

            _.each($ctrl.outputSelection, (output) => {
                if ($ctrl.tableData.hasOwnProperty(output)) {
                    newTableData[output] = $ctrl.tableData[output];

                    return;
                }

                newTableData[output] = [];
            });

            $ctrl.tableData = newTableData;
        }, true);
    }

    /**
     * Retrieve all output from storage
     */
    function getOutputSelectionFromStorage() {
        let storageOutputSelection = store.get(LOCAL_STORAGE_OUTPUT_SELECTION_KEY);

        $ctrl.outputSelection = [];

        if (_.isNil(storageOutputSelection))
            return;

        _.each(storageOutputSelection, (filter) => {
            if ($ctrl.availableFilters.hasOwnProperty(filter))
                $ctrl.outputSelection.push(filter);
        });
    }

    /**
     * Save the output to storage for this event
     */
    function saveOutputSelectionToStorage() {
        store.set(LOCAL_STORAGE_OUTPUT_SELECTION_KEY, $ctrl.outputSelection);
    }
}
