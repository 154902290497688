export default angular.module('eventix.dashboard.wizard.common.eventCloner', [])
    .factory('EventCloner', EventClonerFactory).name;

function EventClonerFactory($q, $translate, generateRandomString, ErrorRejector, DateRangeHelper, AccessProduct, Product, Ticket, Event, EventDate, ProductGroup, SimpleTicket) {
    const ISO8601 = 'YYYY-MM-DDTHH:mm:ssZ';

    function cloneModel(model, omit = [], attributes = {}) {
        attributes = _.omit(_.assign({}, model, attributes), omit.concat(['guid', 'created_at', 'updated_at', 'deleted_at']));

        return model.constructor.new(attributes);
    }

    function required(name) {
        throw new Error(`Parameter ${name} is required`);
    }

    class EventCloner {
        constructor({event = required('event'), name = required('name'), offset = required('offset')}) {
            this.name = name;
            this.offset = offset;

            this.original = {
                event: event,
                eventDates: new Map(),
                tickets: new Map(),
                products: new Map(),
                productGroups: new Map(),
                scannerTypes: new Map(),

                // Relations
                ticketProducts: new Map(),
                productMetaData: new Map(),
                scannerTypeProducts: new Map(),

                // For products from other events -_-, this should be simple ffs!
                otherProducts: new Map(),
                otherTicketProducts: new Map(),
            };

            this.clone = {
                event: null,
                location: null,
                eventDates: new Map(),
                tickets: new Map(),
                products: new Map(),
                productGroups: new Map(),
                scannerTypes: new Map(),

                // Relations
                ticketProducts: new Map(),
                productMetaData: new Map(),
                scannerTypeProducts: new Map(),
            };
        }

        /**
         * Start clone process
         * @return {Promise<Object>} Resolves when done with the cloned models
         */
        start() {
            return $q.resolve()
                .then(this._loadEvent.bind(this))
                .then(this._cloneEvent.bind(this))
                .then(() => this.clone)
                .catch(ErrorRejector.handle);
        }

        /**
         * Save the cloned models and relatinos
         * @return {Promise<Event>} Resolves when done with the cloned event
         */
        save() {
            return $q.resolve()
                .then(this._syncEvent.bind(this))
                .then(this._validate.bind(this))
                .then(this._saveLocation.bind(this))
                .then(this._saveEvent.bind(this))
                .then(() => this.clone.event)
                .catch(ErrorRejector.handle);
        }

        // ---------------------------------------------
        // ------------------- _load -------------------
        // ---------------------------------------------

        _loadEvent() {
            return $q.resolve()
                .then(() => $q.all([
                    this._loadEventDates(),
                    this._loadEventTickets(),
                    this._loadEventProducts(),
                    this._loadScannerTypes()
                ]))
                .then(() => $q.all([
                    this._loadTicketProducts(),
                    // this._loadProductEventDates(),
                    this._loadProductMetaData(),
                    this._loadProductGroups(),
                    this._loadScannerTypeProducts()
                ]))
                .then(() => this.original);
        }

        _loadEventDates() {
            return this.original.event.$queryEventDate()
                .then(eventDates => _.tap(this.original.eventDates, map => _.forEach(eventDates, date => map.set(date.guid, date))))
                .catch(ErrorRejector.handle);
        }

        _loadEventTickets() {
            return this.original.event.$queryTicket(false, 'products')
                .then(tickets => _.tap(this.original.tickets, map => {
                    _.forEach(tickets, ticket => {
                        ticket.originalTicketId = ticket.guid;

                        map.set(ticket.guid, ticket);
                    });
                }))
                .catch(ErrorRejector.handle);
        }

        _loadEventProducts() {
            return this.original.event.$queryProduct(false)
                .then(products => _.tap(this.original.products, map => _.forEach(products, product => map.set(product.guid, product))))
                .catch(ErrorRejector.handle);
        }

        _loadScannerTypes() {
            return this.original.event.$queryScannerType(false, 'products')
                .then(scannerTypes => _.tap(this.original.scannerTypes, map => _.forEach(scannerTypes, scannerType => {
                    if (scannerType.guid !== this.original.event.guid) {
                        map.set(scannerType.guid, scannerType);
                    }
                })))
                .catch(ErrorRejector.handle);
        }

        _loadTicketProducts() {
            let promises = [];

            this.original.tickets.forEach((ticket, ticketId) => {
                let productPromise = ticket.$queryProduct()
                    .then(products => {
                        let otherEventProducts = [];

                        let p = _.map(products, product => {
                            if(product.event_id !== this.original.event.guid){
                                // Set a dummy value on the ticket so the UI can be updated without
                                // running expensive loops to determine the state.
                                ticket.has_products_from_other_events = true;

                                // Save the original 'other' products so they can be attached later
                                // The normal product map only contains products from this event
                                this.original.otherProducts.set(product.guid, product);
                                otherEventProducts.push(product);
                            }

                            return product.guid;
                        });

                        if(otherEventProducts.length){
                            this.original.otherTicketProducts.set(ticketId, otherEventProducts);
                        }

                        return p;
                    })
                    .then(productIds => _.tap(productIds, productIds => this.original.ticketProducts.set(ticketId, productIds)));

                promises.push(productPromise);
            }, this);

            return $q.all(promises)
                .then(() => this.original.ticketProducts)
                .catch(ErrorRejector.handle);
        }

        _loadProductMetaData() {
            let promises = [];

            this.original.products.forEach((product, productId) => {
                let metaDataPromise = product.$queryMetaData()
                    .then(metaData => _.tap(metaData, metaData => this.original.productMetaData.set(productId, metaData)));

                promises.push(metaDataPromise);
            }, this);

            return $q.all(promises)
                .then(() => this.original.productMetaData)
                .catch(ErrorRejector.handle);
        }

        _loadProductGroups() {
            let promises = [];

            this.original.tickets.forEach((ticket, ticketId) => {
                promises.push(ticket.$queryProductGroup().then(groups => _.tap(groups, groups => this.original.productGroups.set(ticketId, groups))));
            }, this);

            return $q.all(promises)
                .then(() => this.original.productGroups)
                .catch(ErrorRejector.handle);
        }

        _loadScannerTypeProducts() {
            this.original.scannerTypes.forEach((scannerTypes, originalId) => {
                let productIds = _.filter(_.map(_.get(scannerTypes, 'belongsToMany.Product', []), 'model'), productId => {
                    return this.original.products.has(productId);
                });

                this.original.scannerTypeProducts.set(originalId, productIds);
            }, this);

            return $q.resolve(this.original.scannerTypeProducts)
                .catch(ErrorRejector.handle);
        }

        // ---------------------------------------------
        // ------------------ _clone -------------------
        // ---------------------------------------------

        _cloneEvent() {
            _.set(this.clone, 'event', cloneModel(this.original.event, ['event_dates', 'status', 'status_until'], {
                name: this.name,
                retrievable_after: this.original.event.retrievable_after
                    ? moment(this.original.event.retrievable_after, ISO8601).add(this.offset, 'minutes').format(ISO8601) : null,
            }));

            return $q.resolve()
                .then(this._cloneEventDates.bind(this))
                .then(this._cloneTickets.bind(this))
                .then(this._cloneProducts.bind(this))
                .then(this._cloneScannerTypes.bind(this))
                .then(this._cloneTicketProducts.bind(this))
                .then(this._cloneProductMetaData.bind(this))
                .then(this._cloneProductGroups.bind(this))
                .then(this._cloneScannerTypeProducts.bind(this))
        }

        _cloneEventDates() {
            this.original.eventDates.forEach((eventDate, originalId) => {
                let overwrite = {
                    name: this.name,
                    start: moment(eventDate.start, ISO8601).add(this.offset, 'minutes').format(ISO8601),
                    end: moment(eventDate.end, ISO8601).add(this.offset, 'minutes').format(ISO8601)
                };

                this.clone.eventDates.set(originalId, cloneModel(eventDate, ['event_id', 'event'], overwrite));
            }, this);

            return $q.resolve(this.clone.eventDates);
        }

        _cloneTickets() {
            let promise = $q.resolve();

            this.original.tickets.forEach((ticket, originalId) => {
                let overwrite = {
                    available_from: ticket.available_from ? moment(ticket.available_from, ISO8601).add(this.offset, 'minutes').format(ISO8601) : null,
                    available_until: ticket.available_until ? moment(ticket.available_until, ISO8601).add(this.offset, 'minutes').format(ISO8601) : null
                };

                const clonedTicket = cloneModel(ticket, [], overwrite);

                this.clone.tickets.set(originalId, clonedTicket);

                promise = promise.then(() => {
                    return ticket.$queryMetaData()
                        .then(children => {
                            Object.defineProperty(clonedTicket, 'metaData', {
                                value: children,
                                writable: true,
                                configurable: true
                            });
                        });
                });
            }, this);

            return promise.then(() => this.clone.tickets);
        }

        _cloneProducts() {
            this.original.products.forEach((product, originalId) => {
                if (product.origin_type === 'product') {
                    this.clone.products.set(originalId, cloneModel(product));
                }
            }, this);

            return $q.resolve(this.clone.products);
        }

        _cloneScannerTypes() {
            this.original.scannerTypes.forEach((scannerType, originalId) => {
                let overwrite = {
                    username: generateRandomString(8),
                    password: generateRandomString(4),
                    expires_at: moment().add(1, 'year').endOf('day').format(ISO8601)
                };

                let nameSuffix = ` - ${$translate.instant('models.models.scannerType', {count: 1})}`;

                if (_.endsWith(scannerType.name, nameSuffix) || _.startsWith(scannerType.name, `${this.original.event.name} - `)) {
                    overwrite.name = this.clone.event.name + nameSuffix;
                }

                if (_.isNull(scannerType.description)) {
                    overwrite.description = '';
                }

                this.clone.scannerTypes.set(originalId, cloneModel(scannerType, ['QR', 'event_id'], overwrite));
            }, this);

            return $q.resolve(this.clone.scannerTypes);
        }

        _cloneTicketProducts() {
            this.original.ticketProducts.forEach((ticketProducts, originalId) => {
                this.clone.ticketProducts.set(originalId, _.clone(ticketProducts));
            }, this);

            return $q.resolve(this.clone.ticketProducts);
        }

        _cloneProductMetaData() {
            this.original.productMetaData.forEach((productMetaData, originalId) => {
                this.clone.productMetaData.set(originalId, productMetaData);
            }, this);

            return $q.resolve(this.clone.productMetaData);
        }

        _cloneProductGroups() {
            this.original.productGroups.forEach((groups) => {
                groups.forEach(group => {
                    this.clone.productGroups.set(group.guid, _.assign(cloneModel(group, ['products', 'members', 'children']), {products: _.clone(group.products || [])}));
                }, this);
            }, this);

            return $q.resolve(this.clone.productGroups);
        }

        _cloneScannerTypeProducts() {
            this.original.scannerTypeProducts.forEach((scannerTypeProducts, originalId) => {
                this.clone.scannerTypeProducts.set(originalId, _.clone(scannerTypeProducts));
            }, this);

            return $q.resolve(this.clone.scannerTypeProducts);
        }

        // ---------------------------------------------
        // ------------------- _sync -------------------
        // ---------------------------------------------

        _syncEvent() {
            return $q.resolve()
                .then(this._syncSimpleTickets.bind(this));
        }

        _syncSimpleTickets() {

            this.clone.tickets.forEach((ticket, originalTicketId) => {
                this.clone.productMetaData.set(originalTicketId, ticket.metaData);
            }, this);

            return $q.resolve(this.clone.tickets);
        }

        // ---------------------------------------------
        // ---------------- _saveModels ----------------
        // ---------------------------------------------

        _saveEvent() {
            // Cloned location always exist here, as it gets filled by the simple event form at initialization.
            // If for some reason it does not, an error is thrown when saving the location.
            this.clone.event.location = this.clone.location;
            return this.clone.event.location.$createEvent(this.clone.event)
                .then(this._saveEventDates.bind(this))
                .then(this._saveTickets.bind(this))
                .then(this._saveProducts.bind(this))
                .then(this._saveScannerTypes.bind(this))
                .then(this._saveTicketProducts.bind(this))
                .then(this._saveProductMetaData.bind(this))
                .then(this._saveProductGroups.bind(this));
        }

        _saveLocation() {
            if (!this.clone.location) {
                throw new Error('Location not found, please refresh the page and try again.');
            }
            let promise = $q.resolve();
            return promise.then(() => this.clone.location.$save())
        }

        _saveEventDates() {
            let promise = $q.resolve();

            this.clone.eventDates.forEach(eventDate => {
                eventDate.event_id = this.clone.event.guid;

                promise = promise.then(() => eventDate.$save());
            }, this);

            return promise;
        }

        _saveTickets() {
            let promise = $q.resolve();

            this.clone.tickets.forEach(ticket => {
                promise = promise.then(() => this.clone.event.$createTicket(ticket))
                    .then(newTicket => _.assign(ticket, newTicket));
            }, this);

            return promise;
        }

        _saveProducts() {
            let promise = $q.resolve();

            this.clone.products.forEach((product) => {
                promise = promise.then(() => this.clone.event.$createProduct(product))
                    .then(newProduct => _.assign(product, newProduct));
            }, this);

            return promise;
        }

        _saveScannerTypes() {
            let promise = $q.resolve();

            this.clone.scannerTypes.forEach((scannerType, originalId) => {
                scannerType.products = _.filter(_.map(this.clone.scannerTypeProducts.get(originalId), productId => {
                    let product = this.clone.products.get(productId);

                    if (!_.isUndefined(product)) {
                        return product.guid;
                    }

                    let originalProduct = this.original.products.get(productId);

                    if (_.isUndefined(originalProduct)) {
                        return null;
                    }

                    // If the product is a trigger product, the guid is the same as the (cloned & saved) ticket.
                    if (originalProduct.origin_type === 'ticket') {
                        const ticket = this.clone.tickets.get(productId);

                        if (_.isUndefined(ticket)) {
                            return null;
                        }

                        return ticket.guid;
                    }

                    // If the product is a trigger product, the guid is the same as the (cloned & saved) event date.
                    if (originalProduct.origin_type === 'date') {
                        const eventDate = this.clone.eventDates.get(productId);

                        if (_.isUndefined(eventDate)) {
                            return null;
                        }

                        return eventDate.guid;
                    }

                    return null;
                }));

                promise = promise.then(() => this.clone.event.$createScannerType(scannerType))
                    .then(newScannerType => _.assign(scannerType, newScannerType));
            }, this);

            return promise;
        }

        _saveTicketProducts() {
            let promise = $q.resolve();

            this.clone.ticketProducts.forEach((ticketProducts, originalId) => {
                let ticket = this.clone.tickets.get(originalId);

                if (_.isUndefined(ticket)) {
                    return;
                }

                ticketProducts.forEach(productId => {
                    let product = this.clone.products.get(productId);

                    if (_.isUndefined(product)) {
                        product = this.original.otherProducts.get(productId);
                    }

                    if (!_.isUndefined(product)) {
                        promise = promise.then(() => ticket.$attachProduct(product));

                        return;
                    }

                    let originalProduct = this.original.products.get(productId);

                    if (_.isUndefined(originalProduct)) {
                        return;
                    }

                    // If the product is a trigger product, the guid is the same as the (cloned & saved) event date.
                    if (originalProduct.origin_type === 'date') {
                        const eventDate = this.clone.eventDates.get(productId);

                        if (_.isUndefined(eventDate)) {
                            return;
                        }

                        promise = promise.then(() => ticket.$attachEventDate(eventDate));
                    }
                }, this);
            }, this);

            return promise;
        }

        _saveProductMetaData() {
            let promise = $q.resolve();

            this.clone.productMetaData.forEach((productMetaData, originalId) => {
                let product = this.clone.products.get(originalId);

                if (!_.isUndefined(product)) {
                    productMetaData.forEach(metaData => {
                        promise = promise.then(() => product.$attachMetaData(metaData));
                    }, this);

                    return;
                }

                let originalProduct = this.original.products.get(originalId);

                if (_.isUndefined(originalProduct)) {
                    return;
                }

                // If the product is a trigger product, the guid is the same as the (cloned & saved) ticket.
                if (originalProduct.origin_type === 'ticket') {
                    const ticket = this.clone.tickets.get(originalId);

                    if (_.isUndefined(ticket)) {
                        return;
                    }

                    productMetaData.forEach(metaData => {
                        promise = promise.then(() => ticket.$attachMetaData(metaData));
                    }, this);
                }
            }, this);

            return promise;
        }

        _saveProductGroups() {
            let promise = $q.resolve();

            this.clone.productGroups.forEach(group => {
                if (_.isEmpty(group.ticket_id)) {
                    promise = promise.then(() => $q.reject('No ticket ID for group'));

                    return;
                }

                let ticket = this.clone.tickets.get(group.ticket_id);

                if (_.isUndefined(ticket)) {
                    promise = promise.then(() => $q.reject('Could not find original ticket for group'));

                    return;
                }

                group.ticket_id = ticket.guid;

                group.products = _.filter(_.map(group.products, productId => {
                    let product = this.clone.products.get(productId);

                    // if product not found, fetch product, check if product is from different event_id than original -> then attach to ticket, and add to group.
                    // Fetch products from tickets -> check if not already attached, if not attached -> attach, else, just add to group.
                    if (_.isUndefined(product)) {
                        product = this.original.otherProducts.get(productId);
                    }

                    if (!_.isUndefined(product)) {
                        return product.guid;
                    }

                    let originalProduct = this.original.products.get(productId);

                    if (_.isUndefined(originalProduct)) {
                        return null;
                    }

                    // If the product is a trigger product, the guid is the same as the (cloned & saved) ticket.
                    if (originalProduct.origin_type === 'ticket') {
                        const ticket = this.clone.tickets.get(productId);

                        if (_.isUndefined(ticket)) {
                            return null;
                        }

                        return ticket.guid;
                    }

                    // If the product is a trigger product, the guid is the same as the (cloned & saved) event date.
                    if (originalProduct.origin_type === 'date') {
                        const eventDate = this.clone.eventDates.get(productId);

                        if (_.isUndefined(eventDate)) {
                            return null;
                        }

                        return eventDate.guid;
                    }

                    return null;

                }));

                promise = promise.then(() => group.$save());
            }, this);

            return promise;
        }

        // ---------------------------------------------
        // ----------------- _validate -----------------
        // ---------------------------------------------

        _validate() {
            let errors = {};

            if (this.clone.event.$invalid) {
                _.set(errors, 'event', this.clone.event.$errors || 'Event validation failed');
            }

            this.clone.eventDates.forEach((eventDate, eventDateId) => {
                if (!(eventDate.validate('start') && eventDate.validate('end') && eventDate.validate('location_id') && eventDate.validate('name'))) {
                    _.set(errors, ['eventDate', eventDateId], eventDate.$errors || 'EventDate validation failed');
                }
            }, this);

            this.clone.tickets.forEach((ticket, ticketId) => {
                if (ticket.$invalid) {
                    _.set(errors, ['ticket', ticketId], ticket.$errors || 'Ticket validation failed');
                }
            }, this);

            this.clone.products.forEach((product, productID) => {
                if (product.$invalid) {
                    _.set(errors, ['ticket', productID], product.$errors || 'Product validation failed');
                }
            }, this);

            this.clone.productGroups.forEach((group, groupId) => {
                if (group.$invalid) {
                    _.set(errors, ['productGroup', groupId], group.$errors || 'ProductGroup validation failed');
                }
            }, this);

            if (_.size(errors)) {
                return $q.reject(errors);
            }

            return $q.resolve();
        }
    }

    return EventCloner;
}
