import templateUrl from './failedPayouts.html';
import './failedPayouts.less';

export default angular.module('eventix.dashboard.admin.payouts.failed', [])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.admin.payouts.failed', {
            url: '/payouts/failed',
            views: {
                'dashboard@eventix.dashboard': {
                    component: 'failedPayouts'
                }
            },
            data: {
                requiredRoles: ['Admin'],
                crumb: 'Failed Payouts'
            }
        });
    })
    .component('failedPayouts', {
        controller: FailedPayoutsController,
        templateUrl: templateUrl
    }).name;

function FailedPayoutsController($http, ErrorRejector) {
    const $ctrl = this;

    $ctrl.totalRows = 0;

    $ctrl.loadData = loadData;

    function loadData(offset, limit, sorting, filters) {
        let query = {
            offset,
            limit
        };

        _.each(sorting, (direction, attribute) => {
            _.set(query, `sorting_${attribute}`, direction);
        });

        _.each(filters, (value, attribute) => {
            _.set(query, `filter_${attribute}`, value);
        });

        return $http.get('/finance/payout/failed', {params: query})
            .then(response => {
                $ctrl.totalRows = response.data.total;

                return response.data.data;
            })
            .catch(ErrorRejector.handle);
    }
}