import template from './cashflow.html';

export default angular.module('eventix.dashboard.financials.cashflow', [])
    .config(function ($stateProvider) {
        $stateProvider.state('eventix.dashboard.financials.cashflow', {
            url: '/cashflow',
            views: {
                'dashboard@eventix.dashboard': {
                    component: 'cashflow'
                }
            },
            data: {
                requiredRoles: ['Admin'],
                crumb: 'common.menu.financials.cashflow'
            }
        });
    })
    .component('cashflow', {
        controller: CashFlowController,
        bindings: {
            company: "<",
        },
        templateUrl: template
    }).name;

function CashFlowController() {
    const $ctrl = this;
}
