import template from './bookables.html';

export default angular.module('eventix.dashboard.financials.reporting.clearings.bookables', [])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.financials.reporting.clearings.bookables', {
            url: '/bookables',
            views: {
                'dashboard@eventix.dashboard': {
                    component: 'bookables'
                }
            },
            data: {
                requiredRoles: ['Company Admin'],
                crumb: 'common.menu.financials.reporting.bookables'
            }
        });
    })
    .component('bookables', {
        controller: BookableController,
        templateUrl: template
    }).name;

function BookableController($scope, $state, $element, $q, $http, $timeout, Role, UIMessages) {
    const $ctrl = this;

    const urlBase = '/finance';
    $ctrl.helpCenter = HELP_CENTER_URL;

    $ctrl.dateRangeSelector = {
        start: moment().tz('Europe/Amsterdam').startOf('year'),
        end: moment().tz('Europe/Amsterdam').endOf('month')
    };

    $ctrl.oldDocumentsEndDate = moment().tz('Europe/Amsterdam').year(2019).startOf('year');
    $ctrl.showOldDocuments = $ctrl.dateRangeSelector.start.isBefore($ctrl.oldDocumentsEndDate);

    $ctrl.isAdmin = Role.isAuthorizedAs('Admin');
    $ctrl.busy = 0;

    // This holds the raw data from the server.
    // - This is used when the list of (visible) bookables should be changed.
    // - Eg: When a filter changes and previously hidden data should be visible again.
    $ctrl.data = null;

    // This holds the list of visible bookables.
    $ctrl.bookables = [];

    // This holds the totals per currency
    $ctrl.tableTotals = {'inTotals': {}, 'outTotals': {}, 'sumTotals': {}};

    // Default filename for download
    $ctrl.fallbackFileName = moment().format('YYYY-MM-DD') + ' Bookable.pdf';

    // Controller methods declaration
    $ctrl.loadData = loadData;
    $ctrl.updateFilter = _.debounce(updateFilter, 300, {leading: false, trailing: true});

    /*
     * Initialization
     */

    $ctrl.$onInit = function() {
        $scope.$watch('$ctrl.dateRangeSelector', $ctrl.updateFilter, true);

        $ctrl.loadData();
    };

    /*
     * Data methods
     */

    function loadData() {
        if ($ctrl.busy)
            return errorResponse('System busy, please try again later');

        busy();

        // Reset the default filename
        $ctrl.fallbackFileName = moment().format('YYYY-MM-DD') + ' Bookable.pdf';

        let url = urlBase + '/bookable' + ($state.params.as ? ('/' + $state.params.as) : '');

        return $http.get(url)
            .then(response => _.map(response.data, bookable => {
                // determine in/out
                bookable.inout = (bookable.type === 'consolidation' && bookable.amount > 0 || bookable.income_type === 'income') ? 'in' : 'out';

                bookable.downloadPath = `${urlBase}/${bookable.type === 'consolidation' ? 'consolidation' : 'invoice'}/${bookable.guid}/download`;
                bookable.fallbackFileName = (bookable.reference_number ? bookable.reference_number + ' ' : '') + $ctrl.fallbackFileName;

                bookable.amount *= 100;

                return bookable;
            }))
            .then(bookables => $ctrl.data = $ctrl.bookables = bookables)
            .then($ctrl.updateFilter)
            .catch(errorResponse)
            .finally(done);
    }

    function updateFilter() {
        busy();

        $ctrl.showOldDocuments = $ctrl.dateRangeSelector.start.isBefore($ctrl.oldDocumentsEndDate);

        let startDate =  moment($ctrl.dateRangeSelector.start).add(1, 'second'); // to exclude new days in a new period
        let endDate =  moment($ctrl.dateRangeSelector.end).add(1, 'second'); // to include new days in a new period

        $ctrl.bookables = _.filter($ctrl.data, bookable => {
            if(_.isNil(bookable.period_end))
                return moment(bookable.period_start).isSameOrBefore(endDate);

            return moment(bookable.period_end).isSameOrAfter(startDate) && moment(bookable.period_end).isSameOrBefore(endDate);
        });

        let allCurrencies = _.sortBy(_.uniqBy(_.map($ctrl.bookables, 'currency')));

        $ctrl.tableTotals = {'inTotals': {}, 'outTotals': {}, 'sumTotals': {}};

        _.forEach(allCurrencies, currency => {
            _.set($ctrl.tableTotals, ['inTotals', currency, 'total'], 0);
            _.set($ctrl.tableTotals, ['outTotals', currency, 'total'], 0);
            _.set($ctrl.tableTotals, ['sumTotals', currency, 'total'], 0);
        });

        _.forEach(_.groupBy($ctrl.bookables, 'inout'), (bookables, inout) => {
            _.forEach(_.groupBy(bookables, 'currency'), (currencyBookables, currency) => {
                let value = _.sumBy(currencyBookables, 'amount') / 100;
                _.set($ctrl.tableTotals, [inout + 'Totals', currency, 'total'], value);
                _.update($ctrl.tableTotals, ['sumTotals', currency, 'total'], current => current + (inout === 'in' ? value : -value));
            });
        });

        return $q.resolve($ctrl.bookables)
            .finally(done);
    }

    /*
     * Utility methods
     */

    function busy() {
        $ctrl.busy++;

        let iterationCallback = function(event) {
            if ($ctrl.busy)
                return;

            event.target.removeEventListener(event.type, iterationCallback);

            event.target.closest('table-view').classList.remove('loading');
        };

        let startCallback = function(event) {
            event.target.removeEventListener(event.type, startCallback);

            event.target.addEventListener('animationiteration', iterationCallback);
        };

        _.forEach($element, element => {
            _.forEach(element.querySelectorAll('table-view'), table => {
                table.addEventListener('animationstart', startCallback);

                table.classList.add('loading');
            });
        });
    }

    function done() {
        $timeout(() => $ctrl.busy--, 500);
    }

    function errorResponse(error) {
        let message = 'Something went wrong, please try again later.';

        UIMessages.push(message);
        console.error(message, error);

        return $q.reject(error);
    }
}
