import template from './payouts.html';

export default angular.module('eventix.dashboard.financials.payouts', [])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.financials.payouts', {
            url: '/payouts',
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'PayoutsController',
                    controllerAs: 'vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/ {
                payouts: function(Payout) {
                    return Payout.query();
                }
            },
            data: {
                crumb: 'common.menu.financials.payouts',
                requiredRoles: ['Company Admin']
            }
        });
    })
    .controller('PayoutsController', function(Payout, payouts, simpleCRUD, $q, $scope) {
        var vm = this;
        vm.crud = simpleCRUD(payouts, () => {
            return Payout.new();
        }, $scope);
    }).name;
