import template from './status.html';

export default angular.module('eventix.dashboard.scanners.status',[ ])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.scanners.status', {
            url: '/:scannerType/status',
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'StatusController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/{
                scannerInstances: function($transition$, ScannerInstance, $q) {
                    return ScannerInstance.query({
                        scanner_type_guid: $transition$.params().scannerType
                    });
                },
                scannerType: function(ScannerType, $transition$) {
                    return ScannerType.get({ guid: $transition$.params().scannerType });
                }
            },
            data: {
                parent: {
                    name: 'eventix.dashboard.scanners',
                    params: {},
                    label: 'common.menu.entranceManagement.scanners'
                }
            }
        });
    })
    .controller('StatusController', function($state, scannerInstances, scannerType, $timeout, $q) {
        const vm = this;
        vm.scannerType = scannerType;
        vm.scannerInstances = scannerInstances;
        vm.markers = [];
        vm.batteryStatusOptions = ['discharging','charging'];
        updateMarkers(scannerInstances);

        vm.infoWindow = marker => {
            return `<h4>${ marker.name }</h4>
<ul>
    <li>Battery: ${ marker.battery }% (${ marker.batteryStatus })</li>
    <li>Last seen: ${ moment(marker.lastSeen, 'YYYY-MM-DDTHH:mm:ss').format('D MMM HH:mm') }</li>
</ul>`;
        };

        function updateMarkers() {
            vm.loading = true;
            let promises = _.map(scannerInstances, instance => instance.$queryPings());
            $q.all(promises).then(() => {
                let markers = _.map(scannerInstances, instance => {
                    let ping = _.first(instance.pings);
                    if (!ping)
                        return null;
                    return {
                        latitude: _.toNumber(ping.latitude),
                        longitude: _.toNumber(ping.longitude),
                        name: instance.name,
                        battery: ping.battery_percentage,
                        batteryStatus: ping.battery_status,
                        lastSeen: ping.submitted_at
                    };
                });
                vm.markers = _.compact(markers);
                $timeout(updateMarkers, 60000);
            }).finally(() => vm.loading = false);
        }
    }).name;

