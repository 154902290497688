import basic from './basic/basic';
import tickets from './tickets/tickets';
import shops from './shops/shops';
import scanners from './scanners/scanners';
import financials from './financials/financials';
import summary from './summary/summary';
import copyEvent from './copyEvent';

export default angular.module(
    'eventix.dashboard.wizard.simple',
    [
        basic,
        tickets,
        shops,
        scanners,
        financials,
        summary,
        copyEvent
    ])
    .config(ModuleConfig).name;

function ModuleConfig($stateProvider) {
    $stateProvider.state('eventix.dashboard.wizard.simple', {
        url: '/simple'
    });
}
