import templateUrl from './summary.html';

export default angular.module('eventix.dashboard.wizard.advanced.summary', [])
    .config(ModuleConfig)
    .component('wizardAdvancedSummary', {
        controller: WizardAdvancedSummaryController,
        templateUrl: templateUrl,
        bindings: {
            event: '<',
            company: '<'
        }
    })
    .name;

function ModuleConfig($stateProvider) {
    $stateProvider.state('eventix.dashboard.wizard.advanced.summary', {
        url: '/summary',
        component: 'wizardAdvancedSummary',
        bindings: {
            event: 'currentEvent',
            company: 'company'
        }
    });
}

function WizardAdvancedSummaryController($q, $scope, $state, $translate, $uibModal, LaravelValidationRules, ErrorRejector, SweetAlert, ScannerType) {
    const $ctrl = this;

    $ctrl.inShop = inShop;
    $ctrl.listRules = listRules;
    $ctrl.inScanner = inScanner;
    $ctrl.getTicketPrice = getTicketPrice;
    $ctrl.openIntegrationModal = openIntegrationModal;

    $ctrl.$postLink = function() {
        loadData();

        $ctrl.hasBankDetails = $ctrl.company.bank_account_number && $ctrl.company.bank_account_name;

        if (moment($ctrl.event.created_at, 'YYYY-MM-DDTHH:mm:ssZ').isAfter(moment().subtract(1, 'hour'))) {
            SweetAlert.swal({
                title: $translate.instant('common.notice.congratulations'),
                text: $translate.instant('common.notice.readyToSell'),
                type: 'success',
                timer: 1500,
                showConfirmButton: false
            });
        }
    };

    function inShop(ticket) {
        return !_.isNil(_.find($ctrl.shops, shop => _.find(shop.tickets, {guid: ticket.guid})));
    }

    function listRules(meta) {
        if (!meta) {
            return '';
        }

        return _.map(LaravelValidationRules.parse(meta.extra), r => LaravelValidationRules[r.name].label).join(', ');
    }

    function inScanner(product) {
        return !_.isNil(_.find($ctrl.scanners, scanner => _.find(scanner.products, {guid: product.guid})));
    }

    function getTicketPrice(ticket) {
        if (!ticket || !ticket.calculateMinimumPrice) {
            return '';
        }

        if (_.has(ticket, 'minimumPrice')) {
            return ticket.minimumPrice;
        }

        if (!ticket.calculating) {
            ticket.calculating = true;
            ticket.calculateMinimumPrice(false);
        }

        return '...';
    }

    function openIntegrationModal(shop) {
        $uibModal.open({
            component: 'shopIntegrationModal',
            resolve: {
                shop: function() {
                    return shop;
                }
            }
        });
    }

    // This method results in a VERY BIG amount of calls...
    // Right now only made it (slightly) more efficient and a LOT more readable!
    // A big update would require a custom API endpoint (Seed Event could be used!!!)
    function loadData() {
        return $q.all([
            loadProductsWithEventDates(),
            loadAddonProducts(),
            loadShops(),
            loadTickets(),
            loadScannerTypes()
        ]);
    }

    function loadProductsWithEventDates() {
        return $ctrl.event.$queryEventDate()
            .then(eventDateProducts => $ctrl.eventDateProducts = eventDateProducts)
            .catch(ErrorRejector.handle);
    }

    function loadAddonProducts() {
        return $ctrl.event.$queryAddonProduct()
            .then(addonProducts => $ctrl.addonProducts = addonProducts)
            .catch(ErrorRejector.handle);
    }

    function loadShops() {
        let shopsPromises = [
            $ctrl.event.$queryShop(),
            $ctrl.company.$getOrCreateShop().then(shop => [shop]),
        ];

        return $q.all(shopsPromises)
            .then(results => _.unionBy(results[0], results[1], 'guid'))
            .then(shops => $q.all(_.map(shops, shop => {
                return $q.all([
                    shop.$queryMetaData().then(metaData => shop.metaData = metaData),
                    shop.$queryTicket().then(tickets => shop.tickets = tickets),
                    shop.$getOrCreatePrimaryTracker().catch(angular.noop)
                ]).then(() => shop);
            })))
            .then(shops => $ctrl.shops = shops)
            .catch(ErrorRejector.handle);
    }

    function loadTickets() {
        return $ctrl.event.$queryTicket()
            .then(tickets => $q.all(_.map(tickets, ticket => {
                return ticket.$hasCustomTemplate()
                    .then(has => ticket.hasCustomTemplate = has)
                    .then(() => ticket);
            })))
            .then(tickets => $ctrl.tickets = tickets)
            .catch(ErrorRejector.handle);
    }

    function loadScannerTypes() {
        return $ctrl.event.$queryOrCreateScannerType(() => ScannerType.newDefault([$ctrl.event.name, $translate.instant('models.models.scannerType', {count: 1})].join(' - ')))
            .then(scannerTypes => $q.all(_.map(scannerTypes, scannerType => {
                return scannerType.$queryProduct()
                    .then(products => scannerType.products = products)
                    .then(() => scannerType);
            })))
            .then(scannerTypes => $ctrl.scanners = scannerTypes)
            .catch(ErrorRejector.handle);
    }

}

function EventDateProduct(product) {
    this.product = product;
    this.eventDates = _.get(product.$savedState, 'event_dates', []);

    this.start = _.min(_.map(this.eventDates, 'start'));
    this.end = _.max(_.map(this.eventDates, 'end'));
    this.capacity = _.sum(_.map(this.eventDates, 'capacity'));
}
Object.defineProperties(EventDateProduct.prototype, {
    guid: {
        get() {
            return this.product ? this.product.guid : null;
        }
    },
    name: {
        get() {
            return this.product ? this.product.name : null;
        }
    },
    price: {
        get() {
            return this.product ? this.product.price : null;
        }
    },
});
