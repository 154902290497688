export default angular.module('eventix.dashboard.wizard.common.simpleTicket', [])
    .factory('SimpleTicket', SimpleTicketFactory).name;

function SimpleTicketFactory(Ticket, Product, $q, BaseClass, Vat, Company) {
    function SimpleTicket(attr) {
        this.ticket = Ticket.new({
            increment: 1,
            status_overrule: 'auto',
            available_stock: 0,
            availability_margin: 0,
            min_orderable_amount_per_order: 1,
            max_orderable_amount_per_order: 20,
            percentage_service_costs_in_ticket: 0,
            min_price: 0,
            vat_percentage: 0,
            combines_products: true,
            swappable: true,
        });
        this.product = Product.new({
            name: 'Product for ',
            type: 'normal',
            price: 100,
            vat_percentage: Vat.getDefaultRateFor(_.get(Company.cached, [Company.getCurrentId(), 'country'], 'NL'))
        });
        this.public = true;
        Object.defineProperty(this, 'products', {
            value: [this.product],
            configurable: true,
            writable: true,
            enumerable: false
        });
        _.assign(this, attr);
    }

    let proto = SimpleTicket.prototype;

    proto.toString = function() { return this.ticket.name; };

    proto.$save = function() {
        let isNew = !this.ticket.guid && !this.product.guid;
        return this.ticket.$save()
            .catch(error => {
                let errors = _.get(error, 'data.error_description', {});

                if (_.isObject(errors)) {
                    _.forEach(errors, (messages, field) => {
                        _.forEach(messages, message => this.ticket.$errors.add(field, message));
                    });
                }

                return $q.reject(error);
            })
            .then(() => {
                this.ticket.sold_count = this.ticket.sold_count || 0;
                this.product.description = this.ticket.guid;
                this.product.name = 'Product for ' + this.ticket.name;
                return this.product.$save()
                    .catch(error => {
                        let errors = _.get(error, 'data.error_description', {});

                        if (_.isObject(errors)) {
                            _.forEach(errors, (messages, field) => {
                                _.forEach(messages, message => this.ticket.$errors.add(field, message));
                            });
                        }

                        return $q.reject(error);
                    });
            })
            .then(() => isNew ? this.ticket.$attachProduct(this.product) : $q.resolve())
            .then(() => {
                this.$savedState = _.cloneDeep(this);
                return this;
            });
    };

    proto.$delete = function() {
        return this.ticket.$delete()
            .then(() => this.product.$delete());
    };

    proto.$queryMetaData = function(...args) { return this.product.$queryMetaData(...args); };
    proto.$reorderMetaData = function(...args) { return this.product.$reorderMetaData(...args); };
    proto.$attachMetaData = function(...args) { return this.product.$attachMetaData(...args); };
    proto.$detachMetaData = function(...args) { return this.product.$detachMetaData(...args); };

    proto.$queryProduct = function(...args) { return this.ticket.$queryProduct(...args); };
    proto.$attachProduct = function(...args) { return this.ticket.$attachProduct(...args); };
    proto.$detachProduct = function(...args) { return this.ticket.$detachProduct(...args); };

    proto.$queryShop = function(...args) {
        return this.ticket.$queryShop(...args).then(shops => {
            this.public = shops.length > 0;
            return shops;
        });
    };
    proto.$attachShop = function(...args) { return this.ticket.$attachShop(...args); };
    proto.$detachShop = function(...args) { return this.ticket.$detachShop(...args); };
    proto.$queryProductGroup = function(...args) { return this.ticket.$queryProductGroup(...args); };

    proto.validate = function(...args) { return this.ticket.validate(...args) && this.product.validate(...args); };
    Object.defineProperties(proto, {
        guid: {
            get: function() { return this.ticket.guid; },
            set: function(g) { return this.ticket.guid = g; },
            enumerable: true
        },
        name: {
            get: function() { return this.ticket.name; },
            set: function(n) {
                this.ticket.name = n;
                this.product.name = 'Product for ' + n;
                return n;
            },
            enumerable: true
        },
        description: {
            get: function() { return this.ticket.description; },
            set: function(d) { return this.ticket.description = d; },
            enumerable: true
        },
        company_id: {
            get: function() { return this.ticket.company_id; },
            set: angular.noop,
            enumerable: true
        },
        $invalid: {
            get: function() {
                this.ticket.$errors.clear();
                this.product.$errors.clear();

                return this.ticket.$invalid || this.product.$invalid;
            }
        }
    });

    SimpleTicket.initFromTicket = function(ticket) {
        return ticket.$queryProduct().then(products => {
            if(!products.length)
                return $q.reject('PriceProduct not found');
            let product = _.find(products, p => p.isTicketProduct(ticket.guid));
            if(!product)
                return $q.reject('PriceProduct not found');
            return SimpleTicket.new({
                ticket: ticket,
                product: product,
                products: products
            });
        });
    };

    BaseClass.apply(SimpleTicket);

    return SimpleTicket;
}
