import template from './exports.html';
import { copyToClipboard } from '../dashboard';

export default angular.module('eventix.dashboard.exports', [])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.exports', {
            url: '/exports?event&type',
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'ExportsController as vm',
                    templateUrl: template
                }
            },
            data: {
                requiredRoles: ['Event Manager']
            },
            resolve: /*@ngInject*/{
                exports: function(Export) {
                    return Export.query({});
                }
            }
        });
    })
    .controller('ExportsController', function($scope, $state, $stateParams, $uibModal, $translate, $filter, $timeout, $q, simpleCRUD, modalCRUD, ErrorRejector, Export, Role, company, exports, events) {
        let vm = this;
        let refreshTimer;

        const timezoneFilter = /^GMT|^Etc|^[A-Z0-9-]+$/;

        vm.isAdmin = Role.isAuthorizedAs('Admin');
        vm.inModal = $('#modalCRUD').length > 0;
        vm.hasPending = false;
        vm.refreshRate = 10000;
        vm.availableFiletypes = ['csv', 'xlsx']; // pdf not allowed yet.
        vm.availableTimezones = moment.tz.names().filter(z => !timezoneFilter.test(z));
        vm.selectedEvents = [];
        vm.company = company;

        vm.refresh = refresh;
        vm.save = save;
        vm.pickEvents = events => $q.resolve(vm.selectedEvents = events);
        vm.copyToClipboard = copyToClipboard;

        vm.crud = simpleCRUD(exports, () => {
            return Export.new({
                name: 'ticketing_export_' + moment().format('YYYY_MM_DD_hh_mm_ss').toString(),
                type: 'orders',
                filetypes: ['xlsx'],
                timezone: vm.company.timezone || 'Europe/Amsterdam'
            });
        }, $scope);

        $scope.$watch('vm.crud.models', setGlobalStatus);
        $scope.$watch('vm.selectedEvents', upsertSelectedEvents);

        vm.$onInit = function() {
            if ($state.params.event || $state.params.type) {
                vm.crud.new();

                if (!_.isNil($state.params.type)) {
                    vm.crud.model.type = $state.params.type;
                }

                if (!_.isNil($state.params.event)) {
                    let event = _.find(events, {guid: $state.params.event});
                    vm.selectedEvents = event ? [event] : [];
                }
            }
        };

        function save() {
            vm.crud.save()
                .then(() => {
                    vm.hasPending = true;

                    refresh();
                })
                .catch(error => {
                    if (error && _.isObject(_.get(error, 'data.error_description', null))) {
                        _.assign(vm.crud.model.$errors, error.data.error_description);
                    }
                });
        }

        function upsertSelectedEvents() {
            vm.crud.model.events = _.map(vm.selectedEvents, 'guid');
        }

        function refresh() {
            $timeout.cancel(refreshTimer);

            return Export.queryDB().then(newExports => {
                vm.crud.models = newExports;
            }).catch(ErrorRejector.handle);
        }

        function setGlobalStatus(exports) {
            if (!exports || !exports.length) {
                return exports;
            }

            vm.hasPending = false;

            _.forEach(exports, function(exportInstance) {
                exportInstance.percentage = $filter('percentage')(1);

                if (_.isNil(exportInstance.download_links)) {
                    exportInstance.ready = false;

                    if (exportInstance.order_count === 0) {
                        exportInstance.percentage = $filter('percentage')(0);
                        exportInstance.status = 'pending... (' + exportInstance.percentage + ')';
                    } else {
                        exportInstance.percentage = $filter('percentage')(exportInstance.processed_order_count / exportInstance.order_count, 0);
                        exportInstance.status = 'creating... (' + exportInstance.percentage + ')';
                    }

                    vm.hasPending = true;
                } else {
                    exportInstance.ready = true;
                    exportInstance.status = 'created';
                }
            });

            $scope.$applyAsync();

            if (vm.hasPending) {
                refreshTimer = $timeout(refresh, vm.refreshRate);
            }

            return exports;
        }
    }).name;
