
import './header.less';
import template from './header.html';
import rudderstack from "../../rudderstack/rudderstack";
import { copyToClipboard } from '../dashboard';
import axios from "axios"

const Header = angular.module('eventix.header', [])
    .controller('HeaderController', function($state, $rootScope, $scope, $transitions, $location, $q, $timeout, $filter, $translate, $window, $http, Locales, EventixSocket, UIMessages, modalCRUD, Role, Event, isAdmin, company, user, Company) {

        var vm = this;
        var pageTitle = document.title;

        vm.isEventManager = Role.isAuthorizedAs('Event Manager');
        vm.isCompanyAdmin = Role.isAuthorizedAs('Company Admin');
        vm.isWhitelabelAdmin = Role.isAuthorizedAs('Whitelabel Admin');

        vm.profileUrlNewDashboard = NEW_DASHBOARD_ROOT + 'profile/general?legacy=true';

        vm.isAdmin = isAdmin;
        vm.locales = Locales;

        vm.company = company;
        vm.user = user;
        vm.userCompany = null;

        vm.switchableCompanies = [];
        vm.currentSwitchableCompaniesPage = undefined;
        vm.hasMoreSwitchableCompanies = true;

        vm.searchTerm = $location.search().search;
        vm.eventSearchTerm = '';
        vm.previousCompany = $window.auth._tokenStorage.lastUsed[1];
        vm.fallbackCompany = $window.auth._tokenStorage.fallback;

        vm.switchToCompanyGuid = function (companyGuid) {
            return Company.switch(companyGuid).then(result => {
                if(result === false){
                    UIMessages.push({
                        type: 'error',
                        message: 'Something went wrong switching companies (If the issue persists, please logout and login)',
                    });

                    return $q.reject(e);
                }

                rudderstack.track('dashboard_company_switch', {
                    switch_to: companyGuid,
                });

                $window.location.href = DASHBOARD_ROOT + "home?as="+companyGuid;
            });
        }


        vm.$onInit = function() {

            // Forward the user's current company to the view
            Company.getMe().then((company) => {
                vm.userCompany = company;
            });

            vm.planhatInfo();

        };

        vm.toggleSearch = () => {
            if (!vm.isAdmin) {
                return;
            }
            vm.searchOrders = !vm.searchOrders;
        };

        vm.setLocale = code => {
            $translate.use(code);
        };

        vm.searchEvents = function(query) {
            return Event.search(query);
        };

        vm.searchCompaniesAndEvents = function(query) {
            if (!_.isString(query) || _.isEmpty(query)) {
                return $q.reject('invalid query');
            }

            return $http.post('/statistics/companies/events/search', {search: query})
                .then(response => {
                    let companies = response.data;

                    let upcoming = [];
                    let past = [];
                    let other = [];

                    _.forEach(companies, function(company) {
                        if (!company.events || !company.events.length) {
                            other.push({
                                guid: '',
                                name: '',
                                start: '',
                                end: '',
                                company: {
                                    guid: company.company.guid,
                                    name: company.company.name,
                                },
                            });
                        } else {
                            _.forEach(company.events, function(event) {
                                const eventData = {
                                    guid: event.guid,
                                    name: event.name,
                                    start: event.start || '',
                                    end: event.end || '',
                                    company: {
                                        guid: company.company.guid,
                                        name: company.company.name,
                                    },
                                };

                                if (!event.start) {
                                    other.push(eventData);
                                } else if (moment().diff(event.start, 'minutes') < 0) {
                                    upcoming.push(eventData);
                                } else {
                                    past.push(eventData);
                                }
                            });
                        }
                    });

                    upcoming = _.orderBy(upcoming, ['start'], ['asc']);
                    past = _.orderBy(past, ['start'], ['desc']);

                    let upcomingHeader = {name: '-------------- Upcoming --------------', guid: '', end: '', start: '', company: {guid: '', name: ''}};
                    let pastHeader = {name: '-------------- Past --------------', guid: '', end: '', start: '', company: {guid: '', name: ''}};
                    let otherHeader = {name: '-------------- Other --------------', guid: '', end: '', start: '', company: {guid: '', name: ''}};

                    return [upcomingHeader].concat(upcoming, [pastHeader], past, [otherHeader], other);
                });
        };

        vm.openEvent = function(e) {
            if (e.guid) {
                // return $state.go('eventix.dashboard.home.event', {
                //     guid: e.guid,
                //     as: e.company.guid
                // }, {reload: true});

                // Fuck die shit, state.go wouldn't work any more -> EOL
                return Company.switch(e.company.guid).then(result => {
                    if (result === false){
                        UIMessages.push({
                            type: 'error',
                            message: 'Something went wrong switching companies (If the issue persists, please logout and login)',
                        });

                        return $q.reject(e);
                    }

                    rudderstack.track('dashboard_company_switch', {
                        switch_to: e.company.guid,
                    });

                    $window.location.href = DASHBOARD_ROOT + "home/event/"+e.guid+"?as="+e.company.guid;
                });

            } else if (e.company.guid) {
                // Fuck die shit, state.go wouldn't work any more -> EOL
                return Company.switch(e.company.guid).then(result => {
                    if(result === false){
                        UIMessages.push({
                            type: 'error',
                            message: 'Something went wrong switching companies (If the issue persists, please logout and login)',
                        });

                        return $q.reject(e);
                    }

                    rudderstack.track('dashboard_company_switch', {
                        switch_to: e.company.guid,
                    });

                    $window.location.href = DASHBOARD_ROOT + "home?as="+e.company.guid;
                });
            }

            vm.eventSearchTerm = '';

            return $q.reject(e);
        };

        vm.returnState = null;

        vm.toggleMuteEventixSocket = EventixSocket.toggleMute;

        vm.isEventixSocketMuted = EventixSocket.isMuted;

        vm.search = _.debounce(() => {
            if ($state.includes('eventix.dashboard.orders') || $state.transition) {
                if (_.isEmpty(vm.searchTerm) && vm.returnState) {
                    return $state.go(vm.returnState.name, vm.returnState.params);
                }
                if ($state.is('eventix.dashboard.orders')) {
                    return $state.go('eventix.dashboard.orders', {search: vm.searchTerm});
                }
            }
            vm.returnState = {name: $state.current.name, params: _.cloneDeep($state.params)};
            if (vm.searchTerm && vm.searchTerm.length >= 3) {
                return $state.go('eventix.dashboard.orders', {search: vm.searchTerm});
            }
            return $state.go('eventix.dashboard.orders', {search: null});
        }, 300);

        vm.breadcrumbs = [];
        vm.goto = crumb => {
            $state.go(crumb.name, crumb.params);
        };

        let deregister = $transitions.onSuccess({}, (trans) => setBreadcrumbs(trans.to(), trans.params()));
        setBreadcrumbs($state.current, $state.params);

        function setBreadcrumbs(state, params) {
            vm.breadcrumbs = [{
                name: state.name,
                params: state.params,
                label: _.get(state, 'data.crumb') || ['common.header', state.name.split('.').slice('-1').join('')].join('.'),
            }];

            document.title = [pageTitle, $filter('capitalize')($translate.instant(vm.breadcrumbs[0].label, {count: 2}))].join(' - ');

            state = findParent(state);
            while (state) {
                vm.breadcrumbs.unshift(state);
                state = findParent(state);
            }

            if (vm.breadcrumbs[0].name === 'eventix.dashboard.home') {
                return;
            }
            vm.breadcrumbs.unshift({
                name: 'eventix.dashboard.home',
                params: {},
                label: 'common.menu.home',
            });

            function findParent(state) {
                let parent = _.get(state, 'data.parent');
                if (parent) {
                    return parent;
                }
                let stateSegments = state.name.split('.');
                for (let i = stateSegments.length - 1; i > 1; i--) {
                    state = $state.get(stateSegments.slice(0, i).join('.'));
                    if (!state) {
                        continue;
                    }
                    parent = _.get(state, 'data.parent');
                    if (parent) {
                        return parent;
                    }
                }
                return false;
            }
        }

        $scope.$on('$destroy', deregister);

        // Invoked when a user opens the profile dropdown
        vm.getSwitchableCompanies = function(page) {

            if (vm.switchableCompanies.length && (!page || page === vm.currentSwitchableCompaniesPage)) {
                return $q.resolve(vm.switchableCompanies);
            }

            vm.company.getSwitchableCompanies({page}).then((data) => {
                vm.currentSwitchableCompaniesPage = data.current_page;
                vm.hasMoreSwitchableCompanies = data.last_page > data.current_page;
                let companies = data.data;

                if (!page) {
                    vm.switchableCompanies = [];
                }

                vm.switchableCompanies.push(..._.map(companies, (company) => {

                    const color = strToColor(company.guid);

                    return {
                        company: company,
                        color: color,
                        colorContrast: colorNeedsContrast(color),
                        letter: company.name.charAt(0),
                    };

                }));

                // Otherwise the loader won't hide
                $scope.$apply();

            }, (error) => {

                UIMessages.push('common.header.notice.failedGetCompanies');

                console.error(error);

                $q.reject(error);

            });

        }

        // Invoked when a user opens the profile dropdown
        vm.loadMoreSwitchableCompanies = function() {

            let page = vm.currentSwitchableCompaniesPage

            if (!vm.switchableCompanies.length) {
                page = undefined;
            }

            return vm.getSwitchableCompanies(page + 1);

        }
        vm.planhatInfo = null;
        vm.planhatOwnerInfo = null;
        vm.accountManager = null;
        vm.serviceLevel = null;
        vm.planhatUrl = null;
        vm.planhatInfo = async function(){
            if(vm.isAdmin || vm.isWhitelabelAdmin) {
                let planhatProxyRoot = "https://planhatproxy.k8s00.openticket.tech/";
                let planhatDashboardRoot = "https://app.planhat.com/";
                let companyId = vm.company.guid;
                let currentToken = $window.auth.getAccessToken().access_token; // for easy override/testing locally
                let tokenString = 'Bearer ' + currentToken;

                // Set Auth
                let request = axios.create({
                    headers: {
                        Authorization: tokenString
                    }
                });

                request.get(planhatProxyRoot + "companies/extid-" + companyId, {headers: {Authorization: tokenString}})
                    .then((data) => {
                        if (data.data) {
                            vm.planhatInfo = data.data;
                        }
                    }).finally(() => {
                    request.get(planhatProxyRoot + "users/" + vm.planhatInfo.owner).then((data) => {
                        if (data.data) {
                            vm.planhatOwnerInfo = data.data
                        }
                    }).finally(() => {
                        console.log(vm.planhatInfo, vm.planhatOwnerInfo)
                        // Company info
                        vm.planhatUrl = planhatDashboardRoot + "profile/extid-" + companyId;
                        if (vm.planhatInfo.custom && vm.planhatInfo.custom.hasOwnProperty("Service Level")) {
                            // Due to screen recordings to customer -> only show first letter (B/S/G)
                            vm.serviceLevel = (vm.planhatInfo.custom["Service Level"]).substring(0, 1);

                        }
                        // Owner info
                        if (vm.planhatOwnerInfo.firstName && vm.planhatOwnerInfo.lastName) {
                            vm.accountManager = vm.planhatOwnerInfo.firstName + " " + vm.planhatOwnerInfo.lastName
                        }
                    });
                });
            }
        }

        vm.selectedLocale = function() {
            return _.find(vm.locales, { code: vm.locales.selected || 'en_GB' })
        }

        // Used for switching to a new company
        vm.switchCompany = function switchCompany(company) {

            if (!company || !company.guid || !vm.userCompany || company.guid === vm.userCompany.guid) {
                return;
            }

            let chain = $q.resolve();

            if (company.is_invitation) {
                chain = $http.post(AUTH_API_ROOT + '/user/invitations/accept/' + company.guid);
            }

            chain
                .then(() => Company.switch(company.guid))
                .then(switchResult => {
                    if(switchResult !== true) {
                        console.error('company switch result: ', switchResult);

                        // Note: This should NEVER happen anymore, but old auth code did this. Leaving this in for compatibility's sake.
                        return $q.reject(new Error('Something went wrong switching companies (If the issue persists, please logout and login)'));
                    }

                    rudderstack.track('dashboard_company_switch', {
                        switch_to: company.guid,
                    });

                    $window.location.href = DASHBOARD_ROOT + `home?as=${company.guid}`;
                })
                .catch((error) => {
                    UIMessages.push({
                        type: 'error',
                        message: 'common.header.notice.failedSwitchCompany',
                    });

                    console.error(error);

                    return $q.reject(error);
                });
        };

        function strToColor(str) {
            let i, hash = 0;

            for (i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }

            let color = '#';

            for (i = 0; i < 3; i++) {
                let value = ((hash >> (i * 8)) + 128) & 0xFF;

                color += ('00' + value.toString(16)).substr(-2);
            }

            return color;
        }

        function colorNeedsContrast(color) {
            // Algorithm from https://www.w3.org/TR/AERT/#color-contrast
            if (color.length !== 7) {
                return false;
            }

            let rgb = [
                color.slice(1, 3),
                color.slice(3, 5),
                color.slice(5, 7),
            ];

            let o = Math.round(((parseInt(rgb[0], 16) * 299) + (parseInt(rgb[1], 16) * 587) + (parseInt(rgb[2], 16) * 114)) / 1000);

            return o <= 125;
        }

        vm.copyToClipboard = (value) => UIMessages.push(copyToClipboard(value));

    });

export default {
    module: Header.name,
    controller: 'HeaderController',
    templateUrl: template
};
