import Common from './_common/_common';
import Clearings from './clearings/clearings';
import Bookables from './bookables/bookables';
import './reporting.less';

export default angular.module('eventix.dashboard.financials.reporting', [Common, Clearings, Bookables])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.financials.reporting', {
            url: '',
            data: {
                requiredRoles: ['Company Admin']
            }
        });
    }).name;