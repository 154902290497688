import templateUrl from './payments.html';
import './payments.less';

export default angular.module('eventix.dashboard.financials.reporting.clearings.payments', [])
    .factory('clearingPayments', function($uibModal, $q) {
        return {
            open: function(clearing) {
                return $q((resolve, reject) => {
                    resolve($uibModal.open({
                        size: 500,
                        component: 'clearingPaymentsModal',
                        resolve: {
                            clearing,
                        },
                    }));

                });

            },
        };
    })
    .component('clearingPaymentsModal', {
        controller: ClearingPaymentsController,
        templateUrl: templateUrl,
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
        },
    })
    .name;

function ClearingPaymentsController($timeout, $scope, $http, $q, $translate, BCValidatable, BCErrorable, Locales, UIMessages, ErrorRejector, Role, $window) {

    const $ctrl = this;
    $ctrl.busy = false;
    $ctrl.payments = {};
    $ctrl.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');
    $ctrl.isAdmin = Role.isAuthorizedAs('Admin');
    $ctrl.helpUrl = DEPOSIT_HELP_DOC;

     // Bind the resolves to the $ctrl
    $ctrl.$onInit = () => {

        if (_.isNil($ctrl.resolve)) {
            throw Error('clearingPayments settings modal -> The modal must be initialised with a resolve binding');
        }

        _.assign($ctrl, $ctrl.resolve);

        $ctrl.busy = true;

        reload();
    };

    $ctrl.generatePayments = generatePayments;
    function generatePayments(amount){
        if(typeof amount == 'undefined') {
            amount = -1*parseInt($ctrl.clearing.realClearingOpenAmount);
        }
        $ctrl.busy = true;
        $http.post(`/finance/clearing/s/${$ctrl.clearing.guid}/payments/create/`, {"amount": amount})
            .then(response => {
                reload();
                $ctrl.busy = false;
            }).catch(error => {
                $ctrl.busy = false;
                return ErrorRejector.handle(_.get(error, 'data.error_description', error.message) || 'common.notice.error');
            });
    }
    $ctrl.pay = pay;
    function pay(payment){
        $ctrl.busy = true;
        console.log(payment);
        $http.post(`/finance/clearing/s/${$ctrl.clearing.guid}/payments/${payment.guid}/pay`)
            .then(response => {
                $ctrl.busy = false;
                $window.top.location = response.data.redirectUrl;
            }).catch(error => {
                $ctrl.busy = false;
                return ErrorRejector.handle(_.get(error, 'data.error_description', error.message) || 'common.notice.error');
            });
    }

    function reload(){
        $ctrl.busy = true;
        $http.get(`/finance/clearing/s/${$ctrl.clearing.guid}`)
            .then(response => {
                $ctrl.clearing = response.data;
                $ctrl.payments = $ctrl.clearing.arbitrary_payments;
                // calculate some amounts
                $ctrl.clearing.totalPaymentsAmount = _.sumBy($ctrl.payments, 'amount');
                $ctrl.clearing.pendingPaymentAmount = _.sumBy(_.filter($ctrl.payments, _.iteratee({ 'status': 'pending'})), 'amount');
                $ctrl.clearing.paidPaymentAmount = _.sumBy(_.filter($ctrl.payments, _.iteratee({ 'status': 'paid'})), 'amount');
                $ctrl.clearing.realClearingAmount = parseInt($ctrl.clearing.amount) + parseInt($ctrl.clearing.paidPaymentAmount);
                $ctrl.clearing.realClearingOpenAmount = parseInt($ctrl.clearing.amount) + parseInt($ctrl.clearing.pendingPaymentAmount);
                $ctrl.busy = false;
            }).catch(error => {
            $ctrl.busy = false;
            return ErrorRejector.handle(_.get(error, 'data.error_description', error.message) || 'common.notice.error');
        });
    }
}
