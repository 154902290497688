import Reporting from './reporting/reporting'
import Payouts from './payouts/payouts'
import Methods from './methods/methods';
import Fees from './fees/fees';
import ArbitrarySplits from './arbitrarySplits/arbitrarySplits';
import CashFlow from './cashflow/cashflow';

export default angular.module('eventix.dashboard.financials', [Reporting, Payouts, Methods, Fees, ArbitrarySplits, CashFlow])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.financials', {
            url: '/financials',
            data: {
                requiredRoles: ['Company Admin']
            }
        });
    }).name;
