import template from './companies.html';

export default angular.module('eventix.dashboard.admin.companies',[])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.admin.companies', {
            url: '/companies',
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'CompaniesController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/{
                whitelabels: function(Whitelabel) {
                    return Whitelabel.query();
                },
                companies: function(Company) {
                    return Company.query();
                }
            },
            data: {
                requiredRoles: ['Whitelabel Admin']
            }
        });
    })
    .controller('CompaniesController', function($state, whitelabels, companies, Company, simpleCRUD, Locales, Currencies, $rootScope, $timeout, $scope, Role, $uibModal) {
        var vm = this;
        vm.whitelabels = whitelabels;
        vm.crud = simpleCRUD(companies, newCompany, $scope);
        vm.locales = Locales;
        vm.currencies = Currencies;

        vm.$onInit = function () {
            vm.isAdmin = Role.isAuthorizedAs('Admin');
        };

        vm.browseAs = function(company) {
            $rootScope.$broadcast('clearCache');
            $timeout(() => {
                $state.go('eventix.dashboard.home', { as: company.guid }, { reload: true });
            });
        };
        vm.openKickbacksModal = openKickbacksModal;

        function newCompany() {
            return Company.new();
        }

        function openKickbacksModal(company) {
            return $uibModal.open({
                size: 'lg',
                controller: function () {
                    const $ctrl = this;

                    $ctrl.$onInit = function () {
                        $ctrl.company = company;
                    };
                },
                controllerAs: '$ctrl',
                template: '<div class="modal-body"><kickbacks applicable-company="$ctrl.company" applied-type="Ticket"></kickbacks></div><div class="modal-footer"><a class="btn btn-default" ng-click="$close()">Close</a></div>'
            });
        }

    }).name;

