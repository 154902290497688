import template from './checkInMonitor.html';
import scanActionTemplate from '../scanactions/scanactions.html';

export default angular.module('eventix.dashboard.checkInMonitor', [])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.checkInMonitor', {
            url: '/checkInMonitor?eventGuid',
            params: {
                eventGuid: {dynamic: true}
            },
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'CheckInMonitorController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/{},
            data: {
                crumb: 'common.menu.entranceManagement.checkInMonitor'
            }
        });
    })
    .controller('CheckInMonitorController', function($state, $stateParams, $scope, $q, $timeout, $translate, store, ErrorRejector, Ticket, ScannerInstance, events, isAdmin, user) {
        const vm = this;
        const OLD_FILTER_KEY = 'checkInFilter';
        const FILTER_KEY = 'checkInMonitor.filters';

        vm.event = null;
        vm.filters = {};
        vm.googleMap = null;

        vm.scanActionTemplate = scanActionTemplate;
        vm.scanActionsResolves = {events, isAdmin, user};

        vm.btnText = '<i class="fa fa-search"></i>';

        vm.applyScannerTypeFilters = applyScannerTypeFilters;
        vm.applyScannerFilters = applyScannerFilters;
        vm.applyTicketFilters = applyTicketFilters;
        vm.applyAccessProductFilters = applyAccessProductFilters;
        vm.applyAddonProductFilters = applyAddonProductFilters;
        vm.selectEvent = selectEvent;

        vm.infoWindow = infoWindow;
        vm.focusOnMap = focusOnMap;
        vm.sortByExpected = sortByExpected;
        vm.sortByRatio = sortByRatio;

        vm.$onInit = function() {
            refactorFilters();

            getFilters();

            $scope.$watch('vm.event', selectEvent);

            vm.event = _.find(events, {guid: $stateParams.eventGuid});
        };

        function selectEvent() {
            if (!vm.event) {
                return $q.reject('No event to select');
            }

            $state.go($state.current.name, {eventGuid: vm.event.guid});

            if (vm.timer) {
                $timeout.cancel(vm.timer);
            }

            vm.loading = true;

            return loadData()
                .finally(() => {
                    vm.loading = false;
                    // vm.timer = $timeout(selectEvent, 60000);
                });
        }

        function loadData() {
            return $q.all([
                loadScannerTypes(),
                loadTickets(),
                loadAccessProducts(),
                loadAddonProducts()
            ]);
        }

        function loadScannerTypes() {
            return vm.event.$queryScannerType(false, 'devices')
                .then(scannerTypes => vm.allScannerTypes = scannerTypes)
                .then(scannerTypes => vm.allScanners = _.flatMap(scannerTypes, scannerType => {
                    return _.map(scannerType.scanners, scanner => {
                        let lastPing = _.first(_.get(scanner, 'pings', []));

                        scanner.type = _.get(scannerType, 'name', '?');
                        scanner.batteryPercentage = _.get(lastPing, 'battery_percentage', '?');
                        scanner.batteryStatus = _.get(lastPing, 'battery_status', '?');
                        scanner.timestamp = _.get(lastPing, 'submitted_at');

                        if (_.isNil(scanner.timestamp)) {
                            scanner.lastSeenMoment = null;
                            scanner.lastSeen = '?';
                        } else {
                            scanner.lastSeenMoment = moment(scanner.timestamp, 'YYYY-MM-DDTHH:mm:ssZ');
                            scanner.lastSeen = scanner.lastSeenMoment.isValid() ? scanner.lastSeenMoment.format('D MMM HH:mm') : '?';
                        }

                        return ScannerInstance.new(scanner);
                    });
                }))
                .then(applyScannerTypeFilters)
                .catch(ErrorRejector.handle);
        }

        function loadTickets() {
            return vm.event.$queryTicket()
                .then(tickets => vm.allTickets = tickets)
                .then(applyTicketFilters)
                .catch(ErrorRejector.handle);
        }

        function loadAccessProducts() {
            return vm.event.$queryAccessProduct()
                .then(accessProducts => vm.allAccessProducts = accessProducts)
                .then(applyAccessProductFilters)
                .catch(ErrorRejector.handle);
        }

        function loadAddonProducts() {
            return vm.event.$queryAddonProduct()
                .then(addonProducts => vm.allAddonProducts = addonProducts)
                .then(applyAddonProductFilters)
                .catch(ErrorRejector.handle);
        }

        function applyScannerFilters() {
            saveFilters();

            return $q.resolve(vm.allScanners)
                .then(scanners => applyFilter(scanners))
                .then(scanners => applyFilter(scanners, 'ScannerType', 'scanner_type_id'))
                .then(scanners => vm.scanners = scanners)
                .then(scanners => {
                    vm.markers = [];

                    _.forEach(scanners, scanner => {
                        let lastPing = _.first(_.get(scanner, 'pings', []));
                        let latitude = _.toNumber(_.get(lastPing, 'latitude', 0));
                        let longitude = _.toNumber(_.get(lastPing, 'longitude', 0));

                        if (_.isNil(lastPing) || (!latitude && !longitude)) {
                            return;
                        }

                        let lastSeen = moment(_.get(lastPing, 'submitted_at'), 'YYYY-MM-DDTHH:mm:ssZ');
                        let active = moment().diff(lastSeen, 'minutes') < 5;

                        let marker = {
                            type: _.get(scanner, 'type', '?'),
                            name: _.get(lastPing, 'name') || _.get(scanner, 'name', '?'),
                            lastSeen: _.get(scanner, 'lastSeen', '?'),
                            active: active,
                            timestamp: _.get(lastPing, 'submitted_at'),
                            batteryPercentage: _.get(scanner, 'batteryPercentage', '?'),
                            batteryStatus: _.get(scanner, 'batteryStatus', '?'),
                            networkQuality: _.get(lastPing, 'network_quality', '?'),
                            networkType: _.get(lastPing, 'network_type', '?'),
                            latitude: latitude,
                            longitude: longitude,
                            // icon: `/img/google-maps-marker-${active ? 'blue' : 'gray'}.png`,
                            // focusIcon: '/img/google-maps-marker-green.png'
                        };

                        scanner.marker = marker;
                        vm.markers.push(marker);
                    });

                    return scanners;
                });
        }

        function applyScannerTypeFilters() {
            saveFilters();

            return $q.resolve(vm.allScannerTypes)
                .then(scannerTypes => applyFilter(scannerTypes))
                .then(scannerTypes => vm.scannerTypes = scannerTypes)
                .then(applyScannerFilters)
                .then(() => vm.scannerTypes);
        }

        function applyTicketFilters() {
            saveFilters();

            return $q.resolve(vm.allTickets)
                .then(tickets => applyFilter(tickets))
                .then(tickets => vm.tickets = tickets)
                .then(tickets => _.tap(tickets, tickets => vm.ticketsTotal = getTotalFromList(tickets)));
        }

        function applyAccessProductFilters() {
            saveFilters();

            return $q.resolve(vm.allAccessProducts)
                .then(accessProducts => applyFilter(accessProducts))
                .then(accessProducts => vm.accessProducts = accessProducts)
                .then(accessProducts => _.tap(accessProducts, accessProducts => vm.accessProductsTotal = getTotalFromList(accessProducts)));
        }

        function applyAddonProductFilters() {
            saveFilters();

            return $q.resolve(vm.allAddonProducts)
                .then(addonProducts => applyFilter(addonProducts))
                .then(addonProducts => vm.addonProducts = addonProducts)
                .then(addonProducts => _.tap(addonProducts, addonProducts => vm.addonProductsTotal = getTotalFromList(addonProducts)));
        }

        function getTotalFromList(list) {
            let totalScanned = _.sumBy(list, 'scanned_count');
            let totalSold = _.sumBy(list, 'sold_count');

            return {
                name: $translate.instant('common.misc.total'),
                scanned_count: totalScanned,
                sold_count: totalSold,
                ratio: totalSold > 0 ? (totalScanned / totalSold) : 0
            };
        }

        function infoWindow(marker) {
            return `<h4>${ marker.name }</h4>
                <ul class="unstyled">
                    <li>Type: ${ marker.type }</li>
                    <li>Battery: ${ marker.batteryPercentage }% (${ marker.batteryStatus })</li>
                    <li>Network: ${ marker.networkQuality } (${ marker.networkType })</li>
                    <li>Last seen: ${ marker.lastSeen }</li>
                </ul>`;
        }

        function focusOnMap(scanner) {
            if (!scanner.marker) {
                return;
            }

            vm.googleMap.openMarker(scanner.marker);
        }

        function sortByExpected(tickets, direction) {
            tickets = _.sortBy(tickets, ticket => Math.max(ticket.sold_count - ticket.scanned_count, 0));

            return direction === 'desc' ? tickets.reverse() : tickets;
        }

        function sortByRatio(tickets, direction) {
            tickets = _.sortBy(tickets, ticket => ticket.sold_count !== 0 ? ticket.scanned_count / ticket.sold_count : '0');

            return direction === 'desc' ? tickets.reverse() : tickets;
        }

        function applyFilter(list, filterName, property) {
            if (_.isEmpty(list)) {
                return list;
            }

            filterName = filterName || _.first(list).constructor.name;
            property = property || _.first(list).constructor.primaryKey;

            if (!filterName || !filterName.length || !property || !property.length) {
                return list;
            }

            let filter = _.get(vm.filters, [vm.event.guid, filterName], []);

            if (_.isEmpty(filter)) {
                return list;
            }

            return _.filter(list, item => _.includes(filter, _.get(item, [property])));
        }

        function getFilters() {
            vm.filters = store.get(FILTER_KEY) || {};
        }

        function saveFilters() {
            store.set(FILTER_KEY, vm.filters);
        }

        /**
         * Check in monitor filters have been extended.
         * The old filters should be removed and added to the new filters.
         */
        function refactorFilters() {
            let oldFilters = store.get(OLD_FILTER_KEY);

            store.remove(OLD_FILTER_KEY);

            if (_.isNil(oldFilters) || _.isEmpty(oldFilters)) {
                return;
            }

            _.forEach(oldFilters, (filters, eventId) => {
                _.set(vm.filters, [eventId, Ticket.name], filters);
            });

            saveFilters();
        }
    }).name;
