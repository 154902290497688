import templateUrl from './color-form.html';

/**
 * The color form in the common shop
 * @author Stan Hurks, Eventix
 */
export default angular.module('eventix.dashboard.wizard.common.shop.colorForm', [])
    .component('colorForm', {
        controller: ColorFormController,
        templateUrl,
        bindings: {
            allShops: '<',
            crud: '<',
            forms: '<',
            isAdmin: '<'
        }
    }).name;

function ColorFormController(ShopStyler) {
    const $ctrl = this;

    $ctrl.cssCopySource = null;
    $ctrl.copyCss = copyCss;
    $ctrl.resetCss = resetCss;
    $ctrl.quickStartCss = quickStartCss;

    /**
     * Copy the CSS styling from another shop
     *
     * @param {Shop} shop A source shop
     */
    function copyCss(shop) {
        $ctrl.crud.model.css = shop.css;
        $ctrl.crud.model.cssVariables = ShopStyler.restoreVariables(shop.css);
    }

    /**
     * Reset shop style to default values
     */
    function resetCss() {
        $ctrl.crud.model.cssVariables.primary = ShopStyler.defaults.primary;
        $ctrl.crud.model.cssVariables.secondary = ShopStyler.defaults.secondary;
        $ctrl.crud.model.cssVariables.css = ShopStyler.cssVariablesGenerator({
            primary: ShopStyler.defaults.primary,
            secondary: ShopStyler.defaults.secondary
        });
    }

    /**
     * Quickstart shop style with primary and secondary colours
     */
    function quickStartCss() {
        let variables = $ctrl.crud.model.cssVariables;
        variables.css = ShopStyler.cssVariablesGenerator({
            primary: variables.primary,
            secondary: variables.secondary
        });
    }
}
