import template from './edit.html';
import editReceiver from './editReceiver/editReceiver';
import editMetadata from './editMetadata/editMetadata';
import returnOrderModal from './returnOrderModal/returnOrderModal';
import ibanModal from './iban/ibanModal';
import './edit.less';
import { copyToClipboard } from '../../dashboard';
import QRCode from 'qrcode';

export default angular.module('eventix.dashboard.orders.edit', [editReceiver, editMetadata, returnOrderModal, ibanModal])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.orders.edit', {
            url: '/:orderGuid',
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'OrderEditController as vm',
                    templateUrl: template,
                },
            },
            resolve: /*@ngInject*/{
                order: function(Order, $transition$) {
                    return Order.get({guid: $transition$.params().orderGuid}, false).then(order => {
                        if (order.created_at) {
                            order.created_at = moment(order.created_at).tz(_.get(order, 'shop.company.timezone', 'Europe/Amsterdam'));
                        }

                        return order;
                    });
                },
                scanActions: function(order, $q, $http) {
                    return $http.get(`/order/${order.guid}/scanactions`)
                        .then(r => r.data);
                },
            },
            data: {
                parent: {
                    name: 'eventix.dashboard.orders',
                    label: 'common.menu.orders.list',
                },
            },
        });
    })
    .controller('OrderEditController', function($http, $window, $q, $timeout, $uibModal, $filter, UIMessages, order, events, metaData, shops, isAdmin, scanActions, Order, Role) {
        const vm = this;

        vm.loadOrder = loadOrder;
        vm.generateOrderLink = generateOrderLink;
        vm.download = downloadOrder;
        vm.rebuild = rebuildOrder;
        vm.resend = resendOrder;
        vm.delete = deleteOrder;
        vm.return = returnOrder;
        vm.newIban = newIban;
        vm.showQRCode = showQRCode;
        vm.showReturnActions = showReturnActions;
        vm.editReceiver = editReceiver;
        vm.editMetadata = editMetadata;
        vm.showEmailLogs = showEmailLogs;
        vm.copyToClipboard = value => UIMessages.push(copyToClipboard(value));

        vm.currencyFormatter = currencyFormatFactory();

        vm.isAdmin = isAdmin;
        vm.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');
        vm.allowEdit = Role.isAuthorizedAs('Shop Manager');

        vm.order = order;
        vm.shops = shops;
        vm.metaData = metaData;
        vm.events = events;
        vm.scanActions = scanActions;

        vm.late_personalizable = false;

        vm.orderDownloadPage = ORDER_ROOT + `download/${vm.order.guid}`;

        computeReturns(vm.order);
        computeRefunds(vm.order);
        computeLatePersonalization(vm.order);
        computeSealedTickets(vm.order);

        function fillReturns(){
            _.forEach(vm.order.tickets, function(ticket) {
                ticket.checkedIn = _.findIndex(vm.scanActions, {order_ticket_id: ticket.guid, action: 'scan'}) > -1;
                ticket.checkedInAt = ticket.checkedIn ? _.find(vm.scanActions, {
                    order_ticket_id: ticket.guid,
                    action: 'scan',
                }).submitted_at : null;

                ticket.is_refundable = true;
                _.forEach(ticket.returns, function(returned){
                   if(returned.amount != 0) {
                       ticket.is_refundable = false;
                   }
                });

                _.forEach(ticket.products, function(product){
                    product.is_refundable = true;
                    _.forEach(product.returns, function(returned){
                        if(returned.amount != 0) {
                            product.is_refundable = false;
                        }
                    });
                });
            });

            _.forEach(vm.order.products, function(product) {
                product.is_refundable = true;
                _.forEach(product.returns, function(returned){
                    if(returned.amount != 0) {
                        product.is_refundable = false;
                    }
                });
            });

            _.forEach(vm.order.payments, function(payment) {
                payment.is_refundable = true;
                _.forEach(payment.returns, function(returned){
                    if(returned.amount != 0){
                        payment.is_refundable = false;
                    }
                });
            });
        }

        function loadOrder() {
            return Order.get({guid: vm.order.guid}, false)
                .then(order => {
                    if (order.created_at) {
                        order.created_at = moment(order.created_at).tz(_.get(order, 'shop.company.timezone', 'Europe/Amsterdam'));
                    }
                    return order;
                })
                .then(order => vm.order = order)
                .then(computeReturns)
                .then(computeRefunds)
                .then(computeLatePersonalization)
                .then(computeSealedTickets);
        }

        function computeReturns(order) {
            fillReturns();

            vm.refundableAmount = calculateRefunableAmount(order);

            return order;
        }
        function computeRefunds(order) {
            vm.refundsTotal = 0;
            _.forEach(order.refunds, function(refund){
                vm.refundsTotal += refund.amount;
                if(refund.status == 'paid'){
                    return;
                }

                if(refund.status == 'pending'){
                    if(!_.isNil(refund.refund_error) && !_.isEmpty(refund.executed_at)){
                        refund.status = 'failed';
                    }
                }
            });
            order.refunds = _.orderBy(order.refunds,['created_at'], ['desc']);

            return order;
        }

        function computeLatePersonalization(order) {
            order._late_personalizable = _.reduce(order.tickets, (carry, ticket) => {
                console.log(carry, _.get(ticket, 'ticket.late_personalization', false));
                return carry || !!_.get(ticket, 'ticket.late_personalization', false);
            }, false);

            console.log('vm.late_personalizable', order._late_personalizable);

            return order;
        }

        function computeSealedTickets(order) {
            order._any_sealed = false;

            const now = moment().unix();

            order._sealable = _.reduce(order.tickets, (carry, ticket) => {
                console.log(carry, _.get(ticket, 'retrievable_after'));
                const sealable = _.get(ticket, 'retrievable_after');

                if (_.isNil(sealable)) {
                    // Never sealed
                    ticket._is_sealed = false;

                    return carry;
                } else if (now <= sealable) {
                    // Currently sealed
                    order._any_sealed = true;
                    ticket._is_sealed = true;
                } else {
                    // Not sealed anymore
                    ticket._is_sealed = false;
                }

                return true;
            }, false);

            console.log('vm.sealable', order._sealable);
            console.log('vm.any_sealed', order._any_sealed);

            return order;
        }

        function generateOrderLink(order, ticket, action) {
            if (arguments.length === 2) {
                action = ticket;
                ticket = null;
            }

            let link = ['order', order.guid];

            if (ticket) {
                link = link.concat(['tickets', ticket.guid]);
            }

            if (action) {
                link.push(action);
            }

            return '/' + link.join('/');
        }

        function downloadOrder(ticket) {
            let link = vm.generateOrderLink(order, ticket, 'downloadlink');

            return $http.get(link)
                .then(response => {
                    $window.open(response.data);
                })
                .catch(() => {
                    UIMessages.push('common.notice.error');
                });
        }

        function rebuildOrder() {
            let link = vm.generateOrderLink(order, 'rebuild');

            return $http.get(link)
                .then(() => {
                    UIMessages.push('common.notice.success');
                })
                .catch(() => {
                    UIMessages.push('common.notice.error');
                });
        }

        function resendOrder() {
            let link = vm.generateOrderLink(order, 'resend');

            return $http.get(link)
                .then(() => {
                    UIMessages.push('common.notice.success');
                })
                .catch(() => {
                    UIMessages.push('common.notice.error');
                });
        }

        function deleteOrder(ticket, product) {
            return returnOrderModal(ticket, product, 'invalidate');
        }

        function returnOrder(ticket, product) {
            return returnOrderModal(ticket, product, 'return');
        }

        function showQRCode(ticketNumber) {
            QRCode.toDataURL(ticketNumber)
                .then(url => {
                    return $uibModal.open({
                        size: 'sm',
                        template: `<div class="modal-header">
                            <h3 class="modal-title" translate>Code: ${ticketNumber}</h3>
                        </div>
                        <div class="modal-body text-center">
                            <img src="${url}" width="150" height="150"/>
                        </div>
                        <div class="modal-footer">
                            <a class="btn btn-danger" ng-click="$close()">Close</a>
                        </div>`,
                    }).result;
                })
                .catch(err => {
                    console.error(err);
                })
        }

        function showReturnActions(item) {
            return $uibModal.open({
                size: 'lg',
                template: `<div class="modal-header">
                    <h3 class="modal-title" translate>{{ $ctrl.name }}</h3>
                </div>
                <div class="modal-body text-center">
                <table class="table text-left" style="width:100%">
                <thead>
                  <tr>
                    <th>action</th>
                    <th>User</th>
                    <th>Date</th>
                    <th>Comment</th>
                    <th>Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr ng-repeat="tuple in $ctrl.returns">
                    <td>{{ tuple.order.purchase_channel }}</td>
                    <td>{{ tuple.order.tech_data.user.email }}</td>
                    <td>{{ tuple.order.created_at | moment:'YYYY-MM-DD HH:mm' }}</td>
                    <td>{{ tuple.order.tech_data.comment }}</td>
                    <td>{{ tuple.amount | formatCurrency:$ctrl.currency}}</td>
                  </tr>
                  </tbody>
                </table>
                
                <div class="modal-footer">
                    <a class="btn btn-danger" ng-click="$close()">Close</a>
                </div>`,
                controllerAs: '$ctrl',
                resolve: {
                    item: () => item,
                    order: () => vm.order,
                },
                controller: function(item, order, $filter) {
                    this.item = item;
                    this.returns = _.orderBy(item.returns, function(e) { return e.order.created_at; }, ['desc']);
                    this.name = 'Returns';
                    if(typeof this.item.ticket !== 'undefined'){
                        this.name = this.item.ticket.name + " " + this.item.ticket_number;
                    }
                    if(typeof this.item.product !== 'undefined'){
                        this.name = this.item.product.name;
                    }
                    else if(typeof this.item.product !== 'undefined'){
                        this.name = this.item.product.name;
                    }

                    this.currency = order.currency;
                },
            }).result;
        }

        function showEmailLogs() {
            return $http.get(`/order/${order.guid}/emails`)
                .then(r => {
                    let emails = r.data;

                    return $uibModal.open({
                        size: 'lg',
                        template: `<div class="modal-header">
                    <h3 class="modal-title" translate>Emails</h3>
                </div>
                <div class="modal-body text-center">
                <table class="table text-left" style="width:100%">
                <thead>
                  <tr>
                    <th>Created_at</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Reason</th>
                    <th>Detail</th>
                    <th>Opens</th>
                    <th>Clicks</th>
                    <th>External</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr ng-hide="$ctrl.emails.length">
                  <td>No emails found</td>
                  </tr>
                  <tr ng-show="$ctrl.emails.length" ng-repeat="email in $ctrl.emails">
                    <td>{{ email.created_at }}</td>
                    <td>{{ email.email }}</td>
                    <td>{{ email.status }}</td>
                    <td>{{ email.reject_reason }}</td>
                    <td>{{ email.reject_detail }}</td>
                    <td>{{ email.opens }}</td>
                    <td>{{ email.clicks }}</td>
                    <td>{{ email.external_id }}</td>
                  </tr>
                  </tbody>
                </table>
                
                <div class="modal-footer">
                    <a class="btn btn-danger" ng-click="$close()">Close</a>
                </div>`,
                        controllerAs: '$ctrl',
                        resolve: {
                            emails: () => emails,
                        },
                        controller: function(emails) {
                            this.emails = emails;
                        },
                    }).result;
                });
        }

        function returnOrderModal(ticket, product, action) {
            if(typeof ticket === 'undefined'){
                ticket = null;
            }

            if (typeof product === 'undefined') {
                product = null;
            }

            return $uibModal.open({
                component: 'returnOrderModal',
                resolve: {
                    order: vm.order,
                    ticket: ticket,
                    product: product,
                    action: function() { return action; },
                    amount: function() { return vm.currencyFormatter(vm.order, ticket, product); },
                },
            }).result.then(loadOrder, error => {
                // Some error occurred or closed
                console.warn('Return modal closed', error);
            });
        }

        function newIban(refund) {
            return $uibModal.open({
                component: 'ibanModal',
                resolve: {
                    refund: refund,
                },
            }).result.then(loadOrder, error => {
                // Some error occurred or closed
                console.warn('Return modal closed', error);
            });
        }

        function editReceiver() {
            return $uibModal.open({
                component: 'editReceiverModal',
                resolve: {
                    order: function() {
                        return Order.new(_.pick(vm.order, ['guid', 'email', 'firstName', 'lastName', 'locale']));
                    },
                },
            }).result.then(order => {
                vm.order = Order.new(_.assign(vm.order, order));
            }, () => {
                vm.order = Order.new(vm.order);
            });
        }

        function editMetadata(orderMetaData, ticket, product) {
            return $uibModal.open({
                component: 'editMetaDataModal',
                resolve: {
                    orderMetaData: function() {
                        return orderMetaData;
                    },
                    abstractMetaData: function() {
                        let type = product ? 'product' : 'person';
                        return _.filter(metaData, m => m.applies_to === type && !_.find(orderMetaData, {metadata_id: m.guid}));
                    },
                    saveFn: function() {
                        return function(meta, isNew) {
                            let url = `/order/${vm.order.guid}`;

                            if (ticket) {
                                url += `/tickets/${ticket.guid}`;
                            }

                            if (product) {
                                url += `/products/${product.guid}`;
                            }

                            url += '/metadata';

                            if (!isNew) {
                                url += '/' + meta.guid;
                            }

                            return $http({
                                method: isNew ? 'POST' : 'PUT',
                                url: url,
                                data: {
                                    metadata_id: meta.metadata_id,
                                    value: meta.value,
                                },
                            })
                                .then(res => {
                                    return res.data;
                                }, error => {
                                    if (error.status >= 400 && error.status < 600) {
                                        return $q.reject(error.data);
                                    }

                                    throw error;
                                });
                        };
                    },
                },
            }).result.finally(() => {
                // There is a watcher that F*CKS this up somewhere...
                // Delaying this to the next render frame 'fixes' this.
                $timeout(() => {
                    _.forEach(orderMetaData, meta => {
                        if (meta.metadata.type === 'values' && _.isArray(meta.value)) {
                            meta.value = meta.value.join(', ');
                        }
                    });
                });
            });
        }

        /**
         * Create a formatter method and return it
         * @return {function(*, *, *=) : *} Formatter method
         */
        function currencyFormatFactory() {
            return (order, ticket, product) => {
                return calculateRefunableAmount(order, ticket, product);
            };
        }

        function calculateRefunableAmount(order, ticket, product){
            let formatter = $filter('formatCurrency');
            let productAmount = _.get(product, 'finn_price');
            let ticketAmount = _.get(ticket, 'finn_price');
            let orderAmount = _.sumBy(order.payments, 'finn_price');

            let alreadyInvalidPaymentsAmount = _.sumBy(_.filter(order.payments, function(p) { return !p.is_refundable; }), function(p) { return p.finn_service_fee; });
            let alreadyInvalidTicketsAmount = _.sumBy(_.filter(order.tickets, function(t) { return !t.is_refundable; }), function(t) { return t.finn_value + t.finn_service_fee; });
            let alreadyInvalidProductsAmount = 0;
            alreadyInvalidProductsAmount += _.sumBy(_.filter(order.products, function(p) { return !p.is_refundable; }), function(p) { return p.finn_value + p.finn_service_fee; });
            _.forEach(order.tickets, function(t) {
                alreadyInvalidProductsAmount += _.sumBy(_.filter(t.products, function(p) { return !p.is_refundable; }), function(p) { return p.finn_value + p.finn_service_fee; });
            });
            let alreadyInvalidAmount = alreadyInvalidTicketsAmount + alreadyInvalidProductsAmount + alreadyInvalidPaymentsAmount;

            let alreadyInvalidAmountForTicket = 0;
            if(!_.isNil(ticketAmount)) {
                alreadyInvalidAmountForTicket = _.sumBy(_.filter(ticket.products, function(p) { return !p.is_refundable; }), function(p) { return p.finn_value + p.finn_service_fee; });
            }

            let alreadyInvalidAmountForProduct = 0;
            if(!_.isNil(productAmount)) {
                alreadyInvalidAmountForProduct = product.is_refundable ? product.finn_value + product.finn_service_fee : 0;
            }

            let amount;

            if (!_.isNil(productAmount)) {
                amount = productAmount - alreadyInvalidAmountForProduct;
            } else if (!_.isNil(ticketAmount)) {
                amount = ticketAmount - alreadyInvalidAmountForTicket;
            } else {
                amount = orderAmount - alreadyInvalidAmount;
            }

            let currency = DEFAULT_CURRENCY
            if(vm.order.payments.length > 0){
                currency = vm.order.payments[0].currency;
            }

            return formatter(amount, currency, order.locale);
        }
    }).name;
