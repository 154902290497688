import template from './metaData.html';
import dndLists from 'angular-drag-and-drop-lists';
import { copyToClipboard } from '../dashboard';
//import Rules from './rules.js';

export default angular.module('eventix.dashboard.metaData',['dndLists'])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.metaData', {
            url: '/metaData',
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'MetaDataController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/{
                metaData: function(MetaData, $transition$, isAdmin) {
                    return MetaData.query();
                }
            },
            data: {
                crumb: 'models.models.metaData'
            }
        });
    })
    .controller('MetaDataController', function($scope, $state, metaData, MetaData, isAdmin, simpleCRUD, UIMessages, $translate, Role) {
        var vm = this;
        vm.crud = simpleCRUD(metaData, newMD, $scope);
        vm.inModal = angular.element('#modalCRUD').length > 0;
        vm.isAdmin = isAdmin;
        vm.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');

        vm.yesno = [{
            value: true,
            label: $translate.instant('common.action.yes')
        },{
            value: false,
            label: $translate.instant('common.action.no')
        }];

        if (vm.isAdmin) {
            vm.formOptionsAvailable = [{
                key: 'forced',
                label: $translate.instant('models.metaData.forced')
            }, {
                key: 'auto_fill_facebook',
                label: $translate.instant('models.metaData.auto_fill_facebook')
            }, {
                key: 'class',
                label: $translate.instant('models.metaData.class')
            }]
        }

        $translate(['common.action.no', 'common.action.yes']).then(translations => {
            vm.yesno[0].label = translations['common.action.yes'];
            vm.yesno[1].label = translations['common.action.no'];
        });

        vm.guids = {
            metadata: {
                guid: vm.crud && vm.crud.model ? vm.crud.model.guid : '',
                icon: 'fa-child',
                name: 'models.models.metaData',
            },
        };

        vm.copyToClipboard = copyToClipboard;

        vm.prefillExtra = function(type) {
            switch(type) {
                case 'values':
                    vm.crud.model.extra = 'array|in:A,B,C';
                    break;
                case 'enum':
                case 'enumOther':
                    vm.crud.model.extra = 'in:A,B,C';
                    break;
                case 'boolean':
                    vm.crud.model.extra = 'accepted';
                    break;
                case 'integer':
                    vm.crud.model.extra = 'numeric|min:0|max:10';
                    break;
                case 'string':
                    vm.crud.model.extra = 'alpha_num';
                    break;
                case 'date':
                    vm.crud.model.extra = 'date';
                    break;
                case 'seatSelect':
                default:
                    vm.crud.model.extra = '';
                    break;
            }
            $scope.$broadcast('updateMetadataExtra');
        };

        vm.save = function() {
            if (vm.crud.model.public && !window.disableMetaDataPublicCheck) {
                UIMessages.push('Not allowed to save public metadata');
                console.info('Admins can save by setting window.disableMetaDataPublicCheck');
                return;
            }

            return vm.crud.save();
        }

        $scope.$watch('vm.crud.model.extra', function(extra) {
            vm.crud.model.forced = /required(\|.*)?$/.test(extra);
        });

        $scope.$watch('vm.crud.model.guid', function(guid) {
            vm.guids.metadata.guid = guid || '';
        });
        vm.guids.metadata.guid = vm.crud && vm.crud.model ? vm.crud.model.guid : '';

        function newMD() {
            return MetaData.new({
                type: 'string',
                extra: 'required',
                public: false,
                applies_to: 'person',
                forced: false
            });
        }
    })
    .directive('metadataExtra', function() {
    /**
     * @ngdoc directive
     * @name MetaData.directives:metadataExtra
     * @description
     * This directive and its chlidren (metadataExtraRule) control
     * the validation rules for a metadata field. These rules are
     * saved to the database as a single string, but are handled
     * as Rule Objects with a configuration, validation and template
     *
     * The parent directive handles the conversion between these
     * two formats, plus rendering list and button. The logic is
     * kept in the directive controller which is nicely seperated
     * from the page logic.
     *
     * The directives puts a shallow watch on the model, if you want
     * to update the extra rules outside the directive, broadcast an
     * `updateMetadataExtra` event.
     *
     * @scope {model: "the metadata under inspection", otherFields: "array of other metadata fields"}
     * @param {Object} model The field containing the stringified metaData extra rules
     * @param {MetaData[]} otherFields List of all other fields
     * @example
     * <pre>
     * <metadata-extra model="vm.field.extra" other-fields="vm.fields" />
     * </pre>
     */
        return {
            restrict: 'E',
            scope: {model: '=', otherFields: '=' },
            template: template(),
            controller: ['$scope','LaravelValidationRules',Controller],
            controllerAs: 'vm',
            link: function(scope,el,attrs, vm){
                scope.$watch('model',vm.parse);
                scope.$watch('vm.rules',vm.stringify,true);
                scope.$on('updateMetadataExtra', () => {
                    vm.parse(scope.model);
                });
            }
        };

        function Controller($scope, LaravelValidationRules){
            var vm = this;
            vm.addRule = addRule;
            vm.deleteRule = deleteRule;
            vm.parse = parse;
            vm.stringify = stringify;
            vm.ruleConfig = LaravelValidationRules;

            function parse(metadata) {
                var rules = LaravelValidationRules.parse(metadata.extra);
                vm.rules = rules || [];
            }

            function stringify(rules) {
                $scope.model.extra = LaravelValidationRules.stringify(rules);
            }

            function addRule(name) {
                vm.rules.push(vm.ruleConfig[name].new());
                for(let i = 0; i < vm.rules.length; i++)
                    LaravelValidationRules.validateRule(vm.rules[i], vm.rules);
            }

            function deleteRule(rule) {
                _.remove(vm.rules, rule);
            }
        }

        function template() {
            return `<ul class="list-unstyled" dnd-list="vm.rules">
                    <li ng-repeat="rule in vm.rules" dnd-draggable="rule" dnd-moved="vm.rules.splice($index,1)"><metadata-extra-rule/></li>
                </ul>
                <div class="btn-group" uib-dropdown>
                    <a class="btn btn-white" uib-dropdown-toggle><span>{{ "models.metaData.add_rule" | translate }}</span>&nbsp;<i class="fa fa-caret-down"></i></a>
                    <ul uib-dropdown-menu>
                        <li ng-repeat="(rule,conf) in vm.ruleConfig" class="dropdown">
                            <a class="dropdown-submenu-toggle" ng-click="vm.addRule(rule)">{{ conf.label }}</a>
                            <ul class="dropdown-submenu hidden">
                                <li translate="validation.help.{{rule}}"></li>
                            </ul>
                        </li>
                    </ul>
                </div>`;
        }
    })
    .directive('dropdownSubmenuToggle', function() {
        return {
            restrict: 'C',
            link: function(scope,el,attr) {
                el.on('mousein, mouseover touchstart', _.throttle(function() {
                    el.next().removeClass('hidden');
                },300));
                el.on('mouseout touchend', _.throttle(function() {
                    el.next().addClass('hidden');
                },300));
            }
        };
    })
    .directive('metadataExtraRule', function($compile, LaravelValidationRules) {
        const Rules = LaravelValidationRules;
        /**
     * @ngdoc directive
     * @name MetaData.directives:metadataExtraRule
     * @requires metadataExtra
     * @description
     * Child directive of metadataExtra responsible for compiling,
     * rendering and binding a rule.
     */
        return {
            restrict: 'E',
            template: `<div class="btn-group">
            <a class="btn btn-xs btn-white"><i class="fa fa-arrows"></i></a>
            <a class="btn btn-xs btn-white" ng-click="vm.deleteRule(rule)" dnd-nodrag><i class="fa fa-trash-o"></i></a>
            </div>
            &nbsp;<span class="rule" dnd-nodrag></span>`,
            require: '^metadataExtra',
            link: function(scope,el,attrs, vm) {
                var html = Rules[scope.rule.name].template;
                if(typeof html === 'function')
                    html = html();
                var ruleEl = el.find('.rule').html(html);
                ruleEl = $compile(ruleEl)(scope);
                var inputs = el.find('input,select');
                inputs.on('blur change focus', function() {
                    Rules[scope.rule.name].validateRule(scope.rule, scope.vm.rules, el);
                });
                inputs.first().focus();
                Rules[scope.rule.name].validateRule(scope.rule, scope.vm.rules, el);
            }
        };
    }).name;
