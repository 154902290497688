import templateUrl from './home.html';
import './home.less';
import c3 from 'c3';
import c3TooltipTemplate from './c3-tooltip.html';
import { copyToClipboard } from '../dashboard';

export default angular.module('eventix.dashboard.home', [])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.home', {
            url: '/home?newSignUp',
            views: {
                'dashboard@eventix.dashboard': {
                    component: 'homePage'
                }
            }
        });
    }).component('homePage', {
        bindings: {
            company: '<'
        },
        controller: HomeController,
        templateUrl: templateUrl
    }).name;

function HomeController($state, $http, $q, $timeout, $templateCache, $translate, $filter, UIMessages, Role) {
    const $ctrl = this;

    const IMMEDIATELY = true;

    $ctrl.isAdmin = Role.isAuthorizedAs('Admin');
    $ctrl.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');
    $ctrl.busy = 0;

    $ctrl.canManageEvents = Role.isAuthorizedAs('Event Manager');
    $ctrl.hasBankDetails = false;

    $ctrl.rawData = null;

    $ctrl.events = {};
    $ctrl.pastEvents = [];
    $ctrl.upcomingEvents = [];

    $ctrl.showLimit = 5;
    $ctrl.showMore = 10;

    $ctrl.hasChartData = false;
    $ctrl.hasTodayData = false;

    $ctrl.chart = null;
    $ctrl.chartRange = {
        start: moment().subtract(13, 'days').startOf('day'),
        stop: moment().add(1, 'day').startOf('day')
    };

    $ctrl.copyToClipboard = copyToClipboard;

    function increaseLimit(loadAll){
        $ctrl.loading_more = true;
        if(loadAll){
            $ctrl.showLimit = $ctrl.upcomingEvents.length;
        }
        else{
            var incremented = $ctrl.showLimit + $ctrl.showMore;
            $ctrl.showLimit = incremented > $ctrl.upcomingEvents.length ? $ctrl.upcomingEvents.length : incremented;
        }
        $timeout(() =>  $ctrl.loading_more = false, 3000);
    }
    $ctrl.increaseLimit = increaseLimit;

    $ctrl.$onInit = function() {
        $ctrl.hasBankDetails = !_.isEmpty($ctrl.company.bank_account_number) && !_.isEmpty($ctrl.company.bank_account_name);

        updateChart();

        getData();
    };

    function getData() {
        if (isBusy())
            return errorRejection('System is busy, please try again later.');

        busy();

        return $http.get('/home')
            .then(result => $ctrl.rawData = result.data)
            .then(updateEvents)
            .then(updateChart)
            .catch(errorRejection)
            .finally(done);
    }

    function updateEvents(data = null) {
        busy();

        let events = _.get(data, 'events', {});

        let today = moment().tz($ctrl.company.timezone).startOf('day');

        $ctrl.events = events;

        let hasAtLeastOneSoldCount = false;
        _.forEach(events, event => {
            event.first_start = moment(event.first_start).tz($ctrl.company.timezone);
            event.last_end = moment(event.last_end).tz($ctrl.company.timezone);

            // Ignore any events that do not have valid dates
            if (!event.first_start.isValid() || !event.last_end.isValid()) {
                console.error('  > Ignoring event. Invalid date(s)', event);

                return;
            }

            _.forEach(event.eventDates, date => {
                date.start = moment(date.start).tz($ctrl.company.timezone);
                date.end = moment(date.end).tz($ctrl.company.timezone);
                hasAtLeastOneSoldCount = hasAtLeastOneSoldCount || date.sold_count > 0;
            });

            event.days_until_start = event.first_start.diff(today, 'days');

            if (event.last_end.isBefore(today))
                $ctrl.pastEvents.push(event);
            else
                $ctrl.upcomingEvents.push(event);
        });

        $ctrl.pastEvents = _.orderBy($ctrl.pastEvents, ['last_end_int', 'first_start_int'], ['desc', 'desc']);
        $ctrl.upcomingEvents = _.orderBy($ctrl.upcomingEvents, ['first_start_int', 'last_end_int'], ['asc', 'asc']);

        return done(IMMEDIATELY)
            .then(() => data);
    }

    function updateChart(data = null) {
        busy();

        let chart = _.get(data, 'chart', {});

        if (data !== null) {
            $ctrl.hasChartData =  _.get(data, 'load_chart', true);
            $ctrl.hasTodayData =  _.get(data, 'load_today', true);
        }

        let rightLimit = moment().tz($ctrl.company.timezone);
        let leftLimit = rightLimit.clone().subtract(14, 'days');

        let dates = [];

        do {
            leftLimit.add(1, 'day');

            dates.push(leftLimit.format('YYYY-MM-DD'));
        } while (leftLimit.diff(rightLimit, 'days'));

        let chartData = {
            rows: {},
            columns: {},
            groups: [],
            names: {},
            x: dates
        };

        _.forEach($ctrl.events, event => {
            _.set(chartData, ['names', event.guid], event.name);
        });

        _.forEach(chart, (eventData, eventId) => {
            chartData.groups.push(eventId);

            _.set(chartData, ['columns', eventId], _.map(chartData.x, dayKey => {
                return _.get(eventData, [dayKey, 'count'], 0);
            }));

            _.set(chartData, ['rows', eventId], _.map(chartData.x, dayKey => {
                return {
                    id: eventId,
                    name: _.get($ctrl.events, [eventId, 'name'], '?'),
                    paid: _.get(eventData, [dayKey, 'count_paid'], 0),
                    free: _.get(eventData, [dayKey, 'count_free'], 0),
                    revenue: _.get(eventData, [dayKey, 'revenue'], 0)
                };
            }));
        });

        generateChart(chartData);

        return done(IMMEDIATELY)
            .then(() => data);
    }

    /**
     * Generate a chart visualising the past days of sales
     *
     * @param {Object} chartData Data compiled in updateChart
     */
    function generateChart(chartData) {
        let stacked = true;

        let c3TooltipFn = _.template($templateCache.get(c3TooltipTemplate), {imports: {_: _, moment: moment}});
        let formatCurrency = $filter('formatCurrency', $ctrl.company.currency, $ctrl.company.locale);
        let translations = {
            event: $filter('humanize')($translate.instant('models.models.event', {count: 1})),
            revenue: $filter('humanize')($translate.instant('models.event.info.revenue.total')),
            paid: $filter('humanize')($translate.instant('models.event.info.paid.total')),
            free: $filter('humanize')($translate.instant('models.event.info.free.total')),
            total: $filter('humanize')($translate.instant('models.event.info.total.title'))
        };

        $ctrl.chart = c3.generate({
            bindto: '#chart',
            data: {
                x: 'x',
                json: _.merge({x: chartData.x}, chartData.columns),
                type: 'bar',
                groups: stacked ? [chartData.groups] : [],
                names: chartData.names
            },
            size: {width: $('#chart').width() * 0.95, height: 250},
            legend: {show: false},
            color: {pattern: ['#b3d9ea', '#1b449c', '#ff4c00', '#9acaeb', '#002656', '#3fd5ae', '#67b2e8', '#002e6d', '#ff7d45', '#002856']},
            axis: {x: {type: 'timeseries', tick: {format: '%Y-%m-%d'}}},
            tooltip: {
                contents: function(sourceRows, defaultTitleFormat, defaultValueFormat, color) {
                    let $$ = this,
                        rows = [];

                    sourceRows = _.filter(sourceRows);

                    _.forEach(sourceRows, row => {
                        if (row.value <= 0)
                            return;

                        rows.push(_.get(chartData, ['rows', row.id, row.index], {
                            id: row.id,
                            name: row.name,
                            paid: '?',
                            free: '?',
                            revenue: '?'
                        }));
                    });

                    let total = {
                        paid: _.sumBy(rows, 'paid'),
                        free: _.sumBy(rows, 'free'),
                        revenue: _.sumBy(rows, 'revenue')
                    };

                    return c3TooltipFn({$$, rows, total, color, translations, formatCurrency});
                }
            }
        });
    }

    function busy() {
        return $ctrl.busy++;
    }

    function done(immediately = IMMEDIATELY) {
        if (immediately === IMMEDIATELY)
            return $q.resolve($ctrl.busy--);

        return $timeout(() => done(IMMEDIATELY), 500);
    }

    $ctrl.isBusy = isBusy;

    function isBusy() {
        return $ctrl.busy > 0;
    }

    function errorRejection(error = null) {
        let errorMessage = error || 'Something went wrong, please try again later.';

        if (errorMessage.data && errorMessage.data.error_description)
            errorMessage = errorMessage.data.error_description;
        else if (errorMessage.message && _.isString(errorMessage.message))
            errorMessage = errorMessage.message;

        console.error(errorMessage, error);

        UIMessages.push({
            type: 'danger',
            message: errorMessage
        });

        return $q.reject(error);
    }
}
