import templateUrl from './basic.html';

export default angular.module('eventix.dashboard.wizard.advanced.basic', [])
    .config(ModuleConfig)
    .component('wizardAdvancedBasic', {
        controller: WizardAdvancedBasicController,
        templateUrl: templateUrl,
        bindings: {
            event: '<',
            events: '<',
            company: '<'
        }
    }).name;

function ModuleConfig($stateProvider) {
    $stateProvider.state('eventix.dashboard.wizard.advanced.basic', {
        url: '/basic',
        component: 'wizardAdvancedBasic',
        bindings: {
            event: 'currentEvent',
            company: 'company',
            events: 'events'
        }
    });
}

function WizardAdvancedBasicController($state, $q, $translate, ErrorRejector, Role) {
    const $ctrl = this;

    $ctrl.back = back;
    $ctrl.save = save;

    // User rights
    $ctrl.isAdmin = Role.isAuthorizedAs('Admin');
    $ctrl.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');

    $ctrl.formOptionsAvailable = [
        {
            key: 'template',
            label: $translate.instant('models.ticket.template'),
        },
        {
            key: 'visitor_contact_url',
            label: $translate.instant('models.event.visitor_contact_url'),
        },
        {
            key: 'visitor_contact_phone',
            label: $translate.instant('models.event.visitor_contact_phone'),
        },
        {
            key: 'visitor_contact_email',
            label: $translate.instant('models.event.visitor_contact_email'),
        }
    ];

    if ($ctrl.isAdminOrWLAdmin) {
        $ctrl.formOptionsAvailable.push({
            key: 'email_info',
            label: $translate.instant('models.event.email_info'),
        });
        $ctrl.formOptionsAvailable.push({
            key: 'gui_mode',
            label: $translate.instant('models.event.gui_mode'),
        });
        $ctrl.formOptionsAvailable.push({
            key: 'type',
            label: $translate.instant('models.event.type'),
        });
        $ctrl.formOptionsAvailable.push({
            key: 'contact_phone',
            label: $translate.instant('models.event.contact_phone'),
        });
        $ctrl.formOptionsAvailable.push({
            key: 'contact_email',
            label: $translate.instant('models.event.contact_email'),
        });
        $ctrl.formOptionsAvailable.push({
            key: 'contact_name',
            label: $translate.instant('models.event.contact_name'),
        });
        $ctrl.formOptionsAvailable.push({
            key: 'retrievable_after',
            label: $translate.instant('models.event.retrievable_after'),
        });
    }

    $ctrl.$onInit = function() {
        _.defaults($ctrl.event, {
            gui_mode: 'advanced',
            type: 'once',
            locale: $ctrl.company.locale,
            currency: $ctrl.company.currency
        });
    };

    function back() {
        return $state.go('eventix.dashboard.events');
    }

    function save(event, location) {
        if (!location) {
            return ErrorRejector.handle('Save called without location');
        }

        if (!event) {
            return ErrorRejector.handle('Save called without event');
        }

        const errors = {
            location: location.$invalid,
            event: event.$invalid,
        };

        if (_.some(errors)) {
            return ErrorRejector.handle('Input invalid, cannot save event');
        }

        if ($ctrl.busy) {
            return ErrorRejector.handle('System busy, cannot save event');
        }

        $ctrl.busy = true;

        const eventIsNew = !event.guid;

        let promise = $q.resolve();

        if (location.$dirty(['group'])) {
            promise = location.$save();
        }

        if (!event.guid) {
            promise = promise.then(() => location.$createEvent(event))
        } else if (event.$dirty(['location'])) {
            promise = promise.then(() => event.$save());
        }

        return promise
            .then(() => {
                if (location.guid !== event.location_id) {
                    return event.$moveLocation(location)
                        .then(() => event);
                }

                return event;
            })
            .then(() => {
                if (eventIsNew) {
                    $ctrl.events.push(event);
                }

                return event;
            })
            .then(() => $state.go('eventix.dashboard.wizard.advanced.products', {guid: event.guid}))
            .catch(ErrorRejector.handle)
            .catch(() => $state.go('eventix.dashboard.wizard.advanced.basic', {guid: event.guid}))
            .finally(() => $ctrl.busy = false);
    }
}
