import templateUrl from './timeChart.html';
import c3 from 'c3';
import './timeChart.less';
import {isMoment} from 'moment';

export default angular.module('eventix.dashboard.components.charts.timeChart', [])
/**
 * Time series chart
 *
 * Every date object is assumed to be UTC. At the last moment (just before formatting it for c3js it is converted to the provided timezone.
 *
 * @param {Object} data Chart data in UTC format: {'key-guid-or-id': {label: 'Label', data: [{x: "2018-10-04 12", y: 4}, {x: "2018-10-04 13", y: 43}, {x: "2018-10-04 14", y: 31}]}}
 * @param {String} timeFormat The format of the x value
 * @param {String} precision The precision of the x value (eg: day, hour, etc.)
 * @param {String} timeZone The timezone the x value should be converted to
 * @param {Moment|String} start The zoom start position in UTC format
 * @param {Moment|String} end The zoom end position in UTC format
 * @param {Object[]} gridLines The grid-lines to display in UTC format [{x: "2018-10-04 12", label: "Event start"}, ...]
 * @param {Boolean} cumulative Type of the chart
 *
 * @example
 * ```
 * <time-chart
 *     data="$ctrl.chartData"
 *     time-format="YYYY-MM-DD HH"
 *     precision="hour"
 *     start="$ctrl.event_date.start"
 *     end="$ctrl.event_date.end"
 *     grid-lines="$ctrl.chartGridLines"
 *     cumulative="true"></help-with-page>
 * ```
 */
    .component('timeChart', {
        bindings: {
            data: '=',
            timeFormat: '@',
            precision: '@',
            timeZone: '<',
            start: '<',
            end: '<',
            gridLines: '<',
            cumulative: '<'
        },
        templateUrl: templateUrl,
        controller: TimeChart
    })
    .name;

function TimeChart($element, $scope, $timeout, $translate) {
    const $ctrl = this;
    const chartElement = _.first($element).querySelector('.time-chart-chart');
    const TOO_MUCH_DATA_LIMIT = 20000;

    const watchDataFn = watchData();
    const renderChartFn = watchChartData();
    const flushChart = debouncedFlushChart();

    $ctrl.timeFormat = $ctrl.timeFormat || 'YYYY-MM-DD';
    $ctrl.timeZone = $ctrl.timeZone || 'Europe/Amsterdam';
    $ctrl.precision = $ctrl.precision || 'day';
    $ctrl.chart = null;

    $ctrl.firstAndLast = {first: null, last: null};
    $ctrl.range = {start: null, end: null};
    $ctrl.chartData = {labels: {}, groups: [], columns: [], gridLines: []};

    $ctrl.tooMuchData = false;

    $ctrl.$onInit = function() {
        $scope.$watch('$ctrl.start', watchMoment('start'));
        $scope.$watch('$ctrl.end', watchMoment('end'));
        $scope.$watch('$ctrl.data', watchDataFn, true);
        $scope.$watch('$ctrl.gridLines', mutateGridLines, true);
        $scope.$watch('$ctrl.cumulative', () => $timeout(() => {
            mutateData($ctrl.data);

            renderChart($ctrl.chartData);
        }, 0), true);
        $scope.$watch('$ctrl.chartData', renderChartFn, true);
    };

    /**
     * Retrieve the first and last date over all data points.
     *
     * If a valid start or end date is present, these will be checked to extend the range if needed.
     *
     * @param {Object} data The raw data
     * @return {Object<{last: (Moment|null), first: (Moment|null)}>} The first and last dates
     */
    function getFirstAndLast(data) {
        let dates = {
            first: null,
            last: null
        };

        if (_.size(data)) {
            dates.first = _.chain(data).map(item => _.chain(item).get('data', {}).minBy('x').get('x').value()).filter().min().value();
            dates.last = _.chain(data).map(item => _.chain(item).get('data', {}).maxBy('x').get('x').value()).filter().max().value();

            // noinspection ES6ModulesDependencies
            if ($ctrl.range.start && (!dates.first || $ctrl.range.start.isBefore(moment.tz(dates.first, 'UTC'), $ctrl.precision))) {
                dates.first = $ctrl.range.start.format($ctrl.timeFormat);
            }

            // noinspection ES6ModulesDependencies
            if ($ctrl.range.end && (!dates.last || $ctrl.range.end.isAfter(moment.tz(dates.last, 'UTC'), $ctrl.precision))) {
                dates.last = $ctrl.range.end.format($ctrl.timeFormat);
            }

            if (dates.first) {
                // noinspection ES6ModulesDependencies
                dates.first = moment.tz(dates.first, 'UTC').format($ctrl.timeFormat);
            }

            if (dates.last) {
                // noinspection ES6ModulesDependencies
                dates.last = moment.tz(dates.last, 'UTC').format($ctrl.timeFormat);
            }
        }

        $ctrl.firstAndLast = dates;

        return $ctrl.firstAndLast;
    }

    /**
     * Morph the input data to something c3js can use.
     *
     * @param {Object} data The input data
     * @return {{columns : Array, gridLines: Array, groups: Array, labels : Object}|null} The morphed data
     */
    function mutateData(data) {
        if (!_.size(data)) {
            return null;
        }

        let chartData = {
            labels: {},
            groups: [],
            columns: [],
            gridLines: $ctrl.chartData.gridLines
        };

        // noinspection JSCheckFunctionSignatures
        let xKeys = ['x'].concat(_.map(mapData({}, $ctrl.firstAndLast), 'x'));
        chartData.columns.push(xKeys);

        _.each(data, (item, key) => {
            _.set(chartData.labels, key, item.label);

            chartData.groups.push(key);

            // noinspection JSCheckFunctionSignatures
            chartData.columns.push([key].concat(_.map(mapData(_.keyBy(item.data, 'x'), $ctrl.firstAndLast), 'y')));
        });

        let valueColumns = _.slice(chartData.columns, 1);

        chartData.totals = _.chain(xKeys).slice(1).keyBy(key => key).mapValues(key => (_.sumBy(valueColumns, _.indexOf(xKeys, key)) || 0)).value();

        chartData.groups = [chartData.groups];

        $ctrl.chartData = chartData;

        return chartData;
    }

    /**
     * Make a nice object of all gridlines and add now to it
     *
     * @param {Array} gridLines The grid-lines config
     * @return {{text : (string|Object), value : string}[]} The combined gridlines
     */
    function mutateGridLines(gridLines) {
        // noinspection ES6ModulesDependencies
        let nowLine = moment();

        if ($ctrl.timeZone) {
            nowLine.tz($ctrl.timeZone);
        }

        let lines = [{
            text: $translate.instant('common.datetime.now'),
            value: nowLine.format($ctrl.timeFormat)
        }];

        if (gridLines && _.isArray(gridLines)) {
            _.each(gridLines, line => {
                let value = _.get(line, 'x', undefined);

                if (_.isString(value)) {
                    // noinspection ES6ModulesDependencies
                    value = moment(value, $ctrl.timeFormat);
                }

                if (isMoment(value)) {
                    if ($ctrl.timeZone) {
                        value.tz($ctrl.timeZone);
                    }

                    lines.push({
                        text: $translate.instant(_.get(line, 'label', '')),
                        value: value.format($ctrl.timeFormat)
                    });
                }
            });

            // Join overlapping gridLines
            lines = _.map(_.groupBy(lines, 'value'), (overlap, value) => {
                // noinspection JSCheckFunctionSignatures
                return {
                    text: _.join(_.map(overlap, 'text')),
                    value: value
                };
            });
        }

        $ctrl.chartData.gridLines = lines;

        return lines;
    }

    /**
     * Map the data to an arrays of x & y values
     *
     * @param {Object} dataPoints An object of data points keyed by their x-value
     * @param {{first: (Moment|null), last: (Moment|null)}} firstAndLast The limits of the data
     * @return {Array<{y: int, x: String}>} The mapped data
     */
    function mapData(dataPoints, firstAndLast) {
        if (!firstAndLast.first || !firstAndLast.last) {
            return [];
        }

        // noinspection ES6ModulesDependencies
        let currentDate = moment.tz(firstAndLast.first, 'UTC');
        // noinspection ES6ModulesDependencies
        let last = moment.tz(firstAndLast.last, 'UTC');

        let newDataPoints = [];

        let currentPoint = 0;

        while (currentDate.isSameOrBefore(last, $ctrl.precision)) {
            // Original timeZone (UTC)
            let timeString = currentDate.format($ctrl.timeFormat);
            let chartDate = currentDate.clone();

            currentPoint += _.get(dataPoints, [timeString, 'y'], 0);

            if ($ctrl.timeZone) {
                chartDate.tz($ctrl.timeZone);
            }

            newDataPoints.push({
                // Format timezone
                x: chartDate.format($ctrl.timeFormat),
                y: currentPoint
            });

            currentDate.add(1, $ctrl.precision);

            if (!$ctrl.cumulative) {
                currentPoint = 0;
            }
        }

        return newDataPoints;
    }

    /**
     * Render the chart
     *
     * @param {{labels: Object, groups: Array, columns: Array, gridLines: Array}} data The chart data
     */
    function renderChart(data) {
        if (_.isNil(data)) {
            console.error('No data, cannot show chart.');

            return;
        }

        destroyChart();

        let countDataPoints = _.sumBy(data.columns, _.size);

        if (countDataPoints > TOO_MUCH_DATA_LIMIT) {
            console.error('Too much data, cannot show chart.', countDataPoints);

            $ctrl.tooMuchData = true;

            return;
        }

        $ctrl.tooMuchData = false;

        const totalString = $translate.instant('common.misc.total');

        $ctrl.chart = c3.generate({
            bindto: chartElement,
            data: {
                x: 'x',
                xFormat: moment2strftime($ctrl.timeFormat),
                columns: data.columns,
                groups: data.groups,
                names: data.labels,
                type: $ctrl.cumulative ? 'area' : 'bar'
            },
            color: {pattern: ['#b3d9ea', '#1b449c', '#ff4c00', '#9acaeb', '#002656', '#3fd5ae', '#67b2e8', '#002e6d', '#ff7d45', '#002856']},
            axis: {x: {type: 'timeseries', tick: {format: moment2strftime($ctrl.timeFormat)}}},
            zoom: {enabled: true, rescale: true, onzoomend: onZoom},
            subchart: {show: true, onbrush: onZoom},
            point: {show: false},
            size: {width: chartElement.getBoundingClientRect().width, height: 400},
            legend: {show: false},
            grid: {x: {lines: data.gridLines}},
            tooltip: {
                horizontal: true, // Not supported by current version (0.6.6), but if ever updated...
                format: {
                    // Total in title instead of content, as it is more efficient
                    title: function(d) {
                        // noinspection ES6ModulesDependencies
                        let key = moment(d).format($ctrl.timeFormat);
                        let keyFrom = moment(d).format('YYYY-MM-DD HH:mm');
                        let keyTo = moment(d).add(1, $ctrl.precision).format('HH:mm');

                        return `${keyFrom} - ${keyTo} (${totalString}: ${_.get($ctrl.chartData.totals, key, '-')})`;
                    }
                }
            }
        });

        zoom($ctrl.range);
    }

    /**
     * Destroy the chart
     */
    function destroyChart() {
        if (!$ctrl.chart) {
            return;
        }

        $ctrl.chart.destroy();
        $ctrl.chart = null;
    }

    /**
     * Zoom the chart to a specific domain
     *
     * @param {{start: (Moment|null), end: (Moment|null)}|null} range The domain to zoom to
     */
    function zoom(range) {
        if (!$ctrl.chart || !$ctrl.firstAndLast.first || !$ctrl.firstAndLast.last) {
            return;
        }

        if (_.isNil(range) || !isMoment(range.start) && !isMoment(range.end)) {
            $ctrl.chart.unzoom();
            $ctrl.chart.flush();

            return;
        }
        // noinspection ES6ModulesDependencies
        let dates = {
            start: moment.tz($ctrl.firstAndLast.first, 'UTC'),
            end: moment.tz($ctrl.firstAndLast.last, 'UTC')
        };

        if (isMoment(range.start) && range.start.isAfter(dates.start)) {
            dates.start = range.start.clone();
        }

        if (isMoment(range.end) && range.end.isBefore(dates.end)) {
            dates.end = range.end.clone();
        }

        if ($ctrl.timeZone) {
            dates.start.tz($ctrl.timeZone);
            dates.end.tz($ctrl.timeZone);
        }

        $ctrl.chart.zoom([dates.start.format($ctrl.timeFormat), dates.end.format($ctrl.timeFormat)]);
    }

    /**
     * The default width of bars when used with time-series data is not adapted on zoom.
     * This zoom hook will auto-magically fix this.
     *
     * @param {Array} range The new domain of the zoomed chart
     */
    function onZoom(range) {
        let allDataLength = _.size(_.first(_.values($ctrl.chart.xs())));
        // noinspection ES6ModulesDependencies
        let zoomDataLength = moment(_.last(range)).diff(_.first(range), $ctrl.precision);

        $ctrl.chart.internal.config.bar_width_ratio = 0.6 * allDataLength / zoomDataLength;

        flushChart();
    }

    /**
     * Create a (double) debounced chart flush function
     *
     * The flush function will also trigger an onZoom hook. When flushing in the onZoom hook, this will result in an infinite loop.
     * By double debounce-ing this call, we ensure the flush is triggered AFTER the zoom and not repeated (more than once anyway).
     *
     * @return {Function} The debounced flush function
     */
    function debouncedFlushChart() {
        return _.debounce(_.debounce(() => $ctrl.chart.flush(), 5, {leading: false, trailing: true}), 15, {leading: true, trailing: false});
    }

    /**
     * Create a debounced watch function for data
     *
     * @return {function(Object): void} Returns the new debounced watcher function.
     */
    function watchData() {
        /**
         * Debounced watch function for data
         *
         * @param {Object} data The raw data to watch
         */
        return _.debounce(function(data) {
            getFirstAndLast(data);

            mutateData(data);
        }, 10, {leading: false, trailing: true, maxWait: 100});
    }

    /**
     * Create a debounced watch function for chart data
     *
     * @return {function(Object): void} Returns the new debounced watcher function.
     */
    function watchChartData() {
        /**
         * Debounced watch function for chart data
         *
         * @param {Object} data The chart data to watch
         */
        return _.debounce(function() {
            renderChart($ctrl.chartData);
        }, 10, {leading: false, trailing: true, maxWait: 100});
    }

    /**
     * Create a watch function for a specific moment instance
     *
     * @param {String} key The key of the specific watch moment instance destination
     * @return {function((Moment|String|null)): (Moment|null)} The watch function
     */
    function watchMoment(key) {
        /**
         * Watch function for a specific moment instance
         * The input will be converted to a Moment object, or null.
         *
         * @param {Moment|String|null} range The datetime instance.
         * @return {Moment|null} The moment object or null
         */
        return function(range) {
            if (_.isString(range)) {
                // noinspection ES6ModulesDependencies
                range = moment.tz(range, 'UTC');
            } else if (!isMoment(range)) {
                range = null;
            }

            _.set($ctrl.range, key, range);

            return range;
        };
    }

    function moment2strftime(format) {
        let strftime = '',
            map = {
                YYYY: '%0Y',
                YY: '%0y',
                Y: '%-y',
                Q: '?',
                M: '%-m',
                MM: '%0m',
                MMM: '%b',
                MMMM: '%B',
                D: '%-d',
                DD: '%0d',
                Do: '%-d',
                DDD: '%-j',
                DDDD: '%0j',
                X: '%-s',
                x: '%-Q',
                gggg: '%0Y',
                gg: '%0y',
                w: '%-W',
                ww: '%0W',
                e: '%w',
                ddd: '%a',
                dddd: '%A',
                GGGG: '%0Y',
                GG: '%0y',
                W: '%-V',
                WW: '%0V',
                E: '%u',
                L: '%x',
                LL: '%x',
                LLL: '%c',
                LLLL: '%c',
                LT: '%0I:%0M %p',
                LTS: '%X',
                H: '%-H',
                HH: '%0H',
                h: '%-I',
                hh: '%0I',
                k: '?',
                kk: '??',
                a: '%p',
                A: '%p',
                m: '%-M',
                mm: '%0M',
                s: '%-S',
                ss: '%0S',
                S: '%-L',
                SS: '%0L',
                SSS: '%0L',
                Z: '%-Z',
                ZZ: '%0Z',
                '%': '%%'
            };

        format = format.match(/(LTS|LT|(\\?.)\2{0,3})/g);

        _.forEach(format, part => {
            strftime += _.get(map, part, part.replace(/\\/g, ''));
        });

        return strftime;
    }
}
