import templateUrl from './scannerForm.html';

export default angular.module('eventix.dashboard.wizard.common.scannerForm', [])
    .component('scannerForm', {
        controller: ScannerFormController,
        templateUrl: templateUrl,
        bindings: {
            crud: '<',
            event: '<',
            translations: '<?',
            save: '&?',
            cancel: '&?',
            formOptions: '<',
        }
    }).name;

function ScannerFormController($q, $scope, ErrorRejector, Role) {
    const $ctrl = this;

    $ctrl.isAdmin = Role.isAuthorizedAs('Admin');
    $ctrl.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');

    $ctrl.guids = {
        event: {
            guid: $ctrl.event ? $ctrl.event.guid : '',
            icon: 'fa-calendar',
            name: 'models.models.event',
        },
        scannerType: {
            guid: $ctrl.crud && $ctrl.crud.model ? $ctrl.crud.model.guid : '',
            icon: 'fa-qrcode',
            name: 'models.models.scannerType',
        },
    };

    $ctrl.submit = submit;
    $ctrl.unsubmit = unsubmit;
    $ctrl.pickProducts = pickProducts;
    $ctrl.isDefaultScanner = isDefaultScanner;

    $ctrl.$postLink = function() {
        if (!$ctrl.translations) {
            $ctrl.translations = {};
        }

        _.defaults($ctrl.translations, {
            save: 'common.action.save',
            cancel: 'common.action.cancel'
        });

        $scope.$watch('$ctrl.event.guid', (guid) => {
            $ctrl.guids.event.guid = guid || '';
        });
        $ctrl.guids.event.guid = $ctrl.event ? $ctrl.event.guid : '';
        $scope.$watch('$ctrl.crud.model.guid', (guid) => {
            $ctrl.guids.scannerType.guid = guid || '';
        });
        $ctrl.guids.scannerType.guid = $ctrl.crud && $ctrl.crud.model ? $ctrl.crud.model.guid : '';

        $ctrl.onlyShowTicketScannerTypes = ($ctrl.isDefaultScanner() || $ctrl.event.gui_mode === 'simple');
        filterProducts();
    };

    function filterProducts() {
        $ctrl.products = _.filter($ctrl.crud.model.products, (product) => product.origin_type === 'product');
        $ctrl.tickets = _.filter($ctrl.crud.model.products, (product) => product.origin_type === 'ticket');
        $ctrl.dates = _.filter($ctrl.crud.model.products, (product) => product.origin_type === 'date');
    }

    function submit() {
        if ($ctrl.save) {
            return $ctrl.save({model: $ctrl.crud.model});
        }

        return $ctrl.crud.save();
    }

    function unsubmit() {
        if ($ctrl.cancel) {
            return $ctrl.cancel({model: $ctrl.crud.model});
        }

        return $ctrl.crud.list();
    }

    function pickProducts(picked, originType = 'product') {

        // Hacking in the origin type as it is not provided by default for date or ticket entities.
        for (const key in picked) {
            picked[key]['origin_type'] = originType;
        }

        let products = [];

        if (originType === 'product') {
            $ctrl.products = picked;
            products = picked.concat($ctrl.tickets).concat($ctrl.dates);
        }

        if (originType === 'ticket') {
            $ctrl.tickets = picked;
            products = picked.concat($ctrl.products).concat($ctrl.dates);
        }

        if (originType === 'date') {
            $ctrl.dates = picked;
            products = picked.concat($ctrl.tickets).concat($ctrl.products);
        }

        if ($ctrl.crud.showNew()) {
            return $q.resolve($ctrl.crud.model.products = products);
        }

        if (!$ctrl.crud.showEdit()) {
            return ErrorRejector.handle('Can not pick products, not editing a scanner.');
        }

        let queue = _.reduce($ctrl.crud.model.products, (queue, product) => {
            return _.findIndex(products, {guid: product.guid}) >= 0 ? queue : queue.then(() => $ctrl.crud.detachProduct(product));
        }, $q.resolve());

        return _.reduce(products, (queue, product) => queue.then(() => $ctrl.crud.attachProduct(product)), queue);
    }

    function isDefaultScanner() {
        return $ctrl.crud.model.guid === $ctrl.event.guid;
    }
}
