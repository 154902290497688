import template from './scanning.html';
import {arrayPopValue} from '../helpers';
import {copyToClipboard} from '../../../dashboard';

export default angular.module('eventix.dashboard.home.event.scanning', [])
    .component('eventHomeScanning', {
        bindings: {event: '<'},
        controller: EventHomeScanningController,
        templateUrl: template
    }).name;

function EventHomeScanningController(CSVExport, $timeout, $http, UIMessages, $q, store, $scope, Company, Role, $uibModal, $translate, $state, scannerLoginModal) {
    // CONTROLLER
    const $ctrl = this;

    // CONSTANTS
    // const TIME_UNIT = 'days';
    // const API_STATISTICS_DATETIME_FORMAT = 'YYYY-MM-DD';
    const ATOM_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
    // const API_STATISTICS_BUCKET_NAME = 'date_info';
    const LOCAL_STORAGE_FLAGS_KEY = 'homeEventScanningFlags';
    const LOCAL_STORAGE_FILTERS_KEY = 'homeEventScanningFilters';
    const REFRESH_RATE = 30000;
    const PROTECT_API_TIME = 3600000;
    const MODULES = {
        tickets: {
            null: 'eventix.dashboard.wizard.advanced.tickets',
            simple: 'eventix.dashboard.wizard.simple.tickets',
            advanced: 'eventix.dashboard.wizard.advanced.tickets'
        },
        products: {
            null: 'eventix.dashboard.wizard.advanced.products',
            advanced: 'eventix.dashboard.wizard.advanced.products'
        },
        scannerTypes: {
            null: 'eventix.dashboard.wizard.advanced.scanners',
            simple: 'eventix.dashboard.wizard.simple.scanners',
            advanced: 'eventix.dashboard.wizard.advanced.scanners'
        }
    };

    // ACTIONS
    $ctrl.reload = {
        scanning: function() {
            getData().catch(console.error);
        }
    };
    $ctrl.hasSection = (section) => hasSection(section);
    $ctrl.download = (section) => downloadData(section);
    $ctrl.sumBy = (collection, field) => {
        return _.sumBy(collection, field);
    };
    $ctrl.copyToClipboard = (value) => UIMessages.push(copyToClipboard(value));
    $ctrl.filterIsActive = (section) => {
        return _.size(_.get(_.get($ctrl.filters, section, {}), 'blacklist', []));
    };
    $ctrl.setFlag = setFlag;
    $ctrl.zoom = () => switchFlag('zoom');
    $ctrl.zoomIn = () => setFlag('zoom', true);
    $ctrl.zoomOut = () => setFlag('zoom', false);
    $ctrl.switchAutoRefresh = () => switchFlag('autoRefresh');
    $ctrl.switchProductInsteadOfTicket = () => switchFlag('productInsteadOfTicket');
    $ctrl.selectProduct = () => setFlag('productInsteadOfTicket', true);
    $ctrl.selectTicket = () => setFlag('productInsteadOfTicket', false);
    $ctrl.ratio = (scannedCount, soldCount, fixed = 2, defaultReturn = NaN) => calcRatio(scannedCount, soldCount, fixed, defaultReturn);
    $ctrl.showQR = (scannerType) => scannerLoginModal(scannerType);
    $ctrl.openWizard = (module, row = null) => {
        let stateName;

        module = _.get(MODULES, module, null);

        if (module === null) {
            return UIMessages.push('common.error.unknownEventModule');
        }

        stateName = _.get(module, $ctrl.event.gui_mode, null);

        if (stateName === null) {
            return UIMessages.push('common.error.unknownEventMode');
        }

        let data = {
            guid: $ctrl.event.guid
        };

        if (row !== null) {
            data.editing = row.guid;
        }

        $state.go(stateName, data);

        return $q.resolve();
    };
    $ctrl.manageTickets = (row = null) => {
        console.warn('Deprecated $ctrl.manageTickets(), please use $ctrl.openWizard(\'tickets\')');
        $ctrl.openWizard('tickets', row);
    };
    $ctrl.manageProducts = (row = null) => {
        console.warn('Deprecated $ctrl.manageProducts(), please use $ctrl.openWizard(\'products\')');
        $ctrl.openWizard('products', row);
    };
    $ctrl.manageScanners = (row = null) => {
        console.warn('Deprecated $ctrl.manageScanners(), please use $ctrl.openWizard(\'scannerTypes\')');
        $ctrl.openWizard('scannerTypes', row);
    };

    // FLAGS
    $ctrl.dataLoading = {
        scanning: true
    };
    $ctrl.flags = {firstTime: true, zoom: false, productInsteadOfTicket: false, autoRefresh: false, cumulative: false};
    $ctrl.flagHooks = {
        productInsteadOfTicket: function() {
            notifyUpdates();
        },
        autoRefresh: function(value) {
        }
    };
    $ctrl.autoRefreshInterval = REFRESH_RATE / 1000;

    // DATA CONTAINERS
    $ctrl.filters = {};
    // noinspection ES6ModulesDependencies
    $ctrl.sections = {
        scan_chart: {},
        tickets: {
            downloads: {
                label: 'scan_data_for_tickets',
                fields: ['guid', 'name', 'scanned_count', 'sold_count']
            },
            examples: {
                'example-ticket-friday': {
                    guid: 'example-ticket-friday',
                    name: 'Example: Friday Ticket',
                    sold_count: 12,
                    scanned_count: 10,
                    example: true
                },
                'example-ticket-saturday': {
                    guid: 'example-ticket-saturday',
                    name: 'Example: Saturday Ticket',
                    sold_count: 24,
                    scanned_count: 0,
                    example: true
                },
                'example-ticket-weekend': {
                    guid: 'example-ticket-weekend',
                    name: 'Example: Weekend Ticket',
                    sold_count: 6,
                    scanned_count: 3,
                    example: true
                }
            }
        },
        products: {
            downloads: {
                label: 'scan_data_for_products',
                fields: ['guid', 'name', 'scanned_count', 'sold_count']
            },
            examples: {
                'example-product-friday': {
                    guid: 'example-product-friday',
                    name: 'Example: Friday Product',
                    sold_count: 18,
                    scanned_count: 13,
                    example: true
                },
                'example-product-saturday': {
                    guid: 'example-product-saturday',
                    name: 'Example: Saturday Product',
                    sold_count: 30,
                    scanned_count: 0,
                    example: true
                }
            }
        },
        scannertypes: {
            downloads: {
                label: 'scanner_type_data',
                fields: ['guid', 'name', 'scanner_count', 'username', 'password', 'expires_at']
            },
            examples: {
                'example-scanner-type-friday': {
                    guid: 'example-scanner-type-friday',
                    name: 'Example: Friday Scanners',
                    scanner_count: 1,
                    username: 'scanner_friday',
                    password: '3v3n71x',
                    expires_at: moment().add(2, 'years').format(ATOM_DATETIME_FORMAT),
                    example: true
                },
                'example-scanner-type-saturday': {
                    guid: 'example-scanner-type-saturday',
                    name: 'Example: Saturday Scanners',
                    scanner_count: 2,
                    username: 'scanner_saturday',
                    password: 's3cr37',
                    expires_at: moment().add(1, 'day').format(ATOM_DATETIME_FORMAT),
                    example: true
                }
            }
        },
        scanners: {
            downloads: {
                label: 'scanner_data',
                fields: ['guid', 'name', 'scanner_type_id', 'scanner_type_name', 'last_seen', 'battery_percentage']
            },
            examples: {
                'example-scanner-friday-roek': {
                    guid: 'example-scanner-friday-roek',
                    name: 'Example: iPhone of Roek (Friday)',
                    scanner_type_id: 'example-scanner-type-friday',
                    scanner_type_name: 'Friday Scanners',
                    battery_percentage: 51,
                    battery_status: 'charging',
                    last_seen: moment().subtract(16, 'minutes'),
                    example: true
                },
                'example-scanner-saturday-boris': {
                    guid: 'example-scanner-saturday-boris',
                    name: 'Example: iPhone of Boris (Saturday)',
                    scanner_type_id: 'example-scanner-type-saturday',
                    scanner_type_name: 'Saturday Scanners',
                    battery_percentage: 85,
                    battery_status: 'discharging',
                    last_seen: moment().subtract(6, 'days'),
                    example: true
                },
                'example-scanner-lost-linda': {
                    guid: 'example-scanner-lost-linda',
                    name: 'Example: Linda\'s Android phone (Lost)',
                    scanner_type_id: 'example-scanner-type-saturday',
                    scanner_type_name: 'Saturday Scanners',
                    battery_percentage: 3,
                    battery_status: '',
                    last_seen: moment().subtract(1, 'year'),
                    example: true
                }
            }
        }
    };
    $ctrl.dataRaw = {};
    $ctrl.data = {};
    $ctrl.summaryData = {
        left: {
            title: 'models.event.home.scanning.summaryData.leftTitle.tickets',
            amount: '',
            limit: '/ ?',
            today: {title: 'models.event.home.scanning.summaryData.leftSubTitle', amount: '?'}
        },
        right: {
            title: 'models.event.home.scanning.summaryData.rightTitle.tickets',
            amount: '',
            limit: '',
            today: {title: '', amount: ''}
        }
    };
    $ctrl.tableData = {};
    $ctrl.chartData = {};
    $ctrl.icons = {
        zoom: () => $ctrl.flags.zoom ? 'fa-search-minus' : 'fa-search-plus'
    };
    $ctrl.showScanChart = false;
    $ctrl.chartStart = null;
    $ctrl.chartEnd = null;
    $ctrl.chartGridLines = [];
    $ctrl.timeZone = _.get(Company.cached, [Company.getCurrentId(), 'timezone'], null);

    $ctrl.$onInit = function() {
        $ctrl.isAdmin = Role.isAuthorizedAs('Admin');
        $ctrl.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');

        resetData();

        getFlagsFromStorage();

        setFlag('autoRefresh', false);

        getFiltersFromStorage();

        processFirstTime();

        processEventMode();

        getData().then(() => refreshData());

        watchFilters();

        protectAPI();
    };

    //
    // HELPERS
    //

    /**
     * Process the first time a visitor enters this page
     */
    function processFirstTime() {
        if ($ctrl.flags.firstTime) {
            $ctrl.flags.firstTime = false;

            saveFlagsToStorage();
        }
    }

    /**
     * Process the event mode (NULL, simple, advanced)
     */
    function processEventMode() {
        if ($ctrl.event.gui_mode === 'simple') {
            $ctrl.flags.productInsteadOfTicket = false;

            saveFlagsToStorage();
        }
    }

    //
    // DATA METHODS
    //

    /**
     * Empty the data containers
     */
    function resetData() {
        $ctrl.dataRaw = {};

        _.each($ctrl.sections, (settings, section) => {
            $ctrl.data[section] = {};
            $ctrl.tableData[section] = [];
            $ctrl.chartData[section] = {};
            $ctrl.filters[section] = {accepted: [], blacklist: []};
        });

        // noinspection ES6ModulesDependencies
        let eventStart = moment($ctrl.event.start).utc();
        // noinspection ES6ModulesDependencies
        let eventEnd = moment($ctrl.event.end).utc();

        // noinspection ES6ModulesDependencies
        $ctrl.chartStart = eventStart.clone().subtract(2, 'hour');
        // noinspection ES6ModulesDependencies
        $ctrl.chartEnd = eventEnd.clone().add(2, 'hour');

        $ctrl.chartGridLines = [
            {
                x: eventStart,
                label: 'models.event_date.start'
            },
            {
                x: eventEnd,
                label: 'models.event_date.end'
            }
        ];
    }

    /**
     * Retrieve data from the API.
     *
     * @return {Promise} Promise
     */
    function getData() {
        if ($ctrl.updated === true) {
            return $q.reject('Just updated');
        }

        $ctrl.dataLoading.scanning = true;

        return $http.get(`/event/${$ctrl.event.guid}/scanningstats`).then(response => {
            $ctrl.dataRaw = response.data;

            notifyUpdates();
        }, error => {
            UIMessages.push('common.notice.error');

            $ctrl.dataLoading.scanning = false;

            return $q.reject(error);
        });
    }

    /**
     * Update all static unbound data visualisations.
     */
    function notifyUpdates() {
        if (!$ctrl.dataRaw) {
            return;
        }

        $ctrl.updated = true;

        $ctrl.dataLoading.scanning = true;

        processData();

        updateSummary();

        updateTables();

        updateScanChart();

        $timeout(() => $ctrl.dataLoading.scanning = false, 500);

        $timeout(() => $ctrl.updated = false, 1000);
    }

    /**
     * Manipulate data into their required formats.
     */
    function processData() {
        _.each($ctrl.sections, (settings, section) => {
            let data = $ctrl.data[section] = _.cloneDeep(_.get($ctrl.dataRaw, section, {}));

            let blacklist = _.get(_.get($ctrl.filters, section, {}), 'blacklist', []);
            let accepted = _.get(_.get($ctrl.filters, section, {}), 'accepted', []);

            _.each(data, (instance) => {
                let isBlacklisted = (_.indexOf(blacklist, instance.guid) !== -1);

                if (!isBlacklisted) {
                    arrayPopValue(accepted, instance.guid);

                    accepted.push(instance.guid);
                }
            });
        });
    }

    /**
     * Update the summary data
     */
    function updateSummary() {
        let data;

        if ($ctrl.flags.productInsteadOfTicket) {
            $ctrl.summaryData.left.title = 'models.event.home.scanning.summaryData.leftTitle.products';
            $ctrl.summaryData.right.title = 'models.event.home.scanning.summaryData.rightTitle.products';
            data = _.values(_.get($ctrl.data, 'products', {}));
        } else {
            $ctrl.summaryData.left.title = 'models.event.home.scanning.summaryData.leftTitle.tickets';
            $ctrl.summaryData.right.title = 'models.event.home.scanning.summaryData.rightTitle.tickets';
            data = _.values(_.get($ctrl.data, 'tickets', {}));
        }

        // noinspection JSCheckFunctionSignatures
        let scannedCount = _.sumBy(data, 'scanned_count');
        // noinspection JSCheckFunctionSignatures
        let soldCount = _.sumBy(data, 'sold_count');

        $ctrl.summaryData.left.amount = '' + scannedCount;
        $ctrl.summaryData.left.limit = '/ ' + soldCount;
        $ctrl.summaryData.right.amount = '' + (soldCount - scannedCount);
        $ctrl.summaryData.left.today.amount = calcRatio(scannedCount, soldCount, 2, '-') + '%';
    }

    /**
     * Update the table data for all sections
     */
    function updateTables() {
        let sections = {};

        if (_.size(arguments)) {
            _.each(arguments, (section) => {
                sections[section] = _.get($ctrl.sections, section, {});
            });
        } else {
            sections = $ctrl.sections;
        }

        _.each(sections, (settings, section) => {
            let data = _.get($ctrl.data, section, {});
            // noinspection JSMismatchedCollectionQueryUpdate
            let tableData = $ctrl.tableData[section] = [];
            let accepted = _.get(_.get($ctrl.filters, section, {}), 'accepted', []);

            if (!$ctrl.hasSection(section)) {
                data = _.get(settings, 'examples', {});
            }

            _.each(data, (instance) => {
                let isAccepted = (_.indexOf(accepted, instance.guid) !== -1) || _.get(instance, 'example', false);

                if (section === 'tickets' || section === 'products') {
                    instance.ratio = $ctrl.ratio(instance.scanned_count, instance.sold_count, 2, '-');
                    instance.expected_count = instance.sold_count - instance.scanned_count;
                }

                if (section === 'scannertypes') {
                    // noinspection ES6ModulesDependencies
                    instance.expires_at_diff = moment(_.get(instance, 'expires_at', 0)).diff(moment(), 'days');
                }

                if (section === 'scanners') {
                    // noinspection ES6ModulesDependencies
                    let lastSeen = moment(_.get(instance, 'last_seen', 0));
                    let batteryIcon, battery = _.get(instance, 'battery_percentage', null);
                    let batteryStatus = _.get(instance, 'battery_status', null);
                    let batteryCharging = batteryStatus === 'charging';

                    if (batteryStatus !== 'charging' && batteryStatus !== 'discharging') {
                        instance.battery_status = 'unknown';
                    }

                    // noinspection ES6ModulesDependencies
                    instance.last_seen_diff = moment().diff(lastSeen, 'minutes');

                    if (Number(parseFloat(battery)) !== battery) {
                        batteryIcon = 'fa-question-circle battery-l';
                    } else if (batteryCharging && battery <= 38.5) {
                        batteryIcon = 'fa-plug battery-l';
                    } else if (batteryCharging && battery <= 68.5) {
                        batteryIcon = 'fa-plug battery-m';
                    } else if (batteryCharging) {
                        batteryIcon = 'fa-plug battery-h';
                    } else if (battery > 90.0) {
                        batteryIcon = 'fa-battery-full battery-h';
                    } else if (battery > 68.5) {
                        batteryIcon = 'fa-battery-three-quarters battery-h';
                    } else if (battery > 38.5) {
                        batteryIcon = 'fa-battery-half battery-m';
                    } else if (battery > 15.0) {
                        batteryIcon = 'fa-battery-quarter battery-l';
                    } else {
                        batteryIcon = 'fa-battery-empty battery-l';
                    }

                    instance.battery_icon = batteryIcon;
                }

                if (isAccepted) {
                    tableData.push(instance);
                }
            });
        });
    }

    function updateScanChart() {
        let data = _.get($ctrl.data, 'scan_chart', {});
        let chartData = $ctrl.chartData.scan_chart = {};
        let accepted = _.get(_.get($ctrl.filters, 'tickets', {}), 'accepted', []);

        if ($ctrl.hasSection('scan_chart')) {
            _.each(data, (instance, key) => {
                if (_.indexOf(accepted, key) !== -1) {
                    let morphedInstance = {
                        label: _.get($ctrl.data, ['tickets', key, 'name'], key),
                        data: []
                    };

                    _.each(_.sortBy(instance, 'hour'), dataPoint => {
                        if (_.has(dataPoint, 'hour')) {
                            morphedInstance.data.push({
                                x: _.get(dataPoint, 'hour', 0),
                                y: _.get(dataPoint, 'scan_count', 0)
                            });
                        }
                    });

                    _.set(chartData, key, morphedInstance);
                }
            });

            // We only change the chartStart as we want to strip any empty data from the left of the chart.
            let chartStart = _.chain(chartData).map(item => _.chain(item).get('data', {}).minBy('x').get('x').value()).filter().min().value();

            // noinspection ES6ModulesDependencies
            let eventStart = moment($ctrl.event.start);

            if (!_.isNil(chartStart) && eventStart.isBefore(chartStart)) {
                // noinspection ES6ModulesDependencies
                chartStart = moment(chartStart);
            } else {
                // noinspection ES6ModulesDependencies
                chartStart = moment($ctrl.event.start);
            }

            $ctrl.chartStart = chartStart.subtract(3, 'hour');
        }
    }

    /**
     * Download a CSV of the data on a specific section
     *
     * @param {String} section Section
     */
    function downloadData(section) {
        let data = _.get($ctrl.data, section, {});
        let settings = _.get(_.get($ctrl.sections, section, {}), 'downloads');

        if (_.isNil(settings)) {
            UIMessages.push('Settings for section not found.');

            console.error('Download: Settings for section not found.');

            return;
        }

        // noinspection ES6ModulesDependencies
        let name = moment().format(ATOM_DATETIME_FORMAT) + '_' + _.get(settings, 'label', 'data');
        let fields = _.get(settings, 'fields', []).reduce((o, val) => {
            o[val] = val;
            return o;
        }, {});

        if (_.isNil(fields)) {
            UIMessages.push('No fields marked for download.');

            console.error('Download: No fields marked for download.');

            return;
        }

        CSVExport.convert(_.values(data), fields, name);
    }

    /**
     * Creates a timer that auto refreshes the data
     */
    function refreshData() {
        if (!REFRESH_RATE || !$ctrl.flags.autoRefresh) {
            $timeout(() => refreshData(), REFRESH_RATE);

            return;
        }

        $timeout(() => getData().then(() => refreshData()), REFRESH_RATE);
    }

    /**
     * Protect the API against forgotten tabs/windows
     *
     * This will automatically turn off auto-refresh after a given time.
     */
    function protectAPI() {
        $timeout(() => {
            setFlag('autoRefresh', false);

            protectAPI();
        }, PROTECT_API_TIME);
    }

    //
    // MUTATORS
    //

    /**
     * Calculate ratio between scanned_count and sold_count
     *
     * @param {number} scannedCount Scanned count
     * @param {number} soldCount Sold count
     * @param {number} fixed Fixed count
     * @param {*} defaultReturn Default
     * @returns {*} Return value
     */
    function calcRatio(scannedCount, soldCount, fixed = 2, defaultReturn = NaN) {
        if (soldCount === 0) {
            return defaultReturn;
        }

        return (Math.exp(Math.log(100 * scannedCount) - Math.log(soldCount))).toFixed(fixed);
    }

    /**
     * Returns if a section has data
     *
     * @param {String} section Section
     * @returns {boolean} Whether the section has data
     */
    function hasSection(section) {
        return _.size(_.get($ctrl.data, section, {})) > 0;
    }

    //
    // STORAGE
    //

    /**
     * Retrieve the flags from storage
     */
    function getFlagsFromStorage() {
        let storageFlags = store.get(LOCAL_STORAGE_FLAGS_KEY + '.' + $ctrl.event.guid);

        if (_.isNil(storageFlags)) {
            storageFlags = store.get(LOCAL_STORAGE_FLAGS_KEY);
        }

        if (_.isNil(storageFlags)) {
            return;
        }

        _.each(_.keys($ctrl.flags), function(flag) {
            if (storageFlags.hasOwnProperty(flag)) {
                $ctrl.flags[flag] = storageFlags[flag];
            }
        });
    }

    /**
     * Save the flags to storage
     */
    function saveFlagsToStorage() {
        // Save global flags (for new events)
        store.set(LOCAL_STORAGE_FLAGS_KEY, $ctrl.flags);

        // Save flags for event
        store.set(LOCAL_STORAGE_FLAGS_KEY + '.' + $ctrl.event.guid, $ctrl.flags);
    }

    /**
     * Retrieve all filters from storage
     */
    function getFiltersFromStorage() {
        let storageFilters = store.get(LOCAL_STORAGE_FILTERS_KEY + '.' + $ctrl.event.guid);

        if (_.isNil(storageFilters)) {
            return;
        }

        _.each($ctrl.sections, (settings, section) => {
            if (storageFilters.hasOwnProperty(section)) {
                $ctrl.filters[section] = storageFilters[section];
            }
        });
    }

    /**
     * Save the filters to storage for this event
     */
    function saveFiltersToStorage() {
        store.set(LOCAL_STORAGE_FILTERS_KEY + '.' + $ctrl.event.guid, $ctrl.filters);
    }

    /**
     * Watches to update tables and save filters to local storage when filter is changed
     */
    function watchFilters() {
        _.each($ctrl.sections, (settings, section) => {
            $scope.$watch('$ctrl.filters.' + section + '.accepted', () => {
                let accepted = _.get(_.get($ctrl.filters, section, {}), 'accepted', []);
                let blacklist = _.get(_.get($ctrl.filters, section, {}), 'blacklist', []);

                _.each(_.get($ctrl.data, section, {}), (data, key) => {
                    let isAccepted = (_.indexOf(accepted, key) !== -1);
                    let isBlacklisted = (_.indexOf(blacklist, key) !== -1);

                    if (!isAccepted && !isBlacklisted) {
                        $ctrl.filters[section].blacklist.push(key);
                    } else if (isAccepted && isBlacklisted) {
                        arrayPopValue(blacklist, key);
                    }
                });

                saveFiltersToStorage();

                updateTables(section);

                updateScanChart();
            });
        });
    }

    //
    // FLAGS
    //

    /**
     * Switch flag, run the flag hook and save it to local storage
     *
     * @param {String} flag Flag
     */
    function switchFlag(flag) {
        setFlag(flag, !_.get($ctrl.flags, flag, true));
    }

    /**
     * Set the flag, run the flag hook and store the value in local storage.
     *
     * @param {String} flag Flag
     * @param {boolean} value Value
     */
    function setFlag(flag, value) {
        _.set($ctrl.flags, flag, value);

        postFlag(flag, value);

        saveFlagsToStorage();
    }

    /**
     * Run the flag hook
     *
     * @param {String} flag Flag
     * @param {boolean} value Value
     */
    function postFlag(flag, value) {
        let hook = _.get($ctrl.flagHooks, flag, () => null);

        hook(value);
    }
}
