import template from './kickbacks.html';
import './kickbacks.less';

export default angular.module('eventix.dashboard.kickbacks', [])
    .component('kickbacks', {
        bindings: {
            applicableModel: '=',
            applicableCompany: '=',
            applicableShop: '=',
            appliedType: '@'
        },
        controller: KickbackController,
        templateUrl: template
    }).name;

function KickbackController($state, $scope, $q, $http, $timeout, Ticket, Role, Company, Whitelabel, ConfirmDeleteSweet, SweetAlert, $translate, UIMessages, Currencies) {
    const $ctrl = this;

    /* TODO Error Logging/View (incl breaking errors: abort and view error) */
    /* TODO Figure out how to get price on ticket (from products) */
    /* TODO Fix default kickback for all model types */

    // Define the binding variables (mainly for IDE auto-completion, not for functionality)
    $ctrl.applicableModel = null;
    $ctrl.applicableCompany = null;
    $ctrl.applicableWhitelabel = null;
    $ctrl.applicableShop = null;
    $ctrl.appliedType = null;
    $ctrl.affiliate = null;
    $ctrl.currencies = Currencies;
    $ctrl.currentCurrency = null;
    $ctrl.selectedProfile = null;
    $ctrl.availableProfiles = [];
    $ctrl.amountAddon = '';
    $ctrl.initialized = false;

    const limits = {
        fixed: 500,
        percentage: 10
    };

    // Define placeholders for the model spec
    $ctrl.modelSpec = {
        type: null,
        modelType: null,
        model: null,
        currentModel: () => false,
        toCompany: () => false,
        selfLoop: () => false,
        owned: () => false,
        computeDefaultKickback: () => {
            return {};
        }
    };

    const MODEL_MAP = {
        TicketTicket: {
            type: 'ticket',
            modelType: 'ticket',
            modelIcon: 'fa fa-ticket',
            allowedTypes: [1, 3, 5],
            currentModel: (kickback) => {
                let appliesTo = (_.get(kickback, 'applies_to', false) === 'App\\Models\\Ticket');
                let appliesToId = (_.get(kickback, 'applies_to_id', false) === _.get($ctrl.applicableModel, 'guid', true));
                let applicableType = (_.get(kickback, 'applicable_type', false) === 'App\\Models\\Company');
                let applicableId = (_.get(kickback, 'applicable_id', false) === _.get($ctrl.applicableCompany, 'guid', true));

                return appliesTo && appliesToId && applicableType && applicableId;
            },
            toCompany: (kickback) => {
                return (_.get(kickback, 'payed_to_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));
            },
            selfLoop: (kickback) => {
                let payedTo = (_.get(kickback, 'payed_to_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));
                let payedBy = (_.get(kickback, 'payed_by_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));

                return payedTo && payedBy;
            },
            owned: (kickback) => {
                return (_.get(kickback, 'company_id', false) === _.get($ctrl.applicableCompany, 'guid', true));
            },
            computeDefaultKickback: () => {
                return {
                    company_id: $ctrl.affiliate || ($ctrl.isAdmin ? _.get($ctrl.applicableWhitelabel, 'owning_company') : _.get($ctrl.applicableCompany, 'guid')),
                    payed_to_guid: $ctrl.affiliate || ($ctrl.isAdmin ? _.get($ctrl.applicableWhitelabel, 'owning_company') : _.get($ctrl.applicableCompany, 'guid')),
                    payed_by_guid: _.get($ctrl.applicableCompany, 'guid'),
                    applicable_id: _.get($ctrl.applicableCompany, 'guid'),
                    applicable_type: 'App\\Models\\Company',
                    applies_to: 'App\\Models\\Ticket',
                    applies_to_id: _.get($ctrl.applicableModel, 'guid'),
                    amount: 20,
                    tax_percentage: 21,
                    type: 'fixed',
                    subtracted: false,
                    includes_tax: true,
                    currency: $ctrl.currentCurrency,
                    payment_profile_id: null,
                    deducted: false
                };
            }
        },
        TicketShopTicket: {
            type: 'ticket',
            modelType: 'ticket',
            modelIcon: 'fa fa-ticket',
            allowedTypes: [2, 4, 5],
            currentModel: (kickback) => {
                let appliesTo = (_.get(kickback, 'applies_to', false) === 'App\\Models\\Ticket');
                let appliesToId = (_.get(kickback, 'applies_to_id', false) === _.get($ctrl.applicableModel, 'guid', true));
                let applicableType = (_.get(kickback, 'applicable_type', false) === 'App\\Models\\Shop');
                let applicableId = (_.get(kickback, 'applicable_id', false) === _.get($ctrl.applicableShop, 'guid', true));

                return appliesTo && appliesToId && applicableType && applicableId;
            },
            toCompany: (kickback) => {
                return (_.get(kickback, 'payed_to_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));
            },
            selfLoop: (kickback) => {
                let payedTo = (_.get(kickback, 'payed_to_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));
                let payedBy = (_.get(kickback, 'payed_by_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));

                return payedTo && payedBy;
            },
            owned: (kickback) => {
                return (_.get(kickback, 'company_id', false) === _.get($ctrl.applicableCompany, 'guid', true));
            },
            computeDefaultKickback: () => {
                return {
                    company_id: $ctrl.affiliate || ($ctrl.isAdmin ? _.get($ctrl.applicableWhitelabel, 'owning_company') : _.get($ctrl.applicableCompany, 'guid')),
                    payed_to_guid: $ctrl.affiliate || ($ctrl.isAdmin ? _.get($ctrl.applicableWhitelabel, 'owning_company') : _.get($ctrl.applicableCompany, 'guid')),
                    payed_by_guid: _.get($ctrl.applicableCompany, 'guid'),
                    applicable_id: _.get($ctrl.applicableShop, 'guid'),
                    applicable_type: 'App\\Models\\Shop',
                    applies_to: 'App\\Models\\Ticket',
                    applies_to_id: _.get($ctrl.applicableModel, 'guid'),
                    amount: 20,
                    tax_percentage: 21,
                    type: 'fixed',
                    subtracted: false,
                    includes_tax: true,
                    currency: $ctrl.currentCurrency,
                    payment_profile_id: null,
                    deducted: false
                };
            }
        },
        CompanyTicket: {
            type: 'ticket',
            modelType: 'company',
            modelIcon: 'fa fa-ticket',
            allowedTypes: [1, 3, 5],
            currentModel: (kickback) => {
                let appliesTo = (_.get(kickback, 'applies_to', false) === 'App\\Models\\Ticket');
                let appliesToId = (_.get(kickback, 'applies_to_id', false) === null);
                let applicableType = (_.get(kickback, 'applicable_type', false) === 'App\\Models\\Company');
                let applicableId = (_.get(kickback, 'applicable_id', false) === _.get($ctrl.applicableCompany, 'guid', true));

                return appliesTo && appliesToId && applicableType && applicableId;
            },
            toCompany: (kickback) => {
                return (_.get(kickback, 'payed_to_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));
            },
            selfLoop: (kickback) => {
                let payedTo = (_.get(kickback, 'payed_to_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));
                let payedBy = (_.get(kickback, 'payed_by_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));

                return payedTo && payedBy;
            },
            owned: (kickback) => {
                return (_.get(kickback, 'company_id', false) === _.get($ctrl.applicableCompany, 'guid', true));
            },
            computeDefaultKickback: () => {
                return {
                    company_id: $ctrl.affiliate || ($ctrl.isAdmin ? _.get($ctrl.applicableWhitelabel, 'owning_company') : _.get($ctrl.applicableCompany, 'guid')),
                    payed_to_guid: $ctrl.affiliate || ($ctrl.isAdmin ? _.get($ctrl.applicableWhitelabel, 'owning_company') : _.get($ctrl.applicableCompany, 'guid')),
                    payed_by_guid: _.get($ctrl.applicableCompany, 'guid'),
                    applicable_id: _.get($ctrl.applicableCompany, 'guid'),
                    applicable_type: 'App\\Models\\Company',
                    applies_to: 'App\\Models\\Ticket',
                    applies_to_id: null,
                    amount: 0,
                    tax_percentage: 21,
                    type: 'fixed',
                    subtracted: false,
                    includes_tax: true,
                    currency: $ctrl.currentCurrency,
                    payment_profile_id: null,
                    deducted: false
                };
            }
        },
        CompanyShopTicket: {
            type: 'ticket',
            modelType: 'company',
            modelIcon: 'fa fa-ticket',
            allowedTypes: [2, 4, 5],
            currentModel: (kickback) => {
                let appliesTo = (_.get(kickback, 'applies_to', false) === 'App\\Models\\Ticket');
                let appliesToId = (_.get(kickback, 'applies_to_id', false) === null);
                let applicableType = (_.get(kickback, 'applicable_type', false) === 'App\\Models\\Shop');
                let applicableId = (_.get(kickback, 'applicable_id', false) === _.get($ctrl.applicableShop, 'guid', true));

                return appliesTo && appliesToId && applicableType && applicableId;
            },
            toCompany: (kickback) => {
                return (_.get(kickback, 'payed_to_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));
            },
            selfLoop: (kickback) => {
                let payedTo = (_.get(kickback, 'payed_to_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));
                let payedBy = (_.get(kickback, 'payed_by_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));

                return payedTo && payedBy;
            },
            owned: (kickback) => {
                return (_.get(kickback, 'company_id', false) === _.get($ctrl.applicableCompany, 'guid', true));
            },
            computeDefaultKickback: () => {
                return {
                    company_id: $ctrl.affiliate || ($ctrl.isAdmin ? _.get($ctrl.applicableWhitelabel, 'owning_company') : _.get($ctrl.applicableCompany, 'guid')),
                    payed_to_guid: $ctrl.affiliate || ($ctrl.isAdmin ? _.get($ctrl.applicableWhitelabel, 'owning_company') : _.get($ctrl.applicableCompany, 'guid')),
                    payed_by_guid: _.get($ctrl.applicableCompany, 'guid'),
                    applicable_id: _.get($ctrl.applicableShop, 'guid'),
                    applicable_type: 'App\\Models\\Shop',
                    applies_to: 'App\\Models\\Ticket',
                    applies_to_id: null,
                    amount: 0,
                    tax_percentage: 21,
                    type: 'fixed',
                    subtracted: false,
                    includes_tax: true,
                    currency: $ctrl.currentCurrency,
                    payment_profile_id: null,
                    deducted: false
                };
            }
        },

        PaymentMethodPaymentMethod: {
            type: 'method',
            modelType: 'method',
            modelIcon: 'fa fa-credit-card',
            allowedTypes: [1, 3, 5],
            currentModel: (kickback) => {
                let appliesTo = (_.get(kickback, 'applies_to', false) === 'App\\Models\\Payment\\Method');
                let appliesToId = (_.get(kickback, 'applies_to_id', false) === _.get($ctrl.applicableModel, 'guid', true));
                let applicableType = (_.get(kickback, 'applicable_type', false) === 'App\\Models\\Company');
                let applicableId = (_.get(kickback, 'applicable_id', false) === _.get($ctrl.applicableCompany, 'guid', true));

                return appliesTo && appliesToId && applicableType && applicableId;
            },
            toCompany: (kickback) => {
                return (_.get(kickback, 'payed_to_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));
            },
            selfLoop: (kickback) => {
                let payedTo = (_.get(kickback, 'payed_to_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));
                let payedBy = (_.get(kickback, 'payed_by_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));

                return payedTo && payedBy;
            },
            owned: (kickback) => {
                return (_.get(kickback, 'company_id', false) === _.get($ctrl.applicableCompany, 'guid', true));
            },
            computeDefaultKickback: () => {
                return {
                    company_id: $ctrl.affiliate || ($ctrl.isAdmin ? _.get($ctrl.applicableWhitelabel, 'owning_company') : _.get($ctrl.applicableCompany, 'guid')),
                    payed_to_guid: $ctrl.affiliate || ($ctrl.isAdmin ? _.get($ctrl.applicableWhitelabel, 'owning_company') : _.get($ctrl.applicableCompany, 'guid')),
                    payed_by_guid: _.get($ctrl.applicableCompany, 'guid'),
                    applicable_id: _.get($ctrl.applicableCompany, 'guid'),
                    applicable_type: 'App\\Models\\Company',
                    applies_to: 'App\\Models\\Payment\\Method',
                    applies_to_id: _.get($ctrl.applicableModel, 'guid'),
                    amount: 20,
                    tax_percentage: 21,
                    type: 'fixed',
                    subtracted: false,
                    includes_tax: true,
                    currency: $ctrl.currentCurrency,
                    payment_profile_id: $ctrl.selectedProfile,
                    deducted: false
                };
            }
        },
        PaymentMethodShopPaymentMethod: {
            type: 'method',
            modelType: 'method',
            modelIcon: 'fa fa-credit-card',
            allowedTypes: [2, 4, 5],
            currentModel: (kickback) => {
                let appliesTo = (_.get(kickback, 'applies_to', false) === 'App\\Models\\Payment\\Method');
                let appliesToId = (_.get(kickback, 'applies_to_id', false) === _.get($ctrl.applicableModel, 'guid', true));
                let applicableType = (_.get(kickback, 'applicable_type', false) === 'App\\Models\\Shop');
                let applicableId = (_.get(kickback, 'applicable_id', false) === _.get($ctrl.applicableShop, 'guid', true));

                return appliesTo && appliesToId && applicableType && applicableId;
            },
            toCompany: (kickback) => {
                return (_.get(kickback, 'payed_to_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));
            },
            selfLoop: (kickback) => {
                let payedTo = (_.get(kickback, 'payed_to_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));
                let payedBy = (_.get(kickback, 'payed_by_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));

                return payedTo && payedBy;
            },
            owned: (kickback) => {
                return (_.get(kickback, 'company_id', false) === _.get($ctrl.applicableCompany, 'guid', true));
            },
            computeDefaultKickback: () => {
                return {
                    company_id: $ctrl.affiliate || ($ctrl.isAdmin ? _.get($ctrl.applicableWhitelabel, 'owning_company') : _.get($ctrl.applicableCompany, 'guid')),
                    payed_to_guid: $ctrl.affiliate || ($ctrl.isAdmin ? _.get($ctrl.applicableWhitelabel, 'owning_company') : _.get($ctrl.applicableCompany, 'guid')),
                    payed_by_guid: _.get($ctrl.applicableCompany, 'guid'),
                    applicable_id: _.get($ctrl.applicableShop, 'guid'),
                    applicable_type: 'App\\Models\\Shop',
                    applies_to: 'App\\Models\\Payment\\Method',
                    applies_to_id: _.get($ctrl.applicableModel, 'guid'),
                    amount: 20,
                    tax_percentage: 21,
                    type: 'fixed',
                    subtracted: false,
                    includes_tax: true,
                    currency: $ctrl.currentCurrency,
                    payment_profile_id: $ctrl.selectedProfile,
                    deducted: false
                };
            }
        },
        CompanyPaymentMethod: {
            type: 'method',
            modelType: 'company',
            modelIcon: 'fa fa-credit-card',
            allowedTypes: [1, 3, 5],
            currentModel: (kickback) => {
                let appliesTo = (_.get(kickback, 'applies_to', false) === 'App\\Models\\Payment\\Method');
                let appliesToId = (_.get(kickback, 'applies_to_id', false) === null);
                let applicableType = (_.get(kickback, 'applicable_type', false) === 'App\\Models\\Company');
                let applicableId = (_.get(kickback, 'applicable_id', false) === _.get($ctrl.applicableCompany, 'guid', true));

                return appliesTo && appliesToId && applicableType && applicableId;
            },
            toCompany: (kickback) => {
                return (_.get(kickback, 'payed_to_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));
            },
            selfLoop: (kickback) => {
                let payedTo = (_.get(kickback, 'payed_to_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));
                let payedBy = (_.get(kickback, 'payed_by_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));

                return payedTo && payedBy;
            },
            owned: (kickback) => {
                return (_.get(kickback, 'company_id', false) === _.get($ctrl.applicableCompany, 'guid', true));
            },
            computeDefaultKickback: () => {
                return {
                    company_id: $ctrl.affiliate || ($ctrl.isAdmin ? _.get($ctrl.applicableWhitelabel, 'owning_company') : _.get($ctrl.applicableCompany, 'guid')),
                    payed_to_guid: $ctrl.affiliate || ($ctrl.isAdmin ? _.get($ctrl.applicableWhitelabel, 'owning_company') : _.get($ctrl.applicableCompany, 'guid')),
                    payed_by_guid: _.get($ctrl.applicableCompany, 'guid'),
                    applicable_id: _.get($ctrl.applicableCompany, 'guid'),
                    applicable_type: 'App\\Models\\Company',
                    applies_to: 'App\\Models\\Payment\\Method',
                    applies_to_id: null,
                    amount: 0,
                    tax_percentage: 21,
                    type: 'fixed',
                    subtracted: false,
                    includes_tax: true,
                    currency: $ctrl.currentCurrency,
                    payment_profile_id: $ctrl.selectedProfile,
                    deducted: false
                };
            }
        },
    };

    // State variables
    $ctrl.busy = 0;

    $ctrl.hasContract = false;
    $ctrl.canAdmin = false;
    $ctrl.isAdmin = false;
    $ctrl.asOrganiser = true;

    $ctrl.warnings = {
        general: {
            expand: false
        },
        model: {
            expand: false
        }
    };

    // View functions
    $ctrl.toggleAsOrganiser = toggleAsOrganiser;
    $ctrl.getKickbackQuantifier = getKickbackQuantifier;
    $ctrl.contactSupport = contactSupport;
    $ctrl.manageAsAffiliate = manageAsAffiliate;
    $ctrl.resetAffiliate = resetAffiliateContainer;

    // Raw data
    $ctrl.data = {};

    // Containers
    $ctrl.globalFees = {};
    $ctrl.kickbacks = [];
    $ctrl.totalFees = {
        fixed: 0,
        percentage: 0
    };
    $ctrl.kickbackInput = null;
    $ctrl.examplePrice = {
        base: 0,
        other: 0,
        self: 0,
        total: 0
    };

    $ctrl.quantifiers = {
        fixed: '?',
        percentage: '%'
    };

    /* //////////////////////////////// */
    /*         MAIN OPERATIONS          */
    /* //////////////////////////////// */

    // Set general data on the controller instance.
    setup();

    $ctrl.$onInit = function() {
        console.debug('appliedType:', $ctrl.appliedType);
        console.debug('Company:', $ctrl.applicableCompany);
        console.debug('Model:', $ctrl.applicableModel);
        console.debug('Shop:', $ctrl.applicableShop);

        $ctrl.currentCurrency = _.get($ctrl.applicableCompany, 'currency', 'EUR');
        $ctrl.busy++;
        $http.get('/company/settings').then((response) => {
            $ctrl.companySettings = response.data;
            $ctrl.selectedProfile = $ctrl.companySettings.payment_profile_id;

            $http.get('/company/settings/paymentprofiles').then(res => {
                $ctrl.availableProfiles = res.data;
                _.forEach($ctrl.availableProfiles, (profile) => {
                    if (profile.guid === $ctrl.companySettings.payment_profile_id) {
                        profile.name = profile.name + "( Currently Active)";
                    }
                });
                $ctrl.initialized = true;

                $ctrl.busy--;
                getData();
            });
        });

        // Retrieve the model spec from the model map for easy retrieval.
        // And setup the most basic settings.
        setupModelSpec().then(() => {
            // (Pre-)set the fees data from the model. (At least something data is visible until the kickbacks are loaded).
            setupFees();

            // Reset all containers
            resetAllContainers();

            // Keep all computed values/containers up to date when the data or input changes.
            // [GlobalFeesContainer, KickbacksContainer, TotalFees, ExamplePrice]
            watchAllContainers();

            done(true);

            // Retrieve the kickbacks specific to this model and update the view.
            getData();
        });
    };

    /* //////////////////////////////// */
    /*         SETUP OPERATIONS         */

    /* //////////////////////////////// */

    function setup() {
        busy();

        $ctrl.canAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');

        $ctrl.hasContract = Role.isAuthorizedAs('Has Contract');

        if (!$ctrl.hasContract) done(true);
    }

    function setupModelSpec() {
        if (!$ctrl.hasContract) return errorRejection('Not authorized to view kickbacks');

        if (!$ctrl.applicableCompany || !$ctrl.appliedType)
            return errorRejection('Cannot initiate kickbacks component without the correct input.');

        let modelType;

        if (!$ctrl.applicableModel)
            modelType = _.get($ctrl.applicableCompany, 'constructor.modelName');
        else
            modelType = _.get($ctrl.applicableModel, 'constructor.modelName');


        if ($ctrl.applicableShop)
            modelType += _.get($ctrl.applicableShop, 'constructor.modelName');

        modelType += $ctrl.appliedType;

        console.debug('> Setting up modelSpec for: ', modelType);

        $ctrl.modelSpec = _.get(MODEL_MAP, modelType);

        // This should never happen, but 'nice' to get an error when changing the spec.
        if (!$ctrl.modelSpec)
            return errorRejection('Kickback component not loaded correctly. Applied-type does not exist');
        else if (!$ctrl.modelSpec.computeDefaultKickback)
            return errorRejection('Model type [' + modelType + '] is missing a default kickback callback');

        $ctrl.modelSpec.currentModel = _.get($ctrl.modelSpec, 'currentModel', () => false);
        $ctrl.modelSpec.toCompany = _.get($ctrl.modelSpec, 'toCompany', () => false);
        $ctrl.modelSpec.selfLoop = _.get($ctrl.modelSpec, 'selfLoop', () => false);
        $ctrl.modelSpec.owned = _.get($ctrl.modelSpec, 'owned', () => false);

        // Set the fixed quantifier to the company's currency.
        _.set($ctrl.quantifiers, 'fixed', $ctrl.currentCurrency || '?');

        let promise = $q.resolve('Setup model spec successful');

        return Whitelabel.get({guid: $ctrl.applicableCompany.whitelabel_id})
            .then(whitelabel => $ctrl.applicableWhitelabel = whitelabel)
            .then(() => promise);
    }

    function setupFees() {
        // (Pre-)set the totals from the current data
        updateTotalFeesContainer();

        // (Pre-)set the example prices iff applicable
        updateExamplePriceContainer();
    }

    /* //////////////////////////////// */
    /*    RESET CONTAINER OPERATIONS    */

    /* //////////////////////////////// */

    function resetAllContainers() {
        console.debug('> RESETTING ALL CONTAINERS');

        busy();

        resetGlobalFeesContainer();

        resetKickbacksContainer();

        resetTotalFeesContainer();

        resetKickbackInputContainer();

        resetAffiliateContainer();

        resetExamplePriceContainer();

        done(true);

        console.debug('  > DONE RESETTING ALL CONTAINERS');
    }

    function resetGlobalFeesContainer() {
        console.debug('  > Resetting global fees container');

        busy();

        $ctrl.globalFees = {};
        _.set($ctrl.globalFees, _.get($ctrl.applicableWhitelabel, 'owning_company', 'self'), {name: _.get($ctrl.applicableWhitelabel, 'name', 'Whitelabel'), fixed: 0});

        _.forEach($ctrl.data, (data, companyId) => {
            _.set($ctrl.globalFees, companyId + '.name', _.get(data, 'name', 'other'));

            let fixed = _.get(data, 'stats.fixed', 0);
            let percentage = _.get(data, 'stats.percentages', 0);

            if ( fixed || !percentage)
                _.set($ctrl.globalFees, companyId + '.fixed', _.get(data, 'stats.fixed', 0));

            if (percentage)
                _.set($ctrl.globalFees, companyId + '.percentage', _.get(data, 'stats.percentages', 0));
        });

        done(true);

        console.debug('    > Done resetting global fees container', _.cloneDeep($ctrl.globalFees));
    }

    function resetKickbacksContainer() {
        console.debug('  > Resetting kickbacks container');

        busy();

        $ctrl.kickbacks = [];

        $ctrl.kickbacks.has = {
            get percentage() {
                return $ctrl.kickbacks.filter(kickback => kickback.dirty.type === 'percentage').length;
            },
            get percentage_owned() {
                return $ctrl.kickbacks.filter(kickback => kickback.dirty.type === 'percentage' && kickback.toCompany === true).length;
            },
            get fixed() {
                return $ctrl.kickbacks.filter(kickback => kickback.dirty.type === 'fixed').length;
            },
            get fixed_owned() {
                return $ctrl.kickbacks.filter(kickback => kickback.dirty.type === 'fixed' && kickback.toCompany === true).length;
            }
        };

        done(true);

        console.debug('    > Done resetting kickbacks container', _.cloneDeep($ctrl.kickbacks));
    }

    function resetTotalFeesContainer() {
        console.debug('  > Resetting total fees container');

        busy();

        $ctrl.totalFees = {
            fixed: 0,
            percentage: 0
        };

        done(true);

        console.debug('    > Done resetting total fees container', _.cloneDeep($ctrl.totalFees));
    }

    function resetKickbackInputContainer() {
        console.debug('  > Resetting kickback input container');

        busy();

        $ctrl.kickbackInput = (new KickbackInput());

        done(true);

        console.debug('    > Done resetting kickback input container', _.cloneDeep($ctrl.kickbackInput));
    }

    function resetAffiliateContainer() {
        console.debug('  > Resetting affiliate container');

        busy();

        $ctrl.affiliate = null;

        done(true);

        console.debug('    > Done resetting affiliate container', _.cloneDeep($ctrl.affiliate));

    }

    function resetExamplePriceContainer() {
        if (!($ctrl.appliedType === 'Ticket'))
            return;

        console.debug('  > Resetting example price container');

        busy();

        $ctrl.examplePrice = {
            base: _.get($ctrl.applicableModel, 'minimumPrice') || _.get($ctrl.applicableModel, 'price') || _.get($ctrl.applicableModel, 'min_price') || 1000,
            other: 0,
            self: 0,
            total: 0
        };

        // if (!$ctrl.examplePrice.base || $ctrl.examplePrice.base === 0)
        //     $ctrl.examplePrice.base = 1000;

        done(true);

        console.debug('    > Done resetting example price container', _.cloneDeep($ctrl.examplePrice));
    }

    function resetEditing() {
        console.debug('  > Resetting kickback editing');

        busy();

        // Remove reference to kickback from the input container
        resetKickbackInputContainer();

        // Reset dirty kickbacks and remove new kickbacks from the kickbacks container
        _.remove($ctrl.kickbacks, (kickback) => {
            console.debug('    > Resetting kickback: ', kickback);

            if (!kickback.model) {
                console.debug('      > New kickback removed: ', kickback);

                return true;
            }

            kickback.editing = false;

            // Reset dirty
            kickback.resetDirty();

            console.debug('      > Kickback reset', kickback);

            return false;
        });

        done(true);

        console.debug('    > Done resetting kickback editing');

        return $q.resolve(true);
    }

    /* //////////////////////////////// */
    /*   UPDATE CONTAINER OPERATIONS    */

    /* //////////////////////////////// */

    function updateGlobalFeesContainer() {
        console.debug('> Updating global fees container');

        busy();

        resetGlobalFeesContainer();

        // The global fees contain all kickbacks. However, we also load the kickbacks DIRECTLY applied to the current model.
        // These amounts will be (falsely) included in the global scope if they are not subtracted.
        _.forEach($ctrl.kickbacks, (kickback) => {
            // Ignore new kickbacks (they are by default not in the fees retrieved from the api) and subtractive kickbacks
            if (!kickback.model || _.get(kickback.model, 'subtracted', false))
                return;

            console.debug('  > Subtracting kickback: ', kickback.model.payed_to_guid, kickback.model.amount);

            let to = kickback.model.payed_to_guid;
            let type = kickback.dirty.type;

            // Decrement the current value, unfortunately lodash does not have a decrement method.
            // Directly decrementing is a but error-prone.
            _.set($ctrl.globalFees, to + '.' + type, _.get($ctrl.globalFees, to + '.' + type, 0) - kickback.model.amount);
        });

        done(true);

        console.debug('  > done updating global fees container', _.cloneDeep($ctrl.globalFees));
    }

    function updateKickbacksContainer() {
        console.debug('> Updating kickbacks container', $ctrl.data);

        busy();

        resetKickbacksContainer();

        // Here all kickbacks that do NOT belong to the current model are filtered out of the list.
        // The filter is different depending on type and model.
        _.forEach($ctrl.data, data => {
            let name = _.get(data, 'name', '?');

            _.chain(data)
                .get('kickbacks', [])
                .filter($ctrl.modelSpec.currentModel)
                .forEach((filteredKickback) => {
                    console.debug('  > Adding kickback', filteredKickback.amount);

                    let kickback = new Kickback();

                    kickback.setModel(filteredKickback)
                        .resetDirty()
                        .setEditing(false)
                        .setPayedTo(name);

                    $ctrl.kickbacks.push(kickback);
                }).value();
        });

        done(true);

        console.debug('  ---');
        console.debug('  > Done updating kickbacks container', _.cloneDeep($ctrl.kickbacks));
    }

    function updateTotalFeesContainer() {
        console.debug('> Updating Total Fees Container');

        busy();

        resetTotalFeesContainer();

        _.forEach($ctrl.globalFees, (fees) => {
            $ctrl.totalFees.fixed += _.get(fees, 'fixed', 0);
            $ctrl.totalFees.percentage += _.get(fees, 'percentage', 0);
        });

        $ctrl.totalFees.fixed += _.chain($ctrl.kickbacks)
            .filter((kickback) => {return _.get(kickback.dirty, 'type', false) === 'fixed';})
            .reject((kickback) => {return _.get(kickback.dirty, 'subtracted', false);})
            .sumBy((kickback) => {return _.get(kickback.dirty, 'amount', 0);})
            .value();

        $ctrl.totalFees.percentage += _.chain($ctrl.kickbacks)
            .filter((kickback) => {return _.get(kickback.dirty, 'type', false) === 'percentage';})
            .reject((kickback) => {return _.get(kickback.dirty, 'subtracted', false);})
            .sumBy((kickback) => {return _.get(kickback.dirty, 'amount', 0);})
            .value();

        done(true);

        console.debug('  > Done updating total fees container', _.cloneDeep($ctrl.totalFees));
    }

    function updateExamplePriceContainer() {
        if (!($ctrl.appliedType === 'Ticket'))
            return;

        console.debug('> Updating example price container');

        busy();

        resetExamplePriceContainer();

        let basePrice = $ctrl.examplePrice.base;

        _.forEach($ctrl.globalFees, (fees, companyId) => {
            if (companyId === _.get($ctrl.applicableCompany, 'guid', false)) {
                $ctrl.examplePrice.self += _.get(fees, 'fixed', 0);
                $ctrl.examplePrice.self += (basePrice * (_.get(fees, 'percentage', 0) / 100));
            } else {
                $ctrl.examplePrice.other += _.get(fees, 'fixed', 0);
                $ctrl.examplePrice.other += (basePrice * (_.get(fees, 'percentage', 0) / 100));
            }
        });

        _.forEach($ctrl.kickbacks, (kickback) => {
            if (_.get(kickback.dirty, 'subtracted', false))
                return;

            let amount = kickback.dirty.amount;
            let to = kickback.toCompany ? 'self' : 'other';

            if (_.get(kickback.dirty, 'type') === 'percentage')
                amount = (basePrice * (amount / 100));

            _.set($ctrl.examplePrice, to, _.get($ctrl.examplePrice, to, 0) + amount);
        });

        $ctrl.examplePrice.total = basePrice + $ctrl.examplePrice.other + $ctrl.examplePrice.self;

        done(true);

        console.debug('  > Done updating example price container', _.cloneDeep($ctrl.examplePrice));
    }

    /* //////////////////////////////// */
    /*         WATCH OPERATIONS         */

    /* //////////////////////////////// */

    function watchAllContainers() {
        console.debug('> INITIALIZING ALL WATCHERS');

        watchCurrency();

        watchProfile();

        watchDataContainer();

        watchGlobalFeesContainer();

        watchKickbacksContainer();

        watchTotalFeesContainer();

        watchKickbackInputContainer();

        watchAffiliateContainer();

        watchExamplePriceContainer();

        console.debug('  > DONE INITIALIZING ALL WATCHERS');
    }

    function watchCurrency() {
        $scope.$watch('$ctrl.currentCurrency', (newVal, oldVal) => {
            if(newVal !== oldVal)
                resetEditing().then(() => {
                    getData();
                });
        }, true);
    }

    function watchProfile() {
        $scope.$watch('$ctrl.selectedProfile', (newVal, oldVal) => {
            if(newVal !== oldVal)
                resetEditing().then(() => {
                    getData();
                });
        }, true);
    }

    function watchDataContainer() {
        console.debug('  > Initializing data watcher');

        $scope.$watch('$ctrl.data', () => {
            console.debug('    > W: data', _.cloneDeep($ctrl.data));

            busy();

            updateKickbacksContainer();

            updateGlobalFeesContainer();

            done(true);

            console.debug('    > W/: data');
        }, true);

        console.debug('    > Done initializing data watcher');
    }

    function watchGlobalFeesContainer() {
        console.debug('  > Initializing global fees watcher');

        $scope.$watch('$ctrl.globalFees', () => {
            console.debug('    > W: globalFees', _.cloneDeep($ctrl.globalFees));

            busy();

            updateTotalFeesContainer();

            updateExamplePriceContainer();

            done(true);

            console.debug('    > W/: globalFees');
        }, true);

        console.debug('    > Done initializing global fees watcher');
    }

    function watchKickbacksContainer() {
        console.debug('  > Initializing kickbacks watcher');

        $scope.$watch(() => {
            return _.map($ctrl.kickbacks, _.iteratee('dirty'));
        }, () => {
            console.debug('    > W: kickbacks', _.cloneDeep($ctrl.kickbacks));

            busy();

            updateTotalFeesContainer();

            updateExamplePriceContainer();

            done(true);

            console.debug('    > W/: kickbacks');
        }, true);

        console.debug('    > Done initializing kickbacks watcher');
    }

    function watchTotalFeesContainer() {
        console.debug('  > Initializing total fees watcher');

        $scope.$watch('$ctrl.totalFees', () => {
            console.debug('    > W: totalFees', _.cloneDeep($ctrl.totalFees));

            busy();

            // Nothing to do

            done(true);

            console.debug('    > W/: totalFees');
        }, true);

        console.debug('    > Done initializing total fees watcher');
    }

    function watchKickbackInputContainer() {
        console.debug('  > Initializing kickback input watcher');

        // Watch only the dirty amount!
        $scope.$watch('$ctrl.kickbackInput.kickback.dirty.amount', (newValue, oldValue) => {
            if (!newValue)
                return;

            console.debug('    > W: kickbackInput', _.cloneDeep($ctrl.kickbackInput));

            busy();

            if (oldValue !== undefined && !_.isEqual(newValue, oldValue)) {
                let type = $ctrl.kickbackInput.kickback.dirty.type;
                let totalFee = _.get($ctrl.totalFees, type, 0);
                let upperLimit = _.get(limits, type, 0);
                let amount = $ctrl.kickbackInput.kickback.dirty.amount;

                if ($ctrl.kickbackInput.sign)
                    $ctrl.kickbackInput.kickback.dirty.amount = Math.abs(amount) * $ctrl.kickbackInput.sign;

                if (totalFee > upperLimit && !$ctrl.isAdmin) {
                    $ctrl.kickbackInput.kickback.dirty.amount = amount - (totalFee - upperLimit);

                    $ctrl.kickbackInput.kickback.error('upper_limit');
                } else if (totalFee < 0) {
                    $ctrl.kickbackInput.kickback.dirty.amount = amount - totalFee;

                    $ctrl.kickbackInput.kickback.error('lower_limit');
                }
            }

            done(true);

            console.debug('    > W/: kickbackInput');
        }, true);

        console.debug('    > Done initializing kickback input watcher');
    }

    function watchAffiliateContainer() {
        console.debug('  > Initializing affiliate watcher');

        $scope.$watch('$ctrl.affiliate', (newAffiliate, oldAffiliate) => {
            if (_.isEqual(newAffiliate, oldAffiliate))
                return;

            console.debug('    > W: affiliate', _.cloneDeep($ctrl.affiliate));

            resetEditing();

            getData();

            console.debug('    > W/: affiliate');
        }, true);

        console.debug('    > Done initializing affiliate watcher');
    }

    function watchExamplePriceContainer() {
        console.debug('  > Initializing example price watcher');

        $scope.$watch('$ctrl.examplePrice', () => {
            console.debug('    > W: examplePrice', _.cloneDeep($ctrl.examplePrice));

            busy();

            // Nothing to do

            done(true);

            console.debug('    > W/: examplePrice');
        }, true);

        console.debug('    > Done initializing example price watcher');
    }

    /* //////////////////////////////// */
    /*       GET DATA OPERATIONS        */

    /* //////////////////////////////// */

    function getData() {
        console.debug('> Getting data');

        if(!$ctrl.initialized) {
            return;
        }

        if (isBusy())
            return errorRejection('Can not get data. The system is busy. Please try again later');

        busy();

        let uri = `/kickback/withfees/${$ctrl.currentCurrency}/${$ctrl.selectedProfile}/${$ctrl.modelSpec.type}`;

        let options = {
            model_type: $ctrl.modelSpec.modelType,
            model_id: $ctrl.applicableCompany.guid,
            company_id: $ctrl.applicableCompany.guid
        };

        if ($ctrl.applicableModel)
            options.model_id = $ctrl.applicableModel.guid;

        if ($ctrl.affiliate)
            options.interest = $ctrl.affiliate;

        if ($ctrl.applicableShop)
            options.shop_id = $ctrl.applicableShop.guid;

        console.debug('  > URI: ', uri, options);

        return $http.post(uri, options).then((response) => {
            console.debug('  > Retrieved data: ', _.cloneDeep(response.data));

            $ctrl.data = response.data;

            return $q.resolve($ctrl.data);
        }).catch(error => {
            return errorRejection(error);
        }).finally(() => {
            done(false);

            console.debug('  > Done getting data');
        });
    }

    /* //////////////////////////////// */
    /*       RESOURCE OPERATIONS        */

    /* //////////////////////////////// */

    function Kickback() {
        return {
            _model: null,
            payedToName: '',
            dirty: null,
            $errors: {},
            editing: true,
            get sign() {
                if (!this.dirty || this.dirty.amount === undefined)
                    return undefined;

                return (this.dirty.amount >= 0) ? 1 : -1;
            },
            get selfLoop() {
                if (!this.dirty)
                    return undefined;

                return $ctrl.modelSpec.selfLoop(this.dirty);
            },
            get toCompany() {
                if (!this.dirty)
                    return undefined;

                return $ctrl.modelSpec.toCompany(this.dirty);
            },
            get owned() {
                if (!this.dirty)
                    return undefined;

                return $ctrl.modelSpec.owned(this.dirty);
            },
            get model() {
                return this._model;
            },
            setModel: function(data) {
                this._model = _.cloneDeep(data);

                return this;
            },
            resetDirty: function() {
                this.dirty = _.merge(_.cloneDeep($ctrl.modelSpec.computeDefaultKickback()), _.cloneDeep(this.model));

                return this;
            },
            setEditing: function(editing) {
                this.editing = editing;

                return this;
            },
            setPayedTo: function(name) {
                this.payedToName = name;

                return this;
            },
            save: function() {
                confirmSaveKickback(this);
            },
            edit: function() {
                editKickback(this);
            },
            delete: function() {
                confirmDeleteKickback(this);
            },
            error: function(slug, delay = 5000) {
                _.set(this.$errors, slug, _.get(this.$errors, slug, 0) + 1);

                if (delay >= 0) {
                    $timeout(() => {
                        let value = _.get(this.$errors, slug, 1) - 1;

                        if (value < 1)
                            delete this.$errors[slug];
                        else
                            _.set(this.$errors, slug, value);
                    }, delay);
                }
            }
        };
    }

    function KickbackInput() {
        return {
            kickback: null,
            wizardIndex: 0,
            sign: 0,
            wizard: [
                // Wizard step 0: add kickback
                function() {
                    this.wizardIndex = 0;
                    this.kickback = null;

                    busy();

                    let name = $ctrl.isAdmin ? '?' : $ctrl.applicableCompany.name;

                    this.kickback = (new Kickback()).resetDirty().setPayedTo(name);

                    done(true);

                    console.debug('        > Initialized new kickback wizard.', _.cloneDeep(this));
                },
                // Wizard step 1: fixed/percentage
                function(type) {
                    busy();

                    if (type === 'percentage')
                        this.kickback.dirty.amount /= 100;

                    this.kickback.dirty.type = type;

                    done(true);

                    console.debug('        > Selected type: ', _.cloneDeep(this));
                },
                // Wizard step 2: up/down
                function(sign) {
                    busy();

                    this.sign = sign ? 1 : -1;
                    this.kickback.dirty.amount = Math.abs(this.kickback.dirty.amount) * (sign ? 1 : -1);

                    done(true);

                    console.debug('        > Selected sign: ', _.cloneDeep(this));
                }
            ],
            start: function() {
                console.debug('    > Start Wizard: ', _.cloneDeep(this));

                this.next();
            },
            next: function(...values) {
                if (this.wizardIndex < 0)
                    return;

                console.debug('      > Step: ', this.wizardIndex + 1);

                this.wizard[this.wizardIndex].apply(this, values);

                this.wizardIndex++;

                if (this.wizardIndex >= this.wizard.length)
                    this.finish();
            },
            finish: function() {
                busy();

                this.wizardIndex = -1;

                $ctrl.kickbacks.push(this.kickback);

                done(true);

                console.debug('      > Finished wizard', _.cloneDeep(this));
            },
            edit: function(kickback) {
                this.kickback = kickback;

                this.kickback.setEditing(true);

                this.wizardIndex = -1;

                if (this.kickback.dirty.amount)
                    this.sign = Math.sign(this.kickback.dirty.amount);
            },
            cancel: function() {
                resetEditing();
            }
        };
    }

    function confirmSaveKickback(kickback) {
        let translations = {
            text: {translation: 'models.kickback.notice.saveConfirm'},
            confirm: 'common.action.save',
            cancelledText: 'models.kickback.notice.nothingWillBeSaved'
        };

        return ConfirmDeleteSweet.open('the service fee', translations, false)
            .then(() => saveKickback(kickback))
            .then((data) => {
                SweetAlert.swal({
                    title: $translate.instant('common.notice.saved'),
                    text: $translate.instant('common.notice.success'),
                    type: 'success',
                    confirmButtonText: $translate.instant('common.action.ok'),
                    closeOnClickOutside: true,
                    timer: 5000
                });

                return $q.resolve(data);
            })
            .catch((data) => {
                let errorText;

                if (data && data.data)
                    errorText = _.get(data.data, 'error_description', 'common.notice.unknownError');
                else if (data && _.isString(data))
                    errorText = data;
                else
                    errorText = 'common.notice.unknownError';

                SweetAlert.swal({
                    title: $translate.instant('common.notice.error'),
                    text: $translate.instant(errorText),
                    type: 'error',
                    confirmButtonText: $translate.instant('common.action.ok'),
                    closeOnClickOutside: true
                });

                return $q.reject(data);
            });
    }

    function saveKickback(kickback) {
        console.debug('> Saving Kickback: ', kickback);

        if (!kickback.editing)
            return errorRejection('Can not save Kickback. The kickback is not being edited.');
        else if (!kickback.owned && !$ctrl.isAdmin)
            return errorRejection('Not allowed to save Kickback.');
        else if (isBusy())
            return errorRejection('Can not save Kickback. The system is busy. Please try again later');

        busy();

        let data = _.cloneDeep(kickback.dirty);

        delete data.guid;
        delete data.created_at;
        delete data.updated_at;

        let promise, kickbackId = _.get(kickback.model, 'guid', false);

        if (kickbackId)
            promise = $http.put(`/kickback/${kickbackId}`, data);
        else {
            let type = kickbackToApiType(data);

            if (_.indexOf($ctrl.modelSpec.allowedTypes, type) < 0)
                promise = errorRejection('Invalid kickback type');
            else
                promise = $http.post(`/kickback/${type}`, data);
        }

        return promise.then((result) => {
            done(true);

            resetEditing().then(() => {
                getData();
            });

            console.debug('  > Done saving kickback', result);

            return $q.resolve(result);
        }, (error) => {
            done(true);

            return errorRejection('ERROR saving kickback: ' + error);
        });
    }

    function editKickback(kickback) {
        if (kickback.editing)
            return $q.resolve();

        console.error('hello');
        console.debug('> Editing kickback: ', kickback);

        if (!kickback.owned && !$ctrl.isAdmin)
            return errorRejection('Not allowed to edit Kickback.');
        else if (isBusy())
            return errorRejection('Can not edit Kickback. The system is busy. Please try again later');

        return resetEditing().then(() => {
            console.debug('  > After reset editing promise');

            busy();

            $ctrl.kickbackInput.edit(kickback);

            done(true);

            console.debug('  > Editing of kickback successfully started.');

            return $q.resolve(kickback);
        });
    }

    function confirmDeleteKickback(kickback) {
        let translations = {
            text: {translation: 'common.notice.deleteConfirm'},
            confirm: 'common.action.delete',
            cancelledText: 'common.notice.nothingWillBeDeleted'
        };

        return ConfirmDeleteSweet.open('the service fee', translations, false)
            .then(() => deleteKickback(kickback))
            .then((data) => {
                SweetAlert.swal({
                    title: $translate.instant('common.notice.deleted'),
                    text: $translate.instant('common.notice.success'),
                    type: 'success',
                    confirmButtonText: $translate.instant('common.action.ok'),
                    closeOnClickOutside: true,
                    timer: 3000
                });

                return $q.resolve(data);
            })
            .catch((data) => {
                let errorText;

                if (data && data.data)
                    errorText = _.get(data.data, 'error_description', 'common.notice.unknownError');
                else if (data && _.isString(data))
                    errorText = data;
                else
                    errorText = 'common.notice.unknownError';

                SweetAlert.swal({
                    title: $translate.instant('common.notice.error'),
                    text: $translate.instant(errorText),
                    type: 'error',
                    confirmButtonText: $translate.instant('common.action.ok'),
                    closeOnClickOutside: true
                });

                return $q.reject(data);
            });
    }

    function deleteKickback(kickback) {
        console.debug('> Deleting Kickback: ', kickback);

        if (!kickback.owned && !$ctrl.isAdmin)
            return errorRejection('Not allowed to delete Kickback.');
        else if (isBusy())
            return errorRejection('Can not delete Kickback. The system is busy. Please try again later');

        busy();

        let promise = $q.resolve(false), kickbackId = _.get(kickback.model, 'guid', false);

        // If the kickback has an ID it exists on the API as well.
        if (kickbackId)
            promise = $http.delete(`/kickback/${kickbackId}`);

        // Remove the kickback from the list
        // This is redundant for existing kickbacks - as in they exist on the API - but by removing it from the local list,
        // the entire operation feels more solid to the user, they don't care if the kickback exists on the API,
        // they just want it deleted.
        return promise.then((apiDeleted) => {
            if (apiDeleted !== false)
                console.debug('    > Done deleting kickback on API, starting with list');

            let deleted = false;

            _.remove($ctrl.kickbacks, (instance) => (instance === kickback) ? (deleted = true) : false);

            done(true);

            return resetEditing().then(() => {
                console.debug('  > Done deleting kickback.');

                return $q.resolve(deleted);
            });
        });
    }

    /* //////////////////////////////// */
    /*    KICKBACK HELPER OPERATIONS    */

    /* //////////////////////////////// */

    function getKickbackQuantifier(type) {
        return _.get($ctrl.quantifiers, type, '?');
    }

    function kickbackToApiType(kickback) {
        // Fee for Whitelabel
        if (_.get(kickback, 'applicable_type', false) === 'App\\Models\\Whitelabel')
            return 6;

        let companyIdCompany = (_.get(kickback, 'company_id', false) === _.get($ctrl.applicableCompany, 'guid', true));
        let payedToCompany = (_.get(kickback, 'payed_to_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));
        let payedByCompany = (_.get(kickback, 'payed_by_guid', false) === _.get($ctrl.applicableCompany, 'guid', true));
        let applicableIdCompany = (_.get(kickback, 'applicable_id', false) === _.get($ctrl.applicableCompany, 'guid', true));
        let applicableTypeCompany = (_.get(kickback, 'applicable_type', false) === 'App\\Models\\Company');

        // Fee for Organiser (self loop)
        if (applicableTypeCompany && companyIdCompany && payedToCompany && payedByCompany && applicableIdCompany)
            return 1;

        let applicableIdShop = (_.get(kickback, 'applicable_id', false) === _.get($ctrl.applicableShop, 'guid', true));
        let applicableTypeShop = (_.get(kickback, 'applicable_type', false) === 'App\\Models\\Shop');

        // Fee for Organiser's Shop (self loop)
        if (applicableTypeShop && companyIdCompany && payedToCompany && payedByCompany && applicableIdShop)
            return 2;

        // When above fails and the user is not an admin, everything else maps to an affiliate fee
        if (!$ctrl.isAdmin || !payedByCompany)
            return 5;

        let companyIdWhitelabelOwner = (_.get(kickback, 'company_id', false) === _.get($ctrl.applicableWhitelabel, 'owning_company', true));
        let payedToWhitelabelOwner = (_.get(kickback, 'payed_to_guid', false) === _.get($ctrl.applicableWhitelabel, 'owning_company', true));

        // Fee for Whitelabel owner's Organiser
        if (applicableTypeCompany && companyIdWhitelabelOwner && payedToWhitelabelOwner && payedByCompany && applicableIdCompany)
            return 3;

        // Fee for Whitelabel owner's Organiser's Shop
        if (applicableTypeShop && companyIdWhitelabelOwner && payedToWhitelabelOwner && payedByCompany && applicableIdShop)
            return 4;

        // Fee for Affiliates
        return 5;
    }

    /* //////////////////////////////// */
    /*     SYSTEM STATE OPERATIONS      */

    /* //////////////////////////////// */

    function busy() {
        $ctrl.busy++;
    }

    function done(immediately = false) {
        if (immediately)
            return $ctrl.busy--;

        $timeout(() => $ctrl.busy--, 500);

        return $ctrl.busy - 1;
    }

    function isBusy() {
        return ($ctrl.busy > 0);
    }

    function toggleAsOrganiser(state) {
        $ctrl.isAdmin = $ctrl.canAdmin ? !state : false;

        resetEditing();
    }

    /* //////////////////////////////// */
    /*         MISC OPERATIONS          */

    /* //////////////////////////////// */

    function errorRejection(errorMessage = null) {
        errorMessage = errorMessage || 'Something went wrong, please try again later.';

        // TODO ERROR LOGGING AND VIEW
        console.error('        > ERROR: ', errorMessage);

        UIMessages.push({
            type: 'danger',
            message: errorMessage
        });

        return $q.reject(errorMessage);
    }

    function contactSupport() {
        let message = 'I am interested in setting up kickbacks. ';
        message += 'Can you jump start me? ';
        message += 'The kickback I want to apply is for ';

        if ($ctrl.applicableModel)
            message += `${$ctrl.appliedType}: '${$ctrl.applicableModel.name}' `;

        if ($ctrl.applicableShop)
            message += `in shop: ${$ctrl.applicableShop.name}`;

        message += '. With the following info: ...';

        // eslint-disable-next-line new-cap
        window.Intercom('showNewMessage', message);
    }

    function manageAsAffiliate(companyId) {
        if (!$ctrl.isAdmin)
            return errorRejection('Not allowed to manage affiliates');

        return $q.resolve($ctrl.affiliate = companyId);
    }
}
