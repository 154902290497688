import style from './upcoming.less';
import c3 from 'c3';
import c3styling from 'c3/c3.css';
import template from './upcoming.html';

export default angular.module('eventix.dashboard.admin.upcoming',[])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.admin.upcoming', {
            url: '/upcoming',
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'UpcomingController as vm',
                    templateUrl: template
                }
            },
            data: {
                requiredRoles: ['Admin']
            }
        });
    })
    .controller('UpcomingController', function($state, $scope, $timeout, $window, SummaryLoader, $filter, $q, cfpLoadingBar, isAdmin) {
        //-----------------------------------------------------------------------------------------//
        //---------------------------------        REPORTS        ---------------------------------//
        //-----------------------------------------------------------------------------------------//

        // The options and parameters of the sections and reports.
        let statistics = [
            {
                name: 'upcomingevents',
                chart: false,
                reports: [
                    {
                        name: 'Upcomingevents',
                        args: [
                            {type: 'constant', value: moment()},
                            {type: 'constant', value: moment().add(6, 'days')},
                            {type: 'constant', value: 'days'}
                        ]
                    }
                ]
            },
            {
                name: 'currentevents',
                chart: false,
                reports: [
                    {
                        name: 'Currentevents',
                        args: [
                            {type: 'constant', value: moment()},
                            {type: 'constant', value: moment().add(1, 'days')},
                            {type: 'constant', value: 'days'}
                        ]
                    }
                ]
            },
            {
                name: 'neworganisationsandevents',
                chart: false,
                reports: [
                    {
                        name: 'Neworganisationsandevents',
                        args: [
                            {type: 'constant', value: 10}
                        ]
                    }
                ]
            }
        ];

        //-----------------------------------------------------------------------------------------//
        //---------------------------------   DEFAULT VARIABLES   ---------------------------------//
        //-----------------------------------------------------------------------------------------//

        // Formats for all interpreter types and all (interval) period settings.
        let dateFormats = {
            minutes: {
                momentAPI: 'YYYY-MM-DD HH:mm',
                momentView: 'DD-MM-YYYY HH:mm',
                c3: '%Y-%m-%d %H:%M'
            },
            hours: {
                momentAPI: 'YYYY-MM-DD HH',
                momentView: 'DD-MM-YYYY HH',
                c3: '%Y-%m-%d %H'
            },
            days: {
                momentAPI: 'YYYY-MM-DD',
                momentView: 'DD-MM-YYYY',
                c3: '%Y-%m-%d'
            },
            weeks: {
                momentAPI: 'YYYY-ww',
                momentView: 'ww-YYYY',
                c3: '%Y-%U'
            },
            months: {
                momentAPI: 'YYYY-MM',
                momentView: 'MM-YYYY',
                c3: '%Y-%m'
            },
            years: {
                momentAPI: 'YYYY',
                momentView: 'YYYY',
                c3: '%Y'
            }
        };

        // Default (interval) period
        let defaultPeriod = 'days';

        // Default date format
        let defaultDateFormat = dateFormats[defaultPeriod];

        //-----------------------------------------------------------------------------------------//
        //---------------------------------  INSTANCE VARIABLES   ---------------------------------//
        //-----------------------------------------------------------------------------------------//
        var vm = this;

        vm.disableForm = false;

        // Range for the data including the (interval) period.
        // The default is the last 7 days, seperated in days.
        vm.range = {
            start: moment().subtract(7 - 1, 'days'),
            stop: moment(),
            period: defaultPeriod,
            get diff() {
                return Math.abs(this.start.diff(this.stop, this.period));
            }
        };

        // Initialize the formatting for date-time instances.
        // A getter is created that will allow a seamingly static value to be dynamic
        Object.defineProperty(vm, 'dateFormat', {
            get: function() {
                if (vm.range.period !== undefined && dateFormats[vm.range.period] !== undefined)
                    return dateFormats[vm.range.period];
                return defaultDateFormat;
            }
        });

        // Create default template array for the content rows
        // Here the array is filled with zeros for the identifier and every date (difference + 1)
        // ['id', 0, 0, ...]
        let defaultRow = _.fill(Array(vm.range.diff + 2), 0);

        // Create default template array for the dates
        // ['dates', Moment, Moment+1, ...]
        let defaultDateRow = ['dates'];
        for(let i = 0, tmpDate = vm.range.start.clone().startOf(vm.range.period); i <= vm.range.diff; tmpDate.add(1, vm.range.period))
            defaultDateRow[++i] = tmpDate.format(vm.dateFormat.momentAPI);

        //-----------------------------------------------------------------------------------------//
        //---------------------------------  INITIALIZE WATCHERS  ---------------------------------//
        //-----------------------------------------------------------------------------------------//

        vm.$onInit = function() {
            resetStatistics();
        };

        // (Deep) Watch for a change in range including (interval) period
        $scope.$watch('vm.range', resetStatistics, true);

        //-----------------------------------------------------------------------------------------//
        //---------------------------------   REPORT FUNCTIONS    ---------------------------------//
        //-----------------------------------------------------------------------------------------//

        function resetStatistics() {
            // POSSIBLY DISABLE ALL DATA, FORMS, ANCHORS, CHARTS, ETC.

            // Disable all forms to wait for all requests
            vm.disableForm = true;

            // Destroy any previous charts
            _.forOwn(vm.reports, function(section) {
                if(section.chart)
                    section.chart.destroy();
            });

            // Delete all reports.
            if (vm.reports)
                delete vm.reports;

            // Re-set the default rows.
            defaultRow = _.fill(Array(vm.range.diff + 2), 0);
            defaultDateRow = ['dates'];
            for(let i = 0, tmpDate = vm.range.start.clone().startOf(vm.range.period); i <= vm.range.diff; tmpDate.add(1, vm.range.period))
                defaultDateRow[++i] = tmpDate.format(vm.dateFormat.momentAPI);

            // (Re)initialize reports structure.
            vm.reports = [];

            // Track all requests
            var promises = [];

            _.forOwn(statistics, function(section) {
                promises = promises.concat(resetSection(section, section.reports));
            });

            // When all requests are resolved, enable Forms on page.
            $q.all(promises).then(() => {
                vm.disableForm = false;
            });
        }

        function resetSection(section, reports) {
            // Create a new section report object.
            vm.reports[section.name] = {
                range: vm.range,
                dateFormat: vm.dateFormat,
                bindElements: {
                    chart: '#chart_' + section.name,
                    table: '#table_' + section.name
                }
            };

            // Fill the section report object with an empty content container.
            createStatistics(section);

            // For all reports in the section:
            // - Get the arguments for the report request (compute if necessary)
            // - Get the report data
            // - Add the report data to the content container
            // - Generate the statistics if necessary (charts, etc.)
            let promises = [];
            _.forOwn(reports, function(report) {
                // Compute or fetch arguments.
                let args = _.map(report.args, function(arg, index) {
                    if (arg.type === 'vm')
                        return _.get(vm, arg.value);
                    return arg.value;
                });

                // Get the report data
                let p = SummaryLoader['get' + report.name].apply(null, args).then(statistics => {
                    // Add the report data to the content container.
                    addStatistics(section, statistics);

                    // Generate the statistics if necessary (charts, etc.).
                    generateStatistics(section, statistics);
                });
                promises.push(p);
            });
            return promises;
        }

        function createStatistics(section) {
            // Create content container.
            var content = {
                rows: {0: defaultDateRow},
                labels: {},
                groups: [[]],
                totals: {0: 'Totals'},
                format: {0: 'none'}
            };

            // Set content container to report section.
            vm.reports[section.name].content = content;
        }

        function addStatistics(section, statistics) {
            var content = vm.reports[section.name].content;

            // For each entry, add statistics to the content container.
            _.forOwn(statistics.report, function(entry) {
                // Get the unique identifier of the report entry. If a guid does is not given, use the label as a fallback.
                let identifier = entry.identifier || entry.label;

                // If the identifier has no row (yet) in the content container, add it
                // Also add to labels, groups, totals and format, where applicable.
                if (!(identifier in content.rows)) {
                    content.rows[identifier] = defaultRow.slice();
                    content.rows[identifier][0] = identifier;

                    content.labels[identifier] = entry.label;

                    if (_.flattenDeep(content.groups).indexOf(identifier) === -1) {
                        if((statistics.data_type === 'stackable_bar' && identifier === 'Others') || statistics.data_type === 'linear_bar')
                            content.groups.push([identifier]);
                        else
                            content.groups[0].push(identifier);
                    }

                    content.totals[identifier] = 0;

                    content.format[identifier] = statistics.format;
                }

                // Depending on the data type:
                // - Calculate the index where the entry should be placed in the row. (Based on datetime)
                // - Determine if the entry value should be narrowed down.
                let index = 1, value = entry;

                if (statistics.data_type === 'stackable_bar' || statistics.data_type === 'linear_bar') {
                    index = -1;

                    if (defaultDateRow.indexOf(entry.x_axis.toString()) > 0) {
                        index = defaultDateRow.indexOf(entry.x_axis.toString());
                        value = value.y_axis;
                        content.totals[identifier] += 1 * value;
                    }
                } else if (statistics.data_type === 'single') {
                    index = 0;
                    value = value.y_axis;
                }

                // Add the value to the correct row/key (if the index is valid)
                if (index > 0)
                    content.rows[identifier][index] = value;
                else if (index === 0)
                    content.rows[identifier] = value;
            });
        }

        function generateStatistics(section, statistics) {
            // Generate the statistics for the report depending on the report type (View operations).
            // Ex. Charts
            // Note: Integers, Strings, Booleans and Tables are directly accessed through HTML/Angular,
            //          but included here for completeness and future features.
            switch (statistics.type) {
                case 'int':
                    //
                    break;
                case 'string':
                    //
                    break;
                case 'boolean':
                    //
                    break;
                case 'chart':
                    generateChart(section);
                    break;
                case 'table':
                    //
                    break;
                case 'chart_and_table':
                    generateChart(section);
                    break;
                default:
                //
            }
        }

        function generateChart(section) {
            // Generating chart if the section has a chart declaration
            if (!section.chart)
                return;

            let report = vm.reports[section.name];

            // Delete any previous charts
            if(report.chart)
                report.chart.destroy();

            // Generate the Chart
            report.chart = c3.generate({
                bindto: report.bindElements.chart,          // Specifies the DOM element this chart should be bound to.
                data: {
                    xFormat: report.dateFormat.c3,          // Specifies the format for the X-Axis
                    x: 'dates',                             // Specifies the key of the X values in the data
                    columns: _.values(report.content.rows), // Specifies all chart data (Y-Axis), in single value array format with the key at index[0]
                    type: section.chart.type,               // Specifies the type of the chart
                    groups: report.content.groups           // Specifies the groups for stacking data.
                },
                size: {                                     // Specifies the size of the chart
                    width: $(report.bindElements.chart).width() * 0.95,
                    height: 250
                },
                legend: section.chart.legend.show,          // Specifies if a legend should be shown.
                tooltip: {                                  // Specifies the format and content of the tooltip.
                    format: {
                        title: function(x) {
                            return moment(x, report.dateFormat.momentAPI).format(report.dateFormat.momentView);
                        }
                    },
                    contents: function(d, defaultTitleFormat, defaultValueFormat, color) {
                        var $$ = this, config = $$.config,
                            titleFormat = config.tooltip_format_title || defaultTitleFormat,
                            nameFormat = config.tooltip_format_name || function(name) { return name; },
                            valueFormat = config.tooltip_format_value || defaultValueFormat,
                            text, i, title, value, name, bgcolor;

                        let dayCount = 0;
                        let dayRev = 0;

                        /*eslint-disable*/
                        for (i = 0; i < d.length; i++) {
                            if (! (d[i] && d[i].value))
                                continue;
                            if (! text) {
                                title = titleFormat ? titleFormat(d[i].x) : d[i].x;
                                text = "<table class='" + $$.CLASS.tooltip + "'>" + (title || title === 0 ? "<tr><th colspan='3'>" + title + "</th></tr>" : "");
                                text += "<tr class='" + $$.CLASS.tooltipName + "-" + d[i].id + "'>";
                                text += "<td class='name'>Event</td>";
                                text += "<td class='value'>#Tickets</td>";
                                text += "</tr>";
                            }

                            name = nameFormat(d[i].name);
                            value = valueFormat(d[i].value, d[i].ratio, d[i].id, d[i].index);
                            dayCount += value;

                            bgcolor = $$.levelColor ? $$.levelColor(d[i].value) : color(d[i].id);

                            text += "<tr class='" + $$.CLASS.tooltipName + "-" + d[i].id + "'>";
                            text += "<td class='name'><span style='background-color:" + bgcolor + "'></span>" + report.content.labels[d[i].name] + "</td>";
                            text += "<td class='value'>" + $filter('format')(value, report.content.format[d[i].name]) + "</td>";
                            text += "</tr>";
                        }

                        if (section.chart.tooltip.total) {
                            text += "<tr>";
                            text += "<td class='name'></span>#total</td>";
                            text += "<td class='value'>" + dayCount + "</td>";
                            text += "</tr>";
                        }
                        return text + "</table>";
                        /*eslint-enable*/
                    }
                },
                //color: { pattern: ['#00BEF6','#0079BF','#00A650','#F46B49','#BB2321'] },
                axis: {
                    y: {                                    // Specifies the format and contents of the Y-Axis.
                        //min: 0,
                        padding: { bottom: 0 },
                        tick: {
                            format: function(d) {
                                if(d % 1 === 0) return d;
                                return '';
                            }
                        }
                    },
                    x: {                                    // Specifies the format and contents of the X-Axis.
                        type: 'timeseries',
                        tick: {
                            culling: true,
                            format: function(x) {
                                return moment(x, report.dateFormat.momentAPI).format(report.dateFormat.momentView);
                            }
                        }
                    }
                }
            });
        }
    }).name;
