import basic from './basic/basic';
import tickets from './tickets/tickets';
import products from './products/products';
import shops from './shops/shops';
import scanners from './scanners/scanners';
import financials from './financials/financials';
import summary from './summary/summary';

export default angular.module(
    'eventix.dashboard.wizard.advanced',
    [
        basic,
        products,
        tickets,
        shops,
        scanners,
        financials,
        summary
    ])
    .config(ModuleConfig).name;

function ModuleConfig($stateProvider) {
    $stateProvider.state('eventix.dashboard.wizard.advanced', {
        url: '/advanced'
    });
}
