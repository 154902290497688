import template from './users.html';
import './users.less';
import inviteTemplate from './invite.html';
import { copyToClipboard } from '../../dashboard';

export default angular.module('eventix.dashboard.company.users', [])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.company.users', {
            url: '/users',
            views: {
                'dashboard@eventix.dashboard': {
                    component: 'userManager',
                },
            },
            data: {
                crumb: 'common.menu.company.users',
                requiredRoles: ['User Manager'],
            },
        });
    })
    .component('inviteUserModal', {
        controller: InviteUserController,
        templateUrl: inviteTemplate,
        bindings: {
            // resolve: '<',
            close: '&',
            dismiss: '&',
        },
    })
    .component('userManager', {
        controller: UserManagerController,
        templateUrl: template,
        bindings: {
            company: '<',
            isAdmin: '<',
            user: '<',
        },
    }).name;

function UserManagerController($q, $http, $state, $translate, $scope, $window, $uibModal, CrudCtrl, User, Role, Company, SweetAlert, ConfirmDeleteSweet, UIMessages, ErrorRejector) {
    const $ctrl = this;

    const companyAdminRoles = [
        'Company Admin',
        'User Manager',
        'Shop Manager',
        'Event Manager',
    ];

    $ctrl.isAdmin = Role.isAuthorizedAs('Admin');
    $ctrl.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');
    $ctrl.totalRows = 0;
    $ctrl.guids = {
        company: {
            guid: $ctrl.company ? $ctrl.company.guid : '',
            icon: 'fa-building-o',
            name: 'models.models.company',
        },
        user: {
            guid: $ctrl.crud && $ctrl.crud.model ? $ctrl.crud.model.guid : '',
            icon: 'fa-user',
            name: 'models.models.user',
        },
    };

    $ctrl.toggleCompanyAdmin = toggleCompanyAdmin;
    $ctrl.toggleBeta = toggleBeta;
    $ctrl.deleteUser = deleteUser;
    $ctrl.inviteUserModal = inviteUserModal;
    $ctrl.copyToClipboard = copyToClipboard;

    $ctrl.$onInit = function() {

        Role.query().then(r => $ctrl.roles = r);

        $ctrl.crud = new CrudCtrl([], newUser, {determineInitState: false});
        $ctrl.crud.addBelongsToMany('roles', 'Role');
        $ctrl.crud.afterQuery(determineUserTypeFromRoles);

        Company.getMe().then(company => {
            $ctrl.company = company;
        });

        fetchInvites();

        $scope.$watch('$ctrl.company.guid', (guid) => {
            $ctrl.guids.company.guid = guid || '';
        });
        $ctrl.guids.company.guid = $ctrl.company ? $ctrl.company.guid : '';
        $scope.$watch('$ctrl.crud.model.guid', (guid) => {
            $ctrl.guids.user.guid = guid || '';
        });
        $ctrl.guids.user.guid = $ctrl.crud && $ctrl.crud.model ? $ctrl.crud.model.guid : '';

    };

    $ctrl.getUsers = getUsers;
    function getUsers(offset, limit, sorting, filters) {
        const query = {
            page: 1 + Math.floor(offset / limit),
            per_page: limit,
        };

        _.each(sorting, (direction, attribute) => {
            query.sort = `${direction === 'desc' ? '-' : ''}${attribute}`;
        });

        _.each(filters, (value, attribute) => {
            if (value) {
                query[`filter[${ attribute }]`] = value;
            }
        });

        return $http.get(AUTH_API_ROOT + `/users/`, {params: query})
            .then(response => {
                $ctrl.totalRows = response.data.total;

                return _.map(response.data.data, user => User.new(user));
            })
            .then(users => {
                $ctrl.crud.models = users;

                return users;
            })
            .catch(ErrorRejector.handle);
    }

    function determineUserTypeFromRoles(error, fnSuffix, user, roles) {
        if (error || fnSuffix !== 'Role')
            return;

        let companyAdmin = _.reduce(companyAdminRoles, (carry, roleName) => {
            return carry + (_.findIndex(roles, ['name', roleName]) >= 0)
        }, 0) === companyAdminRoles.length;

        addRolePropsToUser(user, !!companyAdmin, !!_.find(roles, {'name': 'Beta'}));
    }

    function addRolePropsToUser(user, companyAdmin = false, isBeta = false) {
        Object.defineProperty(user, 'companyAdmin', {
            value: !!companyAdmin,
            writable: true,
        });

        Object.defineProperty(user, 'isBeta', {
            value: !!isBeta,
            writable: true,
        });
    }

    function toggleCompanyAdmin(toggled) {
        // Determine and split state of company admin roles
        // Sort the Company Admin role to always be last.
        // This fixes a bug where someone removes their own roles,
        // but once the company admin & user manager roles are removed,
        // the user is no longer allowed to remove roles....
        let attached = _.sortBy(_.filter($ctrl.crud.model.roles, role => _.includes(companyAdminRoles, role.name)), (r) => r.name === 'Company Admin' ? 1 : 0);
        let detached = _.filter($ctrl.roles, role => _.includes(companyAdminRoles, role.name) && _.findIndex(attached, ['name', role.name]) < 0);

        // Determine action to take based on toggle state
        let action = toggled ? {action: '$attachRole', values: detached} : {action: '$detachRole', values: attached};

        let promise;

        if (!toggled && $ctrl.crud.model.guid === $ctrl.user.guid) {
            promise = $q((resolve, reject) => {
                // ConfirmDeleteSweet does not reject on cancel...
                SweetAlert.swal(
                    {
                        title: $translate.instant('common.notice.attention'),
                        text: $translate.instant('common.notice.deleteConfirm', { object: $translate.instant('models.user.companyAdmin') }),
                        type: 'warning',
                        confirmButtonColor: "#ff9600",
                        confirmButtonText: $translate.instant('common.action.delete'),
                        showCancelButton: true,
                        cancelButtonText: $translate.instant('common.action.cancel'),
                        closeOnCancel: false,
                        closeOnConfirm: false,
                    },
                    (isConfirm) => {
                        if (isConfirm) {
                            SweetAlert.close();

                            resolve();
                        } else {
                            SweetAlert.swal({
                                title: $translate.instant('common.notice.cancelled'),
                                text: $translate.instant('common.notice.nothingWillBeDeleted'),
                                type: 'error',
                                comfirmButtonText: $translate.instant('common.action.ok'),
                                closeOnClickOutside: true,
                                timer: 3000,
                            });

                            reject();
                        }
                    }
                );
            }).then(() => {
                // Create ($http) promise chain. The relational CrudCtrl methods can not attach/detach async. They are blocking calls.
                // Each step calls the API and appends the (current) response to a result collection and hands it to the next promise.
                return _.reduce(action.values, (httpPromise, role) => {
                    return httpPromise.then(results => $ctrl.crud.model[action.action](role).then(result => {
                        results.push(result);
                        return results;
                    }));
                }, $q.resolve([]))
                    .catch(ErrorRejector.handle)
                    .then(() => {
                        $window.location.href = DASHBOARD_ROOT + `home?as=${$ctrl.company.guid}`;
                    });
            }, () => []);
        } else {
            // Create ($http) promise chain. The relational CrudCtrl methods can not attach/detach async. They are blocking calls.
            // Each step calls the API and appends the (current) response to a result collection and hands it to the next promise.
            promise = _.reduce(action.values, (httpPromise, role) => {
                return httpPromise.then(results => $ctrl.crud.model[action.action](role).then(result => {
                    results.push(result);
                    return results;
                }));
            }, $q.resolve([])).catch(ErrorRejector.handle);
        }

        // Retrieve the roles (again) in the finalizer. Does not influence the caller.
        return promise.finally(() => {
            return $ctrl.crud.model.$queryRole(false).then(refreshedRoles => {
                $ctrl.crud.model.roles = refreshedRoles;

                return $ctrl.crud.fire('afterQuery', null, 'Role', $ctrl.crud.model, refreshedRoles).then(() => refreshedRoles);
            });
        });
    }

    function toggleBeta() {
        let role = _.find($ctrl.roles, {'name': 'Beta'});
        let action = $ctrl.crud.model.isBeta ? 'attachRole' : 'detachRole';
        return $ctrl.crud[action](role)
            .catch(ErrorRejector.handle);
    }

    // Forwarded to the new crud controller to build new users based on the returned
    function newUser() {
        const user = User.new({
            default_company_id: _.get($ctrl.crud, ['models', 0, 'default_company_id'], $ctrl.user.default_company_id),
        });

        addRolePropsToUser(user, false, false);

        return user;
    }

    function inviteUserModal() {
        return $uibModal.open({ component: 'inviteUserModal' })
            .result
            .then(() => {
                fetchInvites();

                return User.query(false).then(users => {
                    $ctrl.crud.models = users;

                    return $q.resolve();
                });
            })
            .catch(angular.noop);
    }

    $ctrl.invites = [];
    $ctrl.inviteLoading =true;

    function fetchInvites(){
        $http.get(AUTH_API_ROOT + '/invitations/users?per_page=100')
            .then(result => {
                $ctrl.invites = result.data.data;
                $ctrl.inviteLoading = false;
            });
    }

    $ctrl.deleteInvite = deleteInvite;
    function deleteInvite(invite){
        const translations = {
            text: {translation: 'common.notice.deleteConfirmInvite'},
            confirm: 'common.action.delete',
        };

        return ConfirmDeleteSweet.open(invite.email, translations, false)
            .then(() => $http.delete(AUTH_API_ROOT + '/invitations/users/' + invite.guid).then(() => {
                UIMessages.push(('models.company.notice.inviteDeleted'));
            }))
            .then(() => {
                fetchInvites();
            })
            .then(() => {
                SweetAlert.swal({
                    title: $translate.instant('common.notice.deleted'),
                    text: $translate.instant('common.notice.success'),
                    type: 'success',
                    confirmButtonText: $translate.instant('common.action.ok'),
                    closeOnClickOutside: true,
                    timer: 5000,
                });
            })
            .catch(error => {
                console.error(error);

                let message = _.get(error.data, 'error_description');

                if (!message || !_.isString(message))
                    message = _.get(error, 'data');

                if (!message || !_.isString(message))
                    message = error;

                if (!message || !_.isString(message))
                    message = 'common.notice.unknownError';

                SweetAlert.swal({
                    title: $translate.instant('common.notice.error'),
                    text: $translate.instant(message),
                    type: 'error',
                    confirmButtonText: $translate.instant('common.action.ok'),
                    closeOnClickOutside: true,
                });

                return error;
            });
    }

    function deleteUser(user) {
        let promise = $q.reject('User does not exist, can not delete. Please refresh');

        if (user.guid) {
            let url = AUTH_API_ROOT + '/user/' + user.guid;

            let translations = {
                text: {translation: 'common.notice.deleteConfirmUser'},
                confirm: 'common.action.delete',
            };

            promise = ConfirmDeleteSweet.open(user.name, translations, false)
                .then(() => {
                    return $http.delete(url);
                })
                .then(() => {
                    return User.query(false).then(users => {
                        $ctrl.crud.models = users;

                        return $q.resolve();
                    });
                });
        }

        return promise
            .then(() => {
                SweetAlert.swal({
                    title: $translate.instant('common.notice.deleted'),
                    text: $translate.instant('common.notice.success'),
                    type: 'success',
                    confirmButtonText: $translate.instant('common.action.ok'),
                    closeOnClickOutside: true,
                    timer: 5000,
                });
            })
            .catch(error => {
                console.error(error);

                let message = _.get(error.data, 'error_description');

                if (!message || !_.isString(message))
                    message = _.get(error, 'data');

                if (!message || !_.isString(message))
                    message = error;

                if (!message || !_.isString(message))
                    message = 'common.notice.unknownError';

                SweetAlert.swal({
                    title: $translate.instant('common.notice.error'),
                    text: $translate.instant(message),
                    type: 'error',
                    confirmButtonText: $translate.instant('common.action.ok'),
                    closeOnClickOutside: true,
                });

                return error;
            });
    }
}

function InviteUserController($http, $state, $q, UIMessages) {

    const $ctrl = this;

    const companyAdminRoles = [
        'Company Admin',
        'User Manager',
        'Shop Manager',
        'Event Manager',
    ];

    $ctrl.errors = {
        name: [],
        email: [],
        roles: [],
    };

    $ctrl.companyAdmin = false;

    $ctrl.inviteUser = function() {

        $ctrl.errors = {
            name: [],
            email: [],
            roles: [],
        };

        if (!$ctrl.name) {
            $ctrl.errors.name.push('Provide a valid name');
            return;
        }

        if (!$ctrl.email) {
            $ctrl.errors.email.push('Provide a valid email address');
            return;
        }

        let data = {
            name: $ctrl.name,
            email: $ctrl.email,
            roles: $ctrl.companyAdmin ? companyAdminRoles : [],
        };

        if ($state.params.as) {
            data.company_id = $state.params.as;
        }

        $http.post(AUTH_API_ROOT + '/invitations/users', data).then((response) => {

            UIMessages.push(('models.company.notice.invitedUser'));

            $ctrl.close();

            return response;

        }, error => {

            // TODO: Check status codes and report errors more accurately
            UIMessages.push('models.company.notice.inviteFailed');

            console.error(error);

            return $q.reject(error);

        });

    }
}
