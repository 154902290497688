import templateUrl from './basic.html';

export default angular.module('eventix.dashboard.wizard.simple.basic', [])
    .config(ModuleConfig)
    .component('wizardSimpleBasic', {
        controller: WizardSimpleBasicController,
        templateUrl: templateUrl,
        bindings: {
            event: '<',
            company: '<',
            events: '<'
        }
    }).name;

function ModuleConfig($stateProvider) {
    $stateProvider.state('eventix.dashboard.wizard.simple.basic', {
        url: '/basic',
        component: 'wizardSimpleBasic',
        bindings: {
            event: 'currentEvent',
            events: 'events',
            company: 'company'
        }
    });
}

function WizardSimpleBasicController($state, $q, $translate, ErrorRejector, Role) {
    const $ctrl = this;

    $ctrl.back = back;
    $ctrl.save = save;

    // User rights
    $ctrl.isAdmin = Role.isAuthorizedAs('Admin');
    $ctrl.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');

    $ctrl.formOptionsAvailable = [
        {
            key: 'template',
            label: $translate.instant('models.ticket.template'),
        },
        {
            key: 'visitor_contact_url',
            label: $translate.instant('models.event.visitor_contact_url'),
        },
        {
            key: 'visitor_contact_phone',
            label: $translate.instant('models.event.visitor_contact_phone'),
        },
        {
            key: 'visitor_contact_email',
            label: $translate.instant('models.event.visitor_contact_email'),
        }
    ];

    if ($ctrl.isAdminOrWLAdmin) {
        $ctrl.formOptionsAvailable.push({
            key: 'email_info',
            label: $translate.instant('models.event.email_info'),
        });
        $ctrl.formOptionsAvailable.push({
            key: 'gui_mode',
            label: $translate.instant('models.event.gui_mode'),
        });
        $ctrl.formOptionsAvailable.push({
            key: 'type',
            label: $translate.instant('models.event.type'),
        });
        $ctrl.formOptionsAvailable.push({
            key: 'locale',
            label: $translate.instant('models.event.locale'),
        });
        $ctrl.formOptionsAvailable.push({
            key: 'currency',
            label: $translate.instant('models.event.currency'),
        });
        $ctrl.formOptionsAvailable.push({
            key: 'contact_phone',
            label: $translate.instant('models.event.contact_phone'),
        });
        $ctrl.formOptionsAvailable.push({
            key: 'contact_email',
            label: $translate.instant('models.event.contact_email'),
        });
        $ctrl.formOptionsAvailable.push({
            key: 'contact_name',
            label: $translate.instant('models.event.contact_name'),
        });
        $ctrl.formOptionsAvailable.push({
            key: 'retrievable_after',
            label: $translate.instant('models.event.retrievable_after'),
        });
    }

    $ctrl.$onInit = function() {
        _.defaults($ctrl.event, {
            gui_mode: 'simple',
            type: 'once',
            currency: $ctrl.company.currency,
            locale: $ctrl.company.locale
        });
    };

    function back() {
        return $state.go('eventix.dashboard.events');
    }

    function save(event, location, eventDate) {
        if (!location) {
            return ErrorRejector.handle('Save called without location');
        }

        if (!event) {
            return ErrorRejector.handle('Save called without event');
        }

        if (!eventDate) {
            return ErrorRejector.handle('Save called without eventDate');
        }

        const errors = {
            location: location.$invalid,
            event: event.$invalid,
            eventDate: _.some([
                (!eventDate.$savedState.start || eventDate.start !== eventDate.$savedState.start) && !eventDate.validate('start'),
                (!eventDate.$savedState.end || eventDate.end !== eventDate.$savedState.end) && !eventDate.validate('end'),
                (!eventDate.$savedState.capacity || eventDate.capacity !== eventDate.$savedState.capacity) && !eventDate.validate('capacity'),
            ]),
        };

        if (_.some(errors)) {
            return ErrorRejector.handle('Input invalid, cannot save event');
        }

        if ($ctrl.busy) {
            return ErrorRejector.handle('System busy, cannot save event');
        }

        $ctrl.busy = true;

        const eventIsNew = !event.guid;

        let promise = $q.resolve();

        if (location.$dirty(['group'])) {
            promise = location.$save();
        }

        if (!event.guid) {
            promise = promise
                .then(() => location.$createEvent(event))
                .then(event => {
                    eventDate.event_id = event.guid;

                    return event;
                })
        } else if (event.$dirty(['location', 'event_dates'])) {
            promise = promise
                .then(() => event.$save());
        }

        promise = promise
            .then(() => {
                if (location.guid !== event.location_id) {
                    return event.$moveLocation(location)
                        .then(() => event);
                }

                return event;
            });

        if (eventDate.$dirty()) {
            promise = promise
                .then(() => eventDate.$save());
        }

        return promise
            .then(() => {
                if (eventIsNew) {
                    $ctrl.events.push(event);
                }

                return event;
            })
            .then(() => $state.go('eventix.dashboard.wizard.simple.tickets', {guid: event.guid}))
            .catch(ErrorRejector.handle)
            .catch(() => $state.go('eventix.dashboard.wizard.simple.basic', {guid: event.guid}))
            .finally(() => $ctrl.busy = false);
    }
}
