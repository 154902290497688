import template from './collapses.html';
import dndLists from 'angular-drag-and-drop-lists';
//import Rules from './rules.js';

export default angular.module('eventix.dashboard.collapses',['dndLists'])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.collapse', {
            url: '/collapse',
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'CollapseController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/{
                collapses: function(Collapse) {
                    return Collapse.query();
                }
            },
            data: {
                crumb: 'models.models.collapse'
            }
        });
    })
    .controller('CollapseController', function($scope, $state, $q, collapses, Collapse, simpleCRUD, ErrorRejector) {
        var vm = this;

        vm.pickTickets = pickTickets;

        vm.crud = simpleCRUD(collapses, function() {
            return Collapse.new({});
        }, $scope);
        vm.inModal = angular.element('#modalCRUD').length > 0;

        vm.crud.addBelongsToMany('tickets', 'Ticket');

        function pickTickets(tickets) {
            if (!vm.crud.showing && !vm.crud.editing) return $q.resolve(vm.crud.model.tickets = tickets);

            if (vm.crud.showing || !vm.crud.editing) return ErrorRejector.handle('Can not pick tickets, not editing a collapse.');

            let queue = _.reduce(vm.crud.model.tickets, (queue, ticket) => {
                return _.findIndex(tickets, {guid: ticket.guid}) >= 0 ? queue : queue.then(() => vm.crud.detachTicket(ticket));
            }, $q.resolve());

            return _.reduce(tickets, (queue, ticket) => queue.then(() => vm.crud.attachTicket(ticket)), queue);
        }
    })
    .controller('ShopCollapseController', function($scope, $state, collapses, Collapse, simpleCRUD, shop, shops, Shop, modalCRUD, sortableCallbacks, Ticket, $q) {
        var vm = this;
        vm.shop = shop;
        vm.collapses = collapses;

        vm.crud = simpleCRUD(shops, () => Shop.new({}));
        vm.crud.edit(shop);
        vm.crud.addBelongsToMany('collapses', 'Collapse', {
            attach: sortableCallbacks.onAttach(() => vm.crud.model.collapses),
            detach: sortableCallbacks.onDetach(() => vm.crud.model.collapses)
        });
        vm.collapseSorting = {
            animation: 150,
            onSort: sortableCallbacks.onSort((pred, succ) => {
                if(vm.crud.model.guid) {
                    vm.crud.busy = true;
                    return vm.crud.model.$reorderCollapse(pred, succ)
                        .finally(() => vm.crud.busy = false);
                }
                return $q.resolve();
            })
        };
        // Disable when SimpleCRUD is busy
        Object.defineProperty(vm.collapseSorting, 'disabled', {
            get: () => vm.crud.busy,
            set: v => vm.crud.busy = v,
            enumerable: true
        });
        vm.inModal = angular.element('#modalCRUD').length > 0;

        vm.availableCollapses = collapses;
        function updateCollapseList(){
            vm.availableCollapses = _.filter(vm.collapses, collapse => {
                return _.findIndex(vm.crud.model.collapses, { guid: collapse.guid }) === -1;
            });
        }
        vm.ticketWatcher = $scope.$watch('vm.crud.model.collapses', updateCollapseList, true);

        vm.manageCollapses = function() {
            let resolves = {};
            /* eslint-disable */
            resolves['collapses'] = () => vm.collapses;
            /* eslint-enable */
            modalCRUD('CollapseController', template, resolves)
                .then(() => {
                    collapses = Collapse.cached.where();
                    updateCollapseList();
                });
        };
    }).name;
