import templateUrl from './shops.html';
import collapses from './collapses/collapses';
import colorForm from './color-form/color-form';
import promoteForm from './promote-form/promote-form';
import forcedProducts from './forced-products/forcedProducts';
import jmc from 'jquery-minicolors';
import jmcCss from './jquery.minicolors.css';
import amc from 'angular-minicolors';
import './shops.less';

export default angular.module('eventix.dashboard.wizard.common.shops', [collapses, colorForm, promoteForm, forcedProducts,'minicolors'])
    .component('wizardShops', {
        controller: WizardShopsController,
        templateUrl: templateUrl,
        bindings: {
            event: '<',
            company: '<'
        }
    }).name;

function WizardShopsController($scope, $state, $transitions, $q, $translate, $timeout, $log, $uibModal, Locales, CrudCtrl, RelationAPI, ErrorRejector, ShopStyler, sortableCallbacks, FB, Role, Shop, Ticket, MetaData, PaymentMethod, $filter, $window) {
    const $ctrl = this;

    // The API Communication
    $ctrl.crud = null;

    // User rights
    $ctrl.isAdmin = Role.isAdmin;
    $ctrl.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');

    // Data
    $ctrl.shops = null;
    $ctrl.metaData = null;
    $ctrl.eventShops = null;
    $ctrl.companyShops = null;
    $ctrl.paymentMethods = null;

    // Helpers
    $ctrl.nonce = 0;
    $ctrl.gtm_help = GTM_HELP_DOC;
    $ctrl.shop_root = FANCY_SHOP_ROOT;
    $ctrl.loadingTickets = true;
    $ctrl.showIntegrationCode = false;

    $ctrl.guids = {
        event: {
            guid: $ctrl.event ? $ctrl.event.guid : '',
            icon: 'fa-calendar',
            name: 'models.models.event',
        },
        shop: {
            guid: '',
            icon: 'fa-shopping-cart',
            name: 'models.models.shop',
        },
    };

    // Other functionality
    $ctrl.pickTickets = pickTickets;
    $ctrl.afterSort = refreshPreview;
    $ctrl.canUnlinkShopFromEvent = canUnlinkShopFromEvent;
    $ctrl.unlinkShopFromEvent = unlinkShopFromEvent;
    $ctrl.showAllShops = showAllShops;
    $ctrl.quickStartCss = quickStartCss;
    $ctrl.showOptions = showOptions;

    // Criteria to pin the meta
    $ctrl.pinFixedMeta = (m) => /^(first_name|last_name|email)$/.test(m.name);
    $ctrl.reorderPinnedMetaData = reorderPinnedMetaData;

    // Navigate between sections
    $ctrl.next = next;
    $ctrl.prev = prev;

    // The options menu items
    $ctrl.formOptionsAvailable = [{
        key: 'googleTag',
        label: $translate.instant('models.shop.google_tag')
    }, {
        key: 'companyTerms',
        label: $translate.instant('models.shop.company_terms')
    }, {
        key: 'eventSelection',
        label: $translate.instant('models.shop.event_selection')
    }, {
        key: 'shopCollapses',
        label: $translate.instant('models.shop.collapses')
    }, {
        key: 'styling',
        label: $translate.instant('models.shop.styling')
    }, {
        key: 'facebook_page_url',
        label: $translate.instant('models.shop.facebook_page_url')
    }, {
        key: 'paymentmethods',
        label: $translate.instant('models.shop.paymentmethods')
    }, {
        key: 'forced_products',
        label: $filter('capitalize')($translate.instant('models.shop.forced_products'))
    }];

    if ($ctrl.isAdmin) {
        $ctrl.formOptionsAvailable.push({
            key: 'custom_redirect',
            label: $filter('capitalize')($translate.instant('models.shop.custom_redirect'))
        });
        $ctrl.formOptionsAvailable.push({
            key: 'from_name',
            label: $filter('capitalize')($translate.instant('models.shop.from_name'))
        });
        $ctrl.formOptionsAvailable.push({
            key: 'from_email',
            label: $filter('capitalize')($translate.instant('models.shop.from_email'))
        });
        $ctrl.formOptionsAvailable.push({
            key: 'seats_allow_orphan',
            label: $filter('capitalize')($translate.instant('models.shop.seats_allow_orphan'))
        });
        $ctrl.formOptionsAvailable.push({
            key: 'greedy_date_selection',
            label: $filter('capitalize')($translate.instant('models.shop.greedy_date_selection'))
        });
    }

    // The forms in the page
    $ctrl.forms = {
        selectedForm: 'main',

        // The forms to render using the full width of the page without the live preview and such
        fullWidthFormNames: [
            'promote'
        ],

        // The main form
        main: {},

        // The color form
        color: {
            activeTab: 1,

            open: () => $ctrl.forms.selectedForm = 'color',
            save: () => {
                $ctrl.forms.selectedForm = 'main';
                $ctrl.forms.color.activeTab = 1;
            }
        },

        // The promote form
        promote: {
            open: () => $ctrl.forms.selectedForm = 'promote',
            close: () => $ctrl.forms.selectedForm = 'main'
        }
    };

    $ctrl.$onInit = function() {
        // TODO Fix GTM Help in a less hacky hard coded way...
        $ctrl.showGtmHelp = _.get($ctrl, 'company.whitelabel_id', '') === '2e6d11f0-3668-11e6-8a0b-9107b8ddbc55';

        loadData()
            .then(results => {
                let shops = results[0];

                if ($state.params.editing && !_.find(shops, {guid: $state.params.editing})) {
                    $ctrl.showingAllShops = true;

                    shops = loadAllShops();
                }

                return shops;
            })
            .then(shops => {
                $ctrl.crud = new CrudCtrl(shops, () => Shop.new({type: 'online', event_selection: 'auto'}));

                $ctrl.crud.addBelongsToMany('metaData', 'MetaData');
                $ctrl.crud.addBelongsToMany('tickets', 'Ticket');
                $ctrl.crud.addBelongsToMany('payment_methods', 'PaymentMethod');
                $ctrl.crud.afterQuery(prefillDefaultMetaData);
                $ctrl.crud.afterAttach(refreshPreview);
                $ctrl.crud.afterDetach(refreshPreview);
                $ctrl.crud.beforeEdit(restoreCssAndPrimaryTracker);
                $ctrl.crud.beforeNew(populateShopStyleAndMeta);
                $ctrl.crud.beforeSave(removeFixedMetaBeforeCreate);
                $ctrl.crud.afterSave(afterSaveActions);
                $ctrl.crud.beforeDelete(checkIfEventShop);
                $ctrl.crud.afterDelete(deleteEventShopRelation);

                // Start CrudCtrl in edit state
                $timeout(() => {
                    if (!$state.params.editing && $ctrl.crud.models.length) {
                        $ctrl.crud.edit($ctrl.crud.models[0]);
                    }
                });
            });
    };

    $ctrl.$postLink = function() {
        $scope.$watch('$ctrl.crud.model.cssVariables', (variables, old) => {
            if (variables && old) {
                $ctrl.crud.model.css = ShopStyler.cssGenerator(variables);
            }
        }, true);

        let defaultCss = ShopStyler.cssGenerator(ShopStyler.restoreVariables());

        let confirmUnsavedHook = $transitions.onStart({exiting: $state.current.name}, function(trans) {
            if ($ctrl.crud.model.$dirty($ctrl.crud.model.css === defaultCss ? ['css'] : [])) {
                return confirmUnsavedChanges().then(() => confirmUnsavedHook(), () => trans.abort());
            }

            confirmUnsavedHook();

            return $q.resolve();
        });

        $scope.$watch('$ctrl.event.guid', (guid) => {
            $ctrl.guids.event.guid = guid || '';
        });
        $ctrl.guids.event.guid = $ctrl.event ? $ctrl.event.guid : '';
        $scope.$watch('$ctrl.crud.model.guid', (guid) => {
            $ctrl.guids.shop.guid = guid || '';
        });
        $ctrl.guids.shop.guid = $ctrl.crud && $ctrl.crud.model ? $ctrl.crud.model.guid : '';
    };

    $ctrl.paymentMethodSorting = {
        animation: 150,
        onSort: sortableCallbacks.onSort((pred, succ) => {
            return $ctrl.crud.reorderPaymentMethod(pred, succ)
                .then(r => {
                    refreshPreview();
                    return r;
                });
        }),
        get disabled() {
            return $ctrl.crud && $ctrl.crud.busy;
        },
        set disabled(v) {
            return v;
        }
    };

    function pickTickets(tickets) {
        if ($ctrl.crud.showNew()) {
            return $q.resolve($ctrl.crud.model.tickets = tickets);
        }

        if (!$ctrl.crud.showEdit()) {
            return ErrorRejector.handle('Can not pick tickets, not editing a shop.'); // TODO translate
        }

        let queue = _.reduce($ctrl.crud.model.tickets, (queue, ticket) => {
            return _.findIndex(tickets, {guid: ticket.guid}) >= 0 ? queue : queue.then(() => $ctrl.crud.detachTicket(ticket));
        }, $q.resolve());

        return _.reduce(tickets, (queue, ticket) => queue.then(() => $ctrl.crud.attachTicket(ticket)), queue);
    }

    /**
     * BeforeDelete hook that checks if shop is not a CompanyShop
     *
     * @param {Shop} shop Shop that will be deleted
     * @return {Promise} Resolves if shop can be deleted
     */
    function checkIfEventShop(shop) {
        if (!_.includes($ctrl.eventShops, shop)) {
            return $q.reject('Can\'t delete shops that don\'t belong to this event');
        }

        return $q.resolve();
    }

    /**
     * AfterDelete hook to unlink a shop from event
     *
     * @param {Error} err Optional error
     * @param {Shop} shop Shop that was deleted
     * @return {Promise} Resolves when relationship is deleted
     */
    function deleteEventShopRelation(err, shop) {
        if (err) {
            return $q.resolve();
        }

        $ctrl.crud.edit($ctrl.crud.models[0]);

        return RelationAPI.delete('EventShop', $ctrl.event.guid, shop.guid);
    }

    /**
     * Quick start shop style with primary and secondary colours
     */
    function quickStartCss() {
        let variables = $ctrl.crud.model.cssVariables;

        variables.css = ShopStyler.cssVariablesGenerator({
            primary: variables.primary,
            secondary: variables.secondary
        });
    }

    /**
     * BeforeEdit hook that restores CSS variables and optionally creates/fetches a shop tracker (for a short url)
     *
     * @param {Shop} shop Shop that was deleted
     * @return {Promise} Resolves when tracker is loaded
     */
    function restoreCssAndPrimaryTracker(shop) {
        $ctrl.loadingTickets = true;

        Object.defineProperty(shop, 'cssVariables', {
            value: ShopStyler.restoreVariables(shop.css),
            configurable: true,
            enumerable: false,
            writable: true
        });

        return $q.all([
            shop.isSystemShop(true).then(systemShop => $ctrl.editName = !systemShop, angular.noop),
            shop.$getOrCreatePrimaryTracker().catch(angular.noop)
        ]);
    }

    /**
     * BeforeNew hook that copies custom styling from CompanyShop and adds default metadata to new shop
     *
     * @param {Shop} shop New shop
     */
    function populateShopStyleAndMeta(shop) {
        // Copy CSS from global shop
        $ctrl.company.$getOrCreateShop()
            .then(companyShop => {
                shop.css = companyShop.css;
                return shop;
            });

        // Add default metaData
        // See DD-FE-2726 & DD-FE-2727 for upgrade path.
        // TLDR; Backend/database will be responsible for creating stable/default models.
        let defaults = [
            _.find($ctrl.metaData, {public: true, name: 'first_name'}),
            _.find($ctrl.metaData, {public: true, name: 'last_name'}),
            _.find($ctrl.metaData, {public: true, name: 'email'}),
            _.find($ctrl.metaData, {public: true, name: 'gender'}),
            _.find($ctrl.metaData, {public: true, name: 'city'}),
            _.find($ctrl.metaData, {public: true, name: 'age'}),
            _.find($ctrl.metaData, {public: true, name: 'keep_me_informed'})
        ];

        _.forEach(defaults, m => {
            if (m) {
                shop.metaData.push(m);
            }
        });
    }

    /**
     * BeforeSave hook that removes the fixed metaData because they don't need to be attached
     *
     * @param {Shop} shop New shop
     */
    function removeFixedMetaBeforeCreate(shop) {
        if (shop.guid) {
            return;
        }

        let defaults = [
            _.find($ctrl.metaData, {public: true, name: 'first_name'}),
            _.find($ctrl.metaData, {public: true, name: 'last_name'}),
            _.find($ctrl.metaData, {public: true, name: 'email'})
        ];

        _.forEach(defaults, meta => {
            _.pull(shop.metaData, meta);
        });
    }

    /**
     * AfterSave hook that creates Relations for new shops, refreshes
     * the preview and ensures the user is redirected back to edit page
     *
     * @param {Error} err Optional error
     * @param {Shop} shop Saved shop
     * @param {Boolean} isNew Whether the shop was newly created
     * @return {Promise} Resolves when done
     */
    function afterSaveActions(err, shop, isNew) {
        $ctrl.crud.model.belongsToMany.clear('Ticket');

        if (!err) {
            // Ensure CrudCtrl stays in edit state
            $timeout(() => $ctrl.crud.edit(shop), 500);
        }

        if (!err && isNew) {
            return RelationAPI.create('EventShop', $ctrl.event.guid, shop.guid)
                .then(() => $ctrl.eventShops.push(shop));
        }

        refreshPreview();

        return $q.resolve();
    }

    /**
     * Make sure pinned metadata are at the top of the list
     */
    function reorderPinnedMetaData() {
        _.remove($ctrl.crud.model.metaData, $ctrl.pinFixedMeta);

        let firstName = _.find($ctrl.metaData, {public: true, name: 'first_name'});
        let lastName = _.find($ctrl.metaData, {public: true, name: 'last_name'});
        let email = _.find($ctrl.metaData, {public: true, name: 'email'});

        $ctrl.crud.model.metaData.unshift(firstName, lastName, email);
    }

    /**
     * Refresh the preview by increasing the nonce number
     *
     * @param {Any=} err If an error was given, refresh is not done
     */
    function refreshPreview(err) {
        if (err) {
            return;
        }

        $ctrl.nonce++;
    }

    /**
     * When user clicks the back button but the shop has unsaved changes, ask if we should discard them.
     *
     * @return {Promise} Resolves when user OKs the discard
     */
    function confirmUnsavedChanges() {
        return $uibModal.open({
            template: `<div class="modal-header">
    <h3 class="modal-title" translate>common.notice.unsavedChanges</h3>
</div>
<div class="modal-footer">
    <a class="btn btn-default" ng-click="$dismiss()" translate>common.action.cancel</a>
    <a class="btn btn-danger" ng-click="$close()" translate>common.action.continue</a>
</div>`
        }).result;
    }

    /**
     * Goto previous step
     */
    function prev() {
        let wizard = /\.advanced\./.test($state.current.name) ? 'advanced' : 'simple';
        $state.go(`eventix.dashboard.wizard.${wizard}.tickets`, {guid: $ctrl.event.guid});
    }

    /**
     * Goto next step
     */
    function next() {
        $ctrl.crud.save()
            .then(() => {
                let wizard = /\.advanced\./.test($state.current.name) ? 'advanced' : 'simple';
                let step = 'scanners';
                return $state.go(`eventix.dashboard.wizard.${wizard}.${step}`, {guid: $ctrl.event.guid});
            });
    }

    /**
     * AfterQuery hook to add fixed metadata to the shop
     *
     * @param {Any} err Optional error
     * @param {String} fnSuffix Should be `MetaData`
     * @param {Shop} shop The should being edited
     * @param {Array<MetaData>} metaData List of tickets in shop
     */
    function prefillDefaultMetaData(err, fnSuffix, shop, metaData) {
        if (err || fnSuffix !== 'MetaData') {
            return;
        }

        let firstName = _.find($ctrl.metaData, {public: true, name: 'first_name'});
        let lastName = _.find($ctrl.metaData, {public: true, name: 'last_name'});
        let email = _.find($ctrl.metaData, {public: true, name: 'email'});

        metaData.unshift(firstName, lastName, email);
    }

    function canUnlinkShopFromEvent(shop) {
        return !shop.tickets.length
            && _.includes($ctrl.eventShops, shop)
            && !_.includes($ctrl.companyShops, shop);
    }

    function unlinkShopFromEvent(shop) {
        return RelationAPI.delete('EventShop', $ctrl.event.guid, shop.guid);
    }

    function showAllShops() {
        return loadAllShops()
            .then(shops => {
                $ctrl.showingAllShops = true;

                $ctrl.crud.models = shops;
            });
    }

    function loadData() {
        return $q.all([
            loadShops(),
            loadMetaData(),
            loadPaymentMethods()
        ]);
    }

    function loadShops() {
        return $q.all([
            $ctrl.event.$queryShop(),
            $ctrl.company.$getOrCreateShop().then(shop => [shop]),
        ])
            .then(results => {
                $ctrl.shops = _.unionBy(results[0], results[1], 'guid');
                $ctrl.eventShops = results[0];
                $ctrl.companyShops = results[1];

                return $ctrl.shops.sort((a, b) => a.name.localeCompare(b.name));
            })
            .catch($log.error);
    }

    function loadMetaData() {
        return MetaData.query()
            .then(metaData => $ctrl.metaData = _.map(metaData, meta => {
                meta.disabled = $ctrl.pinFixedMeta(meta);
                return meta;
            }))
            .catch($log.error);
    }

    function loadPaymentMethods() {
        return PaymentMethod.query()
            .then(paymentMethods => $ctrl.paymentMethods = paymentMethods)
            .catch($log.error);
    }

    function loadAllShops() {
        return Shop.query()
            .then(shops => $ctrl.allShops = shops.sort((a, b) => a.name.localeCompare(b.name)))
            .catch($log.error);
    }

    /**
     * Whether or not the options should be shown at the top of the form
     *
     * @return {boolean} Options visibility
     */
    function showOptions() {
        return $ctrl.forms.selectedForm === 'main';
    }

    /*
     * New dashboard shop settings integration
     */
    window.legacyDashboardShopSettingsCallback = testDitDan
    function testDitDan () {
        console.log('legacyDashboardShopSettingsCallback Called');
    }
    $ctrl.showNewDashboardStyling = (NEW_DASHBOARD_ROOT).toString() !== '';
    $ctrl.openNewStyling = openNewStyling;
    function openNewStyling(shop){
        const accessToken = $window.auth ? $window.auth.getAccessToken() : null;
        let url = NEW_DASHBOARD_ROOT + 'shops/'+shop.guid+'/settings?legacy=true&as='+shop.company_id+'&access_token='+accessToken.access_token+'&locale='+Locales.selected;
        $window.open(url, '_blank');
    }
}
