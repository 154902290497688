import template from './whitelabel.html';

export default angular.module('eventix.dashboard.admin.whitelabel',[ ])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.admin.whitelabel', {
            url: '/whitelabel',
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'WhitelabelController as vm',
                    templateUrl: template
                }
            },
            resolve: {
                whitelabels: function(Whitelabel) {
                    return Whitelabel.query();
                }
            },
            data: {
                requiredRoles: ['Admin']
            }
        });
    })
    .controller('WhitelabelController', function($state, simpleCRUD, Whitelabel, whitelabels,
        UIMessages, Locales, $scope) {
        var vm = this;
        vm.crud = simpleCRUD(whitelabels, newWhitelabel, $scope);

        vm.clientRefresh = function(whitelabel) {
            whitelabel.clientBusy = true;
            whitelabel.clientRecreate().then(function() {
                UIMessages.push('Recreated client credentials');
                delete whitelabel.clientBusy;
            }, function(){
                UIMessages.push({
                    type: 'error',
                    message: 'Error recreating client credentials'
                });
                delete whitelabel.clientBusy;
            });
        };

        vm.locales = Locales;
        function newWhitelabel() {
            return Whitelabel.new();
        }
    }).name;

