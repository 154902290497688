import * as rudderanalytics from "rudder-sdk-js"

rudderanalytics.ready(() => {
    console.log("we are all set!!!")
})
rudderanalytics.load('1vtjla6WX66a9VUxEUTeCbiS0eV', 'https://rs.eventix.io/', {
    integrations: {
        All: true,
    },
});
export default rudderanalytics
