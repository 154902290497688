import offlineTicketsTemplate from './offlineTickets.html';
import moment from 'moment';

export default angular.module('eventix.dashboard.import', [])
    .config(ModuleConfig)
    .component('offlineTicketsPage', {
        templateUrl: offlineTicketsTemplate,
        controller: OfflineTicketsPageController,
        bindings: {
            company: '<',
            events: '<'
        }
    }).name;

function OfflineTicketsPageController($q, OrderImporter, $scope, Locales, MetaData, UIMessages, LaravelValidationRules, SweetAlert, CSVExport, $translate, $analytics) {
    const $ctrl = this;
    $ctrl.formOptionsAvailable = [
        {
            key: 'sendEmail',
            label: $translate.instant('models.offlineOrder.send_email'),
        },
        {
            key: 'renderPdf',
            label: $translate.instant('models.offlineOrder.render_pdf'),
        },
        {
            key: 'locale',
            label: $translate.instant('models.offlineOrder.locale'),
        },
    ];
    $ctrl.busy = false;
    $ctrl.fillOrders = fillOrders;
    $ctrl.renderPdf = true;
    $ctrl.sendEmail = true;
    $ctrl.receiverMeta = [{
        guid: 'order.firstName',
        translateName: 'common.metaData.first_name',
        type: 'string',
        extra: 'required'
    }, {
        guid: 'order.lastName',
        translateName: 'common.metaData.last_name',
        type: 'string',
        extra: 'required'
    }, {
        guid: 'order.email',
        translateName: 'common.metaData.email',
        type: 'string',
        extra: 'required|email'
    }];
    $ctrl.locales = Locales;
    $ctrl.locale = Locales.selected;

    resetOfflineTickets();

    $ctrl.$onInit = function() {
        $ctrl.company.$getOrCreateOfflineShop().then(shop => {
            $ctrl.shop = shop;
        });

        MetaData.query().then(m => $ctrl.metaData = m);

        $ctrl.locale = Locales.selected;
        $ctrl.state = 'ticket-select';

        $scope.$watch('$ctrl.receiver', copyReceiverToOrders, true);
        $scope.$watch('$ctrl.locale', copyLocaleToOrders);

        $scope.$watch('$ctrl.amounts', updateTotal, true);
    };

    function resetOfflineTickets() {
        $ctrl.state = 'ticket-select';

        $ctrl.event = null;
        $ctrl.tickets = null;

        $ctrl.orders = [];

        $ctrl.receiver = {};
        $ctrl.receiverErrors = {};

        $ctrl.ticketMeta = {};
        $ctrl.orderMeta = {};

        $ctrl.amounts = {};
        $ctrl.total = 0;

        $ctrl.csv = null;
    }

    $ctrl.changeAmount = function(ticket, amount = 1) {
        $ctrl.amounts[ticket.guid] += amount;
    };

    function updateTotal() {
        let total = 0;

        _.forEach($ctrl.tickets, ticket => {
            total += _.get($ctrl.amounts, ticket.guid, 0) * _.get(ticket, 'minimumPrice', 0);
        });

        $ctrl.total = total;
    }

    /**
     * Fetch tickets belonging to an event
     *
     * Resets ticket amounts to zero
     *
     * @param {Event} event Selected event
     * @return {Promise<Array>} Resolves with an array of tickets
     */
    $ctrl.fetchTickets = function(event) {
        $ctrl.amounts = {};
        $ctrl.total = 0;
        return event.$queryTicket().then(tickets => {
            $ctrl.tickets = tickets;
            return $q.all(_.map(tickets, ticket => ticket.calculateMinimumPrice(false)));
        });
    };

    /**
     * Submit orders to API for importing
     *
     * @return {Promise<String>} Resolves with CSV string
     */
    $ctrl.process = function() {
        let blockingErrors = LaravelValidationRules.validate($ctrl.receiverMeta, $ctrl.receiver);
        if(_.find(blockingErrors, e => !_.isEmpty(e))) {
            UIMessages.push('models.offlineOrder.notice.receiverRequired');
            return $q.reject();
        }

        $ctrl.busy = true;

        return OrderImporter.toCSV($ctrl.orders).then(csv => {
            $ctrl.csv = csv;
            return OrderImporter.process(csv, $ctrl.renderPdf, $ctrl.sendEmail);
        }).then(response => {
            resetOfflineTickets();

            $analytics.eventTrack('import-guestlist-created');

            SweetAlert.swal({
                title: $translate.instant('common.notice.congratulations'),
                text: $translate.instant('models.offlineOrder.notice.success'),
                type: 'success',
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                closeOnConfirm: true,
                closeOnCancel: true,
                cancelButtonText: $translate.instant('common.action.download'),
                allowEscapeKey: true
            }, shouldNotDownload => {
                if (!shouldNotDownload) {
                    let orders = _.flatMap(_.get(response, 'orders', []), order => {
                        return _.map(order.tickets, ticket => {
                            return {
                                order_id: _.get(order, 'guid', 'unknown'),
                                order_first_name: _.get(order, 'firstName', 'unknown'),
                                order_last_name: _.get(order, 'lastName', 'unknown'),
                                order_email: _.get(order, 'email', 'unknown'),

                                order_ticket_id: _.get(ticket, 'guid', 'unknown'),
                                ticket_id: _.get(ticket, 'ticket_id', 'unknown'),
                                barcode: _.get(ticket, 'ticket_number', 'unknown')
                            };
                        });
                    });

                    CSVExport.convert(orders, null, moment().format('YYYY-MM-DD HH:mm') + ' imported', true);
                }
            });

        }).finally(() => {
            $ctrl.busy = false;
        });
    };

    /**
     * Copies metadata from one order to all other orders
     *
     * @param {Object} srcOrder The order to copy from
     */
    $ctrl.copyMeta = function(srcOrder) {
        let metaData = $ctrl.ticketMeta[srcOrder['order.order_tickets.ticket_id']];
        _.forEach($ctrl.orders, order => {
            if(srcOrder === order)
                return;
            _.forEach(metaData, meta => {
                order[meta.guid] = srcOrder[meta.guid];
            });
        });
    };

    /**
     * Remove MetaData to the receiver or a specific ticket type
     *
     * @param {MetaData} metaData The abstract metadata type
     * @param {Object} [order] Optional order to specify the ticket type
     */
    $ctrl.removeMetaData = function(metaData, order) {
        let metas = order ?
            $ctrl.ticketMeta[order['order.order_tickets.ticket_id']] :
            $ctrl.orderMeta;
        _.remove(metas, { guid: metaData.guid });
    };

    /**
     * Add MetaData to the receiver or a specific ticket type
     *
     * @param {MetaData} metaData The abstract metadata type
     * @param {Object} [order] Optional order to specify the ticket type
     */
    $ctrl.addMetaData = function(metaData, order) {
        let fauxMeta = createFauxMetadata(order ? 'ticket' : 'order', [metaData])[0];
        let metas = order ?
            $ctrl.ticketMeta[order['order.order_tickets.ticket_id']] :
            $ctrl.orderMeta;
        if(!_.find(metas, { guid: fauxMeta.guid }))
            metas.push(fauxMeta);
    };

    /**
     * Create an array of orders for a set of tickets
     *
     * Fills the following variables
     *
     *  - `$ctrl.orders` - Array of orders
     *  - `$ctrl.orderMeta` - Array of shop (faux) metaData
     *  - `$ctrl.ticketMeta` - Object with arrays of ticket (faux) metaData keyed by ticket guid
     *
     * @return {Promise<Array>} Resolves with an array of order objects
     */
    function fillOrders() {
        let totalOrders = _.reduce($ctrl.amounts, (t,a) => t + a, 0);
        if(totalOrders > 250) {
            UIMessages.push({
                type: 'error',
                message: 'models.offlineOrder.notice.tooManyOrders'
            });
            return $q.reject();
        }
        let tickets = _.filter($ctrl.tickets, ticket => $ctrl.amounts[ticket.guid] > 0);
        if(!tickets.length) {
            UIMessages.push({
                message: 'models.offlineOrder.notice.emptySelection',
                type: 'error'
            });
            return $q.reject();
        }
        return OrderImporter.newOrders($ctrl.shop, tickets, $ctrl.amounts)
            .then(orders => {
                $ctrl.orders = orders;
                // create a hidden $errors object for metadata validation errors
                _.forEach(orders, order => {
                    Object.defineProperty(order, '$errors', {
                        value: {},
                        writable: true,
                        enumerable: false
                    });
                });
                // Receiver metadata
                $ctrl.shop.$queryMetaData(true)
                    .then(shopMeta => {
                        $ctrl.orderMeta = createFauxMetadata('shop', shopMeta);
                    });
                // Metadata for tickets
                let promises = _.map(tickets, ticket => {
                    return ticket.$queryMetaData(true)
                        .then(ticketMeta => {
                            return [ticket.guid, createFauxMetadata('ticket', ticketMeta)];
                        });
                });
                copyLocaleToOrders($ctrl.locale || Locales.selected);
                return $q.all(promises);
            }).then(ticketMeta => {
                $ctrl.ticketMeta = _.fromPairs(ticketMeta);
                $ctrl.receiverErrors = {};
                $ctrl.state = 'ticket-details';
                return $ctrl.orders;
            });
    }

    /**
     * Creates a fake metadata model that can be fed into `<metadata-collection>`,
     * `<metadata-edit>` and `LaravelValidationRules` and which will work in
     * conjunction with an order object generated by OrderImporter
     *
     * @param {String} type Whether the metaData are `shop` or `ticket`
     * @param {Array<MetaData>} metaData Array of real metaData
     * @return {Array<Object>} Faux metaData
     */
    function createFauxMetadata(type, metaData) {
        return _.map(metaData, meta => {
            return {
                guid: `order.${ type === 'ticket' ? 'order_tickets.' : '' }metadata.${meta.guid}`,
                name: meta.name,
                translateName: (meta.public ? 'common.metaData.' : '') + meta.name,
                type: meta.type,
                extra: meta.extra
            };
        });
    }

    /**
     * Copy receiver data to all orders
     *
     * @param {Object} receiver Receiver data
     */
    function copyReceiverToOrders(receiver) {
        if(!receiver || !$ctrl.orders || !$ctrl.orders.length)
            return;
        $ctrl.orders.forEach(order => _.assign(order, receiver));
    }

    /**
     * Copy the selected locale to all orders
     *
     * @param {string} locale Selected locale
     */
    function copyLocaleToOrders(locale) {
        if (!locale || !$ctrl.orders || !$ctrl.orders.length) {
            return;
        }

        $ctrl.orders.forEach(order => {
            order['order.locale'] = locale;
        });
    }
}

function ModuleConfig($stateProvider) {
    $stateProvider.state('eventix.dashboard.offlineTickets', {
        url: '/guestlist',
        views: {
            'dashboard@eventix.dashboard': {
                component: 'offlineTicketsPage',
                bindings: {
                    company: 'company',
                    events: 'events'
                }
            }
        },
        data: {
            requiredRoles: ['Event Manager'],
            crumb: 'common.menu.guestlist.offlineOrders'
        }
    });
}
