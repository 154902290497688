import template from './demographics.html';
import c3 from 'c3';
import moment from 'moment';
import { get } from 'scriptjs';

export default angular.module('eventix.dashboard.home.event.sales.demographics', [])
    .component('eventHomeSalesDemographics', {
        bindings: {event: '<'},
        controller: EventHomeSalesDemographicsController,
        templateUrl: template
    })
    .directive('c3MetaChart',['$window','$timeout',function($window,$timeout) {
        var chartCounter = 0;
        return {
            restrict: 'E',
            scope: { options: '&' , data: '&', chart: '=', responsive: '@' },
            template: '<div class="c3-chart" ng-class="{hidden: !done}"></div>',
            link: function(scope, element, attrs) {
                chartCounter++;
                var colors = ['#00BEF6','#0079BF','#00A650','#F46B49','#BB2321'];
                var defaults = { data: { columns: [] } , color: {pattern: colors}, size: {}};
                var options = _.defaultsDeep(scope.options(), defaults);
                var data = scope.data();
                var chart;
                options.bindto = $(element).children('.c3-chart')[0];
                switch(scope.responsive) {
                    case 'width':
                        options.size.width = element.parent().width();
                        break;
                    case 'height':
                        options.size.height = element.parent().height();
                        break;
                    case 'both':
                    default:
                        options.size.width = element.parent().width();
                        options.size.height = element.parent().width();
                        break;
                }
                var reflow = function() {
                    var dimensions = {};
                    switch(scope.responsive) {
                        case 'width':
                            dimensions.width = element.parent().width();
                            break;
                        case 'height':
                            dimensions.height = element.parent().height();
                            break;
                        case 'both':
                        default:
                            dimensions.width = element.parent().width();
                            dimensions.height = element.parent().width();
                            break;
                    }
                    chart.resize(dimensions);
                };
                if(scope.responsive !== false)
                    $(window).on('resize.c3Chart',_.debounce(reflow,300));
                chart = c3.generate(options);
                $timeout(function() {
                    if(_.isArray(data)) {
                        if(_.isArray(data[0]))
                            chart.load({columns: data});
                        else
                            chart.load({columns: [data]});
                    }
                    if(!_.isUndefined(scope.chart))
                        scope.chart = chart;
                    if(scope.responsive !== false)
                        reflow();
                    scope.done = true;
                }, 10 * chartCounter);
            }
        };
    }]).directive('googleMaps',['$window','$timeout', '$http', 'UIMessages', '$q',function($window,$timeout,$http, UIMessages, $q) {
        return {
            restrict: 'E',
            scope: { event: '<' },
            template: '<div class="google-maps" id="google-maps" style="height:500px;"></div>',
            link: function(scope, element, attrs) {
                let event = scope.event;
                let map;
                let markers = [];
                let precision = 1;

                const rerender = _.throttle(renderPoints, 1500);

                function renderPoints() {
                    if(!map)
                        return;

                    let bounds =  map.getBounds();

                    if(!bounds)
                        return;

                    let ne = bounds.getNorthEast();
                    let sw = bounds.getSouthWest();

                    let queryString = new URLSearchParams({
                        top_right_lat:  ne.lat(),
                        top_right_lon:  ne.lng(),
                        bottom_left_lat:  sw.lat(),
                        bottom_left_lon:  sw.lng(),
                        precision:precision
                    }).toString();

                    return $http.get(`/statistics/maps/${event.guid}?`+queryString).then(response => {
                        if(!map)
                            return;

                        deleteMarkers();

                        let maxMarkerCount = _.get(response.data, 'max_marker_count', 0);

                        _.forEach(response.data.markers, (marker) => {
                            let size = Math.min(Math.ceil(marker.number_of_tickets*5/maxMarkerCount), 5);
                            let icon = `https://s3-eu-west-1.amazonaws.com/ev3b/public/maps/m${size}.png`;
                            createMarker({
                                position: new google.maps.LatLng(marker.latitude, marker.longitude),
                                map: map,
                                icon: icon,
                                label: ""+marker.number_of_tickets,
                            });
                        });
                    }, error => {
                        UIMessages.push('common.notice.error');
                        return $q.reject(error);
                    });
                }

                function createMarker(options) {
                    markers.push(new google.maps.Marker(options));
                }

                function setMapOnAll(map) {
                    for (let i = 0; i < markers.length; i++) {
                        markers[i].setMap(map);
                    }
                }

                // Removes the markers from the map, but keeps them in the array.
                function clearMarkers() {
                    setMapOnAll(null);
                }

                // Shows any markers currently in the array.
                function showMarkers() {
                    setMapOnAll(map);
                }

                // Deletes all markers in the array by removing references to them.
                function deleteMarkers() {
                    clearMarkers();
                    markers = [];
                }

                function render() {
                    map = new google.maps.Map(document.getElementById("google-maps"), {
                        center: new google.maps.LatLng(53, 4),
                        zoom: 2,
                        mapTypeId: google.maps.MapTypeId.ROADMAP,
                        styles: [
                            {
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#616161"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    }
                                ]
                            },
                            {
                                "featureType": "administrative.land_parcel",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#bdbdbd"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#eeeeee"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#757575"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi.park",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#e5e5e5"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi.park",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#9e9e9e"
                                    }
                                ]
                            },
                            {
                                "featureType": "road",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.arterial",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#757575"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#dadada"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#616161"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.local",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#9e9e9e"
                                    }
                                ]
                            },
                            {
                                "featureType": "transit.line",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#e5e5e5"
                                    }
                                ]
                            },
                            {
                                "featureType": "transit.station",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#eeeeee"
                                    }
                                ]
                            },
                            {
                                "featureType": "water",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#c9c9c9"
                                    }
                                ]
                            },
                            {
                                "featureType": "water",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#9e9e9e"
                                    }
                                ]
                            }
                        ]
                    });

                    google.maps.event.addListener(map, "bounds_changed", function() {
                        // send the new bounds back to your server
                        rerender();
                    });
                    google.maps.event.addListener(map, "zoom_changed", function() {
                        // send the new bounds back to your server
                        precision = Math.min(Math.max((map.getZoom() * 12)/22-1, 1), 12);
                        rerender();
                    });

                    rerender();
                }

                get("https://maps.googleapis.com/maps/api/js?key="+GOOGLE_API_KEY+"&center=36.50086525581848,373.3508390432612&zoom=3&format=png&maptype=roadmap&style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&size=480x360", () => {
                    render();
                });

            }
        };
    }]).name;

function EventHomeSalesDemographicsController($state, MetaData, ElasticLoader, $timeout) {
    // CONTROLLER
    const $ctrl = this;

    let metaData;
    $ctrl.metaData = [];
    $ctrl.stats = {
        ticketCount: { statistics: { doc_count: 0 } },
        ticketCountToday: { statistics: { doc_count: 0 } },
        totalRevenue: { statistics: { statistics: { value: 0 } } },
        totalRevenueToday: { statistics: { statistics: { value: 0 } } }
    };
    $ctrl.dataLoading = {
        demographics: true,
    };

    MetaData.query().then((data) => {
        $ctrl.metaData = data;
    });

    $ctrl.chartOptions = {
        donut: function(buckets, name) {
            let d = {
                data: {
                    columns: _.map(buckets, _.values),
                    type: 'donut'
                },
                legend: { show: false },
                donut: {
                    title: _.get(buckets, '0.key'),
                    label: { show: true }
                }
            };
            return d;
        },
        bar: function(buckets, name) {
            buckets = _.sortBy(buckets, 'key');

            return {
                data: {
                    columns:
                        _.map(buckets, bucket => [bucket.key, bucket.doc_count]),
                    type: 'bar'
                },
                tooltip: {
                    format: {
                        name: function(name, ratio, id, index) {
                            return name;
                        },
                        title: function() { return name; }
                    }
                },
                legend: { show: false },
                bar: { zerobased: true },
                axis: { x: { show: false } }
            };
        }
    };

    $ctrl.$onInit = function () {
        updateStats($ctrl.event);
    };

    function updateStats(event) {
        $ctrl.dataLoading.demographics = true;
        event.$queryEventDate().then(dates => {
            dates = _.sortBy(dates, 'start');
            let nextDate = moment(_.get(_.first(dates), 'start'));
            $ctrl.daysUntil = nextDate.diff(moment(),'days');
        });
        ElasticLoader.getDashboard(event.guid).then(stats => {
            $ctrl.stats = stats;
            populateCharts(stats);
        });
    }

    // function formatData(buckets) {
    //     return _.map(buckets, _.values);
    // }
    //
    // function renderCharts(type, metaGuid, data){
    //     return initMetaChart(metaGuid, data);
    // }

    $ctrl.types = ['orderMetaData', 'ticketMetaData', 'productMetaData'];

    $ctrl.charts = [];

    function populateCharts(stats){
        _.forEach($ctrl.types, type => {
            let data = stats[type];

            if (!data) {
                return;
            }

            if(type === 'ticketMetaData') {
                if (!data.data || !data.data.data) {
                    return;
                }

                data = data.data.data;
            }

            _.forEach(data, (data, metaGuid) => {
                if(metaGuid !== 'doc_count' && metaGuid.indexOf('total') < 0) {
                    const meta = _.find($ctrl.metaData, { guid: metaGuid });

                    if(meta) {
                        $ctrl.charts.push(initMetaChart(meta, data));
                    }
                }
            });
        });

        $timeout(function() {
            $ctrl.dataLoading.demographics = false;
        }, 200);
    }

    function initMetaChart(meta, data) {
        var hidden = [
            'first_name',
            'last_name',
            'email',
            'phonenumber'
        ];

        let chart = {
            type: 'donut',
            meta: meta,
            name: _.get(meta,'translateName', ''),
            data: _.get(data, 'statistics.buckets', []),
            disabled: !_.isObject(data)
            || _.includes(hidden, meta.name)
            || _.get(data, 'statistics.buckets.length', 0) === 0
        };

        if(meta && meta.type === 'date') {
            let currentYear = (new Date()).getFullYear();
            _.forEach(chart.data, bucket => {
                if(/^\d+$/.test(bucket.key))
                    bucket.key = currentYear - bucket.key;
            });
            if(meta.name === 'date_of_birth')
                chart.name = 'common.metaData.age';
        }

        // Calculate avg age -_-
        if(_.get(chart, 'name') === 'common.metaData.age'){
            // calculate avg
            let totalAge = 0;
            let totalCount = 0;
            let buckets = _.get(data,'statistics.buckets');
            _.forEach(buckets, b => {
                totalAge += parseInt(_.get(b, 'key', 0) *_.get(b, 'doc_count', 0));
                totalCount += _.get(b, 'doc_count', 0);
            });
            if(totalAge > 0)
                chart.avg = Math.round(totalAge/totalCount);
        }

        return chart;
    }
}
