import Preferences from './preferences/preferences.js';
import Users from './users/users.js';
import Clients from './clients/clients';
import template from './company.html';
import CompanyForm from './companyForm.js';
import CompanyCreate from './create/create';

export default angular.module('eventix.dashboard.company', [Users, Clients, CompanyForm, CompanyCreate, Preferences])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.company', {
            url: '/company',
            views: {
                dashboard: {
                    component: 'companySettings'
                }
            },
            data: {
                requiredRoles: ['Company Admin']
            }
        });
    })
    .component('companySettings', {
        templateUrl: template,
        bindings: {
            company: '<'
        },
        controller: CompanySettingsController
    }).name;

function CompanySettingsController(UIMessages, Locales, $q, Shop, SweetAlert, Currencies, $scope, $analytics, $translate, Role) {
    const $ctrl = this;
    $ctrl.busy = false;
    $ctrl.save = save;

    $ctrl.$onInit = function() {
        $ctrl.isAdmin = Role.isAuthorizedAs('Admin');

        $scope.$on('input-blur', function(event, name) {
            if (Object.prototype.hasOwnProperty.call($ctrl.company, name)) {
                // Ensure before validating, any 'external' errors are removed.
                $ctrl.company.$errors.clear(name);
            }

            $ctrl.company.validate(name);
        });
    };

    /**
     * Save the company
     *
     * @param {Object} company The company to save
     *
     * @return {Promise} Resolves when done
     */
    function save(company) {
        if (company.$invalid)
            return $q.reject();

        $ctrl.busy = true;

        return company.$save().then(() => {
            company.$errors.clear();

            $ctrl.busy = false;
        }).catch(error => {
            company.$errors.clear();

            let errors = _.get(error, 'data.error_description', {});

            if (_.get(error, 'status') === 406 && _.isObject(errors))
                _.forOwn(errors, (value, key) => {
                    _.forEach([].concat(value), message => {
                        company.$errors.add(key, message);
                    });
                });

            $ctrl.busy = false;

            return $q.reject(error);
        });
    }

}
