import templateUrl from './helpWithPage.html';
import './helpWithPage.less';

export default angular.module('eventix.dashboard.components.helpWithPage', [])
    .service('HelpURLs', function($http, $q, Locales) {
        let map = {};

        this.get = get;

        let promise = $http.get(PAGE_HELP_URLS_JSON)
            .then(response => {
                if (response && response.data) {
                    map = response.data;
                }
            })
            .catch(error => {
                console.error(error);

                return $q.reject(error);
            });

        function get(slug) {
            return promise
                .then(() => {
                    let slugParts = slug.split('.'),
                        fallbackMap = _.get(map, _.get(map, 'fallback_locale', 'en_GB'), {}),
                        langMap = _.defaults(_.get(map, Locales.selected, {}), fallbackMap),
                        value = '';

                    while (slugParts.length) {
                        value = _.get(langMap, [slugParts.join('.')], '');

                        if ((_.isString(value) && !_.isEmpty(value)) || value === false) {
                            break;
                        }

                        value = _.get(fallbackMap, [slugParts.join('.')], '');

                        if ((_.isString(value) && !_.isEmpty(value)) || value === false) {
                            break;
                        }

                        slugParts.pop();
                    }

                    if (_.isString(value)) {
                        return value;
                    }

                    return $q.reject(Error('Help URL map not found'));
                })
                .catch(() => {
                    return '';
                });
        }
    })

    /**
     * Component to add help button to a page
     *
     * The current route name (slug) is used to lookup the help url.
     * If no help url is found, the button is hidden.
     *
     * @param {String} btnClass Overwrite the class used for the button (default: btn btn-default)
     * @param {String} slug Overwrite the help url slug (default: current.route.name)
     * @param {String} title Overwrite the default title. This value will be translated. (default: common.action.helpWithPage)
     * @param {String} icon Overwrite the icon (default: fa fa-graduation-cap)
     *
     * @example
     * ```
     * <help-with-page
     *     btnClass="btn btn-success pull-right"
     *     slug="eventix.dashboard.some.state.name"
     *     title="common.action.helpWithPage"
     *     icon="fa fa-question-mark"></help-with-page>
     * ```
     */
    .component('helpWithPage', {
        bindings: {
            btnClass: '@',
            slug: '@',
            title: '@',
            icon: '@'
        },
        templateUrl: templateUrl,
        controller: HelpWithPage
    })
    .name;

function HelpWithPage($state, $transitions, $window, HelpURLs) {
    const $ctrl = this;

    $ctrl.help = help;

    $ctrl.active = false;

    $ctrl.$onInit = function() {
        reload();

        $transitions.onSuccess({}, reload);
    };

    function reload() {
        $ctrl.active = false;
        $ctrl.url = '';

        $ctrl.classList = $ctrl.btnClass || 'btn btn-default btn-xs';
        $ctrl.titleValue = $ctrl.title || 'common.action.helpWithPage';
        $ctrl.iconValue = $ctrl.icon || 'fa fa-graduation-cap';
        $ctrl.slugValue = $ctrl.slug || _.get($state, 'current.name', '');

        if (_.isString($ctrl.slugValue) && !_.isEmpty($ctrl.slugValue)) {
            HelpURLs.get($ctrl.slugValue).then(url => {
                $ctrl.url = url;

                if (_.isString(url) && !_.isEmpty(url)) {
                    $ctrl.active = true;
                }
            });
        }
    }

    function help() {
        var utm = 'utm_source=Help&utm_medium=dashboard&utm_campaign=more_info_help';
        var utmConnector = $ctrl.url.includes("?")? "&" : "?";

        if (!_.isString($ctrl.url) || _.isEmpty($ctrl.url)) {
            return '';
        }

        $ctrl.url += utmConnector + utm;

        $window.open($ctrl.url, '_blank');

        return $ctrl.url;
    }
}
