/**
 * Merge the keys of the data into their respective values
 *
 * @param {Object} data
 * @param {string} keyName
 * @return {Object} The merged data
 */
export function mergeKeyIntoValues(data, keyName = 'id') {
    _.forEach(data, function (value, key) {
        data[key][keyName] = key;
    });

    return data;
}

/**
 * Pops one or more values from an array
 *
 * @param array
 * @returns {*}
 */
export function arrayPopValue(array) {
    let what, a = arguments, L = a.length, ax;

    while (L > 1 && array.length) {
        what = a[--L];
        while ((ax = array.indexOf(what)) !== -1) {
            array.splice(ax, 1);
        }
    }

    return array;
}
