import template from './simpleTicketForm.html';

export default angular.module('eventix.dashboard.wizard.common.simpleTicketForm', [])
    .component('simpleTicketForm', {
        controller: SimpleTicketFormController,
        templateUrl: template,
        bindings: {
            event: '<',
            company: '<',
            translations: '<?',
            shops: '=',
            public: '=',
            scanners: '=',
            crud: '<',
            save: '&?',
            cancel: '&?',
            formOptions: '<'
        }
    }).name;

function SimpleTicketFormController($scope, $q, $timeout, $uibModal, $translate, $http, SweetAlert, ErrorRejector, TemplateModal, Role, MetaData, ScannerType) {

    const $ctrl = this;

    const highLightTime = 1000;

    $ctrl.isAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');
    $ctrl.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');
    $ctrl.isCompanyAdmin = Role.isAuthorizedAs('Company Admin');
    $ctrl.hasContract = Role.isAuthorizedAs('Has Contract');

    $ctrl.canEditKickbacks = $ctrl.isAdmin || ($ctrl.isCompanyAdmin && $ctrl.hasContract);
    $ctrl.canHideWithoutCoupon = $ctrl.isAdmin;

    $ctrl.barcode_types = ['generate', 'pre'];
    $ctrl.highlight = {};
    $ctrl.guids = {
        event: {
            guid: $ctrl.event ? $ctrl.event.guid : '',
            icon: 'fa-calendar',
            name: 'models.models.event',
        },
        ticket: {
            guid: $ctrl.crud && $ctrl.crud.model ? $ctrl.crud.model.guid : '',
            icon: 'fa-ticket',
            name: 'models.models.ticket',
        },
    };

    $ctrl.togglePublic = togglePublic;
    $ctrl.getAllProductsInTicket = getAllProductsInTicket;

    $ctrl.openTemplateModal = openTemplateModal;
    $ctrl.openKickbacksModal = openKickbacksModal;

    $ctrl.pickShops = pickShops;
    $ctrl.pickDates = pickDates;

    $ctrl.submit = submit;
    $ctrl.unsubmit = unsubmit;

    $ctrl.$postLink = function() {

        loadData().catch(ErrorRejector.handle);

        if (!$ctrl.translations) {
            $ctrl.translations = {};
        }

        _.defaults($ctrl.translations, {
            save: 'common.action.save',
            cancel: 'common.action.cancel'
        });

        $scope.$watch('$ctrl.crud.model.increment', watchIncrement);
        $scope.$watch('$ctrl.crud.model.min_orderable_amount_per_order', watchMinOrderableAmount);
        $scope.$watch('$ctrl.crud.model.max_orderable_amount_per_order', watchMaxOrderableAmount);

        $scope.$watch('$ctrl.event.guid', (guid) => {
            $ctrl.guids.event.guid = guid || '';
        });
        $ctrl.guids.event.guid = $ctrl.event ? $ctrl.event.guid : '';
        $scope.$watch('$ctrl.crud.model.guid', (guid) => {
            $ctrl.guids.ticket.guid = guid || '';
        });
        $ctrl.guids.ticket.guid = $ctrl.crud && $ctrl.crud.model ? $ctrl.crud.model.guid : '';

        // Temporary filter only product-products to make sure before and after the restructure the outputs is the same.
        // When cloning, origin_type is not set, do not filter these out. This filter can be removed after event restructure.
        $ctrl.crud.model.products = _.filter($ctrl.crud.model.products,
            (product) => !('origin_type' in product) || product.origin_type === 'product');
    };

    function submit() {
        if ($ctrl.save) {
            return $ctrl.save({model: $ctrl.crud.model});
        }

        return $ctrl.crud.save();
    }

    function unsubmit() {
        if ($ctrl.cancel) {
            return $ctrl.cancel({model: $ctrl.crud.model});
        }

        return $ctrl.crud.list();
    }

    function watchIncrement(newIncrement, oldIncrement) {
        if (_.isNil(newIncrement) || newIncrement === oldIncrement || !_.isInteger(newIncrement) || newIncrement < 1) {
            return;
        }

        // Changing increment ALWAYS changes min_amount (and if needed max_amount through min_amount)
        $ctrl.crud.model.min_orderable_amount_per_order = newIncrement;

        highlight('min_orderable_amount_per_order');

        // Delay validation so it won't validate before all changes (watchers) are done.
        $timeout(() => $ctrl.crud.model.validate());
    }

    function watchMinOrderableAmount(newMin, oldMin) {
        if (_.isNil(newMin) || newMin === oldMin || !_.isInteger(newMin) || newMin < 1) {
            return;
        }

        // Changing min_amount will only change increment if it becomes invalid.
        if ($ctrl.crud.model.increment > newMin) {
            $ctrl.crud.model.increment = newMin;

            highlight('increment');
        }

        // Changing min_amount will only change max_amount if it becomes invalid.
        if ($ctrl.crud.model.max_orderable_amount_per_order < newMin) {
            $ctrl.crud.model.max_orderable_amount_per_order = newMin;

            highlight('max_orderable_amount_per_order');
        }

        // Delay validation so it won't validate before all changes (watchers) are done.
        $timeout(() => $ctrl.crud.model.validate());
    }

    function watchMaxOrderableAmount(newMax, oldMax) {
        if (_.isNil(newMax) || newMax === oldMax || !_.isInteger(newMax) || newMax < 1) {
            return;
        }

        if ($ctrl.crud.model.increment > newMax) {
            $ctrl.crud.model.increment = newMax;

            highlight('increment');
        }

        // Delay validation so it won't validate before all changes (watchers) are done.
        $timeout(() => $ctrl.crud.model.validate());
    }

    function pickDates(dates) {
        if ($ctrl.crud.showNew()) {
            return $q.resolve($ctrl.crud.model.eventDate = dates);
        }

        if (!$ctrl.crud.showEdit()) {
            return ErrorRejector.handle('Can not pick dates, not editing a ticket.');
        }

        let queue = _.reduce($ctrl.crud.model.eventDate, (queue, date) => {
            return _.findIndex(dates, {guid: dates.guid}) >= 0 ? queue : queue.then(() => $ctrl.crud.model.$detachEventDate(date));
        }, $q.resolve());

        return _.reduce(dates, (queue, date) => queue.then(() => $ctrl.crud.model.$attachEventDate(date)), queue, $q.resolve($ctrl.crud.model.eventDate = dates));
    }

    function pickShops(shops) {
        if ($ctrl.crud.showNew()) {
            return $q.resolve($ctrl.crud.model.shops = shops);
        }

        if (!$ctrl.crud.showEdit()) {
            return ErrorRejector.handle('Can not pick shops, not editing a ticket.');
        }

        let queue = _.reduce($ctrl.crud.model.shops, (queue, shop) => {
            return _.findIndex(shops, {guid: shop.guid}) >= 0 ? queue : queue.then(() => $ctrl.crud.detachShop(shop));
        }, $q.resolve());

        return _.reduce(shops, (queue, shop) => queue.then(() => $ctrl.crud.attachShop(shop)), queue);
    }

    /**
     * Used to toggle the public state of the ticket.
     *
     * @param {Boolean} isPublic Whether the ticket aught to be public
     * @returns {Promise} Resolves when done
     */
    function togglePublic(isPublic) {
        let promise = $q.resolve();

        if (!$ctrl.crud.model.guid) {
            let shops = $ctrl.crud.model.shops;

            shops = isPublic ? _.merge(shops, $ctrl.shops) : _.filter(shops, shop => _.indexOf($ctrl.shops, {guid: shop.guid}) < 0);

            $ctrl.crud.model.shops = shops;

            return promise.then(() => $ctrl.crud.model.shops);
        }

        return _.reduce($ctrl.shops, (promise, shop) => {
            return promise.then(() => isPublic ? $ctrl.crud.attachShop(shop) : $ctrl.crud.detachShop(shop));
        }, promise)
            .then(() => $ctrl.crud.model.shops);
    }

    function getAllProductsInTicket(ticket) {
        return ticket.products;
    }

    /**
     * Pre-load potential children that can be attached to tickets
     *
     * @return {Promise} Resolves when done
     */
    function loadData() {
        return $q.all([
            loadShops(),
            loadScannerTypes(),
            loadMetaData()
        ]);
    }

    function loadShops() {
        let shopsPromises = [
            $ctrl.event.$queryShop(),
            $ctrl.company.$getOrCreateShop().then(shop => [shop])
        ];

        return $q.all(shopsPromises)
            .then(results => _.unionBy(results[0], results[1], 'guid'))
            .then(shops => $ctrl.shops = shops)
            .catch(ErrorRejector.handle);
    }

    function loadScannerTypes() {
        return $ctrl.event.$queryOrCreateScannerType(() => ScannerType.newDefault([$ctrl.event.name, $translate.instant('models.models.scannerType', {count: 1})].join(' - ')))
            .then(scannerTypes => $ctrl.scanners = scannerTypes)
            .catch(ErrorRejector.handle);
    }

    function loadMetaData() {
        return MetaData.query()
            .then(meta => $ctrl.metaData = meta)
            .catch(ErrorRejector.handle);
    }

    function openTemplateModal(ticket) {
        if (!ticket.guid) {
            return saveFirstNotice();
        }

        return TemplateModal.open('ticket', ticket);
    }

    function openKickbacksModal(ticket) {
        if (!ticket.guid) {
            return saveFirstNotice();
        }

        let company = $ctrl.company;

        return $uibModal.open({
            size: 'lg',
            controller: function() {
                const $ctrl = this;

                $ctrl.$onInit = function() {
                    $ctrl.ticket = ticket;
                    $ctrl.company = company;
                };
            },
            controllerAs: '$ctrl',
            template: '<div class="modal-body"><kickbacks applicable-model="$ctrl.ticket" applicable-company="$ctrl.company" applied-type="Ticket"></kickbacks></div><div class="modal-footer"><a class="btn btn-default" ng-click="$close()">Close</a></div>'
        });
    }

    function highlight(key) {
        _.set($ctrl.highlight, [key, 'status'], true);

        // If a promise already exists, the highlight is already scheduled for removal.
        // Delay the cancellation by cancelling the timeout and creating a new timeout.
        let promise = _.get($ctrl.highlight, [key, 'promise']);

        if (promise) {
            $timeout.cancel(promise);
        }

        _.set($ctrl.highlight, [key, 'promise'], $timeout(() => {
            _.set($ctrl.highlight, [key, 'status'], false);
        }, highLightTime));
    }

    function saveFirstNotice(){
        return SweetAlert.swal({
            title: $translate.instant('common.notice.warning'),
            text: $translate.instant('models.ticket.notice.saveFirst'),
            type: 'warning',
            timer: 5000,
            showConfirmButton: true,
            closeOnClickOutside: true
        });
    }

}

