import template from './trackers.html';
import openLinkTemplate from './openLink.html';
import c3 from 'c3';
import c3styling from 'c3/c3.css';

const resolves = /*@ngInject*/{
    shops: function(Shop) {
        return Shop.queryUserShops();
    },
    shop: function() {
        return {};
    },
    trackers: function(Tracker, shops, $q) {
        return Tracker.query().then(trackers => {
            return _.reduce(trackers, (promise, tracker) => {
                tracker.shop = _.find(shops, { guid: tracker.shop_id });

                if (!tracker.shop)
                    return promise.then(() => tracker.$getShop().then(shop => tracker.shop = shop));

                return promise;
            }, $q.resolve())
                .then(() => trackers);
        });
    }
};

export default angular.module('eventix.dashboard.trackers',[])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.trackers', {
            url: '/trackers',
            views: {
                'dashboard@eventix.dashboard': { component: 'trackersPage' }
            },
            resolve: resolves
        });
    })
    .component('trackersPage', {
        templateUrl: template,
        bindings: {
            shops: '<',
            trackers: '<',
            shop: '<'
        },
        controller: TrackersPageController
    }).controller('TrackerModalController', TrackerModalController).name;

function TrackerModalController($sce, $state, simpleCRUD, shops, shop, tracker, Tracker) {
    const $ctrl = this;
    $ctrl.shops = shops;
    $ctrl.shop = shop;
    $ctrl.shop_root = SHOP_ROOT;
    $ctrl.tracker_root = TRACKER_ROOT;
    $ctrl.link = $ctrl.tracker_root + tracker.code;
    $ctrl.tracker = tracker;
}

function TrackersPageController($uibModal, $state, $scope, $q, $filter, $translate, CrudCtrl, modalCRUD, ErrorRejector, UIMessages, ElasticLoader, Company, Role, Tracker) {
    const $ctrl = this;
    $ctrl.exportColumns = {
        'code': 'Code',
        'shop.name': 'Shop',
        'name': 'Name',
        'description': 'Description',
        'type': 'Type',
        'created_at': 'Datum',
        'clicks': 'Clicks',
        'stats.orders': 'Orders',
        'devices.mobile': 'Mobile',
        'devices.tablet': 'Tablet',
        'devices.desktop': 'Desktop',
        'stats.tickets': 'Tickets',
        'stats.revenue': 'Revenue'
    };
    $ctrl.allowEdit = Role.isAuthorizedAs('Event Manager');
    $ctrl.range = {
        start: moment().subtract(1, 'month'),
        stop: moment().add(1, 'day')
    };
    $ctrl.currency = Company.getMe().currency;
    $ctrl.filterShops = filterShops;
    $ctrl.pickShop = pickShop;
    $ctrl.updateTrackerInfo = updateTrackerInfo;
    $ctrl.openLink = openLink;
    $ctrl.refresh = () => Tracker.queryDB().then(trackers => $ctrl.crud.models = trackers);
    $ctrl.refreshGraph = _.debounce(() => { generateStats($ctrl.stats); }, 500);
    const newTrackerData = {};

    $ctrl.$postLink = function() {
        if ($ctrl.shop.guid)
            newTrackerData.shop_id = $ctrl.shop.guid;
        $ctrl.shopFilter = $ctrl.shops;
        $scope.$watch('$ctrl.range', $ctrl.refreshGraph, true);
        $scope.$watch('$ctrl.shopFilter', $ctrl.refreshGraph, true);

        ElasticLoader.getTrackers().then(stats => {
            $ctrl.stats = stats;
            $ctrl.updateTrackerInfo(stats.trackers);
            generateStats(stats);
        });
        setupCRUD();
    };

    $ctrl.save = function() {
        let tracker = $ctrl.crud.model;
        $ctrl.crud.save().then(() => $ctrl.openLink(tracker));
    };

    function setupCRUD() {
        $ctrl.crud = CrudCtrl.new($ctrl.trackers, () => {
            return Tracker.new(newTrackerData);
        }, { determineInitState: $ctrl.allowEdit });

        $ctrl.crud.beforeNew(() => {
            if (!$ctrl.allowEdit)
                return $q.reject({ description: 'common.notice.unauthorized' });

            $ctrl.selectedShop = $ctrl.shop;

            return $q.resolve();
        });

        $ctrl.crud.beforeDelete(() => {
            if (!$ctrl.allowEdit)
                return $q.reject({ description: 'common.notice.unauthorized' });
            return $q.resolve();
        });

        $ctrl.crud.beforeEdit(model => {
            if (!$ctrl.allowEdit)
                return $q.reject({ description: 'common.notice.unauthorized' });

            if (!model.shop || model.shop.guid !== model.shop_id) model.shop = _.find($ctrl.shops, {guid: model.shop_id});

            let promise = $q.resolve(model.shop);

            if (!model.shop) promise = model.$getShop().then(shop => model.shop = shop);

            return promise.then(shop => $ctrl.selectedShop = shop);
        });

        $ctrl.crud.afterSave((err, model, isNew) => {
            if(!isNew || err) return;

            model.clicks = 0;

            updateTrackerInfo($ctrl.stats);
        });

        $ctrl.crud.beforeList(prevState => {
            if (prevState !== 'edit' || !$ctrl.crud.model || $ctrl.crud.model.$savedState.shop_id === $ctrl.crud.model.shop_id) return $q.resolve();

            $ctrl.crud.model.shop_id = $ctrl.crud.model.$savedState.shop_id;

            $ctrl.crud.model.shop = _.find($ctrl.shops, { guid: $ctrl.crud.model.shop_id });

            if (!$ctrl.crud.model.shop)
                return $ctrl.crud.model.$getShop().then(shop => $ctrl.crud.model.shop = shop);

            return $q.resolve();
        });
    }

    function filterShops(shops) {
        return $ctrl.shopFilter = shops;
    }

    function pickShop(shop) {
        if (!$ctrl.crud.showEdit() && !$ctrl.crud.showNew()) return ErrorRejector.handle('Can not pick a shop, not editing a tracker.');

        $ctrl.crud.model.shop_id = shop.guid;
        $ctrl.crud.model.shop = shop;

        return $ctrl.selectedShop = shop;
    }

    function updateTrackerInfo(stats) {
        _.forEach($ctrl.trackers, tracker => {
            let code = tracker.code;
            let trackerStats = _.get(stats, code);
            let devices = _.get(trackerStats, 'statistics.devices.statistics');
            if (devices) {
                let info = {};
                let deviceStats = _.get(devices, 'buckets', []);
                info.total = 0;
                _.forEach(deviceStats, i => {
                    let key = _.get(i, 'key');
                    let count = _.get(i, 'doc_count');
                    info[key] = count;
                    info.total += count;
                });
                tracker.devices = info;
            }


            let trackerInfo = {
                orders: _.get(trackerStats, 'doc_count', 0),
                tickets: _.get(trackerStats, 'statistics.counts.doc_count', 0),
                revenue: _.get(trackerStats, 'statistics.revenue.statistics.value', 0)
            };
            tracker.stats = trackerInfo;
        });
    }

    function openLink(tracker) {
        return modalCRUD('TrackerModalController', openLinkTemplate, {
            tracker: () => tracker,
            shops: () => $ctrl.shops,
            shop: () => tracker.$getShop()
        }, {
            title: $translate.instant('models.models.tracker', { count: 1 }),
            close: 'common.action.close'
        });
    }

    function generateStats(stats) {
        /*
         * Calculate graph range
         */
        let timeUnit = 'day';
        let range = {
            start: $ctrl.range.start.format('YYYY-MM-DD'),
            stop: $ctrl.range.stop.format('YYYY-MM-DD')
        };
        let rangeDiff = Math.abs($ctrl.range.start.diff($ctrl.range.stop, timeUnit));

        /*
         * Prepare X axis
         */
        var dateColumn = ['dates'];
        var plotDateColumn = ['dates'];
        let tmpDate = $ctrl.range.start.clone().startOf(timeUnit);
        for (let i = 0; i < rangeDiff; i++) {
            let tmpCount = tmpDate.add(1, timeUnit);
            dateColumn.push(tmpCount.format('YYYY-MM-DD'));
            plotDateColumn.push(tmpCount.format('YYYY-MM-DD'));
        }
        tmpDate.subtract(rangeDiff, timeUnit);

        /*
         * Prepare chart data
         */
        var columns = {};
        var revenue = {};
        var groups = [];
        var usedColumns = {};
        var plotTrackers = _.keyBy($ctrl.trackers, 'code');
        // Prepare a row for every tracker, filled with X amount of zeros,
        // where X is the amount of weeks/days/hours in range
        _.forEach(plotTrackers, e => {
            if (_.findIndex($ctrl.shopFilter, {guid: e.shop_id}) < 0)
                return;
            let name = e.name + ' (' + e.code + ')';
            let column = [name];
            let rev = [e.code];
            groups.push(name);
            _.times(rangeDiff, i => column.push(0));
            _.times(rangeDiff, i => rev.push(0));
            columns[e.code] = column;
            revenue[name] = rev;
        });

        // Loop over tracker data
        let trackerData = _.get(stats, 'trackersPastFortNight');
        _.forEach(trackerData, (data, code) => {
            if (code === 'doc_count' || !columns[code])
                return;

            let dayCounts = _.get(data, 'statistics.counts.statistics.buckets');
            if (dayCounts.length > 0) {
                dayCounts = _.keyBy(dayCounts, 'key_as_string');
                _.forEach(dateColumn, (dateString, dateKey) => {
                    if (dateKey === 0)
                        return; // skip code name
                    if (dateKey <= range.start || dateKey > range.stop)
                        return;
                    let countObject = _.get(dayCounts, dateString, {});
                    let count = _.get(countObject, 'doc_count', 0);
                    columns[code][dateKey] += count;
                    usedColumns[code] = true;
                });
            }
            let dayRev = _.get(data, 'statistics.revenue.statistics.buckets');
            if (dayRev.length > 0) {
                dayRev = _.keyBy(dayRev, 'key_as_string');
                _.forEach(dateColumn, (dateString, dateKey) => {
                    if (dateKey === 0) return; // skip event name
                    if (dateKey <= range.start || dateKey > range.stop)
                        return;
                    let countObject = _.get(dayRev, dateString, {});
                    let rev = _.get(countObject, 'statistics.value', 0);
                    let currentTracker = plotTrackers[code];
                    let name = currentTracker.name + ' (' + currentTracker.code + ')';
                    revenue[name][dateKey] += rev;
                });
            }
        });

        _.forEach($ctrl.trackers, t => {
            if (!usedColumns[t.code])
                delete columns[t.code];
        });

        /*
         * Initialize chart
         */
        columns = _.values(columns);
        columns.unshift(plotDateColumn);
        if ($ctrl.chart)
            $ctrl.chart.destroy();
        $ctrl.chart = c3.generate({
            bindto: '#statsTrackers',
            data: {
                x: 'dates',
                columns: columns,
                type: 'bar',
                groups: [
                    groups
                ]
            },
            size: {
                width: $('#statsTrackers').width() * 0.95,
                height: 250
            },
            legend: {
                show: false
            },
            tooltip: {
                format: {
                    title: function(x) {
                        return moment(x).format('DD-MM-YYYY');
                    }
                },
                contents: function(d, defaultTitleFormat, defaultValueFormat, color) {
                    var $$ = this, config = $$.config,
                        titleFormat = config.tooltip_format_title || defaultTitleFormat,
                        nameFormat = config.tooltip_format_name || function(name) { return name; },
                        valueFormat = config.tooltip_format_value || defaultValueFormat,
                        text, i, title, value, name, bgcolor;
                    let dayCount = 0;
                    let dayRev = 0;

                    /*eslint-disable*/
                    for (i = 0; i < d.length; i++) {
                        if (!(d[i] && (d[i].value || d[i].value === 0)))
                            continue;
                        if (!text) {
                            title = titleFormat ? titleFormat(d[i].x) : d[i].x;
                            text = "<table class='" + $$.CLASS.tooltip + "'>" + (title || title === 0 ? "<tr><th colspan='3'>" + title + "</th></tr>" : "");
                            text += "<tr class='" + $$.CLASS.tooltipName + "-" + d[i].id + "'>";
                            text += "<td class='name'>Tracker</td>";
                            text += "<td class='value'>#Tickets</td>";
                            text += "<td class='value'>Revenue</td>";
                            text += "</tr>";
                        }

                        name = nameFormat(d[i].name);
                        value = valueFormat(d[i].value, d[i].ratio, d[i].id, d[i].index);
                        if (value === 0)
                            continue;
                        dayCount += value;
                        let currentRevenue = typeof revenue[name] !== 'undefined' && revenue[name][d[i].index + 1] !== 'undefined' ? revenue[name][d[i].index + 1] : 0;
                        dayRev += currentRevenue;
                        currentRevenue = $filter('formatCurrency')(currentRevenue, $ctrl.currency, $ctrl.currency);
                        bgcolor = $$.levelColor ? $$.levelColor(d[i].value) : color(d[i].id);

                        text += "<tr class='" + $$.CLASS.tooltipName + "-" + d[i].id + "'>";
                        text += "<td class='name'><span style='background-color:" + bgcolor + "'></span>" + name + "</td>";
                        text += "<td class='value'>" + value + "</td>";
                        text += "<td class='value'>" + currentRevenue + "</td>";
                        text += "</tr>";
                    }
                    dayRev = $filter('formatCurrency')(dayRev, $ctrl.currency, $ctrl.currency);
                    text += "<tr>";
                    text += "<td class='name'></span>#total</td>";
                    text += "<td class='value'>" + dayCount + "</td>";
                    text += "<td class='value'>" + dayRev + "</td>";
                    text += "</tr>";
                    return text + "</table>";
                    /*eslint-enable*/
                }
            },
            color: { pattern: ['#00BEF6', '#0079BF', '#00A650', '#F46B49', '#BB2321'] },
            axis: {
                y: {
                    min: 0,
                    padding: { bottom: 0 },
                    tick: {
                        format: function(d) {
                            if (d % 1 === 0) return d;
                            return '';
                        }
                    }
                },
                x: {
                    type: 'timeseries',
                    tick: {
                        culling: true,
                        format: function(x) {
                            return moment(x).format('DD-MM-YYYY');
                        }
                    }
                }
            }
        });
    }
}
