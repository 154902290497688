import template from './invitedUser.html';

export default angular.module('eventix.invitedUser', [])
    .config(function ($stateProvider) {
        $stateProvider.state('eventix.invitedUser', {
            url: '/invited?token',
            views: {
                '@': {
                    component: 'invitedUser'
                }
            }
        });
    })
    .component('invitedUser', {
        templateUrl: template,
        controller: InvitedUserController
    }).name;

function InvitedUserController($state, $http, UIMessages, Auth, $rootScope) {

    const $ctrl = this;

    $ctrl.messages = UIMessages;

    $ctrl.$onInit = function () {

        if (!_.has($state.params, 'token.length')) {
            $state.go('eventix.logout');
        }

        $ctrl.token = $state.params.token;

        $http.get(AUTH_API_ROOT + `/user/invited/${$state.params.token}`).then((response) => {
                // determine if we recevied html response
                try {
                    let data = response.data;
                    let search = data.substring(0,15);
                    if(search.includes('DOCTYPE')){
                        // HTML response from auth -> redirect to it
                        window.location.href = AUTH_API_ROOT + `/user/invited/${$state.params.token}`;
                    }else{
                        $ctrl.user = response.data
                    }
                }
                catch  (err) {
                    console.log('Cound not read response, fallback to old auth')
                    $ctrl.user = response.data
                }
            },
            () => {

                $ctrl.user = false;

                UIMessages.push($translate.instant('models.company.invite.invalidToken'));
            });

    };

    $ctrl.submitUser = submitUser;

    function submitUser() {

        if ($ctrl.busy) {
            return;
        }

        $http.post(AUTH_API_ROOT + '/user/invited', {
            token: $ctrl.token,
            name: $ctrl.user.name,
            phone: $ctrl.user.phone,
            new_password: $ctrl.user.password,
            new_password_confirmation: $ctrl.user.password_confirmation
        }).then(() => {

            $ctrl.busy = false;

            UIMessages.push('common.notice.success');

            return Auth.gotoLogin($state);

        }, (error) => {

            $ctrl.busy = false;

            if (_.has(error, 'data.error_description.length')) {

                console.error(error);

                UIMessages.push(error.data.error_description);

                if (_.has(error.data.error_description.new_password)) {
                    console.error('new_password');
                }
                if (_.has(error.data.error_description.new_password_confirmation)) {
                    console.error('new_password_confirmation');
                }
                if (_.has(error.data.error_description.name)) {
                    console.error('name');
                }
                if (_.has(error.data.error_description.phone)) {
                    console.error('phone');
                }

            } else {
                UIMessages.push('common.notice.unknownError');
            }

        });
    }
}

