import router from '@uirouter/angularjs';
import bootstrap from 'angular-ui-bootstrap';
import appStyling from './app.less';
import storage from 'angular-storage';
import translate from 'angular-translate';
import translateUrl from 'angular-translate/dist/angular-translate-loader-url/angular-translate-loader-url.js';
import templateUrl from './app.html';
import ngCookies from 'angular-cookies';
import store from 'angular-storage';
import Dashboard from './dashboard/dashboard.js';
import Common from '@eventix/js-common';
import Login from './login/login.js';
import Register from './register/register.js';
import Loading from './loading/loading.js';
import PasswordReset from './passwordReset/passwordReset.js';
import InvitedUser from './invitedUser/invitedUser.js';
import Sanitize from 'angular-sanitize';
import LoadingBar from 'angular-loading-bar';
import LoadingBarStyling from 'angular-loading-bar/build/loading-bar.css';
import angulartics from 'angulartics';
import NewAuthorization from '@openticket/sdk-auth';

import c3 from 'c3';
import c3styling from 'c3/c3.css';
import daterange from 'bootstrap-daterangepicker';
import daterangeCss from 'bootstrap-daterangepicker/daterangepicker.css';
import bdp from 'eonasdan-bootstrap-datetimepicker';
import bdpStyling from 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css';

/**
 * @ngdoc overview
 * @name eventix
 * @description
 * <h1>Eventix main module</h1>
 * The root module that initializes the app. It's booted in a three-step process.
 *
 * 1. Check (and refresh) authorization token
 * 2. Fetch models from cache
 * 3. Boot app and kick off the router
 */
angular.module('eventix',[router, bootstrap, storage, Sanitize, LoadingBar, PasswordReset, InvitedUser,
    angulartics, translate, translateUrl, Common, Dashboard, Login, Register, Loading,
    ngCookies, store])
    .config(function($locationProvider, $urlRouterProvider, APIPathInterceptorProvider,
        $stateProvider, $httpProvider, OAuthHeadersProvider, CompanyIdHeadersProvider, $translateProvider,
        IntercomProvider, GoogleTagManagerProvider, LocalesProvider, FBProvider,
        $qProvider, $sceDelegateProvider, $uibTooltipProvider) {

        /* Root state */
        $stateProvider.state('eventix', {
            url: '?editing&new&highlight&as&code',
            params: {
                editing: { inherit: false, dynamic: true },
                new: { inherit: false, dynamic: true },
                highlight: { inherit: false, dynamic: true },
                as: undefined,
                code: undefined,
            },
            templateUrl: templateUrl,
            abstract: true,
            resolve: /*@ngInject*/{
            },
        });

        $locationProvider.html5Mode(NODE_ENV !== 'testing');
        $urlRouterProvider.otherwise('/home');

        /* $http OAuth interceptor */
        OAuthHeadersProvider.setGrantType('password');
        APIPathInterceptorProvider.setPrefix(DASHBOARD_API_ROOT);
        $httpProvider.interceptors.push('OAuthHeaders');
        $httpProvider.interceptors.push('CompanyIdHeaders');
        $httpProvider.interceptors.push('APIPathInterceptor');

        $translateProvider.useLoader('languageLoader', {group: 'dashboard'})
            .useStorage('Locales')
            .useInterpolation('LaravelTranslator')
            .registerAvailableLanguageKeys(LocalesProvider.available, LocalesProvider.mapping)
            .uniformLanguageTag('java')
            .determinePreferredLanguage()
            .fallbackLanguage('en_GB');

        IntercomProvider.load();
        GoogleTagManagerProvider.addContainer(DASHBOARD_GTM);
        FBProvider.setAppId(FB_APP_ID_DASHBOARD);
        $qProvider.errorOnUnhandledRejections(NODE_ENV === 'development');


        // Users accepted our terms and conditions all granted
        // function gtag() { window.dataLayer.push(arguments); }
        // gtag("consent", "default", {
        //     ad_user_data: "denied",
        //     ad_personalization: "denied",
        //     ad_storage: "denied",
        //     analytics_storage: "denied",
        //     functionality_storage: "denied",
        //     personalization_storage: "denied",
        //     security_storage: "denied",
        //     wait_for_update: 500,
        // });
        // gtag("consent", "update", {
        //     ad_user_data: "granted",
        //     ad_personalization: "granted",
        //     ad_storage: "granted",
        //     analytics_storage: "granted",
        //     functionality_storage: "granted",
        //     personalization_storage: "granted",
        //     security_storage: "granted",
        // });

        if (NODE_ENV !== 'production') {
            $sceDelegateProvider.resourceUrlWhitelist([
                'self',
                'http://localhost:8080/**'
            ]);
        }

        $uibTooltipProvider.options({ appendToBody: true });

    })
    .run(function($rootScope, $window, $http, $location, $state, $translate, $transitions, Locales, LibraryLoader, EventixSocket) {

        let queryParams = $location.search();

        if (queryParams.language && _.find(Locales, {code: queryParams.language}))
            $translate.use(queryParams.language);

        let transitionTo = {
            state: null,
            params: null
        };

        // Create an SDK authorization instance and bind it to the window
        $window.auth = new NewAuthorization();

        $window.auth.init({
            baseUrl: AUTH_API_ROOT,
            clientId: OAUTH_ID,
            clientSecret: OAUTH_SECRET,
            onLogout: () => {
                $state.go('eventix.logout', {}, { reload: true });
            },

            // Auth_code settings
            authBaseUrl: typeof NG_AUTH_API_ROOT !== 'undefined' ? NG_AUTH_API_ROOT : null,  // Optional, will default to prod in the future
            authLoginBaseUrl: typeof NG_AUTH_LOGIN_ROOT !== 'undefined' ? NG_AUTH_LOGIN_ROOT : null, // Optional, will default to prod in the future

            // DD-DASHBOARD-2722 - Redirect uri is an optional parameter in the OAuth auth code spec.
            // Due to a bug in our implementation, the correct parameter was never send.
            // The spec states that the redirect_uri needs to be strictly equal for the authorize request and the token request.
            // In our system, the authorize request is invoked by the login app and the token request by the application.
            // Therefore, the 2 systems need to be in sync.
            // By omitting the parameter in the classic dashboard, it is not added to the redirect to the login app, which will then coerce the resulting
            // `null` value into the string `'null'`. Which is send as the invalid property redirect_url.
            // After the login app is updated to send the redirect_uri parameter, it is also required to send the same value
            // on the token request. This same update in the login app will only add the parameter if it is present in the query string.
            // Therefore, removing it in the classic dashboard will keep the situation the same before the change and handle it properly after the change.
            // The only downside is that it now (AND after the change) does not support clients with multiple redirect URLs, which
            // the spec DOES support.
            // As the classic dashboard is on the way out, this is deemed acceptable.
            // For the 'new' dashboard, the proper solution is chosen, with a temporary step in between, which uses a query parameter to
            // determine if the second request should contain the redirect_uri parameter.
            // authCodeRedirectUrl: typeof NG_DASHBOARD_REDIRECT_URL !== 'undefined' ? NG_DASHBOARD_REDIRECT_URL : null,

            authCodeClientId: typeof NG_AUTH_CLIENT_ID !== 'undefined' ? NG_AUTH_CLIENT_ID : null,
            authCodeClientSecret: typeof NG_AUTH_CLIENT_SECRET !== 'undefined' ? NG_AUTH_CLIENT_SECRET : null,
        });

        // if ($window.auth.isLoggedIn) {
        //     console.log('Logged in');
        //     console.log(`Company: ${$window.auth._token.company_id}`);
        // } else {
        //     console.log('Not logged in');
        // }

        $transitions.onStart({}, function(trans) {
            transitionTo.state = trans.to().name;
            transitionTo.params = trans.params();
        });

        // Needed in oauthHeaders, yes EOL
        $rootScope.http = $http;

        $rootScope.branding = {
            logo: DASHBOARD_BRAND_LOGO,
            name: DASHBOARD_BRAND_NAME,
            url: DASHBOARD_BRAND_URL
        };

        if (APPCUES_ID && (APPCUES_ID).length) {
            LibraryLoader.load('https://fast.appcues.com/' + APPCUES_ID);
        }

    });

// Bootstrap the app ONLY after we have a token
if(NODE_ENV !== 'testing') {
    angular.element(document).ready(function() {
        var el = document.getElementById('ng-app');
        angular.bootstrap(el, ['eventix']);
        angular.element(el).attr('ng-app','eventix');
    });
}
