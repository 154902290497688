import template from './error.html';

export default angular.module('eventix.dashboard.error',[ ])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.error', {
            url: '/error',
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'ErrorController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/{
                isReload: function($state) {
                    return !$state.includes('eventix.dashboard');
                }
            }
        });
    })
    .controller('ErrorController', function($state, stateChangeErrorService, isAdmin, isReload) {
        var vm = this;
        vm.isAdmin = isAdmin;
        vm.error = stateChangeErrorService.get();
        vm.errorService = stateChangeErrorService;
        if(isReload && !vm.errorService.retries)
            vm.errorService.retry();
    }).name;

