import templateUrl from './tickets.html';
import DesignTemplate from '../../../template/template.html';
import { copyToClipboard } from '../../../dashboard';

export default angular.module('eventix.dashboard.wizard.advanced.tickets', [])
    .config(ModuleConfig)
    .component('wizardAdvancedTickets', {
        controller: WizardAdvancedTicketsController,
        templateUrl: templateUrl,
        bindings: {
            event: '<',
            company: '<',
            isAdmin: '<'
        }
    }).name;

function ModuleConfig($stateProvider) {
    $stateProvider.state('eventix.dashboard.wizard.advanced.tickets', {
        url: '/tickets',
        component: 'wizardAdvancedTickets',
        bindings: {
            event: 'currentEvent',
            company: 'company',
            isAdmin: 'isAdmin'
        }
    });
}

function WizardAdvancedTicketsController($state, $scope, $q, $translate, $filter, $uibModal, CrudCtrl, RelationAPI, TemplateModal, Vat, Company, Role, Ticket, Product, Shop, ProductGroup) {
    const $ctrl = this;

    const vatPercentage = Vat.getDefaultRateFor(_.get(Company.cached, [Company.getCurrentId(), 'country'], 'NL'));

    $ctrl.getTicketPrice = getTicketPrice;
    $ctrl.openKickbacksModal = openKickbacksModal;
    $ctrl.openTemplateModal = openTemplateModal;
    $ctrl.save = save;
    $ctrl.copyToClipboard = copyToClipboard;

    $ctrl.isCompanyAdmin = Role.isAuthorizedAs('Company Admin');
    $ctrl.hasContract = Role.isAuthorizedAs('Has Contract');
    $ctrl.isAdmin = Role.isAuthorizedAs('Admin');
    $ctrl.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');

    $ctrl.formOptionsAvailable = [
        {
            key: 'priceFromProducts',
            label: $translate.instant('models.ticket.priceFromProducts')
        },
        {
            key: 'increment',
            label: $translate.instant('models.ticket.increment')
        },
        {
            key: 'availableDates',
            label: $translate.instant('models.ticket.available_from')
        },
        {
            key: 'minOrderQuantity',
            label: $translate.instant('models.ticket.min_orderable_amount_per_order')
        },
        {
            key: 'maxOrderQuantity',
            label: $translate.instant('models.ticket.max_orderable_amount_per_order')
        },
        {
            key: 'availabilityMargin',
            label: $translate.instant('models.ticket.availability_margin')
        },
        {
            key: 'metaData',
            label: $translate.instant('models.ticket.meta_data'),
        },
        {
            key: 'hideWithoutCoupon',
            label: $translate.instant('models.ticket.hideWithoutCoupon')
        }
    ];

    if ($ctrl.isAdminOrWLAdmin) {
        $ctrl.formOptionsAvailable.push({
            key: 'seats_category_key',
            label: $translate.instant('models.ticket.seats_category_key')
        });
        $ctrl.formOptionsAvailable.push({
            key: 'late_personalization',
            label: $translate.instant('models.ticket.late_personalization')
        });
        $ctrl.formOptionsAvailable.push({
            key: 'barcode_type',
            label: $translate.instant('models.ticket.barcode_type')
        });
        $ctrl.formOptionsAvailable.push({
            key: 'class',
            label: $translate.instant('models.ticket.class')
        });
    }

    $ctrl.$postLink = function () {
        setupCrud();

        if ($ctrl.isAdminOrWLAdmin || ($ctrl.hasContract && $ctrl.isCompanyAdmin)) {
            $ctrl.formOptionsAvailable.push({
                key: 'serviceCost',
                label: $filter('capitalize')($translate.instant('models.kickback.fee'))
            });
        }

        if ($ctrl.isAdminOrWLAdmin) {
            $ctrl.formOptionsAvailable.push({
                key: 'combinesProducts',
                label: $filter('capitalize')($translate.instant('models.ticket.combinesProducts'))
            });
        }

        if ($ctrl.isAdminOrWLAdmin) {
            $ctrl.formOptionsAvailable.push({
                key: 'swappable',
                label: $filter('capitalize')($translate.instant('models.ticket.swappable'))
            });
        }
    };

    function setupCrud() {
        $ctrl.crud = new CrudCtrl(() => $ctrl.event.$queryTicket(), newTicket, {autoNew: false});

        $ctrl.crud.addBelongsToMany('shops', 'Shop');
        $ctrl.crud.addBelongsToMany('products', 'Product');
        $ctrl.crud.addBelongsToMany('groups', 'ProductGroup');
        $ctrl.crud.addBelongsToMany('metaData', 'MetaData');
        $ctrl.crud.addBelongsToMany('eventDate', 'EventDate');

        $ctrl.crud.beforeEdit(preloadRelations);
        $ctrl.crud.afterSave(cleanTicket);
        $ctrl.crud.afterSave(saveGroups);
    }

    function save() {
        return $ctrl.event.$crudCreateOrSaveChild($ctrl.crud, 'Ticket');
    }

    function preloadRelations(ticket) {
        let promises = [
            ticket.$queryProductGroup(),
            ticket.$queryProduct(),
            ticket.$queryShop(),
            ticket.fillProducts()
        ];

        return $q.all(promises)
            .then(() => {
                ticket.public = ticket.shops.length > 0;

                let productGroupPromises = _.map(ticket.groups, group => {
                    return $q.all(_.map(group.products, productId => Product.get({guid: productId})))
                        .then(products => group.children = products);
                });

                return $q.all(productGroupPromises)
                    .then(() => ticket);
            });
    }

    function cleanTicket(err, ticket, isNew) {
        if (err || !isNew) {
            return;
        }

        delete ticket.calculating;
        delete ticket.minimumPrice;
    }

    function saveGroups(err, ticket, isNew) {
        if (err || isNew) {
            return $q.resolve();
        }

        return _.reduce(ticket.groups, (promise, group) => {
            return promise.then(() => group.$save());
        }, $q.resolve());
    }

    /**
     * Return a new ticket for CrudCtrl
     * @return {Ticket} New ticket with some sensible defaults
     */
    function newTicket() {
        return Ticket.new({
            increment: 1,
            status_overrule: 'auto',
            available_stock: 0,
            availability_margin: 0,
            min_orderable_amount_per_order: 1,
            max_orderable_amount_per_order: 20,
            percentage_service_costs_in_ticket: 0,
            min_price: 0,
            vat_percentage: vatPercentage,
            combines_products: true,
            swappable: true,
        });
    }

    // TODO I want this gone... It triggers quite some 'expensive' (even though cached) calls that should not be needed...
    // TODO One option would be to fill this in de API.. Dont't know any other 'acceptable' options.
    function getTicketPrice(ticket) {
        if (!ticket || !ticket.calculateMinimumPrice) {
            console.error('Cannot get ticket price, not properly initialized.');

            return -999999;
        }

        if (_.has(ticket, 'minimumPrice')) {
            return ticket.minimumPrice;
        }

        if (!ticket.calculating) {
            ticket.calculating = true;
            ticket.calculateMinimumPrice(false);
        }

        return '...';
    }

    function openKickbacksModal(ticket) {
        let company = $ctrl.company;
        return $uibModal.open({
            size: 'lg',
            controller: function () {
                const $ctrl = this;

                $ctrl.$onInit = function () {
                    $ctrl.ticket = ticket;
                    $ctrl.ticket.minimumPrice = getTicketPrice(ticket);
                    $ctrl.company = company;
                };
            },
            controllerAs: '$ctrl',
            template: '<div class="modal-body"><kickbacks applicable-model="$ctrl.ticket" applicable-company="$ctrl.company" applied-type="Ticket"></kickbacks></div><div class="modal-footer"><a class="btn btn-default" ng-click="$close()">Close</a></div>'
        });
    }

    function openTemplateModal(ticket) {
        return TemplateModal.open('ticket', ticket);
    }
}
