import template from './events.html';
import RefundSettings from './refund-settings/refund-settings';
import { copyToClipboard } from '../dashboard';

export default angular.module('eventix.dashboard.events', ['eventix.dashboard.events.refundSettings'])
    .config(function ($stateProvider) {
        $stateProvider.state('eventix.dashboard.events', {
            url: '/events?past',
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'EventsController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/{
                eventWithDates: function(events, $q, $window) {
                    var promises = _.map(events, event => {
                        return event.$queryEventDate().then(dates => {
                            event.dates = dates;
                            event.start = _.get(_.minBy(dates, d => moment(d.start).format('X')), 'start', '');
                            event.end = _.get(_.maxBy(dates, d => moment(d.end).format('X')), 'end', '');
                            return event;
                        }).catch(() => {
                            // If refresh wouldn't work, we probably need to logout..
                            $window.auth.logout();
                            $q.reject();
                        });
                    });
                    return $q.all(promises).then(ar => _.flatten(ar)).catch(() => {
                        // If refresh wouldn't work, we probably need to logout..
                        $window.auth.logout();
                        $q.reject();
                    });
                }
            },
            data: {
                crumb: 'common.menu.manage.events',
            }
        });
    })
    .controller('EventsController', function ($state, eventWithDates, ConfirmDeleteSweet, events, Event, Role, company, UIMessages, RefundSettings, $q, isAdmin, SweetAlert, $translate) {

        var vm = this;
        vm.state = 'active';
        vm.isAdmin = isAdmin;
        vm.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');
        vm.isEventManager = Role.isAuthorizedAs('Event Manager');

        vm.showPastEvents = false;
        vm.openRefundSettings = RefundSettings.open;

        vm.copyToClipboard = copyToClipboard;

        vm.$onInit = function() {
            if ($state.params.past) vm.showPastEvents = true;
        };

        divideEvents();

        vm.deleteEvent = function (event) {
            if (!vm.isEventManager && !vm.isAdmin) {
                return;
            }

            let translations = {
                text: {translation: 'common.notice.archiveConfirmTicketsFromShop'},
                confirm: 'common.action.archive'
            };

            ConfirmDeleteSweet.open(event.name, translations, false)
                .then(() => event.$delete())
                .then(() => {
                    SweetAlert.swal({
                        title: $translate.instant('common.notice.archived'),
                        text: $translate.instant('common.notice.success'),
                        type: 'success',
                        confirmButtonText: $translate.instant('common.action.ok'),
                        closeOnClickOutside: true,
                        timer: 3000
                    });
                })
                .then(() => _.remove(eventWithDates, {guid: event.guid}))
                .then(() => _.remove(events, {guid: event.guid}))
                .then(() => divideEvents())
                .catch((data) => {
                    SweetAlert.swal({
                        title: $translate.instant('common.notice.error'),
                        text: $translate.instant(_.get(data.data, 'error_description', 'common.notice.unknownError')),
                        type: 'error',
                        confirmButtonText: $translate.instant('common.action.ok'),
                        closeOnClickOutside: true
                    });
                });
        };

        vm.reinstate = function (event) {
            if (!vm.isEventManager && !vm.isAdmin) {
                return;
            }

            event.$reinstate()
                .then(() => {
                    _.remove(vm.events.current, {guid: event.guid});
                    eventWithDates.push(event);
                    events.push(_.omit(event, ['dates', 'start', 'end']));
                });
        };

        vm.showArchived = function () {
            Event.queryAdmin({company: company.guid, status: 'justtrashed'})
                .then(deletedEvents => {
                    vm.events.current = deletedEvents;
                    vm.state = 'archived';
                });
        };

        vm.showActive = function () {
            divideEvents();
            vm.state = 'active';
        };

        function divideEvents() {
            let now = moment().format('YYYY-MM-DDTHH:mm:ssZ');
            vm.events = {current: [], past: []};
            _.forEach(eventWithDates, event => {
                if (event.end && event.end < now)
                    vm.events.past.push(event);
                else
                    vm.events.current.push(event);
            });
        }
    }).name;
