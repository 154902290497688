import template from './methods.html';

export default angular.module('eventix.dashboard.financials.methods', [])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.financials.methods', {
            url: '/methods',
            views: {
                'dashboard@eventix.dashboard': {
                    component: 'methods'
                }
            },
            data: {
                crumb: 'common.menu.financials.methods',
                requiredRoles: ['Admin', 'Whitelabel Admin']
            }
        });
    })
    .component('methods', {
        controller: MethodsController,
        bindings: {
            company: '<',
            isAdmin: '<'
        },
        templateUrl: template
    }).name;

function MethodsController($scope, $q, $timeout, Role, PaymentMethod, UIMessages, Currencies, $http) {
    const $ctrl = this;

    // Define the binding variables (mainly for IDE auto-completion, not for functionality)
    $ctrl.company = null;
    $ctrl.isAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');
    $ctrl.currencies = Currencies;
    $ctrl.selectedCurrency = null;
    $ctrl.selectedProfile = null;
    $ctrl.availableProfiles = [];
    $ctrl.showCompanyFee = false;
    $ctrl.companySettings = null;

    // State variables
    $ctrl.busy = false;

    // View functions
    $ctrl.getTotalFixedMethodFee = getTotalFixedMethodFee;
    $ctrl.getTotalPercentageMethodFee = getTotalPercentageMethodFee;
    $ctrl.anyTypeIsNegative = anyTypeIsNegative;
    $ctrl.fixedIsNegative = fixedIsNegative;
    $ctrl.percentageIsNegative = percentageIsNegative;

    $ctrl.editMethodFee = editMethodFee;
    $ctrl.doneEditingMethodFee = doneEditingMethodFee;
    $ctrl.showShops = showShops;
    $ctrl.hideShops = hideShops;
    $ctrl.toggleShowCompanyFee = toggleShowCompanyFee;

    $ctrl.attachMethodToCompany = attachMethodToCompany;
    $ctrl.detachMethodFromCompany = detachMethodFromCompany;

    $ctrl.shopUrl = shopUrl;

    $ctrl.copyToClipboard = copyToClipboard;

    // Containers
    $ctrl.methods = [];
    $ctrl.availableMethods = [];

    $ctrl.editingMethod = null;
    $ctrl.editingShop = null;
    $ctrl.showShopsForMethod = null;

    /* //////////////////////////////// */
    /*         MAIN OPERATIONS          */
    /* //////////////////////////////// */

    $ctrl.$onInit = function() {
        $ctrl.busy = true;

        $ctrl.selectedCurrency = $ctrl.company.currency;


        $http.get('/company/settings').then((response) => {
            $ctrl.companySettings = response.data;
            $ctrl.selectedProfile = $ctrl.companySettings.payment_profile_id;
        });

        $http.get('/company/settings/paymentprofiles').then(res => {
            $ctrl.availableProfiles = res.data;
            _.forEach($ctrl.availableProfiles, (profile) => {
                if (profile.guid === $ctrl.companySettings.payment_profile_id) {
                    profile.name = profile.name + "( Currently Active)";
                }
            });
        });

        fetch();
    };

    $scope.$watch('$ctrl.selectedCurrency', function (newVal, oldVal) {
        if (newVal !== oldVal)
            fetch();
    });

    $scope.$watch('$ctrl.selectedProfile', function (newVal, oldVal) {
        if (newVal !== oldVal)
            fetch();
    });

    /* //////////////////////////////// */
    /*       GET DATA OPERATIONS        */

    /* //////////////////////////////// */

    function getAvailableMethods() {
        let promise = (Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin')) ? PaymentMethod.queryWhitelabelMethods() : $q.resolve([]);

        return promise.then(methods => $ctrl.availableMethods = methods);
    }

    function getCurrentMethods() {
        console.log("selectedProfile", $ctrl.selectedProfile);
        return $ctrl.company.$queryPaymentMethodsWithShops($ctrl.selectedCurrency, $ctrl.selectedProfile)
            .then(methods => {
                $ctrl.methods = methods;

                _.forEach($ctrl.availableMethods, availableMethod => {
                    if (_.findIndex($ctrl.methods, method => method.guid === availableMethod.guid) < 0) {
                        availableMethod.notAttachedToCompany = true;
                        $ctrl.methods.push(availableMethod);
                    }
                });

                return $q.resolve(methods);
            }, (error) => {
                return errorRejection(error);
            })
    }

    /* //////////////////////////////// */
    /*  TICKET CALCULATION OPERATIONS   */

    /* //////////////////////////////// */

    function getTotalFixedMethodFee(method, shop = null) {
        return getTotalTypeMethodFee('fixed', method, shop);
    }

    function getTotalPercentageMethodFee(method, shop = null) {
        return getTotalTypeMethodFee('percentages', method, shop);
    }

    function getTotalTypeMethodFee(type, method, shop = null) {
        if (!method && !method.fees)
            return errorRejection('Can not calculate method fee, no method') && -999999999;

        let fees;

        if (shop && !shop.fees)
            return errorRejection('Can not calculate method fee, no shop') && -999999999;
        else if (shop)
            fees = shop.fees;
        else
            fees = method.fees;

        return _.get(_.get(fees, 'other'), type, -999999999) + _.get(_.get(fees, 'self'), type, -999999999);
    }

    function anyTypeIsNegative(method, shop = null) {
        return fixedIsNegative(method, shop) || percentageIsNegative(method, shop);
    }

    function fixedIsNegative(method, shop = null) {
        if (shop && $ctrl.getTotalFixedMethodFee(method, shop) < $ctrl.getTotalFixedMethodFee(method))
            return true;

        return $ctrl.getTotalFixedMethodFee(method, shop) < 0
    }

    function percentageIsNegative(method, shop = null) {
        if (shop && $ctrl.getTotalPercentageMethodFee(method, shop) < $ctrl.getTotalPercentageMethodFee(method))
            return true;

        return $ctrl.getTotalPercentageMethodFee(method, shop) < 0;
    }

    /* //////////////////////////////// */
    /*       RESOURCE OPERATIONS        */

    /* //////////////////////////////// */

    function editMethodFee(method, shop = null) {
        $ctrl.editingMethod = method;
        $ctrl.editingShop = shop;
    }

    function doneEditingMethodFee() {
        $ctrl.busy = true;

        $ctrl.editingMethod = null;
        $ctrl.editingShop = null;

        getCurrentMethods().then(() => {
            $timeout(() => $ctrl.busy = false, 500);
        });
    }

    /* //////////////////////////////// */
    /*         SHOPS OPERATIONS         */

    /* //////////////////////////////// */

    function showShops(method) {
        if ($ctrl.showShopsForMethod === method.guid)
            return hideShops();

        $ctrl.showShopsForMethod = method.guid;
    }

    function hideShops() {
        $ctrl.showShopsForMethod = null;
    }

    function toggleShowCompanyFee() {
        $ctrl.showCompanyFee = !$ctrl.showCompanyFee;

        if(!$ctrl.showCompanyFee)
            fetch();
    }

    function fetch() {
        $ctrl.busy = true;
        getAvailableMethods()
            .then(getCurrentMethods)
            .then(() => {
                $timeout(() => $ctrl.busy = false, 500);
            });
    }

    function shopUrl(shop) {
        if (!shop) {
            errorRejection('Can not create shop URL, no shop');

            return '';
        }

        return FANCY_SHOP_ROOT + shop.guid;
    }

    /* //////////////////////////////// */
    /*        METHOD OPERATIONS         */

    /* //////////////////////////////// */

    function attachMethodToCompany(method) {
        if ($ctrl.busy)
            return errorRejection('System is busy, please try again later');

        $ctrl.busy = true;

        return $ctrl.company.$attachPaymentMethod(method).then(() => {
            return getCurrentMethods().then(() => {
                $timeout(() => $ctrl.busy = false, 500);
            });
        }).catch(error => {
            $ctrl.busy = false;

            return errorRejection(error.data.error_description || error.message || 'Unable to detach method');
        });
    }

    function detachMethodFromCompany(method) {
        if ($ctrl.busy)
            return errorRejection('System is busy, please try again later');

        $ctrl.busy = true;

        return $ctrl.company.$detachPaymentMethod(method).then(() => {
            return getCurrentMethods().then(() => {
                $timeout(() => $ctrl.busy = false, 500);
            });
        }).catch(error => {
            $ctrl.busy = false;

            return errorRejection(error.data.error_description || error.message || 'Unable to detach method');
        });
    }

    /* //////////////////////////////// */
    /*         MISC OPERATIONS          */

    /* //////////////////////////////// */

    function errorRejection(errorMessage = null) {
        errorMessage = errorMessage || 'Something went wrong, please try again later.';

        UIMessages.push(errorMessage);

        // TODO ERROR LOGGING AND VIEW
        console.error('        > ' + errorMessage);

        return $q.reject(errorMessage);
    }

    /**
     * Copy the value to the Clipboard
     *
     * Attributed to http://stackoverflow.com/a/30810322/7305801
     *
     * @param value
     */
    function copyToClipboard(value) {
        let textArea = document.createElement('textarea');

        // Place in top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = '0';
        textArea.style.left = '0';

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = '0';

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textArea.style.background = 'transparent';
        textArea.value = value;

        document.body.appendChild(textArea);
        textArea.select();

        try {
            let successful = document.execCommand('copy');
            let msg = successful ? 'Copied!' : 'common.notice.error';
            UIMessages.push(msg);
        } catch (err) {
            UIMessages.push('common.notice.error');
        }

        document.body.removeChild(textArea);
    }
}
