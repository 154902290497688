var template = `<uib-dropdown auto-close="outsideClick">
    <button type="button"
            uib-dropdown-toggle
            ng-class="$ctrl.btnClass"
            ng-transclude>{{ 'common.action.moreOptions' | translate }}</button>
    <ul uib-dropdown-menu class="dropdown-menu dropdown-menu-right">
        <li role="menuitem" class="header-item">
            <a ng-click="$ctrl.selectAll()">{{ 'common.action.toggle_all' | translate }}</a>
        </li>
        <li ng-repeat="option in $ctrl.options" role="menuitem">
            <a ng-click="$ctrl.toggleOption(option)">
                <i ng-class="$ctrl.optionIcon(option)"></i>
                {{ option.label }}
                <i ng-class="option.icon" class="pull-right" ng-show="option.icon"></i>
            </a>
        </li>
    </ul>
</uib-dropdown>`;
export default angular.module('eventix.dashboard.wizard.common.moreOptions', [])
    /**
     * @ngdoc directive
     * @restrict element
     * @name eventix.common.directives:moreOptions
     * @description
     * A dropdown to toggle boolean options. Options are persisted in localStorage, keyed under
     * the state name of the current page. This component is useful for hiding uncommonly used
     * form elements.
     *
     * @param {Expression} txOptions A specification for selectable options. E.g.: `option.key as option.label for option in options`
     * @param {Expression} ngModel A container with toggled options,
     * e.g.: `{ key_a: true, key_b: false }`
     * @param {String} [name] An optional name for these options, required when using multiple instances in the same page
     * @param {String} [btnClass="btn btn-default"] CSS class for dropdown toggle
     */
    .component('moreOptions', {
        bindings: {
            txOptions: '@',
            name: '@',
            btnClass: '@'
        },
        transclude: true,
        require: {
            ngModel: 'ngModel'
        },
        template: template,
        controller: MoreOptionsController
    }).name;

function MoreOptionsController(txOptionParser, $scope, $state, store) {
    const $ctrl = this;
    var originalScope = $scope.$parent;
    var parser;

    $ctrl.$postLink = function() {
        _.defaults($ctrl, {
            btnClass: 'btn btn-default'
        });
        parser = txOptionParser.parse($ctrl.txOptions);
        $ctrl.grouped = parser.groupBy !== null;
        $ctrl.options = [];

        // Update local list of items if remote changes
        $scope.$watch(
            () => parser.source(originalScope),
            updateItems,
            true
        );

        $ctrl.saveName = _.compact([ $state.current.name, $ctrl.name ]).join('.');
        let storedSettings = _.get(store.get('moreOptions') || {}, $ctrl.saveName);
        if(storedSettings)
            $ctrl.ngModel.$setViewValue(storedSettings);

        // Update local list if model changes
        $scope.$watch('$ctrl.ngModel.$modelValue', checkSelected, true);
    };

    $ctrl.optionIcon = function(option) {
        return option.selected ? 'pull-right fa fa-check-square-o' : 'hidden';
    };

    $ctrl.selectAll = function() {
        let direction = _.get($ctrl.options, '0.selected') ? 'deselect' : 'select';
        _.forEach($ctrl.options, option => {
            if((option.selected && direction === 'deselect') ||
               (!option.selected && direction === 'select')) {
                $ctrl.toggleOption(option);
                return;
            }
        });
    };

    $ctrl.toggleOption = function(option) {
        if(option.disabled())
            return;
        let model = _.isObject($ctrl.ngModel.$modelValue) ?
            _.clone($ctrl.ngModel.$modelValue) :
            {};
        model[option.value] = !model[option.value];
        $ctrl.ngModel.$setViewValue(model);
        $scope.$applyAsync();
    };

    function checkSelected(model) {
        if(_.isNil(model) || !_.isObject(model))
            return;

        model = _.clone(model);

        // Create a flat list of selectable items
        var options = $ctrl.options;
        if($ctrl.grouped)
            options = _.flatten(_.values(options));

        let selected = _.compact(_.map(model, (selected, key) => selected ? key : null));
        // Set internal state to reflect model
        _.forEach(options, option => {
            option.selected = _.indexOf(selected, option.value) >= 0;
        });

        // Update the outside ngModel value (removing selections that are no longer in source array)
        _.forEach(_.keys(model), key => {
            if(_.find(options, { value: selected }) !== undefined)
                delete model[key];
        });
        $ctrl.ngModel.$setViewValue(model);
        saveSettings(model);
    }

    function saveSettings(model) {
        let storedSettings = _.set(store.get('moreOptions') || {}, $ctrl.saveName, model);
        store.set('moreOptions', storedSettings);
    }

    function updateItems(options) {
        $ctrl.options = [];
        if (!angular.isDefined(options) || options === null)
            return;
        _.forEach(options, option => {
            var local = {};
            local[parser.itemName] = option;
            $ctrl.options.push({
                model: option,
                selected: false,
                label: parser.viewMapper(local),
                value: parser.modelMapper(local),
                icon: parser.withIcon ? parser.withIcon(local) : null,
                group: parser.groupBy ? parser.groupBy(local) : null,
                disabled: parser.disableWhen ? parser.disableWhen.bind(null, local) : angular.noop
            });
        });
        if($ctrl.grouped)
            $ctrl.items = _.groupBy($ctrl.items, 'group');
        checkSelected($ctrl.ngModel.$modelValue);
    }
}
