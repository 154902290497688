export default angular.module('eventix.dashboard.wizard.simple.financials', [])
    .config(ModuleConfig)
    .name;

function ModuleConfig($stateProvider) {
    $stateProvider.state('eventix.dashboard.wizard.simple.financials', {
        url: '/financials',
        component: 'wizardFinancials',
        bindings: {
            event: 'currentEvent',
            company: 'company',
            isAdmin: 'isAdmin'
        },
        data: {
            requiredRoles: ['Has Contract', 'Company Admin']
        }
    });
}
