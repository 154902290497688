var template = `<div class="input-group location-picker-existing" ng-show="!$ctrl.loading && $ctrl.locations.length > 1">
    <tx-select ng-model="$ctrl.location"
        tx-options="l as (l.guid ? l.name : 'models.location.action.new' | translate) group by l.group for l in $ctrl.locations"
        btn-class="form-control"
        sublabel="address">
    </tx-select>
    <div class="input-group-btn">
        <button ng-click="$ctrl.editLocation = !$ctrl.editLocation"
                type="button"
                class="btn btn-default"
                ng-disabled="!$ctrl.location.guid">
            <i class="fa fa-pencil-square-o"></i>
        </button>
    </div>
</div>
<button class="btn btn-block btn-default btn-disabled location-picker-loader"
        disabled
        ng-show="$ctrl.loading">
    <i class="fa fa-spin fa-spinner"></i> {{ 'common.notice.loading' | translate }}
</button>
<div ng-show="$ctrl.editLocation" class="location-picker-form">
    <br ng-show="$ctrl.locations.length > 1">
    <input class="form-control"
           type="text"
           name="location_name"
           ng-model="$ctrl.location.name"
           placeholder="{{ 'models.models.location' | translate:{count:1} | capitalize }} {{ 'models.location.name' | translate | lowercase}}" />
    <form-error errors="$ctrl.location.$errors.name"></form-error>
    <br>
    <input class="form-control"
           type="text"
           name="location_address"
           ng-model="$ctrl.location.address"
           placeholder="{{ 'models.models.location' | translate:{count:1} | capitalize }} {{ 'models.location.address' | translate | lowercase }}"
           location-autocomplete
           callback="$ctrl.fillCoords(place)"
           prevent-submit />
    <form-error errors="$ctrl.location.$errors.address"></form-error>
    <br ng-show="$ctrl.showCapacity">
    <input class="form-control"
           ng-show="$ctrl.showCapacity"
           name="location_capacity"
           type="text"
           ng-model="$ctrl.location.capacity"
           placeholder="{{ 'models.models.location' | translate:{count:1} | capitalize }} {{ 'models.location.capacity' | translate | lowercase }}" />
    <form-error errors="$ctrl.location.$errors.capacity"></form-error>
</div>`;

export default angular.module('eventix.dashboard.wizard.common.locationPicker', [])
    /**
     * @ngdoc directive
     * @restrict attribute
     * @name eventix.common.directives:preventSubmit
     * @description
     * Prevent submits if user presses `<Enter>` key. Use on form elements where user is
     * likely to press it for keyboard navigation or autocompletion.
     */
    .directive('preventSubmit', function() {
        return {
            link: function(scope, el) {
                el.on('keydown', prevent);
                scope.$on('$destroy', () => el.off('keydown', prevent));

                function prevent(event) {
                    if(event.which === 13)
                        event.preventDefault();
                    return true;
                }
            }
        };
    })
    /**
     * @ngdoc directive
     * @restrict element
     * @name eventix.common.directives:locationPicker
     * @description
     * A flexible location picker to be used in EventDate forms
     *
     * @param {Expression} ngModel Where to store the picked location
     * @param {Boolean} capacity Boolean attribute whether to show location capacity
     */
    .component('locationPicker', {
        template: template,
        controllerAs: '$ctrl',
        controller: LocationPickerController
    }).name;

function LocationPickerController(EventLocation, $scope, $attrs, $translate, $parse, $timeout) {
    const $ctrl = this;
    $ctrl.fillCoords = fillCoords;

    $ctrl.$onInit = function() {
        $ctrl.loading = true;
        $ctrl.showCapacity = _.has($attrs, 'capacity');
        EventLocation.query().then(locations => {
            let groupName = $translate.instant('models.location.action.useExisting');
            $ctrl.locations = locations.map(l => _.set(l, 'group', groupName));
            setupWatchers();
            populateNewLocation();
            $ctrl.loading = false;
        });
    };

    /**
     * Add an entry to the EventLocation list so that users can create new locations
     */
    function populateNewLocation() {
        $ctrl.locations.unshift(
            EventLocation.new({
                capacity: $ctrl.showCapacity ? '' : 0,
                public: false,
                group: ''
            })
        );
        if($ctrl.locations.length === 1) {
            $ctrl.location = $ctrl.locations[0];
            $ctrl.editLocation = true;
        }
    }

    /**
     * Callback for the Google Maps location autocomplete
     *
     * @param {Object} place A location object
     */
    function fillCoords(place) {
        $ctrl.location.longitude = place.location.longitude.toPrecision(10);
        $ctrl.location.latitude = place.location.latitude.toPrecision(9);
    }

    /**
     * Setup watchers to sync data
     */
    function setupWatchers() {
        let ngModel = $parse($attrs.ngModel);
        // Receive outside data
        $scope.$watch(
            () => ngModel($scope.$parent),
            location => {
                if(location && location.guid)
                    $ctrl.location = _.find($ctrl.locations, { guid: location.guid });
                else if(location && location instanceof EventLocation) {
                    $ctrl.locations.splice(0, 1, location);
                    location.group = '';
                    $ctrl.location = location;
                    $ctrl.editLocation = true;
                } else if(!location && $ctrl.location && $ctrl.location !== $ctrl.locations[0])
                    $ctrl.location = null;
            }
        );
        $scope.$watch('$ctrl.location', location => {
            $ctrl.editLocation = location && !location.guid;
            ngModel.assign($scope.$parent, location);
        });
    }
}
