import template from './clearings.html';
import payoutModalTemplate from './payoutModal.html';
import clearingPayments from './payments/payments';

export default angular.module('eventix.dashboard.financials.reporting.clearings', ['eventix.dashboard.financials.reporting.clearings.payments'])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.financials.reporting.clearings', {
            url: '/clearings',
            views: {
                'dashboard@eventix.dashboard': {
                    component: 'clearings'
                }
            },
            data: {
                requiredRoles: ['Company Admin'],
                crumb: 'common.menu.financials.reporting.clearings'
            }
        });
    })
    .component('clearings', {
        controller: ClearingController,
        templateUrl: template
    })
    .component('payoutModal', {
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&'
        },
        controller: PayoutModalController,
        templateUrl: payoutModalTemplate
    }).name;

function ClearingController($scope, $state, $element, $q, $http, $timeout, $uibModal, Role, UIMessages, clearingPayments) {
    const $ctrl = this;

    const urlBase = '/finance/clearing';
    $ctrl.helpCenter = HELP_CENTER_URL;

    if ((new Date()).getMonth() == 0) {
        $ctrl.dateRangeSelector = {
            start: moment().subtract(1, 'years').tz('Europe/Amsterdam').startOf('year'),
            end: moment().tz('Europe/Amsterdam').endOf('month')
        };
    } else {
        $ctrl.dateRangeSelector = {
            start: moment().tz('Europe/Amsterdam').startOf('year'),
            end: moment().tz('Europe/Amsterdam').endOf('month')
        };
    }

    $ctrl.oldDocumentsEndDate = moment().tz('Europe/Amsterdam').year(2019).startOf('year');
    $ctrl.showOldDocuments = $ctrl.dateRangeSelector.start.isBefore($ctrl.oldDocumentsEndDate);

    $ctrl.isAdmin = Role.isAuthorizedAs('Admin');
    $ctrl.busy = 0;

    // This holds the raw data from the server.
    // - This is used when the list of (visible) clearings should be changed.
    // - Eg: When a filter changes and previously hidden data should be visible again.
    $ctrl.data = null;

    // This holds the list of visible clearings.
    $ctrl.clearings = [];

    // This holds the totals per currency
    $ctrl.tableTotals = {};

    // Default filename for download
    $ctrl.fallbackFileName = moment().format('YYYY-MM-DD') + ' Clearing.pdf';

    // Controller methods declaration
    $ctrl.loadData = loadData;
    $ctrl.updateFilter = _.debounce(updateFilter, 300, {leading: false, trailing: true});
    $ctrl.openPayoutModal = openPayoutModal;

    /*
     * Initialization
     */

    $ctrl.$onInit = function() {
        $scope.$watch('$ctrl.dateRangeSelector', $ctrl.updateFilter, true);

        $ctrl.loadData();
    };

    /*
     * Data methods
     */
    $ctrl.openPayments = clearingPayments.open;

    function loadData() {
        if ($ctrl.busy)
            return errorResponse('System busy, please try again later');

        busy();

        // Reset the default filename
        $ctrl.fallbackFileName = moment().format('YYYY-MM-DD') + ' Clearing.pdf';

        let url = urlBase;

        if ($state.params.as)
            url += '/' + $state.params.as;

        return $http.get(url)
            .then(response => _.map(response.data, clearing => {
                if (_.isNil(clearing.period_end))
                    clearing.status = 'concept';
                else if (!clearing.administered)
                    clearing.status = 'external';
                else if (clearing.document_status == 'ready' && !_.isNil(clearing.period_end) && clearing.amount == 0)
                    clearing.status =   'success';
                else if (clearing.document_status !== 'ready' || _.isEmpty(clearing.payout))
                    clearing.status =   'pending';
                else
                    clearing.status = _.get(clearing, 'payout.status', '');

                clearing.downloadPath = `${urlBase}/${clearing.guid}/download`;
                clearing.fallbackFileName = (clearing.clearing_number ? clearing.clearing_number + ' ' : '') + $ctrl.fallbackFileName;

                clearing.original_amount = clearing.amount;
                clearing.amount *= 100;

                clearing.paidPaymentAmount = _.sumBy(_.filter(clearing.arbitrary_payments, _.iteratee({ 'status': 'paid'})), 'amount');

                clearing.needsPayment = false;
                if(clearing.amount < 0){
                    clearing.needsPayment = true;
                }

                return clearing;
            }))
            .then(clearings => $ctrl.data = $ctrl.clearings = clearings)
            .then($ctrl.updateFilter)
            .catch(errorResponse)
            .finally(done);
    }

    function updateFilter() {
        busy();

        $ctrl.showOldDocuments = $ctrl.dateRangeSelector.start.isBefore($ctrl.oldDocumentsEndDate);

        let startDate =  moment($ctrl.dateRangeSelector.start).add(1, 'second'); // to exclude new days in a new period
        let endDate =  moment($ctrl.dateRangeSelector.end).add(1, 'second'); // to include new days in a new period

        $ctrl.clearings = _.filter($ctrl.data, clearing => {
            if(_.isNil(clearing.period_end))
                return moment(clearing.period_start).isSameOrBefore(endDate);

            return moment(clearing.period_end).isSameOrAfter(startDate) && moment(clearing.period_end).isSameOrBefore(endDate);
        });

        $ctrl.tableTotals = {};

        _.forEach(_.groupBy($ctrl.clearings, 'currency'), (clearings, currency) => {
            _.set($ctrl.tableTotals, [currency, 'total'], _.sumBy(clearings, 'amount') / 100);
        });

        return $q.resolve($ctrl.clearings)
            .finally(done);
    }

    function openPayoutModal(clearing, $event) {
        if ($event.currentTarget.getAttribute('disabled'))
            return;

        $uibModal.open({
            component: 'payoutModal',
            resolve: {
                payout: clearing.payout
            }
        }).result.catch(angular.noop);
    }

    /*
     * Utility methods
     */

    function busy() {
        $ctrl.busy++;

        let iterationCallback = function(event) {
            if ($ctrl.busy)
                return;

            event.target.removeEventListener(event.type, iterationCallback);

            event.target.closest('table-view').classList.remove('loading');
        };

        let startCallback = function(event) {
            event.target.removeEventListener(event.type, startCallback);

            event.target.addEventListener('animationiteration', iterationCallback);
        };

        _.forEach($element, element => {
            _.forEach(element.querySelectorAll('table-view'), table => {
                table.addEventListener('animationstart', startCallback);

                table.classList.add('loading');
            });
        });
    }

    function done() {
        $timeout(() => $ctrl.busy--, 500);
    }

    function errorResponse(error, message = null) {
        message = message || 'Something went wrong, please try again later.';

        UIMessages.push(message);

        return $q.reject(error);
    }
}

function PayoutModalController() {
    const $ctrl = this;

    $ctrl.status = 'pending';

    $ctrl.$postLink = function() {
        _.assign($ctrl, $ctrl.resolve);

        $ctrl.status = _.get($ctrl, 'payout.status', 'pending');
    };
}
