import template from './fees.html';

export default angular.module('eventix.dashboard.financials.fees', [])
    .config(function ($stateProvider) {
        $stateProvider.state('eventix.dashboard.financials.fees', {
            url: '/fees',
            views: {
                'dashboard@eventix.dashboard': {
                    component: 'fees'
                }
            },
            data: {
                requiredRoles: ['Admin', 'Whitelabel Admin'],
                crumb: 'common.menu.financials.fees'
            }
        });
    })
    .component('fees', {
        controller: FeesController,
        bindings: {
            company: "<",
        },
        templateUrl: template
    }).name;

function FeesController() {
    const $ctrl = this;
}
