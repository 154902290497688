import common from './common';
import simple from './simple';
import advanced from './advanced';
import template from './wizard.html';
import style from './wizard.less';

export default angular.module(
    'eventix.dashboard.wizard',
    [
        common,
        simple,
        advanced
    ])
    .config(ModuleConfig)
    .component('wizardPathPicker', {
        controller: WizardPathPickerController,
        templateUrl: template,
        bindings: {
            event: '<',
            events: '<',
            company: '<',
            isAdmin: '<'
        }
    }).name;

//
// State configuration
//
function ModuleConfig($stateProvider) {
    $stateProvider.state('eventix.dashboard.wizard', {
        url: '/eventSettings?guid',
        resolve: /*@ngInject*/ {
            currentEvent: ResolveCurrentEvent
        },
        data: {
            requiredRoles: ['Event Manager'],
            parent: {
                name: 'eventix.dashboard.events',
                params: {},
                label: 'common.menu.manage.events'
            }
        },
        views: {
            'dashboard@eventix.dashboard': {
                component: 'wizardPathPicker',
                bindings: {
                    event: 'currentEvent'
                }
            }
        }
    });
}

//
// Resolves the current event if a GUID is given in the URL params, it'll redirect the user to the correct interface.
// Otherwise creates a new event that can be used by childStates
//
function ResolveCurrentEvent(Event, $transition$, $state, isAdmin, events) {
    const params = $transition$.params();
    const dest = $transition$.to();

    if (params.guid) {
        return Event.get({ guid: params.guid }).then(event => {
            if (dest.name === 'eventix.dashboard.wizard') {
                $transition$.abort();
                let newParams = { guid: event.guid };
                if(params.as)
                    newParams.as = params.as;
                switch (event.gui_mode) {
                case 'simple':
                    $state.go('eventix.dashboard.wizard.simple.basic', newParams);
                    break;
                case 'advanced':
                    $state.go('eventix.dashboard.wizard.advanced.basic', newParams);
                    break;
                default:
                    $state.go('eventix.dashboard.wizard.advanced.basic', newParams);
                    break;
                }
            }
            return event;
        });
    } else if(!/wizard$|basic$/.test(dest.name)) {
        $transition$.abort();
        return $state.go('eventix.dashboard.events');
    }
    return Event.new({});
}

//
// Component controller
//
function WizardPathPickerController($transitions, $state, $translate, Role, $uibModal, RelationAPI, Locales) {
    const $ctrl = this;

    $ctrl.newSimple = newSimple;
    $ctrl.confirmAdvanced = confirmAdvanced;
    $ctrl.confirmTimeslots = confirmTimeslots;
    $ctrl.goto = goto;
    $ctrl.isActive = isActive;
    $ctrl.isEventix = OVERRIDE_JS_NAME == 'Eventix';
    $ctrl.isAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');

    $ctrl.$onInit = function() {
        determinePath($state.current.name);
        $ctrl.hasOldEvent = _.find($ctrl.events, { gui_mode: null });
        RelationAPI.query('CompanyShop').then(relations => {
            if ((!relations || !relations.length) && $ctrl.hasOldEvent)
                openGlobalShopChoice();
        });
        $transitions.onSuccess({}, function(trans) {
            let to = trans.to();
            determinePath(to.name);
        });
    };

    function openGlobalShopChoice() {
        var explanation = [
            'You are hosting a new event? Great!',
            'Currently, you are creating your event in the new flow. This is an easier and quicker way of creating your event through simplified design.',
            'When you use the new flow you have to make a global ticket shop, to do so choose the shop most frequently used, preferably linked to your website and Facebook page. When you have selected this shop you can make it the global shop.',
            'Do not worry. If you want to select another shop this can be done at any time.',
            'If you would rather make your event in the old flow you can do so as well, use the link below your choice of flow.'
        ];
        if(Locales.selected === 'nl_NL') {
            explanation = [
                'Je organiseert een nieuw evenement? Geweldig!',
                'Momenteel creëer je jouw evenement in de nieuwe flow. Dit is een makkelijke en snelle manier om jouw evenement te maken door middel van versimpeld design. ',
                'Wanneer jij de nieuwe flow gebruikt maak je één globale ticket shop. Om dit te doen kies je de shop die het vaakst gebruikt wordt en bij voorkeur gelinkt is aan jouw website en Facebookpagina. Wanneer je deze shop geselecteerd hebt kun je hem global maken.',
                'Geen zorgen, als je van shop wilt wisselen kan dit altijd nog aangepast worden.',
                'Als jij jouw evenement liever in de oude flow aanmaakt kan dat ook, gebruik dan de link onder de keuze van jouw flow.'
            ];
        }
        explanation = '<p>' + explanation.join('</p><p>') + '</p>';
        $uibModal.open({
            size: 'lg',
            template: `<div class="modal-header">
                 <h3 class="modal-title" id="modal-title">Select global shop</h3>
             </div>
             <div class="modal-body" id="modal-body">
                ${ explanation }
                 <form class="form-horizontal">
                    <form-el label="models.company.shop" no-help>
                        <company-shop-selector company="$ctrl.company"></company-shop-selector>
                    </form-el>
                 </form>
             </div>
             <div class="modal-footer">
                 <button class="btn btn-info pull-right" type="button" ng-click="$close()" translate>common.action.continue</button>
             </div>`,
            controllerAs: '$ctrl',
            resolve: {
                company: () => $ctrl.company
            },
            controller: function(company) { this.company = company; }
        });
    }

    function newSimple() {
        return $state.go('eventix.dashboard.wizard.simple.basic');
    }

    function confirmAdvanced() {
        $uibModal.open({
            size: 'lg',
            template: `<div class="modal-header">
     <h3 class="modal-title" id="modal-title" translate>models.event.advanced.howto</h3>
 </div>
 <div class="modal-body" id="modal-body">
     <object data="/img/product_setup.svg" type="image/svg+xml"></object>
 </div>
 <div class="modal-footer">
     <button class="btn btn-default pull-left" type="button" ng-click="$dismiss()" translate>common.action.cancel</button>
     <button class="btn btn-info pull-right" type="button" ng-click="$close()" translate>models.event.advanced.contactsupport</button>
 </div>`
        }).result.then(() => {
            if ($ctrl.isAdmin)
                return $state.go('eventix.dashboard.wizard.advanced.basic');

            return window.Intercom('showNewMessage', $translate.instant('models.event.advanced.interested'));
        });
    }

    function confirmTimeslots() {
        if ($ctrl.isAdmin)
            return $state.go('eventix.dashboard.wizard.advanced.basic');

        return window.Intercom('showNewMessage', $translate.instant('models.event.timeslots.interested'));
    }

    function goto(state) {
        if(!$ctrl.event.guid) return;
        let dest = $state.current.name.split('.').slice(0, -1);
        dest.push(state);
        dest = dest.join('.');
        $state.go(dest, { guid: $ctrl.event.guid });
    }

    function isActive(state) {
        return $state.current.name.indexOf(state) > 24 ? 'active' : '';
    }

    /**
     * Determine if user took the simple or advanced path
     *
     * @param {String} stateName Name of current state
     */
    function determinePath(stateName) {
        $ctrl.advanced = stateName.indexOf('advanced') > 0;
        $ctrl.notInFocus = stateName !== 'eventix.dashboard.wizard';
    }
}
