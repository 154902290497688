import template from './products.html';
import modalTemplate from './productHelp.html';
import { copyToClipboard } from '../../../dashboard';

export default angular.module('eventix.dashboard.wizard.advanced.products', [])
    .config(ModuleConfig)
    .component('wizardAdvancedProducts', {
        controller: WizardAdvancedProductsController,
        templateUrl: template,
        bindings: {
            event: '<',
            company: '<'
        }
    }).name;

function ModuleConfig($stateProvider) {
    $stateProvider.state('eventix.dashboard.wizard.advanced.products', {
        url: '/products',
        component: 'wizardAdvancedProducts',
        bindings: {
            event: 'currentEvent',
            company: 'company'
        }
    });
}

function WizardAdvancedProductsController($q, $translate, $uibModal, $scope, CrudCtrl, Vat, Company, EventDate, Product, MetaData, Role) {
    const $ctrl = this;

    const vatPercentage = Vat.getDefaultRateFor(_.get(Company.cached, [Company.getCurrentId(), 'country'], 'NL'));
    const crudSettings = {
        persistState: false,
        autoNew: false,
    };

    $ctrl.openModal = openModal;
    $ctrl.preFillEnd = preFillEnd;
    $ctrl.saveEventDate = saveEventDate;
    $ctrl.saveAddonProduct = saveAddonProduct;
    $ctrl.disableProductEdit = disableProductEdit;
    $ctrl.copyToClipboard = copyToClipboard;

    $ctrl.product_types = ['normal', 'varying'];
    $ctrl.pricing_methods = ['own', 'container'];
    $ctrl.isAdmin = Role.isAuthorizedAs('Admin');
    $ctrl.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');

    $ctrl.loading = {
        addon: true,
        eventDates: true,
    };
    $ctrl.eventDateGuids = {
        event: {
            guid: $ctrl.event ? $ctrl.event.guid : '',
            icon: 'fa-calendar',
            name: 'models.models.event',
        },
        date: {
            guid: $ctrl.eventDates && $ctrl.eventDates.model ? $ctrl.eventDates.model.guid : '',
            icon: 'fa-ticket',
            name: 'models.models.date',
        },
    };
    $ctrl.addonGuids = {
        event: {
            guid: $ctrl.event ? $ctrl.event.guid : '',
            icon: 'fa-calendar',
            name: 'models.models.event',
        },
        product: {
            guid: $ctrl.addon && $ctrl.addon.model ? $ctrl.addon.model.guid : '',
            icon: 'fa-cubes',
            name: 'models.models.addonProduct',
        },
    };

    // This property will delay the addon product query, as it depends on the (cached) response of the access product query
    // If the addon product query is triggered before the access product query is done, it will do the same request twice.
    $ctrl.delayAddonDeferred = $q.defer();

    $ctrl.$onInit = function() {
        $ctrl.eventDates = new CrudCtrl(loadEventDates, () => EventDate.new({event_id: $ctrl.event.guid}), crudSettings);
        $ctrl.eventDates.afterLoad(() => $ctrl.loading.eventDates = false);
        $ctrl.eventDates.addBelongsToMany('meta_data', 'MetaData');

        $ctrl.addon = new CrudCtrl(loadAddonProducts, () => Product.new({type: 'normal', vat_percentage: vatPercentage}), _.defaults({stateParamNew: false}, crudSettings));
        $ctrl.addon.afterLoad(() => $ctrl.loading.addon = false);
        $ctrl.addon.addBelongsToMany('meta_data', 'MetaData');

        loadMetaData();
    };

    $ctrl.$postLink = function() {
        $ctrl.formOptionsAvailable = [{
            key: 'metaData',
            label: $translate.instant('models.product.metaData')
        }];
        if ($ctrl.isAdminOrWLAdmin) {
            $ctrl.formOptionsAvailable.push({
                key: 'type',
                label: $translate.instant('models.product.type')
            });
            $ctrl.formOptionsAvailable.push({
                key: 'pricing_method',
                label: $translate.instant('models.product.pricing_method')
            });
            $ctrl.formOptionsAvailable.push({
                key: 'class',
                label: $translate.instant('models.product.class')
            });
            $ctrl.formOptionsAvailable.push({
                key: 'skip_late_personalization',
                label: $translate.instant('models.product.skip_late_personalization'),
            });
        }

        $scope.$watch('$ctrl.event.guid', (guid) => {
            $ctrl.eventDateGuids.event.guid = guid || '';
            $ctrl.addonGuids.event.guid = guid || '';
        });
        $ctrl.eventDateGuids.event.guid = $ctrl.event ? $ctrl.event.guid : '';
        $ctrl.addonGuids.event.guid = $ctrl.event ? $ctrl.event.guid : '';
        $scope.$watch('$ctrl.eventDates.model.guid', (guid) => {
            $ctrl.eventDateGuids.date.guid = guid || '';
        });
        $ctrl.eventDateGuids.date.guid = $ctrl.eventDates && $ctrl.eventDates.model ? $ctrl.eventDates.model.guid : '';
        $scope.$watch('$ctrl.addon.model.guid', (guid) => {
            $ctrl.addonGuids.product.guid = guid || '';
        });
        $ctrl.addonGuids.product.guid = $ctrl.addon && $ctrl.addon.model ? $ctrl.addon.model.guid : '';
    };

    function saveEventDate() {
        return $ctrl.event.$crudCreateOrSaveChild($ctrl.eventDates, 'EventDate');
    }

    function saveAddonProduct() {
        return $ctrl.event.$crudCreateOrSaveChild($ctrl.addon, 'Product');
    }

    function openModal() {
        $uibModal.open({
            templateUrl: modalTemplate,
            controller: angular.noop,
            size: 'lg'
        });
    }

    function loadEventDates() {
        return $ctrl.event.$queryEventDate()
            .finally(() => $ctrl.delayAddonDeferred.resolve());
    }

    function loadAddonProducts() {
        return $ctrl.delayAddonDeferred.promise.then(() => $ctrl.event.$queryAddonProduct());
    }

    /**
     * Pre fill an end-time when only the start-time is given
     */
    function preFillEnd() {
        if (($ctrl.eventDates.model.end && $ctrl.eventDates.model.end.length) || !$ctrl.eventDates.model.start || $ctrl.eventDates.model.start.length < 19) {
            return;
        }

        let date = moment($ctrl.eventDates.model.start, 'YYYY-MM-DDTHH:mm:ssZ');

        if (!date.isValid()) {
            return;
        }

        $ctrl.eventDates.model.end = date.add(4, 'hours').format('YYYY-MM-DDTHH:mm:ssZ');
    }

    function loadMetaData() {
        return MetaData.query().then(metaData => $ctrl.metaData = metaData);
    }

    function disableProductEdit() {
        if ($ctrl.addon.state != 'new' && ($ctrl.addon.model.origin_type == 'ticket' || $ctrl.addon.model.origin_type == 'date')) {
            return true;
        }

        return false;
    }
}
