import template from './companyForm.html';
import { timezones } from './timezones';

export default angular.module('eventix.dashboard.company.form', [])
    .component('companyForm', {
        bindings: {
            company: '=',
            busy: '=',
            saveFn: '&',
        },
        controller: CompanyFormController,
        templateUrl: template,
    }).name;

export function CompanyFormController(UIMessages, Locales, $q, Role, Shop, SweetAlert, Currencies, $scope, $analytics, $translate) {
    const $ctrl = this;

    const vatFormatCountries = {
        // AT-Austria	        ATU99999999	1 block of 9 characters
        AT: value => value.replace(/^(?:[^0-9A]*)?(?:A(?:TU?)?)?(?:[^0-9\n]*)?([0-9]?)?(?:[^0-9\n]*)?([0-9]?)?(?:[^0-9\n]*)?([0-9]?)?(?:[^0-9\n]*)?([0-9]?)?(?:[^0-9\n]*)?([0-9]?)?(?:[^0-9\n]*)?([0-9]?)?(?:[^0-9\n]*)?([0-9]?)?(?:[^0-9\n]*)?([0-9]?)?.*$/, 'ATU$1$2$3$4$5$6$7$8'),
        // BE-Belgium	        BE0999999999	1 block of 10 digits
        BE: true,
        // BG-Bulgaria	        BG999999999 or BG9999999999	1 block of 9 digits or1 block of 10 digits
        BG: true,
        // CY-Cyprus	        CY99999999L	1 block of 9 characters
        CY: true,
        // CZ-Czech Republic	CZ99999999 or CZ999999999 or CZ9999999999 1 block of either 8, 9 or 10 digits
        CZ: true,
        // DE-Germany	        DE999999999	1 block of 9 digits
        DE: true,
        // DK-Denmark	        DK99 99 99 99	4 blocks of 2 digits
        DK: value => value.replace(/^(?:[^0-9D\n]*)?(?:DK?)?([0-9])?(?:[^0-9\n]*)?([0-9])?(?:[^0-9\n]*)?([0-9])?(?:[^0-9\n]*)?([0-9])?(?:[^0-9\n]*)?([0-9])?(?:[^0-9\n]*)?([0-9])?(?:[^0-9\n]*)?([0-9])?(?:[^0-9\n]*)?([0-9])?.*$/, 'DK$1$2 $3$4 $5$6 $7$8'),
        // EE-Estonia	        EE999999999	1 block of 9 digits
        EE: true,
        // EL-Greece	        EL999999999	1 block of 9 digits
        EL: true,
        // ES-Spain	            ESX9999999X	1 block of 9 characters
        ES: true,
        // FI-Finland	        FI99999999	1 block of 8 digits
        FI: true,
        // FR-France	        FRXX 999999999	1 block of 2 characters, 1 block of 9 digits
        FR: value => value.replace(/^(?:[^0-9F]*)?(?:FR?)?(?:[^0-9A-Za-z]*)?([0-9A-Za-z])?(?:[^0-9A-Za-z]*)?([0-9A-Za-z])?(?:[^0-9\n]*)?([0-9]?)?(?:[^0-9\n]*)?([0-9]?)?(?:[^0-9\n]*)?([0-9]?)?(?:[^0-9\n]*)?([0-9]?)?(?:[^0-9\n]*)?([0-9]?)?(?:[^0-9\n]*)?([0-9]?)?(?:[^0-9\n]*)?([0-9]?)?(?:[^0-9\n]*)?([0-9]?)?(?:[^0-9\n]*)?([0-9]?)?.*$/, 'FR$1$2 $3$4$5$6$7$8$9$10$11'),
        // GB-United Kingdom	GB999 9999 99 or GB999 9999 99 999 or GBGD999 or GBHA999	1 block of 3 digits, 1 block of 4 digits and 1 block of 2 digits; or the above followed by a block of 3 digits;	or 1 block of 5 characters
        GB: value => _.trimEnd(value.replace(/^(?:[^0-9G]*)?(?:GB?)?(?:[^0-9A-Za-z]*)?(G(?:D|$)|H(?:A|$))?(?:[^0-9\n]*)?([0-9])?(?:[^0-9\n]*)?([0-9])?(?:[^0-9\n]*)?([0-9])?(?:[^0-9\n]*)?([0-9])?(?:[^0-9\n]*)?([0-9])?(?:[^0-9\n]*)?([0-9])?(?:[^0-9\n]*)?([0-9])?(?:[^0-9\n]*)?([0-9])?(?:[^0-9\n]*)?([0-9])?(?:[^0-9\n]*)?([0-9])?(?:[^0-9\n]*)?([0-9])?(?:[^0-9\n]*)?([0-9])?.*$/, 'GB$1$2$3$4 $5$6$7$8 $9$10 $11$12$13')),
        // HR-Croatia	        HR99999999999	1 block of 11 digits
        HR: true,
        // HU-Hungary	        HU99999999	1 block of 8 digits
        HU: true,
        // IE-Ireland	        IE9S99999L IE9999999WI	1 block of 8 characters or 1 block of 9 characters
        IE: value => value.replace(/^(?:[^0-9I]*)?(?:IE?)?(?:[^0-9\n]*([0-9])(?:[^A-Za-z0-9+*]*([A-Za-z0-9+*])(?:[^0-9\n]*([0-9])(?:[^0-9\n]*([0-9])(?:[^0-9\n]*([0-9])(?:[^0-9\n]*([0-9])(?:[^0-9\n]*([0-9])(?:[^A-Z\n]*([A-Z]+|WI))?)?)?)?)?)?)?)?(?:[^\n]*)$/, 'IE$1$2$3$4$5$6$7$8'),
        // IT-Italy	            IT99999999999	1 block of 11 digits
        IT: true,
        // LT-Lithuania	        LT999999999 or LT999999999999	1 block of 9 digits, or 1 block of 12 digits
        LT: true,
        // LU-Luxembourg	    LU99999999	1 block of 8 digits
        LU: true,
        // LV-Latvia	        LV99999999999	1 block of 11 digits
        LV: true,
        // MT-Malta	            MT99999999	1 block of 8 digits
        MT: true,
        // NL-The Netherlands	NL999999999B99	1 block of 12 characters
        NL: value => value.replace(/^(?:[^0-9N]*)?(?:N(?:L|$)?)?(?:[^0-9\n]*([0-9])(?:[^0-9\n]*([0-9])(?:[^0-9\n]*([0-9])(?:[^0-9\n]*([0-9])(?:[^0-9\n]*([0-9])(?:[^0-9\n]*([0-9])(?:[^0-9\n]*([0-9])(?:[^0-9\n]*([0-9])(?:[^0-9\n]*([0-9])(?:[^B0-9\n]*(B)(?:[^0-9\n]*([0-9])(?:[^0-9\n]*([0-9]))?)?)?)?)?)?)?)?)?)?)?)?(?:[^\n]*)$/, 'NL$1$2$3$4$5$6$7$8$9$10$11$12'),
        // PL-Poland	        PL9999999999	1 block of 10 digits
        PL: true,
        // PT-Portugal	        PT999999999	1 block of 9 digits
        PT: true,
        // RO-Romania	        RO999999999	1 block of minimum 2 digits and maximum 10 digits
        RO: true,
        // SE-Sweden	        SE999999999999	1 block of 12 digits
        SE: true,
        // SI-Slovenia	        SI99999999	1 block of 8 digits
        SI: true,
        // SK-Slovakia	        SK9999999999	1 block of 10 digits
        SK: true,
    };

    $ctrl.isAdmin = $ctrl.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');
    $ctrl.currencies = Currencies;
    $ctrl.locales = Locales;
    $ctrl.countries = Locales.countries;
    $ctrl.availableTimezones = timezones;

    $ctrl.guids = {
        company: {
            guid: $ctrl.company ? $ctrl.company.guid : '',
            icon: 'fa-building-o',
            name: 'models.models.company',
        },
    };

    $ctrl.formatUrl = formatUrl;
    $ctrl.save = save;
    $ctrl.changePayoutFrequency = changePayoutFrequency;
    $ctrl.formatVatNumber = formatVatNumber;
    $ctrl.blurVatNumber = blurVatNumber;
    $ctrl.countryInVatSpec = countryInVatSpec;

    let hasBankDetails = false;

    $ctrl.$onInit = function() {
        if (!$ctrl.company) {
            console.error('Company not provided');
        }

        hasBankDetails = _.get($ctrl.company, 'bank_account_number.length', 0) > 0 &&
            _.get($ctrl.company, 'bank_account_name.length', 0) > 0;
        $scope.$on('input-blur', function(event, name) {
            $ctrl.company.validate(name);
        });

        $scope.$watch('$ctrl.company.guid', (guid) => {
            $ctrl.guids.company.guid = guid || '';
        });
        $ctrl.guids.company.guid = $ctrl.company ? $ctrl.company.guid : '';
    };

    /*
     * Prepend protocol to an URL
     */
    function formatUrl() {
        if (_.isString($ctrl.company.website) && $ctrl.company.website.length && $ctrl.company.website.indexOf('http') !== 0) {
            $ctrl.company.website = 'http://' + $ctrl.company.website;
        }
    }

    function save() {
        if (!$ctrl.company) {
            console.error('Company not provided');
            return;
        }

        let promise = $q.when($ctrl.saveFn({company: $ctrl.company}));

        promise.then(() => {
            if (!hasBankDetails && _.get($ctrl.company, 'bank_account_number.length', 0) > 0 && _.get($ctrl.company, 'bank_account_name.length', 0) > 0) {
                $analytics.eventTrack('iban-completed');
                hasBankDetails = true;
            }
        });

    }

    function changePayoutFrequency() {
        if ($ctrl.company.payout_frequency == 'disabled_by_admin' && !$ctrl.isAdmin) {
            $ctrl.company.payout_frequency = 'disabled';
        }

        if ($ctrl.company.payout_frequency === 'threshold') {
            return;
        }

        if (_.isNumber($ctrl.company.payout_threshold) && $ctrl.company.payout_threshold >= 0) {
            return;
        }

        $ctrl.company.payout_threshold = 0;
    }

    function formatVatNumber() {
        if (!$ctrl.company.vat_number) {
            return;
        }

        if ($ctrl.company.vat_number.length < 3) {
            return;
        }

        let country = $ctrl.company.country;
        let vatCountry = _.upperCase($ctrl.company.vat_number.slice(0, 2));

        if (_.has(vatFormatCountries, vatCountry)) {
            country = vatCountry;
        }

        let formatter = _.get(vatFormatCountries, country);

        if (_.isNil(formatter)) {
            return;
        }

        if (formatter === true) {
            formatter = value => value.replace(/[^A-Za-z0-9]/g, '');
        }

        $ctrl.company.vat_number = formatter(_.upperCase($ctrl.company.vat_number));
    }

    function blurVatNumber() {
        if (_.upperCase($ctrl.company.vat_number) === _.upperCase($ctrl.company.country)) {
            $ctrl.company.vat_number = '';
        }
    }

    function countryInVatSpec(country) {
        return _.has(vatFormatCountries, country);
    }
}
