import template from './copySimpleEvent.html';

export default angular.module('eventix.dashboard.wizard.simple.copy', [])
    .config(ModuleConfig)
    .component('copySimpleEvent', {
        controller: CopySimpleEventController,
        templateUrl: template,
        bindings: {
            event: '<',
            events: '<',
            company: '<'
        }
    }).name;

function ModuleConfig($stateProvider) {
    $stateProvider.state('eventix.dashboard.wizard.simple.copy', {
        url: '/copy',
        resolve: /*@ngInject*/ {},
        views: {
            'dashboard@eventix.dashboard': {
                component: 'copySimpleEvent',
                bindings: {
                    event: 'currentEvent',
                    company: 'company'
                }
            }
        }
    });
}

function CopySimpleEventController($q, CrudCtrl, $uibModal, FormInModal, ErrorRejector, Role, $filter, $translate, EventCloner, Ticket, Vat, Company, UIMessages, Shop, $state, SweetAlert) {
    const $ctrl = this;
    const ISO8601 = 'YYYY-MM-DDTHH:mm:ssZ';

    $ctrl.loading = true;

    $ctrl.copyNotAllowed = false;
    $ctrl.isAdmin = Role.isAuthorizedAs('Admin');

    $ctrl.offset = 1;
    $ctrl.offsetUnit = 10080;

    $ctrl.calculateOffset = calculateOffset;
    $ctrl.calculateNewDates = calculateNewDates;
    $ctrl.start = start;
    $ctrl.editTicket = editTicket;
    $ctrl.save = save;

    $ctrl.eventFormOptions = {
        retrievable_after: true,
    }

    $ctrl.ticketFormOptionsAvailable = [
        {key: 'increment', label: $translate.instant('models.ticket.increment')},
        {key: 'availableDates', label: $translate.instant('models.ticket.available_from')},
        {key: 'minOrderQuantity', label: $translate.instant('models.ticket.min_orderable_amount_per_order')},
        {key: 'maxOrderQuantity', label: $translate.instant('models.ticket.max_orderable_amount_per_order')},
        {key: 'availabilityMargin', label: $translate.instant('models.ticket.availability_margin')},
        //{ key: 'serviceCost', label: $translate.instant('models.ticket.service_cost') },
        {key: 'metaData', label: $translate.instant('models.ticket.meta_data')}
    ];

    $ctrl.$postLink = function() {
        $ctrl.name = $ctrl.event.name;

        if ($ctrl.isAdmin) {
            $ctrl.ticketFormOptionsAvailable.push(
                {key: 'hideWithoutCoupon', icon: 'fa fa-lock', label: 'Hide without coupon'},
                {
                    key: 'productGroups',
                    icon: 'fa fa-lock',
                    label: $filter('capitalize')($translate.instant('models.models.productGroup', {count: 2}))
                }
            );
        }

        loadEventDates().then(() => $ctrl.loading = false);
    };

    function calculateOffset() {
        let diff = moment($ctrl.newStart, ISO8601).diff(moment($ctrl.event.start, ISO8601), 'minutes');

        [10080, 1440, 60, 1].find(offsetUnit => {
            if (diff % offsetUnit === 0) {
                $ctrl.offsetUnit = offsetUnit;
                $ctrl.offset = diff / offsetUnit;
                return true;
            }

            return false;
        });

        $ctrl.newEnd = moment($ctrl.event.end, ISO8601).add(diff, 'minutes').format(ISO8601);
    }

    function calculateNewDates() {
        let diff = $ctrl.offset * $ctrl.offsetUnit;

        $ctrl.newEnd = moment($ctrl.event.end, ISO8601).add(diff, 'minutes').format(ISO8601);
        $ctrl.newStart = moment($ctrl.event.start, ISO8601).add(diff, 'minutes').format(ISO8601);
    }

    function start() {
        let offset = $ctrl.offset * $ctrl.offsetUnit;

        $ctrl.cloner = new EventCloner({event: $ctrl.event, name: $ctrl.name, offset: offset});

        $ctrl.openSecondPanel = true;

        $ctrl.cloner.start()
            .then(() => {
                $ctrl.clone = $ctrl.cloner.clone;

                const tickets = [];

                $ctrl.clone.tickets.forEach((ticket, originalTicketId) => {
                    let otherEventProductsInfo = 'Products from other events will be linked (not copied) with this ticket</br></br>Products:</br>';

                    _.forEach($ctrl.cloner.original.otherTicketProducts.get(originalTicketId) || [], function(product){
                        otherEventProductsInfo += "<strong>" + product.name + "</strong><br/>";
                    });

                    otherEventProductsInfo += "<br/>Cannot be disabled here.";

                    ticket.html = otherEventProductsInfo;

                    tickets.push(ticket);
                });


                $ctrl.ticketCrud = CrudCtrl.new(tickets, () => Ticket.new({
                    increment: 1,
                    status_overrule: 'auto',
                    available_stock: 0,
                    availability_margin: 0,
                    min_orderable_amount_per_order: 1,
                    max_orderable_amount_per_order: 20,
                    percentage_service_costs_in_ticket: 0,
                    min_price: 0,
                    vat_percentage: Vat.getDefaultRateFor(_.get(Company.cached, [Company.getCurrentId(), 'country'], 'NL')),
                    combines_products: true,
                    swappable: true,
                }), {
                    determineInitState: false,
                    autoNew: false,
                    stateParamNew: false,
                    persistState: false,
                });

                $ctrl.ticketCrud.addBelongsToMany('metaData', 'MetaData');
            })
            .catch(ErrorRejector.handle);
    }

    function editTicket(ticket) {
        if ($ctrl.busy) {
            return;
        }

        ticket.validate();

        $ctrl.ticketCrud.editDraft(ticket)
            .catch(ErrorRejector.handle);
    }

    function save() {
        $ctrl.busy = true;

        $ctrl.cloner.save()
            .then(() => {
                $ctrl.events.push($ctrl.clone.event);

                SweetAlert.swal({
                    title: 'Event succesfully cloned',
                    text: 'You can now add tickets to shops',
                    type: 'success',
                    showConfirmButton: true
                });

                $state.go('eventix.dashboard.wizard.simple.shops', {guid: $ctrl.clone.event.guid});
            })
            .catch(ErrorRejector.handle)
            .finally(() => $ctrl.busy = false);
    }

    function loadEventDates() {
        return $ctrl.event.$queryEventDate()
            .then(dates => {
                $ctrl.event.seated = _.reduce(dates, (carry, date) => carry || _.size(date.seats_event_key), false);
                $ctrl.event.start = _.minBy(dates, 'start').start;
                $ctrl.event.end = _.maxBy(dates, 'end').end;

                $ctrl.calculateNewDates();

                if ($ctrl.event.gui_mode === 'advanced') {
                    $ctrl.copyNotAllowed = 'advanced-gui-mode';
                } else if (_.isNil($ctrl.event.gui_mode)) {
                    $ctrl.copyNotAllowed = 'old-gui-mode';
                } else if ($ctrl.event.seated) {
                    $ctrl.copyNotAllowed = 'seats';
                }
            })
            .catch(error => {
                $ctrl.copyNotAllowed = 'failed-loading';

                return ErrorRejector.handle(error);
            });
    }
}
