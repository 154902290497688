import templateUrl from './downloadFile.html';
import saveAs from 'file-saver';

export default angular
    .module('eventix.dashboard.financials.reporting.downloadFile', [])
    /**
     * Wrapper to add click and download/view functionality to content.
     *
     * The wrapper does not change any styling. It is only a wrapping anchor. Any styling is assumed to be done outside
     * of this component, in it's parent and on the transcluded content.
     *
     * @param {Expression} path The path to retrieve the blob from
     * @param {String} disposition How to handle the file: 'inline': open in new window, 'attachment': download as file
     * @param {Expression} fileName A filename to use when downloading (disposition 'attachment')
     * @param {Expression} fallbackFileName A fallback filename used if there is no fileName given AND no filename is set in the blob's header (Content-Disposition header)
     * @param {Expression} ngDisabled If the result of this expression evaluates to true, the disabled attribute of the component element is set
     *
     * @example
     * ```
     * <download-file
     *     path="$ctrl.downloadPath"
     *     disposition="attachment"
     *     file-name="$ctrl.downloadFileName"
     *     fallback-file-name="$ctrl.fallbackFileName"
     *     ng-disabled="$ctrl.disableDownload">
     *          <i class="fa fa-download"></i>
     *          <!-- Add custom arbitrary content -->
     * </download-file>
     * ```
     */
    .component('downloadFile', {
        bindings: {
            path: '<',
            disposition: '@',
            fileName: '<',
            fallbackFileName: '<'
        },
        templateUrl: templateUrl,
        transclude: true,
        controller: DownloadFile
    }).name;

function DownloadFile($http, $q, $timeout, $attrs, UIMessages) {
    const $ctrl = this;

    $ctrl.download = download;

    function download() {
        if (_.get($attrs, 'disabled', false)) {
            console.error('Download is disabled, can not download file from API');

            return $q.reject('Download is disabled');
        }

        if (_.isEmpty($ctrl.path)) {
            console.error('No path, can not download file from API');

            return $q.reject('No path, can not download file from API');
        }

        $ctrl.disposition = $ctrl.disposition || 'attachment';

        $ctrl.fallbackFileName = $ctrl.fallbackFileName || 'download';

        return $http.get($ctrl.path, {responseType: 'blob'})
            .then(response => {
                let outputFileName = $ctrl.fileName;
                if (_.isEmpty(outputFileName)) {
                    // Regex to retrieve the filename from the Content-Disposition part
                    // inline; filename="somefile.pdf"
                    let header = response.headers('Content-Disposition');
                    let fileNameMatches = /^(?:[a-z;]*[ ]?)*(?:filename=")([\x20-\x21\x23-\x7e]*)(?:"(?:;.*)?)$/.exec(header);

                    outputFileName = _.get(fileNameMatches, 1, $ctrl.fallbackFileName);
                }

                let objectUrl = window.URL.createObjectURL(response.data);

                if ($ctrl.disposition === 'attachment') {

                    saveAs(response.data, outputFileName);

                    // // With an anchor element we can trigger a download. This SHOULD not trigger a popup
                    // let anchor = document.createElement('a');
                    //
                    // anchor.href = objectUrl;
                    // anchor.target = '_blank';
                    //
                    // // When setting the download attribute,
                    // // most modern browsers will trigger a file download instead of opening a new window.
                    // // If the browser does not trigger a download,
                    // // this might or might not be blocked by a popup/ad blocker
                    // anchor.download = $ctrl.fileName;
                    //
                    // anchor.click();
                } else {
                    // When specifically viewing a document, we trigger a new window to open.
                    // If a popup/ad blocker disables this, we will notify the user.
                    let popup = window.open(objectUrl, '_blank');

                    // We assume here that a user will not close the window themselves within 400ms
                    $timeout(() => {
                        if (!popup || typeof popup === 'undefined' || popup.closed === true) {
                            UIMessages.push('Please disable pop-up and/or ad blocker for the dashboard');

                            console.error('popup blocked');
                        }
                    }, 400);
                }

                // Timeout, because the requests needs to start before the Object URL is revoked.
                // some browsers will be ok without a timeout but some (Firefox & sometimes chrome)
                // will not be.
                // We call this to cleanup the blob after it has been used. After this function terminates,
                // this particular blob will not be accessible, so keeping it around is useless.
                $timeout(() => window.URL.revokeObjectURL(objectUrl), 1000);

                return response;
            })
            .catch(error => {
                console.error('Could not retrieve document', error);

                UIMessages.push(error.statusText || (_.isString(error) ? error : 'Something went wrong'));

                return $q.reject(error);
            });
    }
}