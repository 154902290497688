import template from './editReceiver.html';

export default angular.module('eventix.dashboard.orders.edit.receiver', [])
    .component('editReceiverModal', {
        controller: EditReceiverModalController,
        templateUrl: template,
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&'
        }
    }).name;

function EditReceiverModalController(UIMessages, Locales) {
    const $ctrl = this;

    $ctrl.$onInit = function() {
        _.assign($ctrl, $ctrl.resolve);
        $ctrl.locales = Locales;
    };
    $ctrl.save = function() {
        if($ctrl.order.$invalid || $ctrl.busy)
            return;
        $ctrl.busy = true;
        $ctrl.order.$save()
            .then(() => $ctrl.close({ $value: $ctrl.order }))
            .catch(err => {
                UIMessages.push(err.description || err.message || 'common.notice.error');
                $ctrl.busy = false;
            });
    };
}
