import template from './ibanModal.html';

export default angular.module('eventix.dashboard.orders.iban.modal', [])
    .component('ibanModal', {
        controller: ibanModalController,
        templateUrl: template,
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&'
        }
    }).name;

function ibanModalController(UIMessages, $http, ErrorRejector, $window) {
    const $ctrl = this;

    const accessToken = $window.auth ? $window.auth.getAccessToken() : null;
    $ctrl.$onInit = function() {
        _.assign($ctrl, $ctrl.resolve);

        $ctrl.translateTitle = 'Refund to IBAN';
    };

    $ctrl.save = function() {
        if (!accessToken || !accessToken.access_token || !accessToken.access_token.length) {
            throw Error('TemplateModalController should be initialized with access token');
        }

        $ctrl.busy = true;
        $ctrl.error = {
            account_name: [],
            account_number: [],
            account_bic: []
        }

        const link = "/order/" + $ctrl.refund.original_order_id + "/refunds/" + $ctrl.refund.guid + "/alter";
        let data = {
            account_name: $ctrl.account_name,
            account_number: $ctrl.account_number,
            account_bic: $ctrl.account_bic
        }

        const headers = {
            Authorization: 'Bearer '+ accessToken.access_token,
            'Content-Type': 'application/json',
        };

        if ($window.auth && $window.auth.currentCompanyGuid && $window.auth.currentCompanyGuid.length) {
            headers.Company = $window.auth.currentCompanyGuid;
        }

        return $http.post(link, data, { headers })
            .then(() => {
                $ctrl.busy = false;

                $ctrl.close();
            })
            .catch(error => {
                $ctrl.busy = false;

                let err = _.get(error, 'data.error_description');
                if(err && typeof(err) == 'object'){
                    _.forEach(err, function(error,name){
                        ErrorRejector.handle(...error);
                        $ctrl.error[name].push(...error)
                    })
                    return;
                }
                else {
                    return ErrorRejector.handle(err);
                }
            });
    };
}
