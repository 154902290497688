export default angular.module('eventix.dashboard.wizard.common.childSelector', [])
    /**
     * @ngdoc directive
     * @name eventix.common.directives:childSelector
     * @restrict element
     * @description
     * Manage a belongsToMany relationship using a dropdown selector
     *
     * @param {CrudCtrl} crudCtrl The local CrudCtrl instance
     * @param {Array<Object>} children Array of potential children
     * @param {String} fnSuffix Constructor name of the child model (e.g.: Ticket, Product) that is suffixed to belongsToMany functions (e.g.: `$queryTicket`)
     * @param {String} [label='name'] The property name that is used to label a child
     * @param {String} [sublabel=null] Optional sublabel property for the dropdown box
     * @param {String} dropdownId Value for id attribute to be given to the dropdown menu element
     * @param {String} [btnClass='form-control'] The class for the dropdown toggle
     * @param {String} [groupBy=null] Optional child property that defines groups for the dropdown
     * @param {String} [groupLabel=groupBy] Optional property that specifies the label used for each group
     * @param {String} [disableWhen=null] Optional child property that defines when child is disabled
     * @param {Boolean} [reorder=null] Optional attribute that enables reordering functionality
     * @param {Expression} [pinned=null] Callback to determine whether a child is reorderable (`pinned="isPinned(child)"`)
     * @param {Array<Object>} [childActions=null] Optional array of actions appended to reorderable children
     * Actions can contain `{ngClick[, tooltip, ngHide, icon: 'fa fa-pencil', class: 'btn btn-xs btn-default']}`
     */
    .component('childSelector', {
        bindings: {
            crudCtrl: '<',
            children: '=',
            fnSuffix: '@',
            label: '@',
            sublabel: '@',
            dropdownId: '@',
            btnClass: '@',
            groupBy: '@',
            groupLabel: '@',
            disableWhen: '@',
            pinned: '&',
            childActions: '=',
            beforeReorder: '&',
            afterReorder: '&',
            trackBy: '@'
        },
        controller: function($scope, $attrs, sortableCallbacks, $q) {
            const $ctrl = this;
            var promise = $q.resolve();

            $ctrl.$onInit = function() {
                _.defaults($ctrl, {
                    btnClass: 'form-control',
                    beforeReorder: angular.noop,
                    afterReorder: angular.noop,
                    label: 'name',
                    trackBy: 'guid'
                });
            };

            $ctrl.$postLink = function() {
                let deregister;
                $scope.$watch(`$ctrl.crudCtrl.relationships['${$ctrl.fnSuffix}']`, childContainer => {
                    if(!childContainer) return;
                    if(deregister)
                        deregister();
                    deregister = $scope.$watch(`$ctrl.crudCtrl.model['${childContainer}']`, container => {
                        if(!container) return;
                        $ctrl.container = _.map(container, $ctrl.trackBy);
                        $ctrl.rawContainer = container;
                    }, true);
                });
                $ctrl.reorder = $attrs.reorder !== undefined;
            };
            var onSort = sortableCallbacks.onSort((pre, suc) => $ctrl.crudCtrl['reorder' + $ctrl.fnSuffix](pre, suc));
            $ctrl.sortable = {
                animation: 150,
                filter: '.pinned',
                get disabled() { return $ctrl.crudCtrl && $ctrl.crudCtrl.busy; },
                set disabled(v) { return v; },
                onSort: function(evt) {
                    // dragged child is not at bottom and the new neighbor below is pinned
                    if(evt.newIndex < $ctrl.rawContainer.length - 1 && $ctrl.pinned({ child: $ctrl.rawContainer[evt.newIndex + 1] })) {
                        // revert sorting
                        let child = $ctrl.rawContainer.splice(evt.newIndex, 1);
                        return $ctrl.rawContainer.splice(evt.oldIndex, 0, ...child);
                    }
                    return $q.resolve($ctrl.beforeReorder({ $event: evt }))
                        .then(() => onSort(evt))
                        .then(
                            () => $ctrl.afterReorder({ error: null, $event: evt }),
                            err => $ctrl.afterReorder({ error: err, $event: evt })
                        );
                }
            };
            $ctrl.toggleList = () => $ctrl.showList = !$ctrl.showList;
            $ctrl.beforeSelect = (model) => {
                return promise = promise.then(() => $ctrl.crudCtrl['attach' + $ctrl.fnSuffix](model).then(result => result, error => {
                    // Reset the promise on the child selector, so the component does not get locked after a rejection.
                    // Only all previously chained promises get skipped.
                    promise = $q.resolve();
                    return $q.reject(error);
                }));
            };
            $ctrl.beforeDeselect = (model) => {
                return promise = promise.then(() => $ctrl.crudCtrl['detach' + $ctrl.fnSuffix](model).then(result => result, error => {
                    // Reset the promise on the child selector, so the component does not get locked after a rejection.
                    // Only all previously chained promises get skipped.
                    promise = $q.resolve();
                    return $q.reject(error);
                }));
            };
            $ctrl.isPinned = (child) => ($ctrl.pinned || angular.noop)({ child: child }) ? 'pinned' : '';
        },
        template: `<div ng-class="{ 'input-group': $ctrl.reorder }">
    <tx-select sublabel="{{$ctrl.sublabel}}"
        multiple
        no-purge
        confirm-large-groups="15"
        dropdown-id="$ctrl.dropdownId"
        btn-class="{{$ctrl.btnClass}}"
        tx-options="child.{{ $ctrl.trackBy }} as child.{{ $ctrl.label }}{{ $ctrl.groupBy ? ' group by child.' + $ctrl.groupBy  : '' }}{{ $ctrl.groupLabel ? ' as child.' + $ctrl.groupLabel : '' }}{{ $ctrl.disableWhen ? ' disable when child.' + $ctrl.disableWhen : '' }} for child in $ctrl.children"
        ng-model="$ctrl.container"
        before-select="$ctrl.beforeSelect(item.model)"
        before-deselect="$ctrl.beforeDeselect(item.model)"></tx-select>
    <div class="input-group-btn" ng-show="$ctrl.reorder">
        <button type="button" class="btn btn-default" ng-click="$ctrl.toggleList()" uib-tooltip="{{ 'common.action.ordering' | translate }}"><i class="fa fa-random"></i></button>
    </div>
</div>
<table class="table table-condensed" ng-show="$ctrl.showList">
    <tbody ng-sortable="$ctrl.sortable">
        <tr ng-repeat="child in $ctrl.rawContainer" ng-class="$ctrl.isPinned(child)">
            <td class="shrink">{{ $index + 1 }}</td>
            <td class="expand">{{ child[ $ctrl.label || 'name' ] }}</td>
            <td ng-if="$ctrl.childActions" class="shrink">
                <div class="btn-group">
                    <button type="button"
                            ng-repeat="action in $ctrl.childActions"
                            ng-class="action.class || 'btn btn-xs btn-default'"
                            uib-tooltip="{{ action.tooltip }}"
                            ng-click="action.ngClick(child)"
                            ng-hide="action.ngHide(child)">
                        <i ng-class="action.icon || 'fa fa-pencil-square-o'"></i>
                    </button>
                </div>
            </td>
        </tr>
    </tbody>
</table>`
    })
    .component('metaDataSelector', {
        bindings: {
            crudCtrl: '<',
            metaData: '=',
            btnClass: '@',
            sublabel: '@',
            groupBy: '@',
            disableWhen: '@',
            beforeReorder: '&',
            afterReorder: '&',
            pinned: '&'
        },
        controller: function($uibModal, MetaData, $translate, Role) {
            const $ctrl = this;

            $ctrl.newMeta = function() {
                var meta = MetaData.new({
                    type: 'string',
                    public: false,
                    applies_to: 'person'
                });
                $uibModal.open({
                    controller: ModalCtrl,
                    controllerAs: '$ctrl',
                    template: $ctrl.modalTemplate,
                    resolve: {
                        meta: () => meta,
                        metas: () => $ctrl.metaData
                    },
                    size: 'lg'
                }).result.then(() => {
                    $ctrl.metaData.push(meta);
                    return $ctrl.crudCtrl.attachMetaData(meta);
                });
            };

            $ctrl.editMeta = function(meta) {
                $uibModal.open({
                    controller: ModalCtrl,
                    controllerAs: '$ctrl',
                    template: $ctrl.modalTemplate,
                    resolve: {
                        meta: () => meta,
                        metas: () => $ctrl.metaData
                    },
                    size: 'lg'
                }).result.then(null, error => {
                    if(error === 'backdrop click' || error === 'cancel')
                        _.assign(meta, meta.$savedState);
                });
            };

            $ctrl.actions = [{
                ngClick: $ctrl.editMeta,
                ngHide: function(meta) {
                    return meta.public && !Role.isAdmin;
                },
                tooltip: $translate.instant('common.action.edit')
            }];

            $ctrl.modalTemplate = `<div class="modal-header">
    <h3 class="modal-title">
        <translate ng-hide="meta.guid">{{ 'models.models.metaData_crud.new' | translate:{count: 1} | lowercase }}</translate>
        <translate ng-show="meta.guid">{{ 'models.models.metaData_crud.edit' | translate:{count: 1} | lowercase }}</translate>
    </h3>
</div>
<div class="modal-body">
    <form ng-submit="$ctrl.save()" class="form-horizontal">
        <form-el label="models.metaData.name" errors="$ctrl.meta.$errors.name">
            <input type="text" class="form-control" ng-model="$ctrl.meta.name">
        </form-el>
        <form-el label="models.metaData.description" errors="$ctrl.meta.$errors.description">
            <input type="text" class="form-control" ng-model="$ctrl.meta.shop_description" name="description" />
        </form-el>
        <form-el label="models.metaData.type" errors="$ctrl.meta.$errors.type">
            <select name="type" class="form-control" ng-model="$ctrl.meta.type" ng-change="$ctrl.prefillExtra($ctrl.meta.type)">
                <option value="integer" translate="models.metaData.types.integer"></option>
                <option value="date" translate="models.metaData.types.date"></option>
                <option value="string" translate="models.metaData.types.string"></option>
                <option value="boolean" translate="models.metaData.types.boolean"></option>
                <option value="enum" translate="models.metaData.types.enum"></option>
                <option value="enumOther" translate="models.metaData.types.enumOther"></option>
                <option value="values" translate="models.metaData.types.values"></option>
                <option ng-if="$ctrl.isAdmin" value="seatSelect" translate="models.metaData.types.seatSelect"></option>
            </select>
        </form-el>
        <form-el label="models.metaData.extra" errors="$ctrl.meta.$errors.extra">
            <metadata-extra model="$ctrl.meta" other-fields="$ctrl.metas"></metadata-extra>
        </form-el>
        <form-el label="models.metaData.applies_to" errors="$ctrl.meta.$errors.applies_to" class="hidden">
            <select name="applies_to" class="form-control" ng-model="$ctrl.meta.applies_to">
                <option value="person" translate="models.metaData.applications.person"></option>
                <option value="product" translate="models.metaData.applications.product"></option>
            </select>
        </form-el>
        <form-el label="models.metaData.default" errors="$ctrl.meta.$errors.default">
            <input type="text" class="form-control" ng-model="$ctrl.meta.default">
        </form-el>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" ng-click="$dismiss('cancel')" translate>common.action.cancel</button>
    <button type="button" class="btn btn-info" ng-click="$ctrl.save()" translate>common.action.save</button>
</div>`;

            function ModalCtrl(meta, metas, $q, $scope) {
                const $ctrl = this;
                this.isAdmin = Role.isAdmin;
                this.meta = meta;
                this.metas = metas;
                this.save = function() {
                    if(meta.$invalid)
                        return $q.reject();
                    return meta.$save().then(() => $scope.$close());
                };
                this.prefillExtra = function(type) {
                    switch(type) {
                        case 'values':
                            $ctrl.meta.extra = 'array|in:A,B,C';
                            break;
                        case 'enum':
                        case 'enumOther':
                            $ctrl.meta.extra = 'in:A,B,C';
                            break;
                        case 'boolean':
                            $ctrl.meta.extra = 'accepted';
                            break;
                        case 'integer':
                            $ctrl.meta.extra = 'numeric|min:0|max:10';
                            break;
                        case 'string':
                            $ctrl.meta.extra = 'alpha_num';
                            break;
                        case 'date':
                            $ctrl.meta.extra = 'date';
                            break;
                        case 'seatSelect':
                        default:
                            $ctrl.meta.extra = '';
                            break;
                    }
                    $scope.$broadcast('updateMetadataExtra');
                };
            }

        },
        template: `<child-selector crud-ctrl="$ctrl.crudCtrl"
    children="$ctrl.metaData"
    fn-suffix="MetaData"
    label="translatedName"
    btn-class="{{ $ctrl.btnClass || 'form-control' }}"
    sublabel="{{ $ctrl.sublabel }}"
    group-by="{{ $ctrl.groupBy }}"
    disable-when="{{ $ctrl.disableWhen }}"
    pinned="$ctrl.pinned({child: child})"
    before-reorder="$ctrl.beforeReorder({$event: $event})"
    after-reorder="$ctrl.afterReorder({$event: $event, error: error})"
    child-actions="$ctrl.actions"
    reorder></child-selector>
    `, //<a class="btn btn-link btn-xs pull-right" ng-click="$ctrl.newMeta()">{{ "models.models.metaData_crud.new" | translate:{count: 1} }}</a>
    }).name;


