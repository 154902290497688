import Demographics from './demographics/demographics';
import template from './sales.html';
import c3 from 'c3';
import c3TooltipCumulative from './c3-tooltip-cumulative.html';
import c3TooltipSingle from './c3-tooltip-single.html';
import {arrayPopValue, mergeKeyIntoValues} from '../helpers';
import {copyToClipboard} from '../../../dashboard';

export default angular.module('eventix.dashboard.home.event.sales', [Demographics])
    .component('eventHomeSales', {
        bindings: {
            event: '<',
            company: '<'
        },
        controller: EventHomeSalesController,
        templateUrl: template
    }).name;

function EventHomeSalesController(CSVExport, $timeout, $http, UIMessages, $q, store, $scope, Company, Role, $filter, $state, $templateCache, $translate) {
    // CONTROLLER
    const $ctrl = this;

    const TIME_UNIT = 'days';
    const API_STATISTICS_DATETIME_FORMAT = 'YYYY-MM-DD';
    const ATOM_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
    const API_STATISTICS_BUCKET_NAME = 'date_info';
    const LOCAL_STORAGE_FLAGS_KEY = 'homeEventSalesFlags';
    const LOCAL_STORAGE_FILTERS_KEY = 'homeEventSalesFilter';
    const CHART_PADDING = 1;
    const MODULES = {
        tickets: {
            null: 'eventix.dashboard.wizard.advanced.tickets',
            simple: 'eventix.dashboard.wizard.simple.tickets',
            advanced: 'eventix.dashboard.wizard.advanced.tickets'
        }
    };
    const flagHooks = {
        cumulative: () => {
            this.chart.updateData();
        },
        stacked: () => {
            this.chart.updateFlags();
        },
        zoom: () => {
            this.chart.render();
        }
    };
    const ticketDefaults = {
        downloads: {
            label: 'ticket_sales_data',
            fields: ['guid', 'name', 'min_price', 'status', 'pending_count', 'sold_count', 'available_stock', 'turnover']
        },
        examples: {
            'example-ticket-friday': {
                guid: 'example-ticket-friday',
                name: 'Example: Friday Ticket',
                min_price: 131072,
                status: 'available',
                pending_count: 12,
                sold_count: 10,
                available_stock: 0,
                turnover: 11000,
                example: true
            },
            'example-ticket-saturday': {
                guid: 'example-ticket-saturday',
                name: 'Example: Saturday Ticket',
                min_price: 1320,
                status: 'sold_out',
                pending_count: 12,
                sold_count: 24,
                available_stock: 36,
                turnover: 26400,
                example: true
            },
            'example-ticket-weekend': {
                guid: 'example-ticket-weekend',
                name: 'Example: Weekend Ticket',
                min_price: 7850,
                status: 'available',
                pending_count: 0,
                sold_count: 6,
                available_stock: 0,
                turnover: 6600,
                example: true
            }
        },
        options: {
            sales: [
                {id: 'free', name: $translate.instant('common.options.sales.free')},
                {id: 'paid', name: $translate.instant('common.options.sales.paid')},
                {id: 'free_and_paid', name: $translate.instant('common.options.sales.free_and_paid')}
            ]
        },
        dataPointStub: {amount: 0, turnover: 0}
    };
    const formatCurrency = $filter('formatCurrency', $ctrl.currency, $ctrl.currency);
    const c3TooltipFnSingle = _.template($templateCache.get(c3TooltipSingle), {imports: {_: _, moment: moment}});
    const c3TooltipFnCumulative = _.template($templateCache.get(c3TooltipCumulative), {
        imports: {
            _: _,
            moment: moment
        }
    });
    $ctrl.zoomDefaultLabels = {
        ALL: 'models.event.home.chart.zoom.allTime',
        LAST_90_DAYS: 'models.event.home.chart.zoom.last90days',
        LAST_30_DAYS: 'models.event.home.chart.zoom.last30days',
        LAST_15_DAYS: 'models.event.home.chart.zoom.last15days'
    };
    $ctrl.zoomDefaults = getZoomDefaults();
    $ctrl.zoomAtDefault = null;
    $ctrl.zoomManual = false;

    $ctrl.optionsSelect = ticketDefaults.options;

    // FLAGS
    $ctrl.busy = 0;
    $ctrl.flags = {cumulative: false, stacked: true, zoom: false, preferences: false};
    $ctrl.options = {sales: 'free_and_paid'};
    $ctrl.filter = {accepted: [], blacklist: []};
    $ctrl.filterDelay = 0;

    //
    // DATA CONTAINERS
    //

    $ctrl.dataRaw = null;
    $ctrl.data = {};
    $ctrl.summaryData = {
        left: {
            title: 'models.event.home.sales.summaryData.leftTitle',
            amount: '',
            limit: '',
            today: {title: 'models.event.home.sales.summaryData.leftSubTitle', amount: '?'}
        },
        right: {
            title: 'models.event.home.sales.summaryData.rightTitle',
            amount: '',
            limit: '',
            today: {title: 'models.event.home.sales.summaryData.rightSubTitle', amount: '?'}
        }
    };
    $ctrl.chart = {};
    $ctrl.table = {};
    $ctrl.icons = {
        zoom: () => $ctrl.flags.zoom ? 'fa-search-minus' : 'fa-search-plus'
    };

    $ctrl.hasTickets = hasTickets;
    $ctrl.reload = getData;
    $ctrl.setFlag = setFlag;
    $ctrl.toggleFlag = toggleFlag;
    $ctrl.openWizard = openEventWizard;
    $ctrl.download = download;
    $ctrl.filterIsActive = filterIsActive;
    $ctrl.copyToClipboard = value => UIMessages.push(copyToClipboard(value));
    $ctrl.sumBy = _.sumBy;
    $ctrl.zoomChart = _.throttle(zoomValue => $ctrl.chart.zoom(zoomValue), 1000);

    //
    // INITIALIZATION
    //
    $ctrl.$onInit = function() {
        setUp();

        resetData();

        getFlagsFromStorage();
        getFilterFromStorage();

        getData();
    };

    function setUp() {
        $ctrl.currency = $ctrl.event.currency || $ctrl.company.currency;

        $ctrl.isAdmin = Role.isAuthorizedAs('Admin');
        $ctrl.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');

        $ctrl.chart = new Chart('#chart');
        $ctrl.table = new Table();

        initWatchers();
    }

    function initWatchers() {
        $scope.$watch('$ctrl.options.sales', watchSalesOption);

        $scope.$watch('$ctrl.filter.accepted', watchFilter, true);
    }

    //
    // DATA METHODS
    //

    /**
     * Empty the data containers
     *
     * @return {Promise} Resolves when the data containers have been emptied or rejects when busy
     */
    function resetData() {
        if (isBusy())
            return errorRejection('System is busy, cannot reset data');

        busy();

        $ctrl.chart.reset();
        $ctrl.table.reset();
        $ctrl.data = {};

        $ctrl.dataRaw = null;

        let eventLimit = 0;
        let eventDates = _.get($ctrl.event, 'dates', []);

        if ($ctrl.event.gui_mode === 'simple' && _.size(eventDates) === 1)
            eventLimit = _.get(_.first(eventDates), 'capacity', 0);

        if (eventLimit)
            _.set($ctrl.summaryData, 'left.limit', `/ ${eventLimit}`);

        return done(true);
    }

    /**
     * Retrieve data from the API.
     *
     * @return {Promise} Resolves when data retrieved or rejects on failure
     */
    function getData() {
        if (isBusy())
            return errorRejection('System is busy, cannot retrieve data');

        busy();

        return $http.post(`/event/${$ctrl.event.guid}/salesstats`, $ctrl.options).then(response => {
            $ctrl.dataRaw = response.data;

            done(true);

            return updateData();
        }, error => {
            return errorRejection(error);
        });
    }

    /**
     * Update all views from data
     *
     * @return {Promise} Resolves when data for all views is updated or rejects on failure
     */
    function updateData() {
        if (isBusy())
            return errorRejection('System is busy, cannot update data');

        busy();

        let dataSets = _.cloneDeep(_.get($ctrl.dataRaw, 'tickets', {}));

        let firstDates = [moment($ctrl.event.start, ATOM_DATETIME_FORMAT)];
        let lastDates = [moment($ctrl.event.end, ATOM_DATETIME_FORMAT)];

        _.forEach(dataSets, dataSet => {
            let data = _.get(dataSet, API_STATISTICS_BUCKET_NAME);


            mergeKeyIntoValues(data, 'date');

            if (_.size(data) > 0) {
                let firstDate = null, lastDate = null;

                _.forEach(data, (ignore, date) => {
                    firstDate = date;

                    return false;
                });

                _.forEach(data, (ignore, date) => {
                    lastDate = date;
                });

                dataSet.firstDate = moment(firstDate, API_STATISTICS_DATETIME_FORMAT);
                dataSet.lastDate = moment(lastDate, API_STATISTICS_DATETIME_FORMAT);

                firstDates.push(dataSet.firstDate);
                lastDates.push(dataSet.lastDate);
            } else {
                dataSet.firstDate = null;
                dataSet.lastDate = null;
            }

        });

        let minDate = moment.min(firstDates);
        let maxDate = moment.max(lastDates);

        _.forEach(dataSets, (dataSet, ticketId) => {
            if (_.isNil(dataSet.firstDate) || _.isNil(dataSet.lastDate))
                return;

            let newData = {};

            let diff = maxDate.diff(minDate, TIME_UNIT) + CHART_PADDING;
            let currentDate = minDate.clone();

            let previousWasStub = false;
            let stub = _.cloneDeep(ticketDefaults.dataPointStub);

            for (let i = 0; i <= diff; i++, currentDate.add(1, TIME_UNIT)) {
                let currentDateFormatted = currentDate.format(API_STATISTICS_DATETIME_FORMAT);

                let dataPoint = _.get(dataSet, `${API_STATISTICS_BUCKET_NAME}.${currentDateFormatted}`);

                if (!dataPoint && !previousWasStub)
                    stub = _.cloneDeep(ticketDefaults.dataPointStub);

                if (!dataPoint)
                    dataPoint = stub;

                _.set(newData, currentDateFormatted, dataPoint);
            }

            _.set(dataSets, `${ticketId}.${API_STATISTICS_BUCKET_NAME}`, newData);
        });

        $ctrl.data = dataSets;

        updateFilter();

        updateSummary();

        let promise = $q.defer();

        // Wrap in a timeout to force the rendering into its own digest cycle.
        $timeout(() => {
            $ctrl.zoomDefaults = getZoomDefaults(minDate, maxDate);
            $ctrl.table.updateData(dataSets);
            $ctrl.chart.updateData(dataSets, {minDate: minDate, maxDate: maxDate});

            promise.resolve(done(true));
        });

        return promise.promise;
    }

    /**
     * Update the accepted filter from the blacklist
     */
    function updateFilter() {
        busy();

        let blacklist = _.get($ctrl.filter, 'blacklist', []);
        let accepted = _.get($ctrl.filter, 'accepted', []);

        _.forEach($ctrl.data, (dataSet, ticketId) => {
            arrayPopValue(accepted, ticketId);

            if (_.indexOf(blacklist, ticketId) === -1)
                accepted.push(ticketId);
        });

        done(true);
    }

    /**
     * Update the summary data
     */
    function updateSummary() {
        let data = _.values($ctrl.data);
        let today = moment().format(API_STATISTICS_DATETIME_FORMAT);

        let soldCount = _.sumBy(data, 'sold_count');
        let turnover = _.sumBy(data, 'turnover');
        let todaySoldCount = _.sum(_.map(data, (ticket) => _.get(_.get(ticket[API_STATISTICS_BUCKET_NAME], today, {}), 'amount', 0)));
        let todayTurnover = _.sum(_.map(data, (ticket) => _.get(_.get(ticket[API_STATISTICS_BUCKET_NAME], today, {}), 'turnover', 0)));

        $ctrl.summaryData.left.amount = '' + soldCount;
        $ctrl.summaryData.right.amount = '' + $filter('formatCurrency')(turnover, $ctrl.currency, $ctrl.currency);

        $ctrl.summaryData.left.today.amount = '' + todaySoldCount;
        $ctrl.summaryData.right.today.amount = $filter('formatCurrency')(todayTurnover, $ctrl.currency, $ctrl.currency);
    }

    //
    // PROTOTYPES
    //

    function Chart(element) {
        return {
            _data: {},
            _settings: {},
            chart: null,
            element: element,
            columns: [],
            groups: [],
            gridLines: [],
            type: 'area',

            render: function() {
                busy().catch(console.error);

                this.destroy.call(this);

                this.chart = c3.generate({
                    bindto: this.element,
                    data: {
                        x: 'x',
                        columns: this.columns,
                        groups: this.groups,
                        type: this.type
                    },
                    axis: {x: {type: 'timeseries', tick: {format: '%Y-%m-%d'}}},
                    zoom: {enabled: true, rescale: true, onzoom: onZoom},
                    subchart: {show: true, onbrush: onZoom},
                    point: {show: false},
                    size: {width: $(this.element).width() * 0.95, height: 400},
                    legend: {show: false},
                    grid: {x: {lines: this.gridLines}},
                    bar: {width: {ratio: 2}},
                    tooltip: {
                        contents: function(rows, defaultTitleFormat, defaultValueFormat, color) {
                            let sourceRows = _.filter(rows);
                            rows = [];
                            _.forEach(sourceRows, row => {
                                if (row.value <= 0)
                                    return;

                                row.date = moment(row.x).format(API_STATISTICS_DATETIME_FORMAT);

                                let rowData = $ctrl.chart._data[row.id];

                                row.source = rowData[API_STATISTICS_BUCKET_NAME][row.date];
                                row.name = rowData.name;
                                row.currency = $ctrl.currency;

                                rows.push(row);
                            });

                            const x = _.get(rows, '0.x', '');

                            let tooltipObject = {rows, x, $$: this, color, formatCurrency};

                            if ($ctrl.flags.cumulative)
                                return c3TooltipFnCumulative(tooltipObject);

                            return c3TooltipFnSingle(tooltipObject);
                        }
                    }
                });

                this.zoom();

                return done(true);
            },
            updateData: function(data = null, settings = null) {
                busy().catch(console.error);

                if (!data)
                    data = this._data;

                if (!settings)
                    settings = this._settings;

                this.destroy.call(this);

                data = processCumulative(data);

                this._data = data;
                this._settings = settings;

                this.columns = getColumns(data, settings);
                this.gridLines = getGridLines();

                // Also (re)renders the chart
                this.updateFlags.call(this);

                return done(true);
            },
            updateFlags: function() {
                if (!_.size(this.columns))
                    return errorRejection('Cannot update filters without data');

                busy().catch(console.error);

                this.destroy.call(this);

                this.groups = getGroups(this._data);
                this.type = getType();

                // Force execution into its own digest cycle
                this.render.call(this);

                return done(true);
            },
            zoom: function(zoomSelection = $ctrl.zoomDefaultLabels.LAST_30_DAYS) {
                zoomSelection = _.get($ctrl.zoomDefaults, zoomSelection, zoomSelection);

                if (_.isObject(zoomSelection) && _.has(zoomSelection, 'start') && _.has(zoomSelection, 'end'))
                    zoomSelection = [_.get(zoomSelection, 'start'), _.get(zoomSelection, 'end')];

                if (!_.isArray(zoomSelection) || _.size(zoomSelection) !== 2)
                    return errorRejection('Zoom selection invalid');

                if (moment.isMoment(zoomSelection[0]))
                    zoomSelection[0] = zoomSelection[0].format(API_STATISTICS_DATETIME_FORMAT);

                if (moment.isMoment(zoomSelection[1]))
                    zoomSelection[1] = zoomSelection[1].format(API_STATISTICS_DATETIME_FORMAT);

                return $q.resolve(this.chart.zoom(zoomSelection));
            },
            destroy: function() {
                if (!this.chart)
                    return $q.resolve();

                busy().catch(console.error);

                this.chart.destroy();
                this.chart = null;

                return done(true);
            },
            reset: function() {
                busy().catch(console.error);

                this.destroy.call(this);

                this._data = {};
                this.columns = [];
                this.groups = [];
                this.gridLines = [];
                this.type = 'area';

                return done(true);
            }
        };
    }

    function Table() {
        return {
            _data: {},
            data: [],

            updateData: function(data = null) {
                busy().catch(console.error);

                if (!data)
                    data = this._data;

                this.destroy.call(this);

                this._data = data;

                if (!hasTickets())
                    data = _.get(ticketDefaults, 'examples', {});

                let accepted = _.get($ctrl.filter, 'accepted', []);

                _.each(data, (instance) => {
                    let isAccepted = (_.indexOf(accepted, instance.guid) !== -1) || _.get(instance, 'example', false);

                    if (isAccepted)
                        this.data.push(instance);

                });

                return done(true);
            },
            destroy: function() {
                busy().catch(console.error);

                this.data = [];

                return done(true);
            },
            reset: function() {
                busy().catch(console.error);

                this.destroy.call(this);

                this._data = {};

                return done(true);
            }
        };
    }

    //
    // CHART HELPERS
    //

    /**
     * Mutate dataset to cumulative
     *
     * @param {Array} dataSets Original Data
     * @return {Array} Cumulative data
     */
    function processCumulative(dataSets) {
        _.forEach(dataSets, dataSet => {
            _.reduce(dataSet[API_STATISTICS_BUCKET_NAME], (carry, dataPoint, index) => {
                carry.amount += dataPoint.amount;
                carry.turnover += dataPoint.turnover;

                dataSet[API_STATISTICS_BUCKET_NAME][index] = _.cloneDeep(dataSet[API_STATISTICS_BUCKET_NAME][index]);
                dataSet[API_STATISTICS_BUCKET_NAME][index].cum_amount = carry.amount;
                dataSet[API_STATISTICS_BUCKET_NAME][index].cum_turnover = carry.turnover;

                return carry;
            }, {amount: 0, turnover: 0});
        });

        return dataSets;
    }

    /**
     * Get chart columns
     *
     * @param {Object} data Chart data
     * @param {Object} settings Chart settings
     * @return {Array} Columns
     */
    function getColumns(data, settings) {
        busy().catch(console.error);

        let columns = [getXAxisColumn(settings)];

        _.forEach(data, (dataSet, ticketId) => {
            if (_.indexOf($ctrl.filter.accepted, ticketId) === -1 || !_.size(_.get(dataSet, API_STATISTICS_BUCKET_NAME, {})))
                return;

            let key = 'amount';

            if ($ctrl.flags.cumulative)
                key = 'cum_amount';

            let flatDataSet = _.map(_.get(dataSet, API_STATISTICS_BUCKET_NAME, {}), key);

            columns.push(_.concat(ticketId, flatDataSet));
        });

        done(true).catch(console.error);

        return columns;
    }

    /**
     * Get chart X column
     *
     * @param {Object} settings Chart settings
     * @return {string[]} X column
     */
    function getXAxisColumn(settings) {
        busy().catch(console.error);

        let xData = ['x'];

        let diff = settings.maxDate.diff(settings.minDate, TIME_UNIT);
        let currentDate = settings.minDate.clone();

        for (let i = 0; i <= diff; i++, currentDate.add(1, TIME_UNIT))
            xData.push(currentDate.format(API_STATISTICS_DATETIME_FORMAT));

        done(true).catch(console.error);

        return xData;
    }

    /**
     * Get grid lines
     *
     * @return {Array} Grid lines
     */
    function getGridLines() {
        busy().catch(console.error);

        let today = moment();
        let eventStart = moment($ctrl.event.start, ATOM_DATETIME_FORMAT);
        let eventEnd = moment($ctrl.event.end, ATOM_DATETIME_FORMAT);
        let eventDuration = eventEnd.diff(eventStart, TIME_UNIT);

        let gridLines = [];

        if (eventEnd.isValid() && eventDuration === 0)
            gridLines.push({text: 'Event', value: eventEnd.format(API_STATISTICS_DATETIME_FORMAT)});
        else if (eventStart.isValid() && eventEnd.isValid()) {
            gridLines.push({text: 'Event Start', value: eventStart.format(API_STATISTICS_DATETIME_FORMAT)});
            gridLines.push({text: 'Event End', value: eventEnd.format(API_STATISTICS_DATETIME_FORMAT)});
        }

        if ((eventEnd.isValid() && eventEnd.diff(today) > 0) || (eventStart.isValid() && eventStart.diff(today) > 0) || (!eventEnd.isValid() && !eventStart.isValid()))
            gridLines.push({text: 'Today', value: today.format(API_STATISTICS_DATETIME_FORMAT)});

        done(true).catch(console.error);

        return gridLines;
    }

    /**
     * Get chart groups
     *
     * @param {Object} data Chart data
     * @return {Array} Groups
     */
    function getGroups(data) {
        busy().catch(console.error);

        let groups = [];

        let ticketIdArray = _.map(_.reject(data, (set) => {
            return (_.indexOf(_.get($ctrl.filter, 'accepted', []), _.get(set, 'guid')) === -1);
        }), 'guid');

        if ($ctrl.flags.stacked)
            groups = [ticketIdArray];
        else
            groups = [_.map(ticketIdArray, ticketId => [ticketId])];

        done(true).catch(console.error);

        return groups;
    }

    /**
     * Get chart type
     *
     * @return {string} Type for chart
     */
    function getType() {
        busy().catch(console.error);

        let type = $ctrl.flags.cumulative ? 'area' : 'bar';

        done(true).catch(console.error);

        return type;
    }

    //
    // WATCHERS
    //

    /**
     * Watches sales options, when they change, data is reset and retrieved.
     *
     * @param {String} newValue New value
     * @param {String} oldValue Old value
     */
    function watchSalesOption(newValue, oldValue) {
        if (newValue !== oldValue) {
            resetData().catch(console.error);

            getData().catch(console.error);
        }
    }

    /**
     * Watches to update tables and save filters to local storage when filter is changed
     *
     * @return {Promise<number>} Resolves with busy count
     */
    function watchFilter() {
        $ctrl.filterDelay++;

        busy().catch(console.error);

        let accepted = _.get($ctrl.filter, 'accepted', []);
        let blacklist = _.get($ctrl.filter, 'blacklist', []);

        let changes = _.reduce(_.keys($ctrl.data), (carry, ticketId) => {
            let isAccepted = (_.indexOf(accepted, ticketId) !== -1);
            let isBlacklisted = (_.indexOf(blacklist, ticketId) !== -1);

            if (!isAccepted && !isBlacklisted) {
                $ctrl.filter.blacklist.push(ticketId);

                return carry + 1;
            } else if (isAccepted && isBlacklisted) {
                arrayPopValue(blacklist, ticketId);

                return carry + 1;
            }

            return carry;
        }, 0);

        if (changes > 0) {
            saveFilterToStorage();

            let promise = $q.defer();

            $timeout(() => {
                $ctrl.filterDelay--;

                // Only do action when this is the last change since the start of the timeout
                if (!$ctrl.filterDelay) {
                    $ctrl.table.updateData();
                    $ctrl.chart.updateData();
                }

                promise.resolve(done(true));
            }, 500);

            return promise;
        }

        $ctrl.filterDelay--;

        return done(true);
    }

    //
    // HELPERS
    //

    /**
     * Returns the amount of tickets currently in the data
     *
     * @returns {number} Returns amount of tickets
     */
    function hasTickets() {
        return _.size($ctrl.data);
    }

    /**
     * Download a CSV of the data on a specific section
     *
     * @return {Promise<String>} Returns a rejection or the csv string
     */
    function download() {
        let settings = _.get(ticketDefaults, 'downloads');
        let includePrice = true;

        for (const ticketId in $ctrl.data) {
            if (isNaN($ctrl.data[ticketId].min_price) || $ctrl.data[ticketId].min_price == null) {
                includePrice = false;
            }
        }

        if (_.isNil(settings))
            return errorRejection('Download settings not found');

        let name = moment().format(ATOM_DATETIME_FORMAT) + '_' + _.get(settings, 'label', 'data');
        let fields = _.get(settings, 'fields');

        if (_.isNil(fields))
            return errorRejection('No fields marked for download.');

        if (!includePrice) {
            fields = fields.filter(item => item !== "min_price");
        }

        return $q.resolve(CSVExport.convert(_.map(_.cloneDeep(_.values($ctrl.data)), (row) => {
            row.turnover /= 100;
            if (row.min_price == null) {
                row.min_price = "";
            } else {
                row.min_price /= 100;
            }
            return row;
        }), fields, name));
    }

    /**
     * Retrieve the selection boundaries for default zoom types
     *
     * @param {moment.Moment?} startSales Start of the sales
     * @param {moment.Moment?} endEvent End of the event
     * @return {Object} collection of default selection boundaries
     */
    function getZoomDefaults(startSales = null, endEvent = null) {
        startSales = moment.isMoment(startSales) && startSales.isValid() ? startSales : moment();
        endEvent = moment.isMoment(endEvent) && endEvent.isValid() ? endEvent : moment();

        let defaults = {};

        _.set(defaults, [$ctrl.zoomDefaultLabels.ALL], {
            start: startSales.clone().subtract(1, 'day'),
            end: endEvent
        });

        _.set(defaults, [$ctrl.zoomDefaultLabels.LAST_15_DAYS], {
            start: moment.max(startSales, moment.min(moment(), endEvent).clone().subtract(15, 'days')),
            end: moment.min(moment(), endEvent)
        });

        _.set(defaults, [$ctrl.zoomDefaultLabels.LAST_30_DAYS], {
            start: moment.max(startSales, moment.min(moment(), endEvent).clone().subtract(30, 'days')),
            end: moment.min(moment(), endEvent)
        });

        _.set(defaults, [$ctrl.zoomDefaultLabels.LAST_90_DAYS], {
            start: moment.max(startSales, moment.min(moment(), endEvent).clone().subtract(90, 'days')),
            end: moment.min(moment(), endEvent)
        });

        return defaults;
    }

    /**
     * Chart onZoom callback
     * Determines if the current zoom level is equal to one of the default zoom levels
     *
     * @param {Array} domain Domain of zoom selection
     * @return {String|null} Default label or NULL
     */
    function onZoom(domain) {
        return $ctrl.zoomAtDefault = _.reduce($ctrl.zoomDefaults, (carry, defaultDomain, key) => {
            if (!moment.isMoment(defaultDomain.start) || !defaultDomain.start.isSame(domain[0], 'day')) return carry;
            if (!moment.isMoment(defaultDomain.end) || !defaultDomain.end.isSame(domain[1], 'day')) return carry;

            return key;
        }, null);
    }

    /**
     * Open the event wizard
     *
     * @param {String} module Module to open wizard in
     * @param {BaseClass?} row Optionally open wizard and directly edit item
     * @return {*} Return
     */
    function openEventWizard(module, row = null) {
        let stateName;

        module = _.get(MODULES, module, null);

        if (_.isNil(module))
            return UIMessages.push('common.error.unknownEventModule');

        stateName = _.get(module, $ctrl.event.gui_mode, null);

        if (_.isNil(stateName))
            return UIMessages.push('common.error.unknownEventMode');

        let data = {
            guid: $ctrl.event.guid
        };

        if (row !== null)
            data.editing = row.guid;

        $state.go(stateName, data);

        return true;
    }

    //
    // FLAGS AND FILTERS
    //

    /**
     * Change the value of a flag
     *
     * @param {String} flag Name of the flag
     * @param {Boolean} value New state of the flag
     */
    function setFlag(flag, value) {
        _.set($ctrl.flags, flag, value);

        saveFlagsToStorage();

        let hook = _.get(flagHooks, flag, () => {
        });

        // Force execution to its own digest cycle
        $timeout(hook);
    }

    /**
     * Change the value of a flag to the opposite value
     *
     * @param {String} flag Name of the flag
     */
    function toggleFlag(flag) {
        setFlag(flag, !_.get($ctrl.flags, flag, false));
    }

    /**
     * Retrieve the flags from storage
     */
    function getFlagsFromStorage() {
        let storageFlags = store.get(`${LOCAL_STORAGE_FLAGS_KEY}.${$ctrl.event.guid}`);

        if (_.isNil(storageFlags))
            storageFlags = store.get(LOCAL_STORAGE_FLAGS_KEY);

        if (_.isNil(storageFlags))
            return;

        _.each(_.keys($ctrl.flags), function(flag) {
            if (storageFlags.hasOwnProperty(flag))
                $ctrl.flags[flag] = storageFlags[flag];
        });
    }

    /**
     * Save the flags to storage
     */
    function saveFlagsToStorage() {
        // Save default flags (for new events)
        store.set(LOCAL_STORAGE_FLAGS_KEY, $ctrl.flags);

        // Save flags for event
        store.set(`${LOCAL_STORAGE_FLAGS_KEY}.${$ctrl.event.guid}`, $ctrl.flags);
    }

    /**
     * Retrieve all filters from storage
     */
    function getFilterFromStorage() {
        let storageFilter = store.get(`${LOCAL_STORAGE_FILTERS_KEY}.${$ctrl.event.guid}`);

        if (_.isNil(storageFilter))
            return;

        $ctrl.filter = storageFilter;
    }

    /**
     * Save the filters to storage for this event
     */
    function saveFilterToStorage() {
        store.set(`${LOCAL_STORAGE_FILTERS_KEY}.${$ctrl.event.guid}`, $ctrl.filter);
    }

    /**
     * Test if the filter is active
     *
     * @returns {number} Returns the amount of blacklisted items in the filter
     */
    function filterIsActive() {
        return _.size(_.get($ctrl.filter, 'blacklist', []));
    }

    //
    // SYSTEM STATE OPERATIONS
    //

    /**
     * Increment the busy count
     *
     * @return {Promise<number>} Resolves with current busy count
     */
    function busy() {
        return $q.resolve($ctrl.busy++);
    }

    /**
     * Decrement busy count
     *
     * @param {Boolean} immediately Immediately resolve
     * @return {Promise<number>} Resolves with current busy count
     */
    function done(immediately = false) {
        if (immediately)
            return $q.resolve($ctrl.busy--);

        let promise = $q.defer();

        $timeout(() => promise.resolve($ctrl.busy--), 500);

        return promise.promise;
    }

    /**
     * Determine if system is busy
     *
     * @return {boolean} Returns whether or not the current busy count is higher than zero
     */
    function isBusy() {
        return ($ctrl.busy > 0);
    }

    //
    // MISC OPERATIONS
    //

    /**
     * Error rejector service
     *
     * @todo Replace with global service. Right now in unmerged branch
     *
     * @param {String} errorMessage Message to display and send to console
     * @return {Promise<String>} Rejects with the error message
     */
    function errorRejection(errorMessage = null) {
        errorMessage = errorMessage || 'Something went wrong, please try again later.';

        // TODO ERROR LOGGING AND VIEW
        console.error('SALES: ', errorMessage);

        UIMessages.push({
            type: 'danger',
            message: errorMessage
        });

        return $q.reject(errorMessage);
    }
}
