import templateUrl from './pendingPayouts.html';
import './pendingPayouts.less';

export default angular.module('eventix.dashboard.admin.payouts.pending', [])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.admin.payouts.pending', {
            url: '/payouts/pending',
            views: {
                'dashboard@eventix.dashboard': {
                    component: 'pendingPayouts'
                }
            },
            data: {
                requiredRoles: ['Admin'],
                crumb: 'Pending Payouts'
            }
        });
    })
    .component('pendingPayouts', {
        controller: PendingPayoutsController,
        templateUrl: templateUrl
    }).name;

function PendingPayoutsController($http, ErrorRejector) {
    const $ctrl = this;

    $ctrl.blockedStatii = ['pending', 'blocked'];
    $ctrl.totalRows = 0;

    $ctrl.loadData = loadData;
    $ctrl.postpone = postpone;

    function loadData(offset, limit, sorting, filters) {
        let query = {
            offset,
            limit
        };

        _.each(sorting, (direction, attribute) => {
            _.set(query, `sorting_${attribute}`, direction);
        });

        _.each(filters, (value, attribute) => {
            if (attribute === 'blocked')
                value = value === 'blocked' ? 1 : 0;

            _.set(query, `filter_${attribute}`, value);
        });

        return $http.get('/finance/payout/pending', {params: query})
            .then(response => {
                $ctrl.totalRows = response.data.total;

                return response.data.data;
            })
            .catch(ErrorRejector.handle);
    }

    function postpone(payout) {
        return $http.delete(`/finance/payout/${payout.guid}`)
            .then(response => {
                if (response.status === 204)
                    payout.blocked = true;

                return response;
            })
            .catch(ErrorRejector.handle);
    }
}