import template from './ordersTickets.html';
import c3 from 'c3';
import c3styling from 'c3/c3.css';
import c3Tooltip from './c3-tooltip.html';

export default angular.module('eventix.dashboard.ordersTickets', [])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.ordersTickets', {
            url: '/ordersTickets',
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'OrdersTicketsController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/{
                metaData: function(MetaData) {
                    return MetaData.query();
                }
            }
        });
    })
    .controller('OrdersTicketsController', function($scope, $q, $state, events, isAdmin, Order,
        metaData, user, $filter, $timeout, ElasticLoader, $templateCache, Company) {
        const vm = this;
        vm.isAdmin = isAdmin;
        vm.currency = Company.getMe().currency;
        vm.stacked = true;
        vm.timeUnit = 'autoscale';
        vm.metaData = metaData;
        vm.totalRows = 0;
        vm.pagingSize = 10;
        vm.selectedTickets = [];
        vm.loadTickets = loadTickets;
        vm.pickTickets = tickets => { vm.tickets = _.keyBy(tickets, 'guid'); $q.resolve(vm.selectedTickets = tickets);};
        vm.totals = {};
        vm.statii = ['paid'];
        vm.range = {
            start: moment().subtract(6, 'months'),
            stop: moment().add(1,'day')
        };
        vm.tickets = {}; // empty init due to picker and not preloading everything..
        vm.exportColumns = {
            'order.guid': 'order_id',
            'guid': 'ticket_id',
            'created_at': 'created_at',
            'ticket.name': 'ticket_name',
            'coupon': 'coupon',
            'price': 'price',
            'ticket_number': 'barcode',
            'order.status': 'status',
            'order.firstName': 'first_name',
            'order.lastName': 'last_name',
            'order.email': 'email'
        };
        _.forEach(metaData, m => {
            var column = `meta_data[metadata_id=${m.guid}].value`;
            vm.exportColumns[column] = m.name;
        });

        // Refresh table if any of the configuring variables change
        $scope.$watch('vm.range', updateTable, true);
        $scope.$watch('vm.stacked', updateTable);
        $scope.$watch('vm.selectedTickets', updateTable, true);
        $scope.$watch('vm.timeUnit', updateTable, true);

        function updateTable(){
            $scope.$broadcast('table-view-refresh');
        }

        /**
         * Load and parse Tickets from ElasticSearch
         *
         * This function is used as input for TableView
         *
         * @param {Number} offset Page * pagesize
         * @param {Number} limit Pagesize
         * @param {Object} sorting Sorting definition
         * @param {Object} filters Filter definition
         * @returns {Promise<Order>} Resolves with array of orders
         */
        function loadTickets(offset, limit, sorting, filters) {
            let timeUnit = 'day';
            let start = vm.range.start.clone().startOf(timeUnit);
            let end = vm.range.stop.clone().startOf(timeUnit);

            if (vm.selectedTickets.length === 0)
                return $q.all([]);
            if (!vm.isAdmin)
                filters.status = 'paid';

            vm.loading = true;
            let query = {
                tickets: _.map(vm.selectedTickets, 'guid'),
                start: start,
                end: end,
                timeunit: timeUnit,
                offset: offset,
                limit: limit,
                sorting: sorting,
                filters: filters
            };
            return ElasticLoader.getOrderTickets(query).then(response => {
                // Graph data
                generateGraph(response.data.aggregations);

                // Table data
                let tickets = response.data.tickets.hits;
                if (response.data.tickets.total < 1)
                    return [];
                else if(!_.isArray(tickets))
                    tickets = [tickets];

                vm.totalRows = response.data.tickets.total;



                _.forEach(tickets, ticket => {
                    /* lookup tickets for every order */
                    ticket.firstName = ticket.order.firstName;
                    ticket.lastName = ticket.order.lastName;
                    ticket.email = ticket.order.email;
                    ticket.ticket = vm.tickets[ticket.ticket_id];
                });

                vm.totals = {
                    amount: _.reduce(vm.ordersTicketsOrig, (t, o) => t + o.price, 0),
                    tickets: vm.totalRows
                };

                return tickets;
            });
        }

        const c3TooltipFn = _.template($templateCache.get(c3Tooltip), { imports: { _: _, moment: moment }});
        /**
         * Generate a C3 graph from given statistics
         * @param {Object} stats Aggregations object from ElasticSearch query
         */
        function generateGraph(stats) {
            /*
             * Calculate graph range
             */
            let rangeDiff, timeUnit = (vm.timeUnit === 'autoscale') ? 'day' : vm.timeUnit;
            let elasticsearchDateFormat = (timeUnit === 'hour') ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
            rangeDiff = Math.abs(vm.range.start.diff(vm.range.stop, timeUnit));

            /*
             * Prepare X axis
             */
            var dateColumn = ['dates'];
            var plotDateColumn = ['dates'];
            let tmpDate = vm.range.start.clone().startOf(timeUnit);
            dateColumn.push(tmpDate.format(elasticsearchDateFormat));
            plotDateColumn.push(tmpDate.format(elasticsearchDateFormat));
            for(let i = 0; i < rangeDiff; i++){
                let tmpCount = tmpDate.add(1, timeUnit);
                dateColumn.push(tmpCount.format(elasticsearchDateFormat));
                plotDateColumn.push(tmpCount.format(elasticsearchDateFormat));
            }
            tmpDate.subtract(rangeDiff, timeUnit);

            /*
             * Prepare chart data
             */
            var columns = {};
            var revenue = {};
            var groups = [];
            let usedColumns = [];
            // Prepare a row for every event, filled with X amount of zeros,
            // where X is the amount of weeks/days/hours in range
            _.forEach(vm.tickets, t => {
                let columnName = t.name;
                let column = [columnName];
                let rev = [columnName];
                groups.push(columnName);
                column.push(0);
                rev.push(0);
                _.times(rangeDiff, i => column.push(0));
                _.times(rangeDiff, i => rev.push(0));
                columns[t.guid] = column;
                revenue[columnName] = rev;
            });

            let allTickets =  _.clone(vm.tickets);
            allTickets = _.keyBy(allTickets, 'guid');
            // Loop over every order
            let maxCount = 0;
            let dayCountData = _.get(stats, 'ticketCounts');
            _.forEach(dayCountData, (data, ticketGuid) => {
                if(ticketGuid === 'doc_count') return;
                let ticket = allTickets[ticketGuid];
                let dayCounts = _.get(data, 'statistics.buckets');
                if(dayCounts.length > 0){
                    dayCounts = _.keyBy(dayCounts, 'key_as_string');
                    _.forEach(dateColumn, (dateString, dateKey) => {
                        if(dateKey === 0) return; // skip event name
                        let countObject = _.get(dayCounts, dateString, {});
                        let count = _.get(countObject, 'doc_count', 0);
                        maxCount = count > maxCount ? count : maxCount;
                        columns[ticketGuid][dateKey] += count;
                        usedColumns.push(ticketGuid);
                    });
                }
            });

            let dayRevData = _.get(stats, 'ticketRevenues');
            _.forEach(dayRevData, (data, ticketGuid) => {
                if(ticketGuid === 'doc_count') return;
                let ticket = allTickets[ticketGuid];
                let columnName = [ticket.name];
                let dayRev = _.get(data, 'statistics.buckets');
                if(dayRev.length > 0){
                    dayRev = _.keyBy(dayRev, 'key_as_string');
                    _.forEach(dateColumn, (dateString, dateKey) => {
                        if(dateKey === 0) return; // skip event name
                        let countObject = _.get(dayRev, dateString, {});
                        let rev = _.get(countObject, 'statistics.value', 0);
                        revenue[columnName][dateKey] += rev;
                    });
                }
            });

            //Filter unset Events
            _.forEach(vm.tickets, t => {
                if( !(_.indexOf(usedColumns, t.guid) >= 0) )
                    delete columns[t.guid];
            });

            if(!vm.stacked)
                groups = [];

            //*
            //  * Initialize chart
            //  */
            columns = _.values(columns);
            columns.unshift(plotDateColumn);
            if(vm.chart)
                vm.chart.destroy();
            vm.chart = c3.generate({
                bindto: '#statsEvents',
                data: {
                    x: 'dates',
                    columns: columns,
                    type: 'bar',
                    groups: [
                        groups
                    ],
                },
                size: {
                    width: $('#statsEvents').width() * 0.95,
                    height: 250
                },
                legend: {
                    show: false
                },
                tooltip: {
                    format: {
                        title: function(x) {
                            switch(timeUnit) {
                                case 'month':
                                    return 'Month ' + moment(x).format('MM-YYYY');
                                case 'week':
                                    return 'Week ' + moment(x).format('w-YYYY');
                                case 'hour':
                                    return moment(x).format('DD-MM HH:mm');
                                case 'day':
                                default:
                                    return moment(x).format('DD-MM-YYYY');
                            }
                        }
                    },
                    contents: function(d, defaultTitleFormat, defaultValueFormat, color) {
                        var $$ = this,
                            config = $$.config,
                            nameFormat = config.tooltip_format_name || function(name) { return name; },
                            valueFormat = config.tooltip_format_value || defaultValueFormat,
                            dateFormat = elasticsearchDateFormat;

                        return c3TooltipFn({
                            rows: d,
                            $$: $$,
                            valueFormat: valueFormat,
                            revenue: revenue,
                            nameFormat: nameFormat,
                            dateFormat: dateFormat,
                            color: color,
                            dayCount: 0,
                            dayRev: 0,
                            formatCurrency: $filter('formatCurrency'),
                            currency: vm.currency
                        });
                    }
                },
                color: { pattern: ['#00BEF6','#0079BF','#00A650','#F46B49','#BB2321'] },
                axis: {
                    y: {
                        min: 0,
                        padding: {top: 0, bottom: 0},
                        tick: {
                            format: function(d) {
                                if(d % 1 === 0) return d;
                                return '';
                            }
                        },
                        max: maxCount
                    },
                    x: {
                        type: 'timeseries',
                        tick: {
                            culling: true,
                            format: function(x) {
                                switch(timeUnit) {
                                    case 'month':
                                        return 'Month ' + moment(x).format('MM-YYYY');
                                    case 'week':
                                        return 'Week ' + moment(x).format('w-YYYY');
                                    case 'hour':
                                        return moment(x).format('DD-MM HH:mm');
                                    case 'day':
                                    default:
                                        return moment(x).format('DD-MM-YYYY');
                                }
                            }
                        }
                    }
                }
            });
        }
    }).name;
