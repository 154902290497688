import templateUrl from './refund-settings.html';
import './refund-settings.less';
import AcceptTerm from './acceptTerm/acceptTerm';
import rudderstack from "../../../rudderstack/rudderstack";

export default angular.module('eventix.dashboard.events.refundSettings', [AcceptTerm])
    /**
     * @ngdoc service
     * @name eventix.dashboard.events.refundSettings
     * @description
     * A helper for opening an event's refund settings modal.
     */
    .factory('RefundSettings', function($uibModal, $q) {
        return {
            /**
             * @ngdoc method
             * @name eventix.dashboard.events.refundSettings#open
             * @methodOf eventix.dashboard.events.refundSettings
             * @description
             * Open a template modal to manage the settings of the refunding for an event.
             *
             * @param {Event} event The model of the event to manage the refunds for
             *
             * @return {Promise<Object>} The modal instance containing promises and callbacks. See node_modules/angular-ui-bootstrap/src/modal/modal.js
             */
            open: function(event) {

                return $q((resolve, reject) => {

                    resolve($uibModal.open({
                        size: 'lg',
                        component: 'refundSettingsModal',
                        resolve: {
                            event,
                        },
                    }));

                });

            }
        };
    })
    .component('refundSettingsModal', {
        controller: RefundSettingsController,
        templateUrl: templateUrl,
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
        },
    })
    .name;

function RefundSettingsController($timeout, $scope, $http, $q, $translate, BCValidatable, BCErrorable, Locales, UIMessages, ErrorRejector, Role, $uibModal, ConfirmDeleteSweet) {

    const $ctrl = this;

    const lastStep = 3;

    const customValidatorEvent = new BCValidatable();
    const customValidatorEventRefundSetting = new BCValidatable();

    addValidateToCustomValidator(customValidatorEvent);
    addValidateToCustomValidator(customValidatorEventRefundSetting);

    const NLEmails = {
        moving_donate: SHOP_BRAND_NAME === 'Eventix' ? 'https://custom.eventix.nl/projects/emails/htmlemail_templates/v2-moving_and_donate_NL.html' : '',
        moving_nodonate: SHOP_BRAND_NAME === 'Eventix' ? 'https://custom.eventix.nl/projects/emails/htmlemail_templates/v2-moving_no_donate_NL.html' : '',
        refunding_donate: SHOP_BRAND_NAME === 'Eventix' ? 'https://custom.eventix.nl/projects/emails/htmlemail_templates/v2-refunding_and_donate_NL.html' : '',
    };

    const ENEmails = {
        moving_donate: SHOP_BRAND_NAME === 'Eventix' ? 'https://custom.eventix.nl/projects/emails/htmlemail_templates/v2-moving_and_donate_EN.html' : '',
        moving_nodonate: SHOP_BRAND_NAME === 'Eventix' ? 'https://custom.eventix.nl/projects/emails/htmlemail_templates/v2-moving_no_donate_EN.html' : '',
        refunding_donate: SHOP_BRAND_NAME === 'Eventix' ? 'https://custom.eventix.nl/projects/emails/htmlemail_templates/v2-refunding_and_donate_EN.html' : '',
    };

    $ctrl.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');
    $ctrl.branding = {
        shop_brand_name: SHOP_BRAND_NAME || 'Eventix',
        show_help_url: SHOP_BRAND_NAME === 'Eventix',
        email_preview_url: Locales.selected === 'nl_NL' ? NLEmails : ENEmails,
    };

    $ctrl.initted = false;
    $ctrl.busy = false;
    $ctrl.originalStatus = null;
    $ctrl.step = 1;
    $ctrl.lastStep = lastStep;

    $ctrl.otherSettings = null;
    $ctrl.donateButtons = [];

    $ctrl.disableForm = false;
    $ctrl.disableStatus = false;
    $ctrl.startWizard = false;

    $ctrl.statusUntilHuman = '';
    $ctrl.eventStartHuman = '';
    $ctrl.showActions = false;

    $ctrl.terms = {
        includingFees: false,
        balancePositive: false,
        noEdit: false,
        otherEvents: false,
        email: {
            emailMoment: false,
            noReplacement: false,
            moving: {
                nameChange: false,
                newDate: false,
                noNewEvent: false,
                donate: {
                    email: false,
                },
                noDonate: {
                    email: false,
                },
            },
            refunding: {
                donate: {
                    email: false,
                },
            },
        },
    };

    $ctrl.back = back;
    $ctrl.next = next;

    $ctrl.toggleToggleEl = toggleToggleEl;

    customValidatorEvent.validates({
        status: {
            required: true,
            inclusion: {
                in: ['disabled', 'normal', 'sold_out', 'refunding', 'moving'],
            },
        },
        contact_phone: {
            requiredIf: {
                requiredIf: function(value, instance, field) {
                    return !!(instance && instance.status && instance.status !== 'normal');
                },
            },
        },
        contact_email: {
            requiredIf: {
                requiredIf: function(value, instance, field) {
                    return !!(instance && instance.status && instance.status !== 'normal');
                },
            },
        },
        contact_name: {
            requiredIf: {
                requiredIf: function(value, instance, field) {
                    return !!(instance && instance.status && instance.status !== 'normal');
                },
            },
        },
        visitor_contact_email: {
            requiredIf: {
                requiredIf: function(value, instance, field) {
                    return !!(instance && instance.status && instance.status !== 'normal');
                },
            },
        }
    });

    customValidatorEventRefundSetting.validates({
        refund_fee: {
            format: {boolean: true},
        },
        refund_transaction_fee: {
            format: {boolean: true},
        },
        // refund_message: {
        //     required: true,
        //     length: {
        //         min: 4,
        //         max: 32
        //     },
        // },
        refund_donate_enabled: {
            format: {boolean: true},
        },
        status_until: {
            requiredIf: {
                requiredIf: function(value, instance, field) {
                    return !!($ctrl.event && ($ctrl.event.status === 'moving' || $ctrl.event.status === 'refunding' && !!instance.refund_donate_enabled));
                },
            },
            after: {
                message: $translate.instant('validation.after', {attribute: $translate.instant('models.event.refund.status_until'), date: moment().add(7, 'days').format('YYYY-MM-DD HH:mm')}),
                validator: function(value, instance, field) {
                    if (!value || $ctrl.isAdminOrWLAdmin) {
                        return true;
                    }

                    const mom = moment(value);

                    if (!mom.isValid()) {
                        return true;
                    }

                    return mom.isAfter(moment().add(7, 'days'));
                },
            },
            before: {
                message: $translate.instant('validation.before', {attribute: $translate.instant('models.event.refund.status_until'), date: moment().add(2, 'months').format('YYYY-MM-DD HH:mm')}),
                validator: function(value, instance, field) {
                    if (!value || $ctrl.isAdminOrWLAdmin) {
                        return true;
                    }

                    const mom = moment(value);

                    if (!mom.isValid()) {
                        return true;
                    }

                    return mom.isBefore(moment().add(2, 'months'));
                },
            },
        }
        // refund_donate_options: {}, // TODO
        // refund_send_email: {}, // TODO
    });

    // Bind the resolves to the $ctrl
    $ctrl.$onInit = () => {

        if (_.isNil($ctrl.resolve)) {
            throw Error('Refund settings modal -> The modal must be initialised with a resolve binding');
        }

        _.assign($ctrl, $ctrl.resolve);
//
        $ctrl.originalStatus = $ctrl.event.status;
        if ($ctrl.event.status === 'normal') {
            $ctrl.startWizard = true;
        }
        // else if ($ctrl.event.status === 'refunding' && !$ctrl.isAdminOrWLAdmin) {
        //     $ctrl.disableStatus = true;
        // }
        //
        // if ($ctrl.event.status === 'refunding' || $ctrl.event.status === 'moving') {
        //     $ctrl.disableForm = !$ctrl.isAdminOrWLAdmin;
        // }

        if($ctrl.event.status !== 'normal' && $ctrl.event.event_refund_setting_id){
            $ctrl.showActions = true;
            loadActions();
        }

        $scope.$watch('$ctrl.event.status', clearStatusUntil);
        $scope.$watch('$ctrl.event.start', humanizeEventStart);

        $scope.$on('modal.closing', function(event, reason, closed){
            $ctrl.event.status = $ctrl.originalStatus;
        });

        loadEventRefundSetting()
            .then(eventRefundSetting => {
                $ctrl.otherSettings = eventRefundSetting
                if($ctrl.otherSettings.voucher_end_date_type == 'offset') {
                    let absoluteOffset = moment().unix() + parseInt(eventRefundSetting.voucher_end_date_value);
                    $ctrl.otherSettings.voucher_end_date_value_moment = moment.unix(absoluteOffset);

                }
                $ctrl.initted = true;
                $scope.$watch('$ctrl.otherSettings.refund_donate_enabled', clearStatusUntil);
                $scope.$watch('$ctrl.otherSettings.refund_donate_options', calculateDonateButtons);
                $scope.$watch('$ctrl.otherSettings.status_until', humanizeStatusUntil);
            })
            .then(calculateDonateButtons)
            .catch(ErrorRejector.handle);

        rudderstack.track('dashboard_event_refund_open_modal', {
            event_id: $ctrl.event.guid
        });

    };

    function edit() {
        $ctrl.showActions = false;
        $ctrl.step = 1;
    }
    $ctrl.edit = edit;

    function loadEventRefundSetting() {
        if (!$ctrl.event) {
            return ErrorRejector.handle('Event invalid');
        }

        if (!$ctrl.event.event_refund_setting_id) { // if non existing create new
            // Defaults from DB
            return $q.resolve(new EventRefundSettings.new({
                generate: true,
                refund_fees: true,
                refund_send_email: true,
                refund_transaction_fees: true,
                refund_donate_enabled: true,
                refund_donate_options: "5,15,25,50,100",
                refund_message: "",
                status_until: null,
                status_refundable_before: null,
                refundable_by_payment: true,
                refundable_by_voucher: false,
                refundable_default: 'payment',
                voucher_multiplier: 1,
                voucher_end_date_type: 'offset',
                voucher_end_date_value: '47335389', // 47335389 seconds is 18 months according to google :)
            }));
        }


        return $http.get(`/event/${$ctrl.event.guid}/refundsetting`)
            .then(response => response.data)
            .then(data => EventRefundSettings.new(data));
    }

    $ctrl.actions = [];

    function loadActions(){
        $ctrl.showActions = true;
        return $http.get(`/event/${$ctrl.event.guid}/refundsetting/actions`)
            .then(response => response.data)
            .then(data => $ctrl.actions = data);
    }

    $ctrl.deleteAction = deleteAction;
    function deleteAction(action){
        $ctrl.busy = true;
        return ConfirmDeleteSweet.open(action.name)
            .then(() => {
                return $http.delete(`/event/${$ctrl.event.guid}/refundsetting/actions/${action.guid}`)
                    .then(loadActions)
                    .catch(error => {
                        let err = _.get(error, 'data.error_description');
                        if(err && typeof(err) == 'object'){
                            _.forEach(err, function(error,name){
                                ErrorRejector.handle(...error);
                            })
                            return ErrorRejector.handle('Errors found');
                        }
                        else {
                            return ErrorRejector.handle(err)
                        }
                    })

            }).finally(() => {
                $ctrl.busy = false;
            });


        // $ctrl.busy = true;
        // return $http.delete(`/event/${$ctrl.event.guid}/refundsetting/actions/${action.guid}`)
        //     .then(loadActions)
        //     .catch(error => {
        //         let err = _.get(error, 'data.error_description');
        //         if(err && typeof(err) == 'object'){
        //             _.forEach(err, function(error,name){
        //                 ErrorRejector.handle(...error);
        //             })
        //             return ErrorRejector.handle('Errors found');
        //         }
        //         else {
        //             return ErrorRejector.handle(err)
        //         }
        //     })
        //     .finally(() => {
        //         $ctrl.busy = false;
        //     });
    }

    $ctrl.updateAction = updateAction;
    function updateAction(action, toNow= true){
        $ctrl.busy = true;

        if(toNow) {
            action.execute_after = moment().format('YYYY-MM-DDTHH:mm:ssZ');
        }

        return $http.put(`/event/${$ctrl.event.guid}/refundsetting/actions/${action.guid}`, action)
            .then(loadActions)
            .catch(error => {
                let err = _.get(error, 'data.error_description');
                if(err && typeof(err) == 'object'){
                    _.forEach(err, function(error,name){
                        ErrorRejector.handle(...error);
                    })
                    return ErrorRejector.handle('Errors found');
                }
                else {
                    return ErrorRejector.handle(err)
                }
            })
            .finally(() => {
                $ctrl.busy = false;
                loadActions();
            });
    }

    $ctrl.isASAP = isASAP;
    function isASAP(action){
        if(!action.executed_at && moment(action.execute_after).isBefore()){
            return true;
        }
        return false;
    }

    $ctrl.setDate = setDate;
    function setDate(action){
        return $uibModal.open({
            size: 'sm',
            template: `<div class="modal-header">
                    <h3 class="modal-title" translate>set Date</h3>
                </div>
                <div class="modal-body text-center">
                    <datetime-picker timezone ng-model="$ctrl.date"></datetime-picker>
                </div>
                <div class="modal-footer">
                    <a class="btn btn-default pull-left" ng-click="$dismiss()">close</a>
                    <a class="btn btn-info" ng-click="$close($ctrl.date)">save</a>
                </div>`,
            controllerAs: '$ctrl',
            controller: function () {
                const $ctrl = this;

                $ctrl.$onInit = function () {
                    $ctrl.action = action;
                    $ctrl.date = $ctrl.action.execute_after;
                };
            },
        }).result.then((newDate) => {
            action.execute_after = newDate
            updateAction(action, false);
        }, $ctrl.onDismiss || angular.noop);
    }

    function saveEventRefundSetting() {
        if (!$ctrl.otherSettings) {
            return $q.reject('No settings found');
        }

        if (!$ctrl.event.event_refund_setting_id) {
            return $http.post(`/event/${$ctrl.event.guid}/refundsetting`, $ctrl.otherSettings).then(response => {
                $ctrl.event.event_refund_setting_id = response.data.guid;
            }).catch(error => {
                $ctrl.busy = false;

                let err = _.get(error, 'data.error_description');
                if(err && typeof(err) == 'object'){
                    _.forEach(err, function(error,name){
                        ErrorRejector.handle(...error);
                    })
                    return $q.reject('Errors found');
                }
                else {
                    return $q.reject(err);
                }
            });
        }

        return $http.put(`/event/${$ctrl.event.guid}/refundsetting`, $ctrl.otherSettings).catch(error => {
            $ctrl.busy = false;

            let err = _.get(error, 'data.error_description');
            if(err && typeof(err) == 'object'){
                _.forEach(err, function(error,name){
                    ErrorRejector.handle(...error);

                })
                return $q.reject('Errors found');
            }
            else {
                return $q.reject(err);
            }
        });
    }

    function clearStatusUntil() {
        if(!$ctrl.showActions && $ctrl.initted) {
            if ($ctrl.event.status == 'normal') {
                $ctrl.otherSettings.status_until = null;
            }
            if ($ctrl.event.status == 'refunding' && !$ctrl.otherSettings.refund_donate_enabled) {
                $ctrl.otherSettings.status_until = null;
            }
        }
    }

    function calculateDonateButtons() {
        $ctrl.donateButtons = [];

        if (!$ctrl.otherSettings) {
            return;
        }

        const donateOptions = $ctrl.otherSettings.refund_donate_options || '';

        _.forEach(donateOptions.split(','), function(split) {
            $ctrl.donateButtons.push(split);
        });
    }

    function humanizeStatusUntil() {
        $ctrl.statusUntilHuman = _.isNil($ctrl.otherSettings.status_until) ? '?' : moment($ctrl.otherSettings.status_until).format('YYYY-MM-DD HH:mm');
    }

    function humanizeEventStart() {
        $ctrl.eventStartHuman = _.isNil($ctrl.event.start) ? '?' : moment($ctrl.event.start).format('YYYY-MM-DD HH:mm');
    }

    // Used for saving and closing the modal
    function save() {
        if ($ctrl.disableForm) {
            return ErrorRejector.handle('Form is locked! Process in progress');
        }

        if ($ctrl.busy) {
            return ErrorRejector.handle('System is busy');
        }

        $ctrl.busy = true;

        const validEvent = customValidatorEvent.validate($ctrl.event);
        const validEventRefundSetting = customValidatorEventRefundSetting.validate($ctrl.otherSettings);

        if (!validEvent || !validEventRefundSetting) {
            console.error($ctrl.event.$errors, $ctrl.otherSettings.$errors);

            $ctrl.busy = false;

            return ErrorRejector.handle('Invalid input');
        }

        rudderstack.track('dashboard_event_refund_submit', {
            event_id: $ctrl.event.guid
        });

        // return $ctrl.event.$save()
        //     .then(saveEventRefundSetting)
        return saveEventRefundSetting().then(() => {
                $ctrl.event.$save()
            })
            .then(() => {
                $ctrl.originalStatus = $ctrl.event.status;
                UIMessages.push({
                    message: 'Saved',
                    type: 'success',
                });
            })
            .then(loadActions)
            .catch((error) => {
                ErrorRejector.handle(error)
            })
            .finally(() => {
                $ctrl.busy = false
            });
    }

    function next() {
        if ($ctrl.busy) {
            return ErrorRejector.handle('System is busy');
        }

        $ctrl.busy = true;

        const validEvent = customValidatorEvent.validate($ctrl.event);
        const validEventRefundSetting = customValidatorEventRefundSetting.validate($ctrl.otherSettings);

        if (!validEvent || !validEventRefundSetting) {
            console.error($ctrl.event.$errors, $ctrl.otherSettings.$errors);

            $ctrl.busy = false;

            return ErrorRejector.handle('Invalid input');
        }

        $ctrl.busy = false;

        if ($ctrl.disableForm) {
            return ErrorRejector.handle('Form is locked! Process in progress');
        }

        if ($ctrl.event.status === 'normal') {
            return save();
        }

        if ($ctrl.step == 3 || $ctrl.disableForm) {
            if (!validTerms() && !$ctrl.isAdminOrWLAdmin) {
                return ErrorRejector.handle('Terms not accepted');
            }

            save();

            return $q.resolve(++$ctrl.step);
        }

        if ($ctrl.step == 4 || $ctrl.disableForm) {
            $ctrl.close();
        }

        return $q.resolve(++$ctrl.step);
    }

    function back() {
        if ($ctrl.step <= 1 || $ctrl.disableForm) {
            return $q.resolve($ctrl.close());
        }

        return $q.resolve(--$ctrl.step);
    }

    function toggleToggleEl() {
        if ($ctrl.disableForm) {
            return $q.reject('Form locked');
        }

        return $q.resolve();
    }

    function validTerms() {
        const terms = $ctrl.terms;

        // terms.includingFees
        // terms.noEdit
        // terms.otherEvents
        // terms.balancePositive
        if (!terms.includingFees || !terms.noEdit || !terms.otherEvents || !terms.balancePositive) {
            return false;
        }

        if ($ctrl.otherSettings.refund_send_email && !($ctrl.event.status === 'refunding' && !$ctrl.otherSettings.refund_donate_enabled)) {
            // terms.email.emailMoment
            // terms.email.noReplacement
            if (!terms.email.emailMoment || !terms.email.noReplacement) {
                return false;
            }

            if ($ctrl.event.status === 'moving') {
                // terms.email.moving.nameChange
                // terms.email.moving.newDate
                // terms.email.moving.noNewEvent
                if (!terms.email.moving.nameChange || !terms.email.moving.newDate || !terms.email.moving.noNewEvent) {
                    return false;
                }

                if ($ctrl.otherSettings.refund_donate_enabled) {
                    // terms.email.moving.donate.email
                    if (!terms.email.moving.donate.email) {
                        return false;
                    }
                } else if (!terms.email.moving.noDonate.email) {
                    // terms.email.moving.noDonate.email
                    return false;
                }
            }

            if ($ctrl.event.status === 'refunding' && $ctrl.otherSettings.refund_donate_enabled) {
                // terms.email.refunding.donate.email
                if (!terms.email.refunding.donate.email) {
                    return false;
                }
            }
        }

        return true;
    }

    function addValidateToCustomValidator(validator) {
        Object.defineProperty(validator, 'validate', {
            enumerable: false,
            configurable: false,
            value: function(object, fieldName) {
                return this.validations.validate(object, fieldName);
            },
        });
    }

    function EventRefundSettings(attributes) {
        _.assign(this, attributes);
    }

    EventRefundSettings.new = function(attributes) {
        return new EventRefundSettings(attributes);
    };

    include(EventRefundSettings, BCErrorable);
    include(EventRefundSettings, BCErrorable);
}


/**
 * Run Module() and make its properties available on the base
 *
 * Properties whose name starts with '__' are excluded
 *
 * @param {Object} base The object which will receive properties
 * @param {Function} Module The function whose properties will be harvested
 */
function extend(base, Module) {
    const toHarvest = new Module();
    const classPropNames = Object.getOwnPropertyNames(toHarvest).filter(
        function(propName) {
            return propName.slice(0,2) !== '__';
        });

    classPropNames.forEach(function(classPropName) {
        if(base[classPropName] !== undefined) return; //dont overwrite
        const propertyDescriptor = Object.getOwnPropertyDescriptor(toHarvest, classPropName);
        Object.defineProperty(base, classPropName, propertyDescriptor);
    });
}


/**
 * Run Module() and make its public __properties available to Objects created with Function.new()
 *
 * Exclusively properties whose name starts with '__' are harvested
 * Example: `Module() { this.__index = 1; }` will be available to `(Function.new()).index`
 *
 * @param {Object} base The object that will be extended
 * @param {Function} Module The function whose __properties will be harvested
 */
function include(base, Module) {
    const oldConstructor = base.new;
    const toHarvest = new Module();
    const instancePropNames = Object.getOwnPropertyNames(toHarvest).filter(
        function(propName) {
            return propName.slice(0, 2) === '__';
        });

    //Exposing instanceProperties to instances through `Function.new()`
    base.new = function() {
        const instance = oldConstructor.apply(this, arguments);

        instancePropNames.forEach(function(instancePropName) {
            if(this[instancePropName.slice(2)] !== undefined) return; //dont overwrite
            var propDescriptor = Object.getOwnPropertyDescriptor(toHarvest, instancePropName);
            Object.defineProperty(this, instancePropName.slice(2), propDescriptor);
        },instance);

        return instance;
    };

}
