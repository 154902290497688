import template from './shops.html';
import trackers from '../trackers/trackers.js';
import collapses from '../collapses/collapses.js';
import manageCollapsesTemplate from './collapses/collapses.html';
import Styling from './styling/styling.js';
import modal from './ShopOptionsModal.html';
import './ShopOptionsModal.less';

const resolves = /*@ngInject*/{
    currentEvent: function() {
        return null;
    }
};

export {resolves};
export default angular.module('eventix.dashboard.shops', [Styling, trackers, collapses])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.shops', {
            url: '/shops',
            views: {
                'dashboard@eventix.dashboard': {
                    component: 'shopsPage'
                }
            },
            resolve: resolves
        });
    })
    .component('shopOptionsModal', {
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&'
        },
        controller: ShopOptionsModalController,
        templateUrl: modal
    })
    .component('shopsPage', {
        templateUrl: template,
        controller: ShopsPageController,
        bindings: {currentEvent: '<', events: '<'}
    }).name;

function ShopsPageController($state, $scope, $q, $uibModal, $http, CrudCtrl, modalCRUD, ErrorRejector, UIMessages, Role, Shop, Collapse, Ticket, Product, MetaData) {
    const $ctrl = this;
    $ctrl.isAdmin = Role.isAdmin;
    $ctrl.shop_root = FANCY_SHOP_ROOT;
    $ctrl.gtm_help = GTM_HELP_DOC;

    $ctrl.openTrackers = trackers;
    $ctrl.openCollapses = openCollapses;
    $ctrl.pickTickets = pickTickets;
    $ctrl.shopOptions = [];

    $ctrl.$postLink = function() {
        $ctrl.crud = new CrudCtrl(Shop.query(), newShop);
        $ctrl.crud.addBelongsToMany('tickets', 'Ticket');
        $ctrl.crud.addBelongsToMany('products', 'Product');
        $ctrl.crud.addBelongsToMany('metaData', 'MetaData');
        $ctrl.crud.addBelongsToMany('collapses', 'Collapse');

        loadChildren();
    };

    $ctrl.openShopOptionsModal = () => {
        if (!$ctrl.crud.showEdit()) {
            return ErrorRejector.handle('Can not pick tickets, not editing a shop.');
        }

        $http.get(DASHBOARD_API_ROOT + `/shop/${ $ctrl.crud.model.guid }/options`)
            .then(res => {
                $ctrl.shopOptions = res.data;

                $uibModal.open({
                    component: 'shopOptionsModal',
                    resolve: {
                        shopOptions: () => {
                            return $ctrl.shopOptions;
                        },
                        updateUrl: () => {
                            return DASHBOARD_API_ROOT + `/shop/${ $ctrl.crud.model.guid }/options`;
                        },
                        resetUrl: () => {
                            return DASHBOARD_API_ROOT + `/shop/${ $ctrl.crud.model.guid }/options/reset`;
                        }
                    }
                }).result.catch(angular.noop);
            });
    };

    function openCollapses(shop) {
        modalCRUD('ShopCollapseController', manageCollapsesTemplate, {
            collapses: () => Collapse.query(),
            shop: () => shop,
            shops: () => $ctrl.crud.models,
            tickets: () => Ticket.query()
        });
    }

    function newShop() {
        return Shop.new({
            type: 'online'
        });
    }

    function loadChildren() {
        return $q.resolve([
            MetaData.query().then(m => $ctrl.metaData = m),
            Collapse.query().then(c => $ctrl.collapses = c)
        ]);
    }

    function pickTickets(tickets) {
        if ($ctrl.crud.showNew()) {
            return $q.resolve($ctrl.crud.model.tickets = tickets);
        }

        if (!$ctrl.crud.showEdit()) {
            return ErrorRejector.handle('Can not pick tickets, not editing a shop.');
        }

        let queue = _.reduce($ctrl.crud.model.tickets, (queue, ticket) => {
            return _.findIndex(tickets, {guid: ticket.guid}) >= 0 ? queue : queue.then(() => $ctrl.crud.detachTicket(ticket));
        }, $q.resolve());

        return _.reduce(tickets, (queue, ticket) => queue.then(() => $ctrl.crud.attachTicket(ticket)), queue);
    }
}

function ShopOptionsModalController($http) {
    const $ctrl = this;

    $ctrl.updateShopOptions = () => {
        $http.put($ctrl.resolve.updateUrl, $ctrl.resolve.shopOptions)
            .then(res => {
                $ctrl.close();
            });
    }

    $ctrl.resetShopOptions = () => {
        $http.post($ctrl.resolve.resetUrl)
            .then(res => {
                $ctrl.close();
            });
    }
}