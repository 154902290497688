import PendingPayouts from './pendingPayouts/pendingPayouts';
import FailedPayouts from './failedPayouts/failedPayouts';

export default angular.module('eventix.dashboard.admin.payouts',[PendingPayouts, FailedPayouts])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.admin.payouts', {
            url: '/payouts',
            abstract: true,
            data: {
                requiredRoles: ['Admin'],
                crumb: 'Payouts'
            }
        });
    }).name;
