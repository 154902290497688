/**
 * @ngdoc directive
 * @restrict atribute
 * @name eventix.common.directives:markRequired
 * @description
 * Mark all fields belonging to a model that have a required rule with a red asterisk
 *
 * It finds required fields by searching for `form-group` and `form-el`, fetching their `label` or its childs `ng-model` attributes
 * and checking the last segment of this attribute against validations on the Model constructor.
 *
 * @example
 * We've got a model with validations
 *
 * ```
 * Model.validates({
 *  name: { required: true }
 * });
 * ```
 *
 * And a form with
 *
 * ```
 * <form mark-required="Model">
 *  <form-el label="models.model.name">
 *      <input ng-model="$ctrl.model.name">
 *  </form-el>
 *  <form-el label="models.model.type">
 *      <input ng-model="$Ctrl.model.type">
 *  </form-el>
 * </form>
 * ```
 *
 * The first `form-group` will receive the class `required`
 *
 * @param {String} markRequired The model name that is managed by this form
 */
export default angular.module('eventix.dashboard.wizard.common.markRequired', [])
    .directive('markRequired', function($injector) {
        return {
            scope: { markRequired: '@' },
            link: function(scope, el, attrs) {
                let Model = $injector.get(scope.markRequired);

                el.find('form-group,form-el').each(function() {
                    let group = $(this);
                    let ngModel = $(this).find('[ng-model]').attr('ng-model');
                    if(isRequired(ngModel)) {
                        group.addClass('required');
                        return;
                    }
                    let label = $(this).attr('label');
                    if(isRequired(label)) {
                        group.addClass('required');
                        return;
                    }
                });

                function isRequired(attribute) {
                    if(!_.isString(attribute))
                        return false;
                    attribute = attribute.split('.').slice(-1).join('');
                    if(!_.has(Model.validations, attribute))
                        return false;
                    return _.find(Model.validations[attribute], { validator: 'required' });
                }
            }
        };
    }).name;
