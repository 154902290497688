import template from './advancedTicketForm.html';

export default angular.module('eventix.dashboard.wizard.common.advancedTicketForm', [])
    .component('advancedTicketForm', {
        controller: AdvancedTicketFormController,
        templateUrl: template,
        bindings: {
            event: '<',
            company: '<',
            translations: '<?',
            crud: '<',
            save: '&?',
            cancel: '&?',
            formOptions: '<',
        },
    }).name;

function AdvancedTicketFormController($q, $uibModal, $translate, $scope, ErrorRejector, SweetAlert, TemplateModal, Role, EventLocation, MetaData, $filter) {

    const $ctrl = this;

    $ctrl.isCompanyAdmin = Role.isAuthorizedAs('Company Admin');
    $ctrl.hasContract = Role.isAuthorizedAs('Has Contract');
    $ctrl.isAdmin = Role.isAuthorizedAs('Admin');
    $ctrl.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');
    $ctrl.barcode_types = ['generate', 'pre'];
    $ctrl.minPriceNull = false;
    $ctrl.previousMinPrice = 0;
    $ctrl.priceMismatch = false;
    $ctrl.guids = {
        event: {
            guid: $ctrl.event ? $ctrl.event.guid : '',
            icon: 'fa-calendar',
            name: 'models.models.event',
        },
        ticket: {
            guid: $ctrl.crud && $ctrl.crud.model ? $ctrl.crud.model.guid : '',
            icon: 'fa-ticket',
            name: 'models.models.ticket',
        },
    };

    $ctrl.calculatedProductPrice = null;
    $ctrl.calculatedTicketPrice = null;

    $ctrl.submit = submit;
    $ctrl.unsubmit = unsubmit;
    $ctrl.pickShops = pickShops;
    $ctrl.pickDates = pickDates;
    $ctrl.togglePublic = togglePublic;

    $ctrl.getTicketPrice = getTicketPrice;
    $ctrl.toggleMinPriceNull = toggleMinPriceNull;

    $ctrl.openTemplateModal = openTemplateModal;
    $ctrl.openKickbacksModal = openKickbacksModal;

    $ctrl.$postLink = function() {

        loadPotentialChildren();

        if (!$ctrl.translations) {
            $ctrl.translations = {};
        }

        _.defaults($ctrl.translations, {
            save: 'common.action.save',
            cancel: 'common.action.cancel',
        });

        $ctrl.previousMinPrice = $ctrl.crud.model.min_price || 0;

        $scope.$watch('$ctrl.crud.model.min_price', (minPrice, oldMinPrice) => {
            $ctrl.minPriceNull = _.isNull(minPrice);

            if (minPrice !== oldMinPrice) {
                $ctrl.crud.model.calculateMinimumPrice(true);
            }
        });

        const determinePriceMismatch = () => {
            const originalPrice = _.get($ctrl.crud.model, 'original_price', 0);
            const productPriceSum = _.get($ctrl.crud.model, 'product_price_sum', 0);
            const minPrice = _.get($ctrl.crud.model, 'min_price', 0);
            const savedMinPrice = _.get($ctrl.crud.model, '$savedState.min_price', 0);

            const dirty = minPrice !== savedMinPrice;

            $ctrl.calculatedProductPrice = $filter('formatCurrency')(productPriceSum, $ctrl.company.currency, $ctrl.company.locale);
            $ctrl.calculatedTicketPrice = $filter('formatCurrency')(minPrice, $ctrl.company.currency, $ctrl.company.locale);

            $ctrl.priceMismatch = !dirty && originalPrice !== productPriceSum;
        };

        $scope.$watch('$ctrl.crud.model.original_price', determinePriceMismatch);
        $scope.$watch('$ctrl.crud.model.product_price_sum', determinePriceMismatch);
        $scope.$watch('$ctrl.crud.model.allTicketProductGuids', () => {
            $ctrl.crud.model.calculateMinimumPrice(true);
        });

        $scope.$watch('$ctrl.event.guid', (guid) => {
            $ctrl.guids.event.guid = guid || '';
        });
        $ctrl.guids.event.guid = $ctrl.event ? $ctrl.event.guid : '';
        $scope.$watch('$ctrl.crud.model.guid', (guid) => {
            $ctrl.guids.ticket.guid = guid || '';
        });
        $ctrl.guids.ticket.guid = $ctrl.crud && $ctrl.crud.model ? $ctrl.crud.model.guid : '';

        // Temporary filter only product-products to make sure before and after the restructure the outputs is the same.
        // When cloning, origin_type is not set, do not filter these out. This filter can be removed after event restructure.
        $ctrl.crud.model.products = _.filter($ctrl.crud.model.products,
            (product) => !('origin_type' in product) || product.origin_type === 'product');
    };

    function submit() {
        if ($ctrl.save) {
            return $ctrl.save({ model: $ctrl.crud.model });
        }

        return $ctrl.crud.save();
    }

    function unsubmit() {
        if ($ctrl.cancel) {
            return $ctrl.cancel({ model: $ctrl.crud.model });
        }

        return $ctrl.crud.list();
    }

    function pickDates(dates) {
        if ($ctrl.crud.showNew()) {
            return $q.resolve($ctrl.crud.model.eventDate = dates);
        }

        if (!$ctrl.crud.showEdit()) {
            return ErrorRejector.handle('Can not pick dates, not editing a ticket.');
        }

        let queue = _.reduce($ctrl.crud.model.eventDate, (queue, date) => {
            return _.findIndex(dates, {guid: dates.guid}) >= 0 ? queue : queue.then(() => $ctrl.crud.model.$detachEventDate(date));
        }, $q.resolve());

        return _.reduce(dates, (queue, date) => queue.then(() => $ctrl.crud.model.$attachEventDate(date)), queue, $q.resolve($ctrl.crud.model.eventDate = dates));
    }

    function pickShops(shops) {
        if ($ctrl.crud.showNew()) {
            return $q.resolve($ctrl.crud.model.shops = shops);
        }

        if (!$ctrl.crud.showEdit()) {
            return ErrorRejector.handle('Can not pick shops, not editing a ticket.');
        }

        let queue = _.reduce($ctrl.crud.model.shops, (queue, shop) => {
            return _.findIndex(shops, { guid: shop.guid }) >= 0 ? queue : queue.then(() => $ctrl.crud.detachShop(shop));
        }, $q.resolve());

        return _.reduce(shops, (queue, shop) => queue.then(() => $ctrl.crud.attachShop(shop)), queue);
    }

    /**
     * Attach/detach shops to ticket
     *
     * @param {Boolean} isPublic Whether the ticket aught to be public
     * @returns {Promise} Resolves when done
     */
    function togglePublic(isPublic) {
        let promise = $q.resolve();

        if (!$ctrl.crud.model.guid) {
            let shops = $ctrl.crud.model.shops;

            shops = isPublic ? _.merge(shops, $ctrl.shops) : _.filter(shops, shop => _.indexOf($ctrl.shops, { guid: shop.guid }) < 0);

            $ctrl.crud.model.shops = shops;

            return promise.then(() => $ctrl.crud.model.shops);
        }

        return _.reduce($ctrl.shops, (promise, shop) => {
            return promise.then(() => isPublic ? $ctrl.crud.attachShop(shop) : $ctrl.crud.detachShop(shop));
        }, promise)
            .then(() => $ctrl.crud.model.shops);
    }

    function loadPotentialChildren() {
        EventLocation.query();
        $q.all([
            $ctrl.event.$queryShop(),
            $ctrl.company.$getOrCreateShop().then(shop => [ shop ]),
            loadMetaData(),
        ]).then(results => {
            $ctrl.shops = _.unionBy(results[0], results[1], 'guid');
        });
    }

    function getTicketPrice(ticket) {
        if (!ticket || !ticket.calculateMinimumPrice) {
            return '';
        }

        if (_.has(ticket, 'minimumPrice')) {
            return ticket.minimumPrice;
        }

        if (!ticket.calculating) {
            ticket.calculateMinimumPrice(false);
            ticket.calculating = true;
        }

        return '...';
    }

    function toggleMinPriceNull(toggled) {
        if (toggled) {
            // Save the old value, so it can be reused if the toggle is turned off again.
            $ctrl.previousMinPrice = _.isNil($ctrl.crud.model.min_price) ? 0 : $ctrl.crud.model.min_price;
        }

        // Note: Toggle value will be set by watch on min_price
        $ctrl.crud.model.min_price = toggled ? null : $ctrl.previousMinPrice;
    }

    function openTemplateModal(ticket) {
        if (!ticket.guid) {
            return SweetAlert.swal({
                title: $translate.instant('common.notice.warning'),
                text: $translate.instant('models.ticket.notice.saveFirst'),
                type: 'warning',
                timer: 5000,
                showConfirmButton: true,
                closeOnClickOutside: true,
            });
        }

        return TemplateModal.open('ticket', ticket);
    }

    function loadMetaData() {
        return MetaData.query()
            .then(meta => $ctrl.metaData = meta)
            .catch(ErrorRejector.handle);
    }

    function openKickbacksModal(ticket) {
        let company = $ctrl.company;
        return $uibModal.open({
            size: 'lg',
            controller: function() {
                const $ctrl = this;

                $ctrl.$onInit = function() {
                    $ctrl.ticket = ticket;
                    $ctrl.ticket.minimumPrice = getTicketPrice(ticket);
                    $ctrl.company = company;
                };
            },
            controllerAs: '$ctrl',
            template: '<div class="modal-body"><kickbacks applicable-model="$ctrl.ticket" applicable-company="$ctrl.company" applied-type="Ticket"></kickbacks></div><div class="modal-footer"><a class="btn btn-default" ng-click="$close()">Close</a></div>',
        });
    }

}
