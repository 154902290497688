import template from './loading.html';
import style from './loading.less';

export default angular.module('eventix.loading',[ ])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.loading', {
            url: '/loading',
            views: {
                '@': {
                    controller: 'LoadingController as vm',
                    templateUrl: template
                }
            }
        })
            .state('eventix.dashboard.loading', {
                url: '/loading',
                views: {
                    'dashboard@eventix.dashboard': {
                        controller: 'LoadingController as vm',
                        templateUrl: template
                    }
                }
            });
    })
    .factory('$stateLoader', function($state) {
        const $stateLoader = {
            go: function(state, params, options) {
                this.destination = {
                    state: state,
                    params: params,
                    options: options
                };
                if($state.includes('eventix.dashboard'))
                    return $state.go('eventix.dashboard.loading');
                return $state.go('eventix.loading');
            },
            continue: function() {
                let d = this.destination;
                return $state.go(d.state, d.params, d.options);
            },
            destination: {
                state: 'eventix.dashboard.home'
            }
        };

        return $stateLoader;
    })
    .controller('LoadingController', function($timeout, TipStore, $scope, $stateLoader, Auth) {
        var vm = this;
        vm.tips = TipStore;
        vm.shopTip = false;
        $stateLoader.continue();

        TipStore.start();
        $scope.$on('$destroy', () => {
            TipStore.stop();
        });
    }).name;

