import style from './register.less';
import template from './register.html';
import rudderstack from "../rudderstack/rudderstack";

export default angular.module('eventix.register', [])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.register', {
            url: '/register',
            views: {
                '@': {
                    controller: 'RegisterController as vm',
                    templateUrl: template
                }
            },
        });
    })
    .controller('RegisterController', function(LaravelValidationRules, UIMessages, $window, Locales, Currencies, $translate, $analytics, $state) {

        // Redirect the user to the dashboard home if already authenticated
        if ($window.auth.isLoggedIn) {
            $window.location.href = DASHBOARD_ROOT + 'home';
        }
        const vm = this;

        vm.setLocale = l => $translate.use(l);

        vm.messages = UIMessages;
        vm.currencies = Currencies;
        vm.locales = Locales;
        vm.countries = Locales.countries;

        vm.signup = {

            // Company
            name: '',
            country: 'NL',
            locale: '',
            currency: DEFAULT_CURRENCY,

            // User; transformed to something the API accepts below
            user_name: '',
            phone: '',
            email: '',
            password: '',
            password_confirmation: '',
            timezone: 'Europe/Amsterdam', // Overwritten by the browser's default in the $onInit
            accept_tos: false,

        };

        vm.terms = ORGANISER_TERMS_URL;
        vm.privacyPolicy = ORGANISER_PRIVACY_URL;
        vm.brandName = DASHBOARD_BRAND_NAME;

        // Fn to add the selected locale lang code to the url
        vm.addLang = function(url) {
            return url + ((url.indexOf('?') === -1) ? '?' : '&') + 'lang=' + Locales.selected;
        };

        // Get the validation rules out of the auth SDK and compute the validations
        vm.rules = [];

        function addRules(input, rules) {

            vm.rules.push({
                guid: input,
                extra: rules.join('|'),
            });

        }

        $window.auth.getSignupValidationRules().then((validationRules) => {

            const inputs = Object.keys(validationRules);

            if (!inputs || !inputs.length) {
                throw new Error('No validation rules provided');
            }

            inputs.forEach((inputName) => {

                // Rules are returned for the website, but the website is not
                // asked in the form and the validation rules (nullable|url) are
                // not supported in the LaravelValidationRules factory
                if (inputName === 'website') {
                    return;
                }

                // User validation is nested one level deeper
                if (inputName === 'user') {

                    Object.keys(validationRules.user).forEach((userInput) => {

                        // Every key can be added directly except for the user_name, as the company name is already named "name"
                        addRules(userInput === 'name' ? `user_${userInput}` : userInput, validationRules.user[userInput]);

                    });

                    return;

                }

                addRules(inputName, validationRules[inputName]);

            });

            // Also add the validation rule for the accept_tos
            if (vm.terms || vm.privacyPolicy) {
                vm.rules.push({
                    guid: 'accept_tos',
                    name: 'accept_tos',
                    extra: 'accepted',
                });
            }

        }, (err) => {

            vm.messages.push({
                message: 'Error getting the validation rules',
                type: 'error',
            });

            console.error('Error getting the validation rules:', err);

        });

        vm.$onInit = function() {

            // Overwrite the default "Europe/Amsterdam" timezone with the browsers' default timezone
            const dateTimeFormat = Intl.DateTimeFormat().resolvedOptions();

            if (
                dateTimeFormat &&
                dateTimeFormat.timeZoneName &&
                dateTimeFormat.timeZoneName.length
            ) {
                vm.signup.timezone = dateTimeFormat.timeZoneName;
            }

        };

        vm.valid = function($event) {

            if ($event) {

                vm.errors = {};

                const name = $event.target.name;

                vm.errors[name] = LaravelValidationRules.validate(vm.rules, vm.signup)[name];

            } else {

                vm.errors = LaravelValidationRules.validate(vm.rules, vm.signup);

            }

            if (
                vm.signup.password_confirmation.length &&
                vm.signup.password !== vm.signup.password_confirmation
            ) {
                vm.errors.passwordConfirm = ['Passwords do not match'];
            }

            return _.isEmpty(_.flatten(_.values(vm.errors)));

        };

        vm.mailgun = (error, valid, suggestion) => {

            if (!suggestion) {
                return;
            }

            vm.errors.email.push($translate.instant('common.notice.didYouMean', { suggestion }));

        };

        vm.register = function() {

            if (vm.signup.country) {

                let countryLocales = _.get(Locales.countries, [vm.signup.country, 'locales'], ['en_GB']);
                let foundLocale = _.find(Locales, allowedLocale => _.find(countryLocales, countryLocale => countryLocale === allowedLocale.code));

                vm.signup.locale = _.get(foundLocale, 'code', 'en_GB');

            }

            if (vm.busy) {
                return;
            }

            if (!vm.valid()) {

                console.error('error', vm.signup, vm.errors);
                return;

            }

            if (vm.signup.password !== vm.signup.password_confirmation) {

                vm.errors.passwordConfirm = [$translate.instant('validation.same', {
                    attribute: $translate.instant('models.user.password').toLowerCase(),
                    other: $translate.instant('models.user.passwordConfirm').toLowerCase(),
                })];

                return;

            }

            vm.busy = true;

            // Transform the data to something the new API accepts,
            // following the SignUpParams interface of the SDK
            const newSignup = {
                user: {},
            };

            for (const value in vm.signup) {

                if (vm.signup.hasOwnProperty(value)) {

                    switch (value) {

                        case 'user_name':
                            newSignup.user.name = vm.signup[value];
                            break;

                        case 'phone':
                        case 'email':
                        case 'password':
                        case 'password_confirmation':
                            // case 'accept_tos':
                            newSignup.user[value] = vm.signup[value];
                            break;

                        // Apply the default timezone for both the user as the company
                        case 'timezone':
                            newSignup.user[value] = vm.signup[value];
                            newSignup[value] = vm.signup[value];
                            break;

                        default:
                            newSignup[value] = vm.signup[value];

                    }

                }

            }

            $window.auth.signup(newSignup).then(() => {

                vm.messages.push('Valid user, creating');

                $translate.use(vm.signup.locale);

                $analytics.eventTrack('signUp', {
                    label: vm.signup.name,
                    value: vm.signup.email,
                });

                // Directly log in the new user
                $window.auth.login(vm.signup.email, vm.signup.password).then(() => {

                    $window.location.href = DASHBOARD_ROOT + "home?newSignUp=1";

                }, () => {

                    vm.busy = false;

                    vm.messages.push({
                        message: 'Error logging in after registration',
                        type: 'error',
                    });

                });

            }, (err) => {

                vm.busy = false;

                let message = 'Unknown error occurred';

                if (err.data && err.message) {
                    message = err.message;
                }

                vm.messages.push({
                    message: message,
                    type: 'error',
                });

                if (err.data && err.message) {
                    message = err.message;
                }
                else if (_.has(err, 'error_description')) {
                    if (err.error_description.name) {
                        if(err.error_description.name[0].includes('already been taken')){
                            message = 'Company name already in use';
                            vm.errors.name.push(message);
                        }
                    }
                    if (err.error_description['user.email']) {
                        if(err.error_description['user.email'][0].includes('unique')){
                            message = 'Email already in use, please use reset password';
                            vm.errors.email.push(message)
                        }
                    }
                }

                rudderstack.track('dashboard register error', {
                    message: message,
                    error: err
                });

                vm.messages.push({
                    message: message,
                    type: 'error',
                });
                $scope.$digest();
            });

        };

    }).name;
