import OrderImporter from './OrderImporter';
import template from './import.html';
import fileSaver from 'file-saver';
import moment from 'moment';

export default angular.module('eventix.dashboard.offlineTickets', [OrderImporter])
    .config(ModuleConfig)
    .directive('input', function() {
        return {
            priority: -10,
            require: '?ngModel',
            link: function(scope, el, attrs, ngModelCtrl) {
                if (attrs.type !== 'file' || !ngModelCtrl)
                    return;
                el.on('change', function(event) {
                    let file = el.get(0).files;
                    if (!_.has(attrs, 'multiple'))
                        file = _.first(file);
                    ngModelCtrl.$setViewValue(file);
                });
            }
        };
    })
    .component('importPage', {
        templateUrl: template,
        controller: ImportPageController,
        bindings: {
            events: '<',
            user: '<',
            isAdmin: '<',
            company: '<'
        }
    }).name;

function ImportPageController($scope, $translate, OrderImporter, UIMessages, $q, Ticket, Locales, $state, MetaData, SweetAlert, CSVExport, $analytics) {
    const $ctrl = this;
    $ctrl.state = 'file-select';
    $ctrl.busy = false;
    $ctrl.locales = Locales;
    $ctrl.validate = validate;
    $ctrl.translateErrorColumnName = translateErrorColumnName;
    $ctrl.downloadExample = downloadExample;
    $ctrl.parseFile = parseFile;
    $ctrl.next = next;
    $ctrl.back = back;
    $ctrl.renderPdf = true;
    $ctrl.sendEmail = true;
    $ctrl.acceptResponsibility = false;
    $ctrl.csvColumnNames = [
        'ignore',
        'order.shop_id',
        'order.firstName',
        'order.lastName',
        'order.email',
        'order.locale',
        'order.order_tickets.ticket_id',
        'order.order_tickets.number_of_tickets',
        'order.order_tickets.ticket_number',
        'order.metadata',
        'order.order_tickets.metadata'
    ];

    $ctrl.formOptionsAvailable = [{
        label: $translate.instant('models.import.sendEmail'),
        key: 'sendEmail'
    }, {
        label: $translate.instant('models.import.renderPdf'),
        key: 'renderPdf'
    }];

    $ctrl.$onInit = function() {
        if ($ctrl.isAdmin)
            $ctrl.csvColumnNames.push('order.created_at', 'order.order_tickets.created_at');
        let translate = _.map($ctrl.csvColumnNames, name => 'models.import.columns.' + name);
        $translate(translate).then(translations => {
            $ctrl.csvToHuman = _.mapKeys(translations, (human, key) => {
                return key.slice(22);
            });
            $ctrl.humanToCsv = _.zipObject(_.values($ctrl.csvToHuman), _.keys($ctrl.csvToHuman));
        });

        $ctrl.company.$getOrCreateImportShop().then(shop => $ctrl.shop = shop);
        MetaData.query().then(m => $ctrl.metaData = m);

        $scope.$watch('$ctrl.file', $ctrl.parseFile);
        $scope.$on('jsonEditor.deleteRow', function(event, subtree, index, row) {
            _.pull($ctrl.orders, row);
        });
    };

    /**
     * Download an example CSV
     *
     * @param {String} type One of `advanced`,`manual` or `barcode`
     */
    function downloadExample(type) {
        let csv;
        switch (type) {
            case 'advanced':
                csv = $ctrl.csvColumnNames.slice(1).join(',');
                break;
            default:
                let name = $ctrl.user.name.split(' ');
                let defaultData = _.fromPairs([
                    [$ctrl.csvToHuman['order.firstName'], name.slice(0, 1).join(' ')],
                    [$ctrl.csvToHuman['order.lastName'], name.slice(1).join(' ')],
                    [$ctrl.csvToHuman['order.email'], $ctrl.user.email]
                ]);

                if (type === 'barcode')
                    defaultData[$ctrl.csvToHuman['order.order_tickets.ticket_number']] = 'ABC123-EXAMPLE';
                else
                    defaultData[$ctrl.csvToHuman['order.order_tickets.number_of_tickets']] = 1;
                csv = OrderImporter.$Papa.unparse([defaultData], {
                    quotes: false,
                    quoteChar: '"',
                    delimiter: ',',
                    newline: '\r\n',
                    header: true
                });
                break;
        }

        fileSaver.saveAs(new Blob([csv], {type: 'text/csv;charset=utf-8'}), 'importOrders.csv');
    }

    /**
     * Parse a CSV file and determine import settings
     *
     * @param {File} file A CSV file from a file input
     * @return {Promise} Empty promise when done
     */
    function parseFile(file) {
        if (!file) return null;

        $ctrl.busy = true;

        return OrderImporter.toJSON(file).then(csv => {
            let orders = _.filter(csv.data, (order, index) => {
                // Filter out empty CSV rows
                let nonEmpty = _.findKey(order, v => !_.isEmpty(v));
                return !!nonEmpty;
            });

            if (!orders || !orders.length) return $q.reject($translate.instant('models.import.notice.emptyFile'));

            if (orders.length > 250) return $q.reject($translate.instant('models.import.notice.tooManyOrders'));

            if (_.size(orders[0]) < csv.meta.fields.length) return $q.reject($translate.instant('models.import.notice.duplicateHeader'));

            $ctrl.orders = orders;

            $ctrl.importSettings = {
                hasTicketGuid: _.has(orders, [0, 'order.order_tickets.ticket_id']),
                hasShopGuid: _.has(orders, [0, 'order.shop_id']),
                hasLocale: _.has(orders, [0, 'order.locale']),
                ticket: null
            };

            if (!$ctrl.importSettings.hasLocale)
                $ctrl.importSettings.locale = Locales.selected;

            $ctrl.busy = false;

            return $q.resolve();
        }).catch(error => {
            _.set($ctrl, 'importSettings.$errors.orders', angular.isString(error) ? [error] : ['CSV file invalid']);

            $ctrl.busy = false;
        });
    }

    /**
     * Go to the next step in this import wizard
     */
    function next() {
        if ($ctrl.busy) return;
        switch ($ctrl.state) {
            case 'file-select':
                $ctrl.importSettings.$errors = {};

                if (!($ctrl.importSettings.hasTicketGuid || $ctrl.importSettings.ticket))
                    $ctrl.importSettings.$errors.ticket = [$translate.instant('validation.rules.required')];

                if (!($ctrl.importSettings.hasLocale || $ctrl.importSettings.locale))
                    $ctrl.importSettings.$errors.locale = [$translate.instant('validation.rules.required')];

                if (_.find($ctrl.importSettings.$errors, e => !_.isEmpty(e)))
                    return;

                guessColumnMapping();

                $ctrl.state = 'remap';

                break;
            case 'remap':
                let missingMapping = _.findKey($ctrl.keyMapping, (map, key) => {
                    if (key.indexOf('$') === 0) return false;

                    if (!map)
                        return true;

                    if (map.indexOf('metadata') > 0 && !$ctrl.metaMapping[key])
                        return true;

                    return false;
                });

                if (missingMapping === undefined) {
                    validate().then(() => {
                        $ctrl.state = $ctrl.validation.valid ? 'confirm' : 'validate';
                    });
                } else {
                    UIMessages.push('models.import.notice.unmappedFields');
                }
                break;
            case 'validate':
                if ($ctrl.validation.valid)
                    $ctrl.state = 'confirm';
                else {
                    validate().then(() => {
                        if ($ctrl.validation.valid)
                            $ctrl.state = 'confirm';
                    });
                }
                break;
            case 'confirm':
                if ($ctrl.acceptResponsibility)
                    process();
                else
                    UIMessages.push('models.import.notice.acceptResponsibility');
                break;
            default:
                break;
        }
    }

    /**
     * Go to a previous step in this wizard
     */
    function back() {
        if ($ctrl.busy) return;
        switch ($ctrl.state) {
            case 'remap':
                $ctrl.state = 'file-select';
                break;
            case 'validate':
            case 'confirm':
                $ctrl.state = 'remap';
                break;
            default:
                break;
        }
    }

    /**
     * Translate the columnName returned on validation errors to a human readable format
     *
     * @param {String} name A CSV column name (i.e.: `order.firstName`)
     * @return {String} A user-friendly column name (i.e.: First name)
     */
    function translateErrorColumnName(name) {
        var metaData;
        let isMeta = name.indexOf('metadata') >= 0;
        if (isMeta) {
            metaData = MetaData.cached[name.slice(-36)];
            name = name.slice(0, -36);
        }
        name = $translate.instant('models.import.columns.' + name);
        if (isMeta)
            name = name.replace(/\(.+\)$/, $translate.instant(metaData.translateName));
        return name;
    }

    /**
     * Validate the orders currently in memory
     *
     * Sets the `$ctrl.valid` to true/false, saves validation errors and filters order rows to only show rows with errors
     *
     * @return {Promise} Resolves when done
     */
    function validate() {
        $ctrl.busy = true;
        $ctrl.validation = {
            valid: true
        };
        let orders = mapOrdersForImport();
        return OrderImporter.validate(orders)
            .then(validity => {
                $ctrl.validation.valid = validity.status === 'valid';
                $ctrl.validation.errors = validity.invalid;
                $ctrl.validation.invalid = _.map(validity.invalid, error => {
                    return $ctrl.orders[error.row.rowId - 2];
                });
                $ctrl.busy = false;
            }, error => {
                $ctrl.busy = false;
                UIMessages.push(error.message || error.description || 'common.notice.error');
            });
    }

    /**
     * Runs an import through the system
     *
     * @returns {Promise} resolves when done
     */
    function process() {
        $ctrl.busy = true;
        let orders = mapOrdersForImport();
        return OrderImporter.process(orders, $ctrl.renderPdf, $ctrl.sendEmail)
            .then(response => {
                $analytics.eventTrack('import-created');
                SweetAlert.swal({
                    title: $translate.instant('common.notice.congratulations'),
                    text: $translate.instant('models.import.notice.success'),
                    type: 'success',
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    showCancelButton: true,
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    cancelButtonText: $translate.instant('common.action.download'),
                    allowEscapeKey: true,
                }, shouldNotDownload => {
                    if (!shouldNotDownload) {
                        let orders = _.flatMap(_.get(response, 'orders', []), order => {
                            return _.map(order.tickets, ticket => {
                                return {
                                    order_id: _.get(order, 'guid', 'unknown'),
                                    order_first_name: _.get(order, 'firstName', 'unknown'),
                                    order_last_name: _.get(order, 'lastName', 'unknown'),
                                    order_email: _.get(order, 'email', 'unknown'),

                                    order_ticket_id: _.get(ticket, 'guid', 'unknown'),
                                    ticket_id: _.get(ticket, 'ticket_id', 'unknown'),
                                    barcode: _.get(ticket, 'ticket_number', 'unknown'),
                                };
                            })
                        });

                        CSVExport.convert(orders, null, moment().format('YYYY-MM-DD HH:mm') + ' imported', true);
                    }
                });

                $ctrl.file = null;
                $ctrl.busy = false;
                $ctrl.importSettings = {};
                $ctrl.orders = null;
                $ctrl.state = 'file-select';
                $ctrl.acceptResponsibility = false;
            }, error => {
                $ctrl.busy = false;
                $ctrl.acceptResponsibility = false;
                UIMessages.push(error.message || error.description || 'common.notice.error');
            });
    }

    /**
     * Convert the JSON objects read from CSV to API format
     *
     * Adds missing columns & renames column headers
     *
     * @return {Array<Object>} Array of orders formatted for importing
     */
    function mapOrdersForImport() {
        return _.map($ctrl.orders, (order, index) => {
            order = _.mapKeys(order, (value, key) => {
                if (key.indexOf('$') === 0) return key;

                let newKey = $ctrl.keyMapping[key];

                if (newKey.indexOf('metadata') > 0)
                    newKey += '.' + $ctrl.metaMapping[key];

                return newKey;
            });

            if (!order['order.shop_id'])
                order['order.shop_id'] = $ctrl.shop.guid;

            if (!$ctrl.importSettings.hasTicketGuid)
                order['order.order_tickets.ticket_id'] = _.get($ctrl.importSettings, 'ticket.guid');

            if (!$ctrl.importSettings.hasLocale)
                order['order.locale'] = _.get($ctrl.importSettings, 'locale');

            order.rowId = index + 2;
            return order;
        });
    }

    /**
     * Take an educated guess at column name mapping (`First name => order.firstName`)
     */
    function guessColumnMapping() {
        $ctrl.keyMapping = {};
        $ctrl.metaMapping = {};
        let keys = _.keys($ctrl.orders[0]);
        _.forEach(keys, key => {
            if ($ctrl.csvColumnNames.indexOf(key) >= 0)
                return $ctrl.keyMapping[key] = key;
            if ($ctrl.humanToCsv[key])
                return $ctrl.keyMapping[key] = $ctrl.humanToCsv[key];
            if (/order\.(order_tickets\.)?metadata\.([a-zA-Z0-9-]{36})/.test(key)) {
                $ctrl.metaMapping[key] = key.slice(-36);
                return $ctrl.keyMapping[key] = key.slice(0, -37);
            }
            return $ctrl.keyMapping[key] = null;
        });
    }
}

function ModuleConfig($stateProvider) {
    $stateProvider.state('eventix.dashboard.import', {
        url: '/import',
        views: {
            'dashboard@eventix.dashboard': {
                component: 'importPage',
                bindings: {}
            }
        },
        data: {
            requiredRoles: ['Event Manager']
        }
    });
}
