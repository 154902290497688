import templateUrl from './promote-platform.html'
import './promote-platform.less'

/**
 * A single promote platform in the promotion form of common/shops
 * @author Stan Hurks, Eventix
 */
export default angular.module('eventix.dashboard.wizard.common.shop.promoteForm.promotePlatform', [])
    .component('promotePlatform', {
        controller: PromotePlatformController,
        templateUrl,
        bindings: {
            platform: '=',
            isPlatformPromoted: '&',
            event: '<',
            shop: '<'
        }
    }).name

function PromotePlatformController ($http, UIMessages) {
    const $ctrl = this

    // Whether or not the interactions in this component are disabled
    $ctrl.requestsDisabled = false

    /**
     * Create a promotion
     */
    $ctrl.createPromotion = () => {
        $ctrl.requestsDisabled = true
        if (!$ctrl.event || !$ctrl.shop) {
            $ctrl.requestsDisabled = false
            UIMessages.push({
                message: 'models.shop.promote.noEventOrShop',
                type: 'danger'
            })
            return
        }
        $http.post('/event/' + $ctrl.event.guid + '/calendars/' + $ctrl.shop.guid + '/' + $ctrl.platform.guid, {}).then(() => {
            $ctrl.platform.promoted = true
            $ctrl.requestsDisabled = false
            UIMessages.push('models.shop.promote.notice.createSucces')
        }, (e) => {
            $ctrl.requestsDisabled = false
            UIMessages.push({
                message: 'models.shop.promote.notice.createFailed',
                type: 'danger'
            })
        })
    }

    /**
     * Delete a promotion
     */
    $ctrl.deletePromotion = () => {
        $ctrl.requestsDisabled = true
        if (!$ctrl.event || !$ctrl.shop) {
            $ctrl.requestsDisabled = false
            UIMessages.push({
                message: 'models.shop.promote.noEventOrShop',
                type: 'danger'
            })
            return
        }
        $http.delete('/event/' + $ctrl.event.guid + '/calendars/' + $ctrl.shop.guid + '/' + $ctrl.platform.guid).then(() => {
            $ctrl.platform.promoted = false
            $ctrl.requestsDisabled = false
            UIMessages.push('models.shop.promote.notice.deleteSuccess')
        }, (e) => {
            $ctrl.requestsDisabled = false
            UIMessages.push({
                message: 'models.shop.promote.notice.deleteFailed',
                type: 'danger'
            })
        })
    }
}