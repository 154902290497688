import template from './scanners.html';
import Status from './status/status.js';

export default angular.module('eventix.dashboard.scanners', [Status])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.scanners', {
            url: '/scanners',
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'ScannersController as vm',
                    templateUrl: template
                }
            },
            data: {
                requiredRoles: ['Event Manager']
            },
            resolve: /*@ngInject*/{
                scannerTypes: function(ScannerType, $transition$) {
                    return ScannerType.query();
                }
            }
        });
    })
    .controller('ScannersController', function($state, $location, $scope, $q, $uibModal, $translate, CrudCtrl, sortByPredecessor, ErrorRejector, UIMessages, ScannerType, scannerTypes, isAdmin, scannerLoginModal) {
        const vm = this;
        vm.isAdmin = isAdmin;

        if (vm.isAdmin) {
            vm.formOptionsAvailable = [
                {
                    key: 'expires_at',
                    label: $translate.instant('models.scanner_type.expires_at'),
                },
                {
                    key: 'scan_types',
                    label: $translate.instant('models.scanner_type.scan_types'),
                },
            ];
        }

        vm.selectedEvent;

        vm.save = save;
        vm.showQR = (scannerType) => scannerLoginModal(scannerType);
        vm.pickProducts = pickProducts;

        $scope.$watch('vm.selectedEvent', event => {
            if (_.isNil(event)) {
                return;
            }

            fetchScanners();
        });

        function fetchScanners() {
            vm.crud = new CrudCtrl(vm.selectedEvent.$queryScannerType(), () => ScannerType.newDefault());
            vm.crud.addBelongsToMany('products', 'Product');
            vm.edit = vm.crud.edit;
        }

        function save() {
            if (!vm.selectedEvent || !vm.crud) {
                console.error('Fatal error, no event selected or no crud initialized');

                return ErrorRejector.handle('Fatal error');
            }

            if (!(vm.crud.model.products && vm.crud.model.products.length)) {
                return ErrorRejector.handle('models.scanner_type.notice.shouldHaveTicketsOrProducts');
            }

            if (vm.crud.model.password && !vm.crud.model.password.length) {
                delete vm.crud.model.password;
            }

            return vm.selectedEvent.$crudCreateOrSaveChild(vm.crud, 'ScannerType')
                .catch(error => {
                    if (_.get(error, 'data.error_description.username.0') === 'The username has already been taken.') {
                        UIMessages.push({
                            type: 'danger',
                            message: 'The username has already been taken.'
                        });
                    }

                    if (_.get(error, 'data.error_description.password.0') === 'The password must be at least 4 characters.') {
                        UIMessages.push({
                            type: 'danger',
                            message: 'The password must be at least 4 characters.'
                        });
                    }

                    return error;
                });
        }

        function pickProducts(products) {
            // When scanner is new, the attaches will be done by the save methods
            if (vm.crud.showNew()) {
                return $q.resolve(vm.crud.model.products = products);
            }

            if (!vm.crud.showEdit()) {
                return ErrorRejector.handle('Can not pick products, not editing a scanner.');
            }

            let queue = _.reduce(vm.crud.model.products, (queue, product) => {
                return _.findIndex(products, {guid: product.guid}) >= 0 ? queue : queue.then(() => vm.crud.detachProduct(product));
            }, $q.resolve());

            return _.reduce(products, (queue, product) => queue.then(() => vm.crud.attachProduct(product)), queue);
        }
    }).name;
