import template from './vouchers.html';
import './vouchers.less';

export default angular.module('eventix.dashboard.vouchers', [])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.vouchers', {
            url: '/vouchers',
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'VouchersController as vm',
                    templateUrl: template,
                },
            },
            data: {
                requiredRoles: ['Event Manager'],
            },
        });
    })
    .controller('VouchersController', function($state, $q, $http, $translate, CrudCtrl, ErrorRejector, Role, Coupon, Company, ConfirmDeleteSweet, SweetAlert, CSVExport) {
        const vm = this;

        vm.isAdmin = Role.isAuthorizedAs('Admin');

        vm.getCoupons = getVouchers;
        vm.disable = disable;

        vm.currency = Company.getMe().currency;
        vm.statii = {
            enabled: $translate.instant('models.coupon.statii.enabled'),
            disabled: $translate.instant('models.coupon.statii.disabled'),
        };
        vm.vouchers = [];
        vm.totalAvailable = 0;
        vm.totalUsed = 0;

        vm.crud = CrudCtrl.new([], () => Coupon.new({amount: 1, type: 'cheque'}), {determineInitState: false});
        vm.crud.addBelongsToMany('products', 'Product');
        vm.crud.afterSave(function(error, coupon, isNew) {
            if (!error && isNew) {
                vm.crud.options.persistState = false;
                $state.go('eventix.dashboard.coupons.codes', {couponGuid: coupon.guid, newCodes: true});
            }
        });

        function getVouchers() {
            const url = '/voucher/normal' + ($state.params.as ? ('/' + $state.params.as) : '');

            return $http.get(url)
                .then(response => {
                    vm.totalAvailable = 0;
                    vm.totalUsed = 0;
                    const vouchers = response.data;
                    const voucherCounts = {};
                    _.forEach(vouchers, (voucher) => {
                        _.set(voucherCounts, voucher.guid, _.get(voucherCounts, voucher.guid, 0) + 1);

                        voucher.amount_left = voucher.amount - voucher.used_amount;
                        vm.totalAvailable += voucher.amount
                        vm.totalUsed += voucher.used_amount

                        if(!voucher.note){
                            voucher.note = "";
                        }
                        if(!voucher.description){
                            voucher.description = "";
                        }

                    });

                    _.forEach(vouchers, (voucher) => {
                        voucher.isMultiCodeCoupon = _.get(voucherCounts, voucher.guid, 0) > 1;
                    });

                    vm.vouchers =  vouchers;
                })
                .catch(ErrorRejector.handle);
        }
        getVouchers();

        function disable(coupon){
            let translations = {
                text: {translation : 'Are you sure you want to disable this voucher :object ?'},
                confirm: 'common.action.disable'
            };

            ConfirmDeleteSweet.open(coupon.code, translations, false)
                .then(() => disableCoupon(coupon.guid))
                .then((data) => {
                    let title = $translate.instant('common.notice.archived');
                    let text = $translate.instant('common.notice.success');
                    title =  data + ' Voucher(s) disabled';
                    SweetAlert.swal({
                        title: title,
                        text: text,
                        type: 'success',
                        confirmButtonText: $translate.instant('common.action.ok'),
                        closeOnClickOutside: true,
                        timer: 3000
                    });
                })
                .catch((data) => {
                    SweetAlert.swal({
                        title: $translate.instant('common.notice.error'),
                        text: $translate.instant(_.get(data.data, 'error_description', 'common.notice.unknownError')),
                        type: 'error',
                        confirmButtonText: $translate.instant('common.action.ok'),
                        closeOnClickOutside: true
                    });
                });
        }

        function disableCoupon(guid){
            const url = '/voucher/' + guid;
            return $http.delete(url)
                .then(response => {
                    getVouchers();
                    return response.data;
                })
                .catch(ErrorRejector.handle);
        }

        vm.exportVouchers = exportVouchers;
        function exportVouchers(){
            let columns = {
                'name': 'Campaign',
                'code': 'Code',
                'description': 'Description',
                'note': 'Note',
                'amount': 'Amount',
                'used_amount': 'Used amount',
                'amount_left': 'Available',
                'status': 'Status',
                'start_date': 'Start date',
                'end_date': 'End date',
                'created_at': 'Created at',
            };

            return CSVExport.convert(vm.vouchers, columns, 'Vouchers')
        }

    }).name;

