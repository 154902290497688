import template from './preferences.html';
import forcedProductsController from '../../../dashboard/wizard/common/shops/forced-products/forcedProducts.js';
import './preferences.less';
import paymentProfilesTemplate from './paymentProfiles.html';

export default angular.module('eventix.dashboard.company.preferences', [forcedProductsController])
    .config(function ($stateProvider) {
        $stateProvider.state('eventix.dashboard.company.preferences', {
            url: '/preferences',
            views: {
                'dashboard@eventix.dashboard': {
                    component: 'preferences'
                }
            },
            data: {
                crumb: 'common.menu.company.preferences',
                requiredRoles: ['Company Admin']
            }
        });
    })
    .component('companyShopSelector', {
        template: `<tx-select tx-options="shop.guid as shop.name for shop in $ctrl.shops"
           ng-model="$ctrl.companyShop.right"
           ng-change="$ctrl.switchGlobalShop($ctrl.companyShop.right)"
           btn-class="form-control" no-purge>
        </tx-select>`,
        bindings: {
            company: '<'
        },
        controller: function(RelationAPI, Shop, $q, UIMessages) {
            const $ctrl = this;
            $ctrl.$onInit = function() {
                Shop.query().then(shops => $ctrl.shops = shops);
                RelationAPI.get('CompanyShop', $ctrl.company.guid).then(relations => {
                    if(relations && relations.length)
                        $ctrl.companyShop = relations[0];
                });
            };

            $ctrl.switchGlobalShop = function(shopGuid) {
                if($ctrl.busy)
                    return $q.reject('busy');
                $ctrl.busy = true;
                return $ctrl.company.$updateCompanyShop(shopGuid)
                    .then(
                        () => {
                            $ctrl.busy = false;
                            UIMessages.push({
                                message: 'Successfully saved company-wide shop',
                                type: 'info',
                            });
                        },
                        er => {
                            UIMessages.push(er.message || er.error_description || 'common.notice.error');
                            $ctrl.busy = false;
                        }
                    );
            };
        }
    })
    .component('companyPaymentProfileSelector', {
        template: ` <span class="btn btn-default btn-block" ng-click="$ctrl.openModal()">{{ 'models.company.payment_profiles.edit_button_text' | translate }}</span>`,
        bindings: {
            company: '='
        },
        controller: function($q, UIMessages, $uibModal) {
            const $ctrl = this;

            $ctrl.$onInit = function() {
                // $ctrl.company.getPaymentProfiles().then(paymentProfiles => {
                //     $ctrl.payment_profiles = paymentProfiles;
                // });
                $ctrl.payment_profiles = [];
            };

            $ctrl.openModal = function() {
                let resolves = {};
                /* eslint-disable */
                resolves['paymentProfiles'] = () => $ctrl.payment_profiles;
                resolves['company'] = () => $ctrl.company;
                /* eslint-enable */
                $uibModal.open({
                    size: 'lg',
                    controller: PaymentProfileController,
                    templateUrl: paymentProfilesTemplate,
                    controllerAs: '$ctrl',
                    resolve: resolves
                });
            };

        }
    })
    .component('preferences', {
        controller: PreferencesController,
        bindings: {
            company: '<',
            whitelabel: '<'
        },
        templateUrl: template
    }).name;

function PreferencesController($scope, TemplateModal, UIMessages, Role) {
    const $ctrl = this;

    $ctrl.openTemplateModal = openTemplateModal;
    $ctrl.save = save;

    $ctrl.isAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');
    $ctrl.guids = {
        company: {
            guid: $ctrl.company ? $ctrl.company.guid : '',
            icon: 'fa-building-o',
            name: 'models.models.company',
        },
    };

    $ctrl.$onInit = function() {
        $scope.$watch('$ctrl.company.guid', (guid) => {
            $ctrl.guids.company.guid = guid || '';
        });
        $ctrl.guids.company.guid = $ctrl.company ? $ctrl.company.guid : '';
    };

    function openTemplateModal() {
        TemplateModal.open('company', $ctrl.company);
    }

    function save() {
        UIMessages.push({
            message: 'Saved',
            type: 'info',
        });
    }
}


function PaymentProfileController($scope, $http, $uibModalInstance, company, paymentProfiles, UIMessages) {
        const $ctrl = this;
        $ctrl.busy = false;
        $ctrl.payment_profiles = {};
        $ctrl.company = company;
        $ctrl.companySettings = {};
        $ctrl.currentGuid = null;
        $ctrl.showPreview = false;
        $ctrl.previewMethods = [];

        $ctrl.cancel = function() {
            $uibModalInstance.dismiss();
        };
        //
        $ctrl.$onInit = function() {
            $http.get('/company/settings').then((response) => {
                $ctrl.companySettings = response.data;
                $ctrl.currentGuid = $ctrl.companySettings.payment_profile_id;
                console.log($ctrl.currentGuid);
            });

            $http.get('/company/settings/paymentprofiles').then(res => {
                $ctrl.payment_profiles = res.data;
                console.log($ctrl.payment_profiles);
            });
        };
        //
        $scope.$watch('$ctrl.currentGuid', profileChanged, true);
        //
        function profileChanged(){
            $ctrl.showPreview = false;
        }

        $ctrl.save = function() {
            $http.post('/company/settings/paymentprofiles/switch/' + $ctrl.currentGuid).then(() => {
                $ctrl.companySettings.payment_profile_id = $ctrl.currentGuid;
                UIMessages.push('common.notice.success');

                $uibModalInstance.close();
            }, error => {
                $ctrl.busy = false;
                if(_.get(error,'data.error_description.length'))
                    UIMessages.push(error.data.error_description);
                else
                    UIMessages.push('common.notice.unknownError');
            });
        };

        $ctrl.togglePreview = function() {
            if($ctrl.showPreview){
                $ctrl.showPreview = false;
                return;
            }
            $ctrl.busy = true;

            $ctrl.showPreview = true;
            $ctrl.previewMethods = [];

            $http.get('/company/settings/paymentprofiles/preview/' + $ctrl.currentGuid).then((response) => {
                $ctrl.busy = false;
                $ctrl.previewMethods = response.data;

                _.forEach($ctrl.previewMethods, function(method) {
                   method.visitor_total_fixed = method.fees.fees_for.will_be_payed.fixed
                   method.visitor_total_percentages = Number(Math.round(_.get(method.fees, 'fees_for.will_be_payed.percentages', 0)+'e2')+'e-2');
                   // TODO why did i choose minus here?
                   method.organizer_total_fixed = method.fees.fees_for.has_to_pay.fixed;
                   method.organizer_total_percentages = Number(Math.round(_.get(method.fees, 'fees_for.has_to_pay.percentages', 0)+'e2')+'e-2');
                });
            }, error => {
                $ctrl.busy = false;
                if(_.get(error,'data.error_description.length'))
                    UIMessages.push(error.data.error_description);
                else
                    UIMessages.push('common.notice.unknownError');$ctrl.busy = false;
            });
        }
}
