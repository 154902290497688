/* eslint-disable no-console */
/**
 * @typedef Datapoint
 * @type { object }
 * @property { Moment } at - The moment object of the datapoint.
 * @property { string } timestamp - The human-readable timestamp of the datapoint.
 * @property { number | null } sales - The sales revenue for this datapoint.
 * @property { number | null } cumulative_sales - The cumulative sales revenue up to and including this datapoint.
 * @property { number | null } count - The ticket count for this datapoint.
 * @property { number | null } cumulative_count - The ticket count up to and including this datapoint.
 */

/**
 * @typedef DatasetConfig
 * @type { object }
 * @property { string } label - The description of the dataset.
 * @property { 'month' | 'week' | 'day' | 'hour' } timeunit - The timeunit of the datapoints in the dataset.
 * @property { { start: string; end: string; } } event - The event model of the dataset.
 *
 * and more...
 */

/** @typedef { 'left' | 'right' | 'right-end' } Alignment */
/** @typedef { { [key in Alignment ]: { left: number; right: number; }; } } AlignedOffsets */

/**
 * @typedef TransformedDataset
 * @type { object }
 * @property { DatasetConfig } config - The config object of this dataset.
 * @property { Datapoint[] } datapoints - The datapoints for this set.
 * @property { number } saleStartIndex - The index of the start of sales in the dataset.
 * @property { number } eventStartIndex - The index of the event's start in the dataset.
 * @property { number } eventEndIndex - The index of the event's end in the dataset.
 * @property { moment.Moment } first - The moment object of the first datapoint.
 * @property { moment.Moment } last - The moment object of the last datapoint.
 * @property { moment.Moment } saleStart - The moment object of the start of sales date.
 * @property { moment.Moment } eventStart - The moment object of the event start date.
 * @property { moment.Moment } eventEnd - The moment object of the event end date.
 * @property { AlignedOffsets } alignedOffsets - The offset given an alignment type.
 */

/**
 * @typedef RawData
 * @type { object }
 * @property { { buckets: { [key: string]: { key_as_string: string; sales: { value: number }; cumulative_sales: { value: number }; }; }; } } statistics
 * @property { { buckets: { [key: string]: { key_as_string: string; count: { value: number }; cumulative_count: { value: number }; }; }; } } statis_count
 */

/**
 * Get the moment format string specific to the timeunit.
 *
 * @param { 'month' | 'week' | 'day' | 'hour' } timeunit
 * @param { boolean } partialIso
 * @return { string }
 */
export function getMomentFormatForTimeUnit(timeunit, partialIso = false) {
    return (timeunit === 'hour' ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD').replace(' ', partialIso ? 'T' : ' ');
}

/**
 * Add extra datapoints to the original array. This mutates the input array!
 *
 * Depending on the pushOrUnshift parameter, this will be done at the end or beginning of the array.
 *
 * @param { TransformedDataset } transformedData
 * @param { number } n
 * @param { Moment } start
 * @param { 'push' | 'unshift' } pushOrUnshift
 *
 * @return { TransformedDataset }
 */
export function addExtraDataPoints(transformedData, n, start, pushOrUnshift) {
    const timeunitFormat = getMomentFormatForTimeUnit(transformedData.config.timeunit);

    const recurseTimestamp = start.clone();

    (pushOrUnshift === 'unshift' ? Array.prototype.unshift : Array.prototype.push).apply(transformedData.datapoints, _.times(n, () => {
        const newDatapoint = {
            at: recurseTimestamp.clone(),
            timestamp: recurseTimestamp.format(timeunitFormat),
            sales: null, // TODO Or should be zero?
            cumulative_sales: null, // TODO Or should be zero?
            count: null, // TODO Or should be zero?
            cumulative_count: null, // TODO Or should be zero?
        };

        recurseTimestamp.add(1, transformedData.config.timeunit);

        return newDatapoint;
    }));

    return updateComputedProperties(transformedData);
}

/**
 * Transform the raw data set to a transformed dataset.
 *
 * The transformed data set will ALWAYS include the event start and end datapoints.
 * And therefore will never be empty.
 *
 * @param { DatasetConfig } config
 * @param { RawData } data - The raw data
 * @return { TransformedDataset }
 */
export function transformDataSet(config, data) {
    console.groupCollapsed('Transform dataset: ', config.label);

    try {
        console.log('Raw response data: ', JSON.parse(JSON.stringify(data)));

        const timeunitFormat = getMomentFormatForTimeUnit(config.timeunit);
        const timeunitFormatPartialIso = getMomentFormatForTimeUnit(config.timeunit, true);

        const datapoints = _.sortBy(_.map(data.statistics.buckets, (salesDatapoint, key) => {
            const countDatapoint = _.get(data, [ 'statis_count', 'buckets', key ], {});
            const timestamp = _.get(salesDatapoint, 'key_as_string', null);

            return {
                at: moment(timestamp, timeunitFormat),
                timestamp,
                sales: _.get(salesDatapoint, 'sales.value', null),
                cumulative_sales: _.get(salesDatapoint, 'cumulative_sales.value', null),
                count: _.get(countDatapoint, 'count.value', null),
                cumulative_count: _.get(countDatapoint, 'cumulative_count.value', null),
            };
        }), 'timestamp');

        const transformedData = updateComputedProperties({
            config,
            datapoints,
            saleStart: _.size(datapoints) ? _.first(datapoints).at.clone() : moment(config.event.start, timeunitFormatPartialIso),
            eventStart: moment(config.event.start, timeunitFormatPartialIso),
            eventEnd: moment(config.event.end, timeunitFormatPartialIso),
        }, true);

        const eventDuration = 1 + transformedData.eventEnd.diff(transformedData.eventStart, config.timeunit);

        console.log('Initial transformed data', JSON.parse(JSON.stringify(transformedData)));

        if (!_.size(transformedData.datapoints)) {
            addExtraDataPoints(transformedData, eventDuration, transformedData.eventStart, 'push');
        } else {
            if (transformedData.eventStart.isBefore(transformedData.first, config.timeunit)) {
                // Event start is before data range
                addExtraDataPoints(transformedData, transformedData.first.diff(transformedData.eventStart, config.timeunit), transformedData.eventStart, 'unshift');
            }

            if (transformedData.eventEnd.isAfter(transformedData.last, config.timeunit)) {
                // Event end is after data range
                addExtraDataPoints(transformedData, transformedData.eventEnd.diff(transformedData.last, config.timeunit), transformedData.last.clone().add(1, config.timeunit), 'push');
            }
        }

        if (!_.size(transformedData.datapoints)) {
            console.error('datapoints empty', transformedData);

            throw new Error(`No datapoints for ${config.label}, unable to create comparable data.`);
        }

        console.log('TRANSFORMED', transformedData);

        return transformedData;

    } finally { console.groupEnd(); }
}

/**
 * Update the computed properties of the transformed dataset.
 *
 * This mutates the input object!
 *
 * @param { Omit<TransformedDataset, 'first' | 'last' | 'eventStartIndex' | 'eventEndIndex'> } transformedDataset
 * @param { boolean } allowMissing
 * @return { TransformedDataset }
 */
function updateComputedProperties(transformedDataset, allowMissing = false) {
    const updated = Object.assign(transformedDataset, {
        first: _.get(_.first(transformedDataset.datapoints), 'at', null),
        last: _.get(_.last(transformedDataset.datapoints), 'at', null),
        saleStartIndex: _.findIndex(transformedDataset.datapoints, (point) => point.at.diff(transformedDataset.saleStart, transformedDataset.config.timeunit) === 0),
        eventStartIndex: _.findIndex(transformedDataset.datapoints, (point) => point.at.diff(transformedDataset.eventStart, transformedDataset.config.timeunit) === 0),
        eventEndIndex: _.findIndex(transformedDataset.datapoints, (point) => point.at.diff(transformedDataset.eventEnd, transformedDataset.config.timeunit) === 0),
    });

    if (!allowMissing) {
        if (transformedDataset.first === null) {
            console.error('Dataset does not contain computed property', 'first', transformedDataset);

            throw new Error(`Dataset does not contain computed property first`);
        }

        if (transformedDataset.last === null) {
            console.error('Dataset does not contain computed property', 'last', transformedDataset);

            throw new Error(`Dataset does not contain computed property last`);
        }

        if (transformedDataset.saleStartIndex < 0) {
            console.error('Dataset does not contain computed property', 'saleStartIndex', transformedDataset);

            throw new Error(`Dataset does not contain computed property saleStartIndex`);
        }

        if (transformedDataset.eventStartIndex < 0) {
            console.error('Dataset does not contain computed property', 'eventStartIndex', transformedDataset);

            throw new Error(`Dataset does not contain computed property eventStartIndex`);
        }

        if (transformedDataset.eventEndIndex < 0) {
            console.error('Dataset does not contain computed property', 'eventEndIndex', transformedDataset);

            throw new Error(`Dataset does not contain computed property eventEndIndex`);
        }
    }

    // | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | Index
    // | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | length
    // | L |   |   | S |   | E |   |   |
    //
    //               left    = index[datapoint]
    // left:         0       = 0
    // right:        3       = 3
    // right-end:    5       = 5
    //
    //               right   = length - index[datapoint] - 1
    // left:         7       = 8 - 0 - 1
    // right:        4       = 8 - 3 - 1
    // right-end:    2       = 8 - 5 - 1
    return Object.assign(updated, {
        alignedOffsets: {
            left: {
                left: transformedDataset.saleStartIndex < 0 ? 0 : transformedDataset.saleStartIndex,
                right: (transformedDataset.saleStartIndex < 0 || !transformedDataset.datapoints.length)
                       ? 0
                       : transformedDataset.datapoints.length - transformedDataset.saleStartIndex - 1,
            },
            right: {
                left: transformedDataset.eventStartIndex < 0 ? 0 : transformedDataset.eventStartIndex,
                right: (transformedDataset.eventStartIndex < 0 || !transformedDataset.datapoints.length)
                       ? 0
                       : transformedDataset.datapoints.length - transformedDataset.eventStartIndex - 1,
            },
            'right-end': {
                left: transformedDataset.eventEndIndex < 0 ? 0 : transformedDataset.eventEndIndex,
                right: (transformedDataset.eventEndIndex < 0 || !transformedDataset.datapoints.length)
                       ? 0
                       : transformedDataset.datapoints.length - transformedDataset.eventEndIndex - 1,
            },
        },
    });
}

/**
 * Add datapoints to all datasets to fill them to the same length, aligned by the correct datapoint.
 *
 * This mutates the (nested) input arrays!
 *
 * @param { TransformedDataset[] } transformedDatasets
 * @param { 'left' | 'right' | 'right-end' } alignment
 * @return { TransformedDataset[] }
 */
export function alignDatasets(transformedDatasets, alignment) {
    const maxLeftRight = _.reduce(transformedDatasets, reduceMaxLeftRightForDataset, {
        alignment,
        left: 0,
        right: 0,
    });

    _.forEach(transformedDatasets, _.curry(alignDataset)(maxLeftRight));

    console.log('TRANSFORMED DATASETS', JSON.parse(JSON.stringify(transformedDatasets)));

    return transformedDatasets;
}

/**
 * Reducer to calculate the maximum number of datapoints left and right of the alignment point.
 *
 * The returned values do NOT include the alignment point itself.
 * i.e.: The event start and event end points for `right` and `right-end` and the first point in case of `left`.
 *
 * @param { { alignment: Alignment; left: number;  right: number; } } carry
 * @param { TransformedDataset } dataset
 * @return { { alignment: Alignment; left: number;  right: number; } }
 */
function reduceMaxLeftRightForDataset(carry, dataset) {
    if (!dataset.alignedOffsets[carry.alignment]) {
        console.error('Missing alignment', carry, dataset);

        throw new Error(`Missing alignment ${carry.alignment} for ${dataset.config.label}`);
    }

    return {
        alignment: carry.alignment,
        left: Math.max(carry.left, dataset.alignedOffsets[carry.alignment].left),
        right: Math.max(carry.right, dataset.alignedOffsets[carry.alignment].right),
    }
}

/**
 * Iterator for the alignDatasets function
 *
 * @param { { alignment: Alignment; left: number;  right: number; } } options
 * @param { TransformedDataset } dataset
 */
function alignDataset(options, dataset) {
    if (options.left > dataset.alignedOffsets[options.alignment].left) {
        const n = options.left - dataset.alignedOffsets[options.alignment].left;

        addExtraDataPoints(
            dataset,
            n,
            dataset.first.clone().subtract(n, dataset.config.timeunit),
            'unshift'
        );
    }

    if (options.right > dataset.alignedOffsets[options.alignment].right) {
        const n = options.right - dataset.alignedOffsets[options.alignment].right;

        addExtraDataPoints(
            dataset,
            n,
            dataset.last.clone().add(1, dataset.config.timeunit),
            'push'
        );
    }
}
