import template from './passwordReset.html';

export default angular.module('eventix.passwordReset',[ ])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.passwordReset', {
            url: '/passwordReset?email&token',
            views: {
                '@': {
                    controller: 'PasswordResetController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/{
            }
        });
    })
    .controller('PasswordResetController', function($state, UIMessages, $translate, $transition$, $http, Auth) {
        var vm = this;
        vm.hasToken = _.has($transition$.params(), 'token.length');
        vm.messages = UIMessages;
        vm.email = $transition$.params().email || '';
        const urlPrefix = AUTH_API_ROOT;
        vm.mailgun = (error, valid, suggestion) => {
            if(!vm.errors)
                vm.errors = { email: [] };
            if(!valid)
                _.set(vm, 'errors.email', [$translate.instant('validation.email',{ attribute: 'Email' })]);
            if(suggestion)
                vm.errors.email.push($translate.instant('common.notice.didYouMean', {suggestion: suggestion }));
        };
        vm.resetPassword = function() {
            if(vm.busy) return;
            vm.busy = true;
            $http.post(urlPrefix + '/passwords/reset/token', {
                token: $transition$.params().token,
                new_password: vm.password,
                new_password_confirmation: vm.password
            }).then(() => {
                vm.busy = false;
                UIMessages.push('common.notice.success');

                return Auth.gotoLogin($state);
            }, error => {
                vm.busy = false;
                if(_.get(error,'data.error_description.length'))
                    UIMessages.push(error.data.error_description);
                else
                    UIMessages.push('common.notice.unknownError');
            });
        };
        vm.requestReset = function() {
            if(vm.busy) return;
            vm.busy = true;
            $http.post(urlPrefix + '/passwords/forgot', { email: vm.email, client_id: OAUTH_ID }).then(() => {
                vm.busy = false;
                vm.checkMail = true;
            }, error => {
                vm.busy = false;
                if(_.get(error,'data.error_description.length'))
                    UIMessages.push(error.data.error_description);
                else
                    UIMessages.push('common.notice.unknownError');
            });
        };
    }).name;

