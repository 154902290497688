import templateUrl from './financials.html';
import './financials.less';
import { copyToClipboard as dashboardCopyToClipboard } from "../../../dashboard";

export default angular.module('eventix.dashboard.wizard.common.financials', [])
    .component('wizardFinancials', {
        controller: WizardFinancialsController,
        templateUrl: templateUrl,
        bindings: {
            event: '<',
            company: '<',
            isAdmin: '<'
        }
    }).name;

function WizardFinancialsController($q, ErrorRejector, UIMessages, Role) {
    const $ctrl = this;

    $ctrl.isAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');
    $ctrl.loading = true;
    $ctrl.tickets = [];

    $ctrl.editTicketFee = editTicketFee;
    $ctrl.doneEditingTicketFee = doneEditingTicketFee;
    $ctrl.copyToClipboard = copyToClipboard;

    $ctrl.$onInit = function () {
        loadTickets().catch(ErrorRejector.handle);
    };

    function loadTickets() {
        $ctrl.loading = true;

        return $ctrl.event.$queryTicketsWithShops()
            .then(wrapTickets)
            .then(tickets => $ctrl.tickets = tickets)
            .finally(() => $ctrl.loading = false);
    }

    function wrapTickets(tickets) {
        return $q.all(_.map(tickets, wrapTicket));
    }

    function wrapTicket(ticket) {
        return getOrCalculateMinimumPrice(ticket)
            .then(minimumPrice => new TicketWrapper(ticket, minimumPrice));
    }

    function getOrCalculateMinimumPrice(ticket) {
        if (!ticket.calculating) {
            ticket.calculating = ticket.calculateMinimumPrice(false)
                .then(() => {
                    console.debug('    > Done calculating minimumPrice for ticket', ticket.minimumPrice, ticket);
                })
                .then(() => ticket.minimumPrice)
                .finally(() => delete ticket.calculating);
        }

        return ticket.calculating;
    }

    function editTicketFee(ticket, shop = null) {
        $ctrl.editingShop = shop ? shop.shop : null;
        $ctrl.editingTicket = ticket.ticket;
    }

    function doneEditingTicketFee() {
        $ctrl.editingTicket = null;
        $ctrl.editingShop = null;

        loadTickets().catch(ErrorRejector.handle);
    }

    function copyToClipboard(value) {
        return UIMessages.push(dashboardCopyToClipboard(value))
    }
}

function TicketWrapper(ticket, basePrice) {
    this.ticket = ticket;

    this.minimumPrice = basePrice;
    this.basePrice = basePrice;

    if (basePrice === 0) {
        this.otherFee = 0;
        this.selfFee = 0;
    } else {
        this.otherFee = _.get(ticket.fees, ['other', 'fixed'], 0) + (basePrice * _.get(ticket.fees, ['other', 'percentages'], 0) / 100);
        this.selfFee = _.get(ticket.fees, ['self', 'fixed'], 0) + (basePrice * _.get(ticket.fees, ['self', 'percentages'], 0) / 100);
    }

    this.totalFee = this.otherFee + this.selfFee;

    this.totalPrice = basePrice + this.totalFee;

    this.shops = _.map(ticket.shops, shop => new TicketShopWrapper(shop, basePrice));

    this.showShops = false;
}

Object.defineProperties(TicketWrapper.prototype, {
    guid: {
        get() {
            return this.ticket ? this.ticket.guid : null;
        }
    },
    name: {
        get() {
            return this.ticket ? this.ticket.name : null;
        }
    },
    toggleShops: {
        value() {
            this.showShops = !this.showShops;
        }
    },
});

function TicketShopWrapper(shop, basePrice) {
    this.shop = shop;

    if (basePrice === 0) {
        this.otherFee = 0;
        this.selfFee = 0;
    } else {
        this.otherFee = _.get(shop.fees, ['other', 'fixed'], 0) + (basePrice * _.get(shop.fees, ['other', 'percentages'], 0) / 100);
        this.selfFee = _.get(shop.fees, ['self', 'fixed'], 0) + (basePrice * _.get(shop.fees, ['self', 'percentages'], 0) / 100);
    }

    this.totalFee = this.otherFee + this.selfFee;

    this.totalPrice = basePrice + this.totalFee;
}

Object.defineProperties(TicketShopWrapper.prototype, {
    guid: {
        get() {
            return this.shop ? this.shop.guid : null;
        }
    },
    name: {
        get() {
            return this.shop ? this.shop.name : null;
        }
    },
    url: {
        get() {
            return (this.shop && this.guid) ? FANCY_SHOP_ROOT + this.guid : null;
        }
    },
});
