import template from './codes.html';
import './codes.less';
import { copyToClipboard } from '../../dashboard';

export default angular.module('eventix.dashboard.coupons.codes', [])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.coupons.codes', {
            url: '/:couponGuid/codes?newCodes',
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'CodesController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/{
                coupon: function(Coupon, $transition$) {
                    return Coupon.get({guid: $transition$.params().couponGuid});
                }
            },
            data: {
                parent: {
                    name: 'eventix.dashboard.coupons',
                    label: 'common.menu.manage.coupons'
                }
            }
        });
    })
    .controller('CodesController', function($state, $http, $translate, $scope, $q, CrudCtrl, ErrorRejector, CSVExport, SweetAlert, ConfirmDeleteSweet, coupon, Coupon, CouponCode, Role) {
        const vm = this;

        vm.getCouponCodes = getCouponCodes;
        vm.newCodes = newCodes;
        vm.cancelNewCouponCodes = cancelNewCouponCodes;
        vm.saveNew = saveNew;
        vm.deleteCode = deleteCode;
        vm.disableCode = disableCode;
        vm.exportCodes = exportCodes;
        vm.copyToClipboard = copyToClipboard;

        vm.isAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');

        vm.newCouponCodes = {};
        vm.$errors = {};
        vm.coupon = coupon;
        vm.totalRows = 0;

        vm.crud = CrudCtrl.new([], () => CouponCode.new(), {determineInitState: false});
        vm.crud.beforeList(function(state) {
            if (state !== 'loading') {
                $state.params.newCodes = undefined;
            }
        });

        vm.showNew = false;
        vm.manual = false;

        $scope.$watch('vm.crud.model.manual', m => {
            vm.crud.model.generate = !m;
        });

        vm.yesno = [{
            value: true,
            label: $translate.instant('common.action.yes')
        }, {
            value: false,
            label: $translate.instant('common.action.no')
        }];

        vm.exportColumns = {
            'coupon.name': 'Campaign',
            'coupon.description': 'Description',
            'coupon.type': 'Type',
            'coupon.amount': 'Amount',
            'coupon.applies_to': 'Applies to',
            'code': 'Code',
            'usage_count': 'Usage Count',
            'applies_to_count': 'Applies to count',
            'used_count': 'Used Count',
            'created_at': 'Created at'
        };

        vm.guids = {
            coupon: {
                guid: vm.coupon ? vm.coupon.guid : '',
                icon: 'fa-gift',
                name: 'models.models.coupon',
            },
            code: {
                guid: vm.crud && vm.crud.model ? vm.crud.model.guid : '',
                icon: 'fa-ellipsis-h',
                name: 'models.models.couponCode',
            },
        };

        $scope.$watch('vm.coupon.guid', function(guid) {
            vm.guids.coupon.guid = guid || '';
        });
        vm.guids.coupon.guid = vm.coupon ? vm.coupon.guid : '';
        $scope.$watch('vm.crud.model.guid', function(guid) {
            vm.guids.code.guid = guid || '';
        });
        vm.guids.code.guid = vm.crud && vm.crud.model ? vm.crud.model.guid : '';

        function getCouponCodes(offset, limit, sorting, filters) {
            let query = {
                offset,
                limit
            };

            _.each(sorting, (direction, attribute) => {
                _.set(query, `sorting_${attribute}`, direction);
            });

            _.each(filters, (value, attribute) => {
                _.set(query, `filter_${attribute}`, value);
            });

            return $http.get(`/coupon/${coupon.guid}/codes/`, {params: query})
                .then(response => {
                    vm.totalRows = response.data.total;

                    if ($state.params.newCodes === true || $state.params.newCodes === 'true' || $state.params.newCodes === 1) {
                        newCodes();
                    }

                    return _.map(response.data.data, couponCode => CouponCode.new(couponCode));
                })
                .then(couponCodes => {
                    vm.crud.models = couponCodes;

                    return couponCodes;
                })
                .catch(ErrorRejector.handle);
        }

        function newCodes() {
            vm.newCouponCodes = {
                amount: 1,
                length: 9,
                format: 'upper',
                ambiguous: false,
                usage_count: 1,
                applies_to_count: 1
            };

            vm.$errors = {};
            vm.showNew = true;
            vm.manual = false;
        }

        function cancelNewCouponCodes() {
            vm.crud.list();
            vm.showNew = false;
        }

        function saveNew() {
            let promise;

            vm.$errors = {};

            if (vm.manual) {
                let codes = _.filter(_.get(vm.newCouponCodes, 'codes', '').split(/\s*[,;\n]\s*/gm));

                promise = $http.put(`/coupon/${vm.coupon.guid}/codes`, {
                    codes: _.map(codes, code => _.set({}, 'code', code)),
                    applies_to_count: vm.newCouponCodes.applies_to_count,
                    usage_count: vm.newCouponCodes.usage_count
                });
            } else {
                let data = Object.assign({}, vm.newCouponCodes);

                promise = $http.post(`/coupon/${vm.coupon.guid}/codes`, data);
            }

            promise
                .then(data => {
                    cancelNewCouponCodes();

                    return data;
                })
                .catch(error => {
                    if (error.data && error.data.error_description && _.isObject(error.data.error_description)) {
                        if (error.status === 409 || error.data.status_code === 409) {
                            let duplicates = _.get(error.data.error_description, 'codes', []);

                            let errorMsg = $translate.instant('models.coupon.notice.duplicates') + ' <ul><li>' + duplicates.join('</li><li>') + '</li></ul>';

                            _.set(error.data.error_description, ['codes'], [errorMsg]);
                        }

                        _.forEach(error.data.error_description, (errors, field) => {
                            let key = field.split('.')[0];

                            _.set(vm.$errors, key, _.concat(_.get(vm.$errors, key, []), errors));
                        });
                    }

                    return ErrorRejector.handle(error);
                });
        }

        function deleteCode(code, translations) {
            if (!code || !code.guid) {
                return ErrorRejector.handle('No code to disable');
            }

            if (code.used_count > 0 || code.pending_count > 0) {
                return ErrorRejector.handle('common.error.409.already-tickets-sold');
            }

            translations = _.defaults({}, translations, {
                text: {translation: 'common.notice.deleteConfirmCode'},
                confirm: 'common.action.delete'
            });

            return ConfirmDeleteSweet.open(code.code, translations, false)
                .then(() => vm.crud.fire('beforeDelete', code))
                .then(() => code.$delete())
                .then(() => vm.crud.fire('afterDelete', null, code), error => vm.crud.fire('afterDelete', error, code).then(() => $q.reject(error)))
                .then(() => vm.crud.list(), error => {
                    vm.crud.busy = false;
                    return vm.crud.handleError(error);
                })
                .then(data => {
                    $scope.$broadcast('table-view-refresh');
                    return data;
                })
                .then(() => {
                    SweetAlert.swal({
                        title: $translate.instant('common.notice.deleted'),
                        text: $translate.instant('common.notice.success'),
                        type: 'success',
                        confirmButtonText: $translate.instant('common.action.ok'),
                        closeOnClickOutside: true,
                        timer: 5000
                    });
                })
                .catch(error => {
                    error = ErrorRejector.handle(error);

                    SweetAlert.swal({
                        title: $translate.instant('common.notice.error'),
                        text: $translate.instant(error),
                        type: 'error',
                        confirmButtonText: $translate.instant('common.action.ok'),
                        closeOnClickOutside: true
                    });

                    return error;
                });
        }

        function disableCode(code) {
            if (!code || !code.guid) {
                return ErrorRejector.handle('No code to disable');
            }

            if (code.used_count > 0 || code.pending_count > 0) {
                code.usage_count = 1;
                code.applies_to_count = 1;

                if (vm.crud.showForm()) {
                    return vm.crud.save();
                }

                return code.$save()
                    .then(response => {
                        $scope.$broadcast('table-view-refresh');

                        return response;
                    });
            }

            return deleteCode(code, {text: {translation: 'common.notice.deleteConfirmCodeDisableUnused'}});
        }

        function exportCodes() {
            return $http.get(`/coupon/${coupon.guid}/codes/`)
                .then(response => response.data)
                .then(couponCodes => CSVExport.convert(couponCodes, vm.exportColumns, coupon.name))
                .then(csv => csv instanceof Error ? $q.reject(csv) : csv)
                .catch(ErrorRejector.handle);
        }
    }).name;
