import shopCollapseDemo from './shopCollapseDemo';
import templateUrl from './collapses.html';
import './collapses.less';

export default angular.module('eventix.dashboard.wizard.common.shop.collapses', [shopCollapseDemo])
    .component('shopCollapses', {
        controller: ShopCollapsesController,
        templateUrl: templateUrl,
        bindings: {
            shop: '<',
            event: '<',
            events: '<'
        }
    }).name;

function ShopCollapsesController($scope, $q, $log, CrudCtrl, Collapse, sortableCallbacks, $translate, $state) {
    const $ctrl = this;

    $ctrl.formOptionsAvailable = [{
        key: 'pre_title',
        label: $translate.instant('models.collapse.pre_title')
    }, {
        key: 'pre_content',
        label: $translate.instant('models.collapse.pre_content')
    }, {
        key: 'inner_title',
        label: $translate.instant('models.collapse.inner_title')
    }, {
        key: 'inner_content',
        label: $translate.instant('models.collapse.inner_content')
    }, {
        key: 'post_content',
        label: $translate.instant('models.collapse.post_content')
    }];

    $ctrl.$postLink = function() {
        $ctrl.shop.$queryTicket()
            .then(tickets => _.map(tickets, ticket => _.tap(ticket, ticket => ticket.event = _.find($ctrl.events, {guid: ticket.event_id}))))
            .then(tickets => $ctrl.tickets = tickets);

        $ctrl.crud = CrudCtrl.new(
            () => $ctrl.shop.$queryCollapse(),
            () => Collapse.new(),
            {
                autoNew: false,
                persistState: false,
                stateParamNew: false,
                determineInitState: false
            }
        );

        $ctrl.crud.addBelongsToMany('tickets', 'Ticket');
        $ctrl.crud.afterSave((err, collapse, isNew) => {
            if(err || !isNew) {
                return $q.resolve();
            }

            return $ctrl.shop.$attachCollapse(collapse)
                .then(() => {
                    $ctrl.sorting.attach(collapse);

                    if(!collapse.pre_tickets) {
                        return $q.resolve();
                    }

                    let oldIndex = _.findIndex($ctrl.crud.models, { guid: collapse.guid });
                    let newIndex = 0;

                    $ctrl.crud.models.splice(oldIndex, 1);
                    $ctrl.crud.models.splice(0, 0, collapse);

                    return $ctrl.sorting.sort({
                        model: collapse,
                        models: $ctrl.crud.models,
                        oldIndex: oldIndex,
                        newIndex: newIndex
                    });
                })
                .then(() => {
                    $ctrl.beforeTickets = _.filter($ctrl.crud.models, { pre_tickets: true });
                    $ctrl.afterTickets = _.filter($ctrl.crud.models, { pre_tickets: false });
                });
        });

        $ctrl.crud.afterLoad((err, models) => {
            if(err) {
                return;
            }

            $ctrl.beforeTickets = _.filter(models, { pre_tickets: true });
            $ctrl.afterTickets = _.filter(models, { pre_tickets: false });
        });

        $ctrl.crud.afterDelete((err, collapse) => {
            if(err) {
                return;
            }

            $ctrl.sorting.detach(collapse);
        });
    };

    $ctrl.back = function() {
        let path = $state.includes('eventix.dashboard.wizard.simple') ? 'simple' : 'advanced';

        $state.go(`eventix.dashboard.wizard.${path}.shops`, {
            editing: $ctrl.shop.guid,
            guid: $ctrl.event.guid
        });
    };

    $ctrl.sorting = {
        group: {
            pull: true,
            put: true,
            name: 'collapses'
        },
        animation: 150,
        get disabled() {
            return _.get($ctrl, 'crud.busy', true);
        },
        set disabled(b) {
            return _.get($ctrl, 'crud.busy', true);
        },
        onUpdate: function(ev) {
            let oldIndexGlobal = _.findIndex($ctrl.crud.models, { guid: ev.model.guid });
            let newIndexGlobal = null;

            if(ev.newIndex > 0) {
                let siblingAbove = ev.models[ev.newIndex - 1].guid;

                newIndexGlobal = _.findIndex($ctrl.crud.models, { guid: siblingAbove }) + 1;
            } else if(ev.newIndex < ev.models.length - 1) {
                let siblingBelow = ev.models[ev.newIndex + 1].guid;

                newIndexGlobal = _.findIndex($ctrl.crud.models, { guid: siblingBelow }) - 1;
            } else if(ev.originalEvent.from !== ev.originalEvent.to && ev.models.length === 1) {
                let dst = angular.element(ev.originalEvent.to);

                newIndexGlobal = dst.hasClass('before') ? 0 : $ctrl.crud.models.length - 1;
            }

            if(newIndexGlobal === null) {
                return true;
            }

            if(oldIndexGlobal === newIndexGlobal) {
                return true;
            }

            if(oldIndexGlobal < newIndexGlobal) {
                newIndexGlobal--;
            }

            $ctrl.crud.models.splice(oldIndexGlobal, 1);
            $ctrl.crud.models.splice(newIndexGlobal, 0, ev.model);

            return $ctrl.sorting.sort({
                oldIndex: oldIndexGlobal,
                newIndex: newIndexGlobal,
                model: ev.model,
                models: $ctrl.crud.models
            });
        },
        onAdd: function(ev) {
            let dst = angular.element(ev.originalEvent.to);

            ev.model.pre_tickets = dst.hasClass('before');
            ev.model.$save();

            return $ctrl.sorting.onUpdate(ev);
        },
        sort: sortableCallbacks.onSort((pred, succ) => {
            return $ctrl.shop.$reorderCollapse(pred, succ);
        }),
        attach: sortableCallbacks.onAttach(() => $ctrl.crud.models),
        detach: sortableCallbacks.onDetach(() => $ctrl.crud.models)
    };

    $ctrl.new = function(type) {
        return $ctrl.crud.new().then(() => {
            $ctrl.crud.model.start_open = true;
            $ctrl.crud.model.pre_tickets = type === 'before';
        });
    };

    function assignChildren(model, container, children) {
        Object.defineProperty(model, container, {
            value: children,
            enumerable: false,
            configurable: true,
            writable: true
        });

        return children;
    }
}
