import templateUrl from './productsAndGroups.html';

export default angular.module('eventix.dashboard.wizard.common.productsAndGroups', [])
    .component('productsAndGroups', {
        templateUrl: templateUrl,
        bindings: {
            event: '=',
            crud: '=',
            adminOnly: '<',
        },
        controller: productsAndGroupsController,
    }).name;

function productsAndGroupsController(sortableCallbacks, UIMessages, ErrorRejector, ProductGroup, $q) {

    const $ctrl = this;

    $ctrl.forcedProducts = [];

    $ctrl.$postLink = function() {

        // Used for the product groups
        $ctrl.sortable = {
            animation: 150,
            get disabled() {
                return $ctrl.crud && $ctrl.crud.busy;
            },
            set disabled(v) {
                return v;
            },
            onSort: sortableCallbacks.onSort((pre, suc) => $ctrl.crud.reorderProductGroup(pre, suc)),
        };

        computeForcedProducts();

    };

    /**
     * Compute the (current) forced products of the ticket, which are equal to the products
     * that are currently attached to the ticket, but not to a group of the ticket.
     */
    function computeForcedProducts() {
        return $ctrl.crud.model.fillProducts(true, true).then(res => {
            $ctrl.forcedProducts = $ctrl.crud.model.products.filter(({ guid }) => {
                return $ctrl.crud.model.nonGroupProducts.indexOf(guid) > -1;
            });
        });
    }

    /**
     * Used to check if a product is attached to either the ticket or a group, also
     * for the latter because every product (also if it is attached to a group)
     * is present within the ticket's products prop.
     *
     * @param {Product} product The product to check if it currently is attached
     * @return {Boolean} If the product is attached
     */
    function alreadyAttached(product) {

        // Every attached product is present in the ticket.products,
        // so forwarding a group to check it in is obsolete
        const attachedModelIds = $ctrl.crud.model.products.map(({ guid }) => guid);

        return attachedModelIds.indexOf(product.guid) > -1;

    }

    /**
     * Used for handling errors.
     * @param {String} message The message to show
     * @param {Error} err The error that was thrown
     * @param {Function} cb The possible cb to execute
     */
    function errorHandler(message, err, cb) {

        ErrorRejector.handle(message);

        if (err) {
            console.error(message, err);
        }

        (cb || angular.noop)();

    }

    /**
     * Used to pick products for either a ticket or a forwarded group, making sure that
     * products that are not attached are attached and that products that are attached but
     * were not selected anymore are detached.
     *
     * @param {Product} products The products that were picked by the user in the model picker
     * @param {ProductGroup} group A ProductGroup instance to possible attach/detach products to/from
     */
    $ctrl.pickProducts = function(products, group) {

        if (!$ctrl.crud.showEdit() && !$ctrl.crud.showNew()) {
            return ErrorRejector.handle('Can not pick products, not editing/creating a ticket.');
        }

        if (group && !group.products) {
            return ErrorRejector.handle('Can not pick products, the forwarded group does not contain products.');
        }

        // Get a reference to the right models to check for attaching/detaching
        const models = group ? group.children : $ctrl.forcedProducts;

        // Detach models that are currently attached but not selected anymore
        const detachQueue = _.reduce(models, (detachQueue, product) => {

            const detach = _.findIndex(products, { guid: product.guid }) < 0;

            return !detach
                   ? detachQueue
                   : detachQueue.then(() => $ctrl.detachProduct(product, group));

        }, $q.resolve());

        // Attach models that are currently selected but not attached already
        const attachQueue = _.reduce(products, (attachQueue, product) => {

            const attach = _.findIndex(models, { guid: product.guid }) < 0;

            return !attach
                   ? attachQueue
                   : attachQueue.then(() => $ctrl.attachProduct(product, group));

        }, detachQueue);

        // Used for handling errors via the ctrl's error handler
        const handleError = (err) => {
            errorHandler('Something went wrong while attaching/detaching products.', err, computeForcedProducts);
        };

        // Always re-compute the forced products after all attaching/detaching is done,
        // so the $ctrl.forcedProducts[] is always up to date after a round of
        // attaching/detaching and this logic keeps working as expected
        return attachQueue.then(() => {

            if (!group) {
                computeForcedProducts();
                return;
            }

            // Make sure to sync the children's prop with the just selected products
            group.children = products;

            // Save the group when it's about an already saved group
            if (group.ticket_id) {
                return group.$save()
                    .then(computeForcedProducts, handleError)
                    .then(() => products)
                    .catch(handleError);
            }

            return computeForcedProducts().then(() => products);
        }, handleError).catch(handleError);

    };

    /**
     * Used to attach the forwarded product to either the ticket or the forwarded group. The saving of
     * the updated group happens in the pickProducts fn when all attaching/detaching is done.
     *
     * @param {Product} product The product to attach to either the ticket or the forwarded group
     * @param {ProductGroup} group A ProductGroup instance for current model
     * @return {Promise} Resolves when attached
     */
    $ctrl.attachProduct = function(product, group) {
        let isCurrentlyNonGrouped = $ctrl.crud.model.nonGroupProducts.indexOf(product.guid) > -1;
        let isAlreadyInAGroup = $ctrl.crud.model.groupProductGuids.indexOf(product.guid) > -1;
        let isAlreadyAttached = alreadyAttached(product);

        if (isAlreadyAttached && isAlreadyInAGroup && !group) {
            UIMessages.push({
                message: 'Product is already attached to a group, please remove it first',
                type: 'error',
            });
            return $q.reject(new Error('AlreadyAttached'));
        }
        if (isAlreadyAttached && isAlreadyInAGroup && group) {
            UIMessages.push({
                message: 'Product is already attached to another group, please remove it first',
                type: 'error',
            });
            return $q.reject(new Error('AlreadyAttached'));
        }

        if (isAlreadyAttached && !isCurrentlyNonGrouped) {
            UIMessages.push('Product already attached');
            return $q.reject(new Error('AlreadyAttached'));
        }

        if (!isAlreadyAttached) {
            return $ctrl.crud.attachProduct(product).then(() => {

                // But only also attach it to a group when a group is provided
                if (!group) {
                    return;
                }

                // Update the min bound and max bound accordingly to keep the group required
                const isRequiredGroup = group.isRequiredGroup();

                if (isRequiredGroup) {
                    group.min_bound++;
                    group.max_bound++;
                }

                return group.$attachProduct(product);
            });
        } else if (isAlreadyAttached && isCurrentlyNonGrouped && group) {
            const isRequiredGroup = group.isRequiredGroup();

            if (isRequiredGroup) {
                group.min_bound++;
                group.max_bound++;
            }

            return group.$attachProduct(product);
        }
    };

    /**
     * Used to detach the forwarded product to either the ticket or the forwarded group. The saving of
     * the updated group happens in the pickProducts fn when all attaching/detaching is done.
     *
     * @param {Product} product The product to attach to either the ticket or the forwarded group
     * @param {ProductGroup} group A ProductGroup instance for current model
     * @return {Promise} Resolves when detached
     */
    $ctrl.detachProduct = function(product, group) {

        // Always detach the product from the ticket
        return $ctrl.crud.detachProduct(product).then(() => {

            // But only also detach it from a group when a group has been provided
            if (!group) {
                return;
            }

            // Update the min bound and max bound accordingly to keep the group required
            const isRequiredGroup = group.isRequiredGroup();

            if (isRequiredGroup) {
                group.min_bound--;
                group.max_bound--;
            }

            // Directly detach from the group if the group was already
            // saved and simply remove it out his children if not
            if (group.guid) {
                group.$detachProduct(product);
            } else {
                _.pull(group.children, product);
            }

        });

    };

    /**
     * Used to add a new product group to the current ticket, at which the children's prop
     * is being added automatically in the pickProducts fn.
     */
    $ctrl.addProductGroup = function() {

        const newProductGroup = ProductGroup.new({
            ticket_id: $ctrl.crud.model.guid,
            type: 'min_choose',
            bound: 0,
            min_bound: 0,
            max_bound: 0,
            uniqueness: 1,
            products: [],
        });

        $ctrl.crud.model.groups.push(newProductGroup);

        // Make sure the children Array is present within the group, so the
        // pickerProducts fn won't throw an error, quick fix for now
        newProductGroup.children = [];

    };

    /**
     * Used for deleting a product group, making sure all the
     * attached products are being detached first.
     *
     * @param {ProductGroup} group A ProductGroup instance for current model
     * @return {Promise} Resolves when deleted
     */
    $ctrl.deleteProductGroup = function(group) {

        // Make sure to first detach the products
        const products = _.filter($ctrl.crud.model.products, (product) => _.includes(group.products, product.guid));

        // Used for handling errors via the ctrl's error handler
        const handleError = (err) => {
            errorHandler('Something went wrong while deleting the group', err);
        };

        return _.reduce(products, (promise, product) => {

            return promise.then(() => $ctrl.crud.detachProduct(product));

        }, $q.resolve()).then(() => {

            if (!group.guid) {
                return $q.resolve(_.remove($ctrl.crud.model.groups, group));
            }

            return group.$delete().then(() => {
                return _.remove($ctrl.crud.model.groups, group);
            });

        }, handleError).catch(handleError);

    };

}
