import locationPicker from './components/locationPicker';
import childSelector from './components/childSelector';
import moreOptions from './components/moreOptions';
import unlimitedInput from './components/unlimitedInput';
import markRequired from './components/markRequired';
import shopIntegrationModal from './components/shopIntegrationModal';
import productsAndGroups from './components/productsAndGroups/productsAndGroups';
import shops from './shops/shops';
import financials from './financials/financials';
import forms from './forms';
import SimpleTicket from './SimpleTicket';
import EventCloner from './EventCloner';

export default angular.module('eventix.dashboard.wizard.common', [
    locationPicker,
    childSelector,
    moreOptions,
    unlimitedInput,
    markRequired,
    shopIntegrationModal,
    productsAndGroups,
    shops,
    financials,
    forms,
    SimpleTicket,
    EventCloner
]).name;
