import Whitelabel from './whitelabel/whitelabel';
import Companies from './companies/companies';
import Upcoming from './upcoming/upcoming';
import Payouts from './payouts/payouts';

export default angular.module('eventix.dashboard.admin',[Whitelabel, Companies, Upcoming, Payouts])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.admin', {
            url: '/admin',
            abstract: true,
            data: {
                requiredRoles: ['Admin'],
                crumb: 'Admin'
            }
        });
    }).name;
