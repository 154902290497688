import template from './returnOrderModal.html';
import './returnOrderModal.less';
import rudderstack from "../../../../rudderstack/rudderstack";

export default angular.module('eventix.dashboard.orders.return.modal', [])
    .component('returnOrderModal', {
        controller: returnOrderModalController,
        templateUrl: template,
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
        },
    }).name;

function returnOrderModalController(UIMessages, $http, ErrorRejector, $window, $filter, $timeout, $scope, Role) {
    const $ctrl = this;
    const formatter = $filter('formatCurrency');

    const accessToken = $window.auth ? $window.auth.getAccessToken() : null;

    // Will be filled in onInit
    $ctrl.returns = null;
    $ctrl.submitDisabled = true;
    $ctrl.comment = '';
    $ctrl.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');

    $ctrl.toggleOrder = toggleOrder;
    $ctrl.toggleTicket = toggleTicket;
    $ctrl.toggleProduct = toggleProduct;
    $ctrl.toggleAllProducts = toggleAllProducts;
    $ctrl.onRefundChanged = onRefundChanged;
    $ctrl.onFeesChanged = onFeesChanged;
    $ctrl.onTransactionChanged = onTransactionChanged;
    $ctrl.save = save;
    $ctrl.formatCurrency = formatCurrency;

    $ctrl.$onInit = function() {
        if (!accessToken || !accessToken.access_token || !accessToken.access_token.length) {
            throw Error('TemplateModalController should be initialized with access token');
        }

        _.assign($ctrl, $ctrl.resolve);

        $ctrl.returns = _setOrderStructure($ctrl.order);

        _determinePresets();

        rudderstack.track('dashboard_order_refund_open_modal', {
            order_id: $ctrl.order.guid
        });
    };

    function _setOrderStructure(order) {
        const returns = {
            guid: order.guid,
            value: false,
            price: {
                total: calculateRefundableAmount(order, null, null, true, true),
                inc_fees: calculateRefundableAmount(order, null, null, true, false),
                inc_transaction: calculateRefundableAmount(order, null, null, false, true),
                value_only: calculateRefundableAmount(order, null, null, false, false),
            },
            returnable: !order.invalidated_since,
            refundable: $ctrl.isAdminOrWLAdmin || !order.invalidated_since,
            invalidated_reason: order.invalidated_reason,
            hasTickets: false,
            ticketsOpen: true,
            tickets: {},
            hasProducts: false,
            productsOpen: false,
            allProducts: false,
            products: {},
            refund: $ctrl.action === 'return',
            transaction: true,
            transactionFeeRefundable: true,
            fees: true,
            totalRefundPrice: 0,
            overwriteInfo: {
                toggle: false,
                account_name: '',
                account_number: '',
                account_bic: '',
            },
            overwriteAmount: {
                toggle: false,
                vat: 0,
                amount: 0,
                input: null,
                // percentage: 0,
            },
        };

        _.forEach(order.payments, function(payment) {
            payment.is_refundable = true;
            _.forEach(payment.returns, function(returned){
                if(returned.amount != 0){
                    returns.transaction = false;
                    returns.transactionFeeRefundable = false;
                }
            });
        });

        order.tickets.forEach(ticket => {
            returns.tickets[ticket.guid] = {
                guid: ticket.guid,
                value: false,
                name: ticket.ticket.name,
                ticket_number: ticket.ticket_number,
                price: {
                    total: calculateRefundableAmount(order, ticket, null, true),
                    value_only: calculateRefundableAmount(order, ticket, null, false),
                },
                checkedIn: false,
                returnable: !order.invalidated_since && !ticket.invalidated_since,
                refundable: (!order.invalidated_since && !ticket.invalidated_since) || ticket.is_refundable,
                invalidated_reason: ticket.invalidated_reason,
                hasChildren: false,
                childrenOpen: false,
                products: {},
            };

            returns.hasTickets = true;

            ticket.products.forEach(product => {
                returns.tickets[ticket.guid].products[product.guid] = {
                    guid: product.guid,
                    value: false,
                    name: product.product.name,
                    checkedIn: false,
                    price: {
                        total: calculateRefundableAmount(order, ticket, product, true),
                        value_only: calculateRefundableAmount(order, ticket, product, false),
                    },
                    returnable: !order.invalidated_since && !ticket.invalidated_since && !product.invalidated_since,
                    refundable: (!order.invalidated_since && !ticket.invalidated_since && !product.invalidated_since) || (ticket.is_refundable && product.is_refundable),
                    invalidated_reason: product.invalidated_reason,
                };

                returns.tickets[ticket.guid].hasChildren = true;

                if (product.scanned_amount > 0) {
                    returns.tickets[ticket.guid].products[product.guid].checkedIn = true;
                    returns.tickets[ticket.guid].checkedIn = true;
                }
            });
        });

        order.products.forEach(product => {
            returns.products[product.guid] = {
                guid: product.guid,
                value: false,
                name: product.product.name,
                checkedIn: false,
                price: {
                    total: calculateRefundableAmount(order, null, product, true),
                    value_only: calculateRefundableAmount(order, null, product, false),
                },
                returnable: !order.invalidated_since && !product.invalidated_since,
                refundable: (!order.invalidated_since && !product.invalidated_since) || product.is_refundable,
                invalidated_reason: product.invalidated_reason,
            };

            returns.hasProducts = true;

            if (product.scanned_amount > 0) {
                returns.products[product.guid].checkedIn = true;
            }
        });

        return returns;
    }

    $ctrl.return_method = 'disabled';
    $ctrl.changeReturnMethod = changeReturnMethod;
    function changeReturnMethod(){
        if($ctrl.return_method == 'disabled'){
            return;
        }
        else {
            $ctrl.returns.refund = ($ctrl.return_method === 'true');
            _setTotalRefundPrice();
        }
    }


    function _determinePresets() {
        // Determine any presets
        if ($ctrl.product && $ctrl.ticket) {
            const ticket = $ctrl.returns.tickets[$ctrl.ticket.guid];

            if (ticket) {
                const product = ticket.products[$ctrl.product.guid];

                if (product) {
                    ticket.childrenOpen = true;

                    _setProduct(ticket, product, true);
                }
            }
        } else if ($ctrl.ticket) {
            const ticket = $ctrl.returns.tickets[$ctrl.ticket.guid];

            if (ticket) {
                _setTicket(ticket, true);
            }
        } else if ($ctrl.product) {
            const product = $ctrl.returns.products[$ctrl.product.guid];

            if (product) {
                $ctrl.returns.productsOpen = true;

                _setProduct(undefined, product, true);
            }
        } else {
            _setOrder(true);
        }

        _setTotalRefundPrice();
    }

    function toggleOrder() {
        if (!$ctrl.returns.returnable && !$ctrl.returns.refundable) {
            console.error('Order is not returnable');

            return;
        }

        _setOrder($ctrl.returns.value === false);

        _setTotalRefundPrice();
    }

    function toggleTicket(ticketId) {
        if (!$ctrl.returns.returnable && !$ctrl.returns.refundable) {
            console.error('Order is not returnable');

            return;
        }

        const ticket = $ctrl.returns.tickets[ticketId];

        if (!ticket) {
            console.error('Failed to toggle ticket, not found: ', ticketId);

            return;
        }

        if (!ticket.returnable && !ticket.refundable) {
            console.error('Ticket is not returnable', ticketId);

            return;
        }

        // NULL and true should become false
        _setTicket(ticket, ticket.value === false);

        _setTotalRefundPrice();
    }

    function toggleProduct(ticketId, productId) {
        if (!$ctrl.returns.returnable && !$ctrl.returns.refundable) {
            console.error('Order is not returnable');

            return;
        }

        const parent = ticketId ? $ctrl.returns.tickets[ticketId] : $ctrl.returns;

        if (!parent) {
            console.error('Failed to toggle product, no parent: ', ticketId, productId);

            return;
        }

        if (!parent.returnable && !parent.refundable) {
            console.error('Product parent is not returnable', ticketId, productId);

            return;
        }

        const product = parent.products[productId];

        if (!product) {
            console.error('Failed to toggle product, not found: ', ticketId, productId);

            return;
        }

        if (!product.returnable && !product.refundable) {
            console.error('Product is not returnable', ticketId, productId);

            return;
        }

        // NULL and true should become false
        _setProduct(ticketId ? parent : undefined, product, product.value === false);

        _setTotalRefundPrice();
    }

    function toggleAllProducts() {
        if (!$ctrl.returns.returnable && !$ctrl.returns.refundable) {
            console.error('Order is not returnable');

            return;
        }

        _.forEach($ctrl.returns.products, (product) => {
            // NULL and true should become false
            _setProduct(undefined, product, product.value === false);
        });

        _setTotalRefundPrice();
    }

    function onRefundChanged() {
        _setTotalRefundPrice();
    }

    function onFeesChanged() {
        _setTotalRefundPrice();
    }

    function onTransactionChanged() {
        if(!$ctrl.returns.transactionFeeRefundable){
            $ctrl.returns.transaction = false;
            return ErrorRejector.handle('models.order.return.refund.transaction_already_processed');
        }
        _setTotalRefundPrice();
    }

    function _setOrder(value) {
        if (!$ctrl.returns.returnable && !$ctrl.returns.refundable) {
            console.error('Order is not returnable');

            return;
        }

        // NULL and true should become false
        $ctrl.returns.value = value;

        _.forEach($ctrl.returns.tickets, (ticket) => {
            _setTicket(ticket, value, true);
        });

        _.forEach($ctrl.returns.products, (product) => {
            _setProduct(undefined, product, value, true);
        });

        _determineAllProductsToggle();

        _setTotalRefundPrice();
    }

    function _setTicket(ticket, value, ignoreParents = false) {
        if ((!$ctrl.returns.returnable && !$ctrl.returns.refundable) || (!ticket.returnable && !ticket.refundable)) {
            console.error('Ticket is not returnable', ticket.guid);

            return;
        }

        if (ticket.returnable || ticket.refundable) {
            ticket.value = value;

            _.forEach(ticket.products, (product) => {
                _setProduct(ticket, product, value, true);
            });
        }

        if (ignoreParents) {
            return;
        }

        _determineOrderToggle();

        _setTotalRefundPrice();
    }

    function _setProduct(ticket, product, value, ignoreParents = false) {
        if ((!$ctrl.returns.returnable && !$ctrl.returns.refundable) || (ticket && !ticket.returnable && !ticket.refundable) || (!product.returnable && !product.refundable)) {
            console.error('Product is not returnable', ticket && ticket.guid, product.guid);

            return;
        }

        product.value = value;

        if (ignoreParents) {
            return;
        }

        if (ticket) {
            _determineTicketToggle(ticket.guid);
        } else {
            _determineAllProductsToggle();
        }

        _determineOrderToggle();

        _setTotalRefundPrice();
    }

    function _determineOrderToggle() {
        let atLeastOne = false;
        let all = true;

        if (!$ctrl.returns.hasTickets && !$ctrl.returns.hasProducts) {
            $ctrl.returns.value = !!$ctrl.returns.value;

            _setTotalRefundPrice();

            return;
        }

        if ($ctrl.returns.hasTickets) {
            _.forEach($ctrl.returns.tickets, (ticket) => {
                if (!ticket.returnable && !ticket.refundable) {
                    return;
                }

                if (!ticket.value) {
                    all = false;
                }

                if (ticket.value || ticket.value === null) {
                    atLeastOne = true;
                }
            });
        }

        if ($ctrl.returns.hasProducts) {
            if (!$ctrl.returns.allProducts) {
                all = false;
            }

            if ($ctrl.returns.allProducts || $ctrl.returns.allProducts === null) {
                atLeastOne = true;
            }
        }

        if (all) {
            $ctrl.returns.value = true;
        } else {
            $ctrl.returns.value = atLeastOne ? null : false;
        }

        _setTotalRefundPrice();
    }

    function _determineTicketToggle(ticketId) {
        const ticket = $ctrl.returns.tickets[ticketId];

        if (!ticket) {
            console.error('Failed to determine ticket toggle, no ticket: ', ticketId);

            return;
        }

        if (!ticket.hasChildren) {
            ticket.value = !!ticket.value;

            return;
        }

        let atLeastOne = false;
        let all = true;

        _.forEach(ticket.products, (product) => {
            if (!product.returnable && !product.refundable) {
                return;
            }

            if (!product.value) {
                all = false;
            }

            if (product.value || product.value === null) {
                atLeastOne = true;
            }
        });

        if (all) {
            ticket.value = true;
        } else {
            ticket.value = atLeastOne ? null : false;
        }
    }

    function _determineAllProductsToggle() {
        if (!$ctrl.returns.hasProducts) {
            $ctrl.returns.allProducts = !!$ctrl.returns.allProducts;

            return;
        }

        let atLeastOne = false;
        let all = true;

        _.forEach($ctrl.returns.products, (product) => {
            if (!product.returnable && !product.refundable) {
                return;
            }

            if (!product.value) {
                all = false;
            }

            if (product.value || product.value === null) {
                atLeastOne = true;
            }
        });

        if (all) {
            $ctrl.returns.allProducts = true;
        } else {
            $ctrl.returns.allProducts = atLeastOne ? null : false;
        }
    }

    $ctrl.recalculate = _setTotalRefundPrice;

    function _setTotalRefundPrice() {
        $ctrl.submitDisabled = $ctrl.return_method == 'disabled' || $ctrl.comment == '' || !$ctrl.returns.toc;

        if (!$ctrl.returns.refund) {
            $ctrl.returns.totalRefundPrice = 0;

            return;
        }

        if ($ctrl.returns.value) {
            if ($ctrl.returns.fees && $ctrl.returns.transaction) {
                $ctrl.returns.totalRefundPrice = $ctrl.returns.price.total;
            } else if ($ctrl.returns.fees) {
                $ctrl.returns.totalRefundPrice = $ctrl.returns.price.inc_fees;
            } else if ($ctrl.returns.transaction) {
                $ctrl.returns.totalRefundPrice = $ctrl.returns.price.inc_transaction;
            } else {
                $ctrl.returns.totalRefundPrice = $ctrl.returns.price.value_only;
            }

            $ctrl.returns.overwriteAmount.amount = $ctrl.returns.totalRefundPrice;

            if($ctrl.returns.overwriteAmount.toggle === false){
                $ctrl.returns.overwriteAmount.input = $ctrl.returns.overwriteAmount.amount;
            }
            else{
                $ctrl.returns.overwriteAmount.amount = $ctrl.returns.overwriteAmount.input;
            }

            return;
        }

        let sumAmount = 0;

        _.forEach($ctrl.returns.tickets, (ticket) => {
            if (ticket.value) {
                sumAmount += $ctrl.returns.fees ? ticket.price.total : ticket.price.value_only;

                return;
            }

            _.forEach(ticket.products, (product) => {
                if (product.value) {
                    sumAmount += $ctrl.returns.fees ? product.price.total : product.price.value_only;
                }
            });
        });

        _.forEach($ctrl.returns.products, (product) => {
            if (product.value) {
                sumAmount += $ctrl.returns.fees ? product.price.total : product.price.value_only;
            }
        });

        if ($ctrl.returns.transaction) {
            sumAmount += _.sumBy($ctrl.order.payments, 'finn_service_fee');
        }

        $ctrl.returns.totalRefundPrice = sumAmount;

        $ctrl.returns.overwriteAmount.amount = $ctrl.returns.totalRefundPrice;

        if($ctrl.returns.overwriteAmount.toggle === false){
            $ctrl.returns.overwriteAmount.input = $ctrl.returns.overwriteAmount.amount;
        }
        else{
            $ctrl.returns.overwriteAmount.amount = $ctrl.returns.overwriteAmount.input;
        }
    }

    function calculateRefundableAmount(order, ticket = null, product = null, incFees = true, incTransaction = true){
        let productAmount = _.get(product, 'finn_price');
        let ticketAmount = _.get(ticket, 'finn_price');
        let orderAmount = _.get(order, 'finn_price');

        if (!incFees) {
            if (!_.isNil(productAmount)) {
                productAmount -= _.get(product, 'finn_service_fee', 0);
            }

            if (!_.isNil(ticketAmount)) {
                ticketAmount -= _.get(ticket, 'finn_service_fee', 0);
            }

            orderAmount -= _.get(order, 'finn_service_fee', 0);
        }

        if (incTransaction) {
            orderAmount += _.sumBy(order.payments, 'finn_service_fee');
        }

        let alreadyInvalidPaymentsAmount = _.sumBy(_.filter(order.payments, function(p) { return !p.is_refundable; }), function(p) { return incTransaction ? p.finn_service_fee : 0; });
        let alreadyInvalidTicketsAmount = _.sumBy(_.filter(order.tickets, function(t) { return !t.is_refundable; }), function(t) { return t.finn_value + (incFees ? t.finn_service_fee : 0); });
        let alreadyInvalidProductsAmount = 0;
        alreadyInvalidProductsAmount += _.sumBy(_.filter(order.products, function(p) { return !p.is_refundable; }), function(p) { return p.finn_value + (incFees ? p.finn_service_fee : 0); });
        _.forEach(order.tickets, function(t) {
            alreadyInvalidProductsAmount += _.sumBy(_.filter(t.products, function(p) { return !p.is_refundable; }), function(p) { return p.finn_value + (incFees ? p.finn_service_fee : 0); });
        });
        let alreadyInvalidAmount = alreadyInvalidTicketsAmount + alreadyInvalidProductsAmount + alreadyInvalidPaymentsAmount;

        let alreadyInvalidAmountForTicket = 0;
        if(!_.isNil(ticketAmount)) {
            alreadyInvalidAmountForTicket = _.sumBy(_.filter(ticket.products, function(p) { return !p.is_refundable; }), function(p) { return p.finn_value + (incFees ? p.finn_service_fee : 0); });
        }

        let alreadyInvalidAmountForProduct = 0;
        if(!_.isNil(productAmount)) {
            alreadyInvalidAmountForProduct = !product.is_refundable ? product.finn_value + (incFees ? product.finn_service_fee : 0) : 0;
        }

        let amount;

        if (!_.isNil(productAmount)) {
            amount = productAmount - alreadyInvalidAmountForProduct;
        } else if (!_.isNil(ticketAmount)) {
            amount = ticketAmount - alreadyInvalidAmountForTicket;
        } else {
            amount = orderAmount - alreadyInvalidAmount;
        }

        return amount;
    }

    function formatCurrency(amount) {
        return formatter(amount, $ctrl.order.currency, $ctrl.order.locale);
    }

    function  save() {
        if($ctrl.submitDisabled){
            return;
        }
        if ($ctrl.busy) {
            console.error('Already saving return');

            return $q.reject('Busy: Already saving return');
        }

        if( $ctrl.return_method === 'disabled'){
            return ErrorRejector.handle('models.order.return.refund.comment_missing');
        }

        if(!$ctrl.comment){
            return ErrorRejector.handle('models.order.return.refund.comment_missing');
        }

        $ctrl.busy = true;

        const data = {
            comment: $ctrl.comment,
            refund: $ctrl.returns.refund,
            entire_order: !!($ctrl.returns.value && (!$ctrl.returns.refund || ($ctrl.returns.fees && $ctrl.returns.transaction))),
            includes_fees: $ctrl.returns.fees,
            // Added below (if applicable):
            // includes_fees
            // items
            // info
            // arbitrary
        };

        if (!data.entire_order) {
            const items = [];

            _.forEach($ctrl.returns.tickets, (ticket) => {
                if (ticket.value) {
                    items.push({
                        'guid': ticket.guid,
                        'type': 'ticket',
                    });
                }

                _.forEach(ticket.products, (product) => {
                    if (product.value) {
                        items.push({
                            'guid': product.guid,
                            'type': 'product',
                        });
                    }
                });
            });

            _.forEach($ctrl.returns.products, (product) => {
                if (product.value) {
                    items.push({
                        'guid': product.guid,
                        'type': 'product',
                    });
                }
            });

            if ($ctrl.returns.refund && $ctrl.returns.transaction) {
                _.forEach($ctrl.order.payments, (payment) => {
                    items.push({
                        'guid': payment.guid,
                        'type': 'payment',
                    });
                });
            }

            data.items = items;
        }

        if ($ctrl.returns.overwriteAmount.toggle) {
            data.arbitrary = {
                vat: $ctrl.returns.overwriteAmount.vat,
                amount: $ctrl.returns.overwriteAmount.amount,
                // percentage: $ctrl.returns.overwriteAmount.percentage,
            };
        }

        const accessToken = $window.auth ? $window.auth.getAccessToken() : null;

        rudderstack.track('dashboard_order_refund_submit', {
            data
        });

        return $http.delete(`/order/${ $ctrl.order.guid }/custom_refund`, {
            data,
            headers: {
                Authorization: 'Bearer '+ accessToken,
                Company: $window.auth.currentCompanyGuid,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        })
            .then(() => {
                $ctrl.busy = false;

                $ctrl.close();
            })
            .catch(error => {
                $ctrl.busy = false;

                return ErrorRejector.handle(error);
            });
    }
}
