import template from './clients.html';
import './clients.less';
import { copyToClipboard } from '../../dashboard';

export default angular.module('eventix.dashboard.company.clients', [])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.company.clients', {
            url: '/clients',
            views: {
                'dashboard@eventix.dashboard': {
                    component: 'clientManager'
                }
            },
            data: {
                crumb: 'common.menu.company.clients',
                requiredRoles: ['Company Admin']
            }
        });
    })
    .component('clientManager', {
        controller: ClientManagerController,
        templateUrl: template,
        bindings: {
            isAdmin: '<',
            user: '<',
            company: '<',
        },
    }).name;

function ClientManagerController($scope, CrudCtrl, Client, Role) {
    const $ctrl = this;

    $ctrl.isAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');

    $ctrl.guids = {
        company: {
            guid: $ctrl.company ? $ctrl.company.guid : '',
            icon: 'fa-building-o',
            name: 'models.models.company',
        },
        id: {
            guid: $ctrl.crud && $ctrl.crud.model ? $ctrl.crud.model.id : '',
            icon: 'fa-id-badge',
            label: 'models.client.id',
        },
        secret: {
            guid: $ctrl.crud && $ctrl.crud.model ? $ctrl.crud.model.secret : '',
            icon: 'fa-key',
            label: 'models.client.secret',
        },
    };

    $ctrl.copyToClipboard = copyToClipboard;

    $ctrl.$onInit = function () {
        $ctrl.crud = new CrudCtrl(Client.query(), newClient);

        $scope.$watch('$ctrl.company.guid', (guid) => {
            $ctrl.guids.company.guid = guid || '';
        });
        $ctrl.guids.company.guid = $ctrl.company ? $ctrl.company.guid : '';
        $scope.$watch('$ctrl.crud.model.id', (guid) => {
            $ctrl.guids.id.guid = guid || '';
        });
        $ctrl.guids.id.guid = $ctrl.crud && $ctrl.crud.model ? $ctrl.crud.model.id : '';
        $scope.$watch('$ctrl.crud.model.secret', (guid) => {
            $ctrl.guids.secret.guid = guid || '';
        });
        $ctrl.guids.secret.guid = $ctrl.crud && $ctrl.crud.model ? $ctrl.crud.model.secret : '';
    };

    // Forwarded to the new crud controller to build new users based on the returned
    function newClient() {
        return Client.new({
            name: 'My Application',
            redirect: 'https://www.google.nl/'
        });
    }
}
