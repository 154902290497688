export default angular.module('eventix.dashboard.wizard.common.unlimitedInput', [])
    .component('priceInput', {
        template: `<div class="input-group">
    <input type="text" class="form-control" currency-input="{{ $ctrl.currency }}" ng-model="$ctrl.value" ng-disabled="$ctrl.value === 0" name="{{ $ctrl.inputName }}">
    <div class="input-group-addon">
        <input type="checkbox" ng-model="$ctrl.checked" ng-click="$ctrl.toggleUnlimited()">
        <span ng-click="$ctrl.checked = !$ctrl.checked; $ctrl.toggleUnlimited()" translate>common.notice.free</span>
    </div>
</div>`,
        require: { ngModel: 'ngModel' },
        bindings: { currency: '@' },
        controller: UnlimitedInputController
    })
    .component('justPriceInput', {
        template: `<input type="text" class="form-control" currency-input="{{ $ctrl.currency }}" ng-model="$ctrl.value" name="{{ $ctrl.inputName }}">`,
        require: { ngModel: 'ngModel' },
        bindings: { currency: '@' },
        controller: UnlimitedInputController
    })
    .component('unlimitedInput', {
        template: `<div class="input-group">
    <input type="number" class="form-control" ng-model="$ctrl.value" ng-disabled="$ctrl.value === 0" name="{{ $ctrl.inputName }}">
    <div class="input-group-addon">
        <input type="checkbox" ng-model="$ctrl.checked" ng-click="$ctrl.toggleUnlimited()">
        <span ng-click="$ctrl.checked = !$ctrl.checked; $ctrl.toggleUnlimited()" translate>common.notice.unlimited</span>
    </div>
</div>`,
        require: { ngModel: 'ngModel' },
        controller: UnlimitedInputController
    }).name;

function UnlimitedInputController($scope, $attrs) {
    const $ctrl = this;

    $ctrl.$postLink = function() {
        $scope.$watch('$ctrl.ngModel.$modelValue', v => {
            $ctrl.value = v;
            $ctrl.checked = v === 0;
        });
        $scope.$watch('$ctrl.value', v => {
            if(v !== $ctrl.ngModel.$modelValue)
                $ctrl.ngModel.$setViewValue(v);
            $ctrl.checked = v === 0;
        });
        $ctrl.inputName = $attrs.ngModel.split('.').slice(-1).join('');
    };
    $ctrl.toggleUnlimited = function() {
        if($ctrl.checked)
            $ctrl.value = 0;
        else if($ctrl.value === 0)
            $ctrl.value = undefined;
    };
}
