import template from './create.html';
import CompanyForm from '../companyForm';

export default angular.module('eventix.dashboard.company.create', [ CompanyForm ])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.company.create', {
            url: '/create',
            views: {
                'dashboard@eventix.dashboard': {
                    component: 'createCompany',
                },
            },
            data: {
                requiredRoles: [],
                parent: {
                    name: 'eventix.dashboard.company',
                    label: 'models.models.company',
                },
            },
        });
    })
    .component('createCompany', {
        controller: CreateCompanyController,
        templateUrl: template,
        bindings: {
            company: '<',
        },
    }).name;

function CreateCompanyController($q, $http, $window, $rootScope, $state, $scope, Company, UIMessages) {
    const $ctrl = this;
    $ctrl.busy = false;
    $ctrl.save = save;

    $ctrl.$onInit = function() {
        $ctrl.company = Company.new({
            timezone: $ctrl.company.timezone,
            locale: $ctrl.company.locale,
            currency: $ctrl.company.currency,
            country: $ctrl.company.country,
            payout_frequency: 'weekly',
        });

        $scope.$on('input-blur', function(event, name) {
            if (Object.prototype.hasOwnProperty.call($ctrl.company, name)) {
                // Ensure before validating, any 'external' errors are removed.
                $ctrl.company.$errors.clear(name);
            }

            $ctrl.company.validate(name);
        });
    };

    /**
     * Save the company
     *
     * @param { object } company Company to save
     * @return { Promise } Resolves when done
     */
    function save(company) {
        if (company.$invalid) {
            return $q.reject();
        }

        $ctrl.busy = true;

        // Make a request to the auth.eventix endpoint
        return $http.post(AUTH_API_ROOT + '/companies', company).then(() => {
            company.$errors.clear();
            $ctrl.busy = false;
            $window.location.href = '/home'; // Redirect and clear cache
        }, (error) => {
            console.error(error);

            company.$errors.clear();

            let errors = _.get(error, 'data.error_description', {});

            if (_.get(error, 'status') === 406 && _.isObject(errors))
                _.forOwn(errors, (value, key) => {
                    _.forEach([].concat(value), message => {
                        company.$errors.add(key, message);
                    });
                });

            $ctrl.busy = false;

            return $q.reject(error);
        });
    }
}
