import templateUrl from './copyGuids.html';
import './copyGuids.less';
import { copyToClipboard } from '../../dashboard';

export default angular.module('eventix.dashboard.components.copyGuids', [])
    /**
     * Show a button group with a button for each given guid.
     *
     * If the guid is falsey, it will be disabled.
     *
     * @param {{ [key: string]: { guid: string; icon: string; name?: string; label?: string; disabled?: boolean; }}} guids List of guids and their icons
     *
     * @example
     * ```
     * <copy-guids guids="{ event: { guid: 'guid', icon: 'fa fa-calendar' } }"></copy-guids>
     * ```
     */
    .component('copyGuids', {
        bindings: {
            guids: '<',
        },
        templateUrl: templateUrl,
        controller: CopyGuids,
    })
    .name;

function CopyGuids($translate, UIMessages, Role) {
    const $ctrl = this;

    $ctrl.name = name;
    $ctrl.copyToClipboard = (value) => UIMessages.push(copyToClipboard(value));

    $ctrl.isAdmin = Role.isAuthorizedAs('Admin');
    $ctrl.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');

    function name(guidSpec) {
        if (guidSpec.label) {
            return 'Copy ' + $translate.instant(guidSpec.label, { count: 1 });
        }

        if (guidSpec.name) {
            return 'Copy ' + $translate.instant(guidSpec.name, { count: 1 }) + ' guid';
        }

        return 'Copy guid';

    }
}
