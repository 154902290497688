import template from './arbitrarySplits.html';
import './arbitrarySplits.less';

export default angular.module('eventix.dashboard.financials.arbitrarySplits', [])
    .component('arbitrarySplits', {
        bindings: {company: '<'},
        controller: ArbitrarySplitsController,
        templateUrl: template
    }).name;

function ArbitrarySplitsController($q, $http, $timeout, $translate, $state, ArbitrarySplit, CashFlow, Company, Whitelabel, Currencies, SweetAlert, UIMessages, $scope, Role) {
    const $ctrl = this;

    const DEFAULT_CASH_FLOW = {
        code: 999,
        name: 'Other',
        description: '',
        count: 1,
        amount: 0,
        tax_percentage: 21,
        currency: 'EUR',
        administered: true,
        payed_by: {
            valid: false,
            focused: false,
            guid: null,
            name: ''
        },
        payed_to: {
            valid: false,
            focused: false,
            guid: null,
            name: ''
        }
    };

    $ctrl.isAdmin = false;
    $ctrl.currencies = Currencies;
    $ctrl.cashflows = [];
    $ctrl.arbitrarySplits = [];
    $ctrl.loggedinCompany = null;
    $ctrl.whitelabel = null;
    $ctrl.new = {
        model: null,
        $errors: null,
        selectedArbitrarySplit: null,
        populateForm: populateForm,
        save: saveNewCashFlow,
        cancel: cancelNewCashFlow,
        new: startNewCashFlow,
        changePayedBy: changePayedBy,
        populatePayedBy: populatePayedBy,
        changePayedTo: changePayedTo,
        populatePayedTo: populatePayedTo,
        switchPayees: switchPayees,
        busy: false,
    };
    $ctrl.deleteCashFlow = deleteCashFlow;

    $ctrl.$onInit = function() {
        $ctrl.isAdmin = Role.isAuthorizedAs('Admin');

        Company.getMe()
            .then(company => $ctrl.loggedinCompany = company)
            .then(() => {
                if ($ctrl.isAdmin)
                    return Whitelabel.get({guid: $ctrl.company.whitelabel_id})
                        .then(whitelabel => $ctrl.whitelabel = whitelabel);
            })
            .then(() => {
                resetNewCashFlow();

                refreshCashFlow();

                refreshArbitrarySplits();

                // $scope.$watch('$ctrl.new.selectedArbitrarySplit', populateForm);
            });

        $ctrl.currency = $ctrl.company.currency;
    };

    function refreshArbitrarySplits() {
        let promise;

        if ($state.params.as || $ctrl.loggedinCompany.guid !== $ctrl.company.guid)
            promise = ArbitrarySplit.queryAdmin({company: $ctrl.company.guid});
        else
            promise = ArbitrarySplit.queryDB();

        return promise.then(results => {
            $ctrl.arbitrarySplits = results;
            return results;
        })
    }

    function refreshCashFlow() {
        let promise;

        if ($state.params.as || $ctrl.loggedinCompany.guid !== $ctrl.company.guid)
            promise = CashFlow.queryAdmin({company: $ctrl.company.guid});
        else
            promise = CashFlow.queryDB();

        return promise.then(results => {
            $ctrl.cashflows = results;

            return results;
        });
    }

    function resetNewCashFlow() {
        $ctrl.new.model = null;
        $ctrl.new.$errors = null;
        $ctrl.new.busy = false;
        $ctrl.new.selectedArbitrarySplit = null;
    }

    function startNewCashFlow() {
        if (!$ctrl.isAdmin)
            return;

        resetNewCashFlow();

        $ctrl.new.model = _.cloneDeep(DEFAULT_CASH_FLOW);

        $ctrl.new.model.payed_to = {
            valid: true,
            focused: false,
            guid: $ctrl.whitelabel.owning_company,
            name: $ctrl.whitelabel.name
        };

        $ctrl.new.model.payed_by = {
            valid: true,
            focused: false,
            guid: $ctrl.company.guid,
            name: $ctrl.company.name
        };

        $ctrl.initingWatchers = true;
        $scope.$watch('$ctrl.new.model.count', updateAmount);
        $scope.$watch('$ctrl.new.model.amount_ex', updateAmount);
        $scope.$watch('$ctrl.new.model.tax_percentage', updateAmount);
        $scope.$watch('$ctrl.new.model.currency', updateCurrency);
        $ctrl.initingWatchers = false;
    }

    function updateAmount(){
        if($ctrl.initingWatchers)
            return true;

        if(!$ctrl.new.model)
            return;

        $ctrl.new.model.amount = ($ctrl.new.model.amount_ex + Math.floor($ctrl.new.model.amount_ex * $ctrl.new.model.tax_percentage / 100));
        $ctrl.new.model.total_amount = $ctrl.new.model.count * ($ctrl.new.model.amount_ex + Math.floor($ctrl.new.model.amount_ex * $ctrl.new.model.tax_percentage / 100));
    }
    function updateCurrency(){
        if($ctrl.initingWatchers)
            return true;

        if(!$ctrl.new.model)
            return;

        $ctrl.currency = $ctrl.new.model.currency;
    }

    function deleteCashFlow(cashFlow) {
        if (!$ctrl.isAdmin)
            return $q.reject('Not Authorized');

        function cashFlowIsFinalError(slug) {
            SweetAlert.swal({
                title: $translate.instant('common.notice.error'),
                text: $translate.instant(slug),
                type: 'error',
                confirmButtonText: $translate.instant('common.action.ok'),
                closeOnClickOutside: true,
                timer: 3000
            });
        }

        if (cashFlow.clearing_is_final || cashFlow.invoice_is_final) {
            cashFlowIsFinalError('models.cashFlow.final');

            return $q.reject('CashFlow is final');
        }

        return $http.post('/split/arbitrary/' + cashFlow.original_id + '/cashflow/delete', {
            cashflow_ids: cashFlow.splittable_ids.split(',')
        }).then(result => {
            return result;
        }, error => {
            if (error.status === 406)
                cashFlowIsFinalError('models.cashFlow.final');
            else
                cashFlowIsFinalError('common.notice.serverError');

            return error;
        }).then((result) => {
            refreshCashFlow();

            return result;
        });
    }

    function switchPayees() {
        let temp = $ctrl.new.model.payed_by;

        $ctrl.new.model.payed_by = $ctrl.new.model.payed_to;
        $ctrl.new.model.payed_to = temp;
    }

    function saveNewCashFlow() {
        if (!$ctrl.isAdmin)
            return $q.reject('Not Authorized');

        if (!$ctrl.new.model || $ctrl.new.busy)
            return $q.reject('Nothing to save');

        $ctrl.new.busy = true;

        let cashFlow = CashFlow.new({
            company_id: $ctrl.company.guid,
            code: $ctrl.new.model.code,
            name: $ctrl.new.model.name,
            description: $ctrl.new.model.description,
            amount: $ctrl.new.model.amount,
            tax_percentage: $ctrl.new.model.tax_percentage,
            currency: $ctrl.new.model.currency,
            administered: $ctrl.new.model.administered,
            payed_by: $ctrl.new.model.payed_by.guid,
            payed_to: $ctrl.new.model.payed_to.guid,
            count: $ctrl.new.model.count
        });

        if (cashFlow.$invalid) {
            $ctrl.new.$errors = cashFlow.$errors;

            $ctrl.new.busy = false;

            return $q.reject('Model invalid');
        }

        return cashFlow.$save().then(result => {
            refreshCashFlow();
            startNewCashFlow();
            refreshArbitrarySplits();
            return result;
        }, error => {
            if (typeof error.data.error_description === 'string') {
                $ctrl.new.$errors = null;

                UIMessages.push({
                    type: 'error',
                    message: error.data.error_description,
                });
            } else {
                $ctrl.new.$errors = error.data.error_description;
            }

            return error;
        }).then((result) => {
            $ctrl.new.busy = false;

            return result;
        });
    }

    function cancelNewCashFlow() {
        resetNewCashFlow();
    }

    function changePayedBy() {
        $ctrl.new.model.payed_by.valid = false;
        $ctrl.new.model.payed_by.focused = true;
    }

    function populatePayedBy() {
        if (!$ctrl.new.model || $ctrl.new.busy || !$ctrl.new.model.payed_by)
            return $q.reject('Can\'t populate Payed By');

        return populatePayee($ctrl.new.model.payed_by);
    }

    function changePayedTo() {
        $ctrl.new.model.payed_to.valid = false;
        $ctrl.new.model.payed_to.focused = true;
    }

    function populatePayedTo() {
        if (!$ctrl.new.model || $ctrl.new.busy || !$ctrl.new.model.payed_to)
            return $q.reject('Can\'t populate Payed To');

        return populatePayee($ctrl.new.model.payed_to);
    }

    function populatePayee(payee) {
        if (!payee.guid)
            return $q.reject('Can\'t populate');

        $ctrl.new.busy = true;

        payee.name = '';

        return Company.get({guid: payee.guid}).then((company) => {
            payee.guid = company.guid;
            payee.name = company.name;
            payee.valid = true;

            return company;
        }, (error) => {
            // TODO error

            return error;
        }).then((result) => {
            payee.focused = false;
            $timeout(() => $ctrl.new.busy = false, 500);

            return result;
        });
    }

    function populateForm() {
        if (!$ctrl.new.model)
            return;

        if (!$ctrl.new.selectedArbitrarySplit) {
            $ctrl.new.model.code = DEFAULT_CASH_FLOW.code;
            $ctrl.new.model.name = DEFAULT_CASH_FLOW.name;

            return;
        }

        $ctrl.new.model.code = $ctrl.new.selectedArbitrarySplit.code;
        $ctrl.new.model.name = $ctrl.new.selectedArbitrarySplit.name;
    }
}
