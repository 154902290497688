let template = `<div class="modal-header">
    <h3 class="modal-title" id="modal-title">Shop Integration</h3>
</div>
<div class="modal-body" id="modal-body">
    <form class="form-horizontal">
        <form-el label="models.shop.link" no-help>
            <div class="input-group">
                <input type="text" readonly value="{{ $ctrl.shop.link }}" ctrl-c class="form-control">
                <div class="input-group-btn">
                    <a class="btn btn-default" target="_BLANK" ng-href="{{ $ctrl.shop.link }}">
                        <i class="fa fa-external-link"></i></a>
                </div>
            </div>
        </form-el>
        <form-el label="models.shop.integrate" no-help>
            <textarea class="form-control monospace" rows="5" readonly ctrl-c>&lt;div id=&quot;shop-frame&quot; data-url=&quot;{{ $ctrl.shop.$getLink() }}&quot; style=&quot;max-width: 600px; margin:0 auto;&quot;&gt;&lt;/div&gt;
        &lt;script src=&quot;{{ $ctrl.shop_root }}build/integrate.js&quot;&gt;&lt;/script&gt;</textarea>
        </form-el>
    </form>
</div><div class="modal-footer">
    <button ng-click="$ctrl.close()" class="btn btn-info pull-right" translate>common.action.close</button>
</div>`;

export default angular.module('eventix.dashboard.wizard.common.shopIntegrationModal', [])
    .component('shopIntegrationModal', {
        template: template,
        controller: ShopIntegrationModalController,
        bindings: {
            resolve: '<',
            close: '&'
        }
    }).name;

function ShopIntegrationModalController() {
    const $ctrl = this;
    $ctrl.shop_root = FANCY_SHOP_ROOT;

    $ctrl.$postLink = function() {
        _.assign($ctrl, $ctrl.resolve);
    };
}
