import template from './orders.html';
import c3 from 'c3';
import 'c3/c3.css';
import Edit from './edit/edit.js';
import c3Tooltip from './c3-tooltip.html';
import './orders.less';
import { copyToClipboard } from '../dashboard';

export default angular.module('eventix.dashboard.orders', [Edit])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.orders', {
            url: '/orders?search&page&event_ids',
            params: {
                search: {dynamic: true},
                page: {dynamic: true}
            },
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'OrdersController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/{
                shops: function(Shop, $transition$) {
                    return Shop.query();
                },
                metaData: function(MetaData, $transition$) {
                    return MetaData.query();
                }
            }
        });
    })
    .controller('OrdersController', function($scope, $q, $state, $filter, $timeout, $location, $templateCache, $transitions, $stateParams, ElasticLoader, ErrorRejector, events, user, isAdmin, Order, Company, Product, Ticket, metaData, shops, $analytics, Role) {
        const vm = this;

        const c3TooltipFn = _.template($templateCache.get(c3Tooltip), {imports: {_: _, moment: moment}});
        const products = {};
        const tickets = {};

        vm.loadOrders = loadOrders;
        vm.pickEvents = pickEvents;
        vm.copyToClipboard = copyToClipboard;

        vm.isAdmin = isAdmin;
        vm.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');

        vm.events = _.sortBy(events, ['start', 'name']);
        vm.metaData = metaData;
        vm.shops = _.keyBy(shops, 'guid');

        vm.currency = Company.getMe().currency;
        vm.statii = isAdmin ? ['paid', 'pending', 'cancelled'] : ['paid'];
        vm.channels = ['shop', 'import', 'cashr', 'api', 'swap']; // ['shop', 'import', 'guestlist', 'cashr', 'api', 'swap';

        vm.selectedEvents = [];
        vm.totalRows = 0;
        vm.totals = {};
        vm.range = {
            start: moment().subtract(6, 'months'),
            stop: moment().add(1, 'day')
        };

        vm.loaded = false;
        vm.page = 0; // always start at 0, see after load orders
        vm.displayedPage = $location.search().page || 1;
        vm.changingPages = false;
        vm.searching = $stateParams.search && $stateParams.search.length;
        vm.shouldSort = !vm.searching
        if(vm.searching){
            vm.range = {
                start: moment('01-01-2014', 'DD-MM-YYYY'),
                stop: moment().add(1, 'day')
            };
        }
        vm.currentSearch = '';

        vm.showGraph = true;
        vm.stacked = true;
        vm.timeUnit = 'autoscale';

        vm.exportColumns = {
            'guid': 'order_id',
            'created_at': 'created_at',
            'shopName': 'shop',
            'eventNames': 'event_names',
            'amount': 'amount',
            'coupons': 'coupons',
            'paymentMethod': 'payment_method',
            'status': 'status',
            'tech_data.decoded.queryParams.shop_code': 'tracker',
            'tickets.length': 'number_of_tickets',
            'firstName': 'first_name',
            'lastName': 'last_name',
            'email': 'email'
        };

        let pathEvents = $location.search().event_ids;
        if (pathEvents) {
            vm.selectedEvents = _.intersectionWith(events, _.split(pathEvents, ','), (event, guid) => event.guid === guid);
        }

        _.forEach(metaData, m => {
            vm.exportColumns[`meta_data[metadata_id=${m.guid}].value`] = m.translatedName;
        });

        $scope.$watch('vm.range', updateFilters, true);
        $scope.$watch('vm.stacked', updateFilters);
        $scope.$watch('vm.selectedEvents', updateFilters, true);
        $scope.$watch('vm.timeUnit', updateFilters, true);

        $scope.$watch('vm.page', updatePage);
        $scope.$watch('vm.displayedPage', updateDisplayedPage);

        $transitions.onSuccess({to: $state.current.name}, updateSearching);

        function pickEvents(events) {
            return $q.resolve(vm.selectedEvents = events);
        }

        function updateTable() {
            if (!vm.changingPages || $state.params.search !== vm.currentSearch) {
                $scope.$broadcast('table-view-refresh');

                vm.currentSearch = $state.params.search;
            }
        }

        // Refresh table if any of the configuring variables change
        function updateFilters() {
            vm.changingPages = false;

            updateTable();

            $state.go('.', {event_ids: _.isEmpty(vm.selectedEvents) ? undefined : _.join(_.map(vm.selectedEvents, 'guid'), ',')});
        }

        function updateSearching(transition) {
            vm.searching = _.get(transition.params().search, 'length') > 0;
            vm.shouldSort = !vm.searching

            updateTable();
        }

        function updatePage(newPage) {
            if (vm.loaded) {
                vm.changingPages = true;
                vm.displayedPage = newPage + 1;
            }
        }

        function updateDisplayedPage(newPage) {
            $state.go('.', {page: newPage});
        }

        /**
         * Load and parse orders from ElasticSearch
         *
         * This function is used as input for TableView
         *
         * @param {Number} offset Page * pagesize
         * @param {Number} limit Pagesize
         * @param {Object} sorting Sorting definition
         * @param {Object} filters Filter definition
         * @returns {Promise<Order>} Resolves with array of orders
         */
        function loadOrders(offset, limit, sorting, filters) {
            vm.loading = true;

            let timeUnit = (vm.timeUnit === 'autoscale') ? 'day' : vm.timeUnit;
            let start = vm.range.start.clone().startOf(timeUnit);
            let end = vm.range.stop.clone().startOf(timeUnit);

            if (!vm.isAdmin) {
                filters.status = 'paid';
            }

            _.forEach(filters, (val, key) => {
                filters[key] = _.isString(val) ? val.toLowerCase() : val;
            });

            let query = {
                start: start,
                end: end,
                timeunit: timeUnit,
                offset: offset,
                limit: limit,
                sorting: sorting,
                filters: filters,
            };

            let promise;

            if ($state.params.search) {
                // Reset pages if searching
                query.search = $state.params.search;
                query.events = _.map(vm.selectedEvents, 'guid');


                if (!vm.changingPages) {
                    vm.loaded = false;
                    vm.displayedPage = 1;
                }

                promise = ElasticLoader.search(query);
            } else {
                // Add events to query only if not searching
                query.events = _.map(vm.selectedEvents, 'guid');

                promise = ElasticLoader.getOrders(query);
            }

            return promise.then(response => response.data)
                .then(data => {
                    // Graph data
                    vm.showGraph = _.has(data, 'aggregations');

                    if (!vm.searching && vm.showGraph) {
                        generateGraph(data.aggregations);
                    }

                    // Table data
                    vm.totalRows = _.get(data, 'hits.total', 0);

                    // For triggering events when a companies has orders
                    if(vm.totalRows > 0) {
                        $analytics.eventTrack('orderlist-has-orders');
                    }

                    if(vm.totalRows > 9) {
                        $analytics.eventTrack('orderlist-has-orders-9');
                    }

                    vm.ordersPerPage = vm.searching ? 10 : 20;

                    let orders = _.get(data, 'hits.hits', []);

                    if (!_.isArray(orders)) {
                        orders = [orders];
                    }

                    orders = _.map(orders, o => Order.new(o._source));

                    // Extend order object with data on its relations
                    let ordersPromise = _.reduce(orders, (orderPromise, order) => {
                        order.eventIds = [];
                        order.shopName = _.get(vm.shops, [order.shop_id, 'name'], '');

                        let orderProducts = _.chain(order.tickets)
                            .flatMap(ticket => ticket.products)
                            .concat(order.products)
                            .value();

                        let orderTickets = order.tickets;

                        order.coupons = _.chain(orderProducts)
                            .map('coupon_code')
                            .uniq()
                            .filter()
                            .join(', ')
                            .value();

                        order.totalTickets = order.tickets.length;
                        order.validTickets = 0;
                        if(order.totalTickets > 0){
                            let validTickets = 0;
                            _.forEach(order.tickets, function(ticket){
                               if(_.isNull(ticket.invalidated_since)){
                                   validTickets ++ ;
                               }
                            });
                            order.validTickets = validTickets;
                        }

                        if(order.shopName == ''){
                            return $q.resolve();
                        }

                        orderPromise = _.reduce(orderTickets, (ticketPromise, orderTicket) => {
                            let ticketId = _.get(orderTicket, 'ticket_id');

                            return ticketPromise
                                .then(() => _.get(tickets, ticketId))
                                .then(ticket => _.isNil(ticket) ? $q.reject() : ticket)
                                .catch(() => Ticket.get({guid: ticketId}))
                                .then(ticket => _.isNil(ticket) ? $q.reject() : ticket)
                                .then(ticket => {
                                    _.set(tickets, ticketId, ticket);

                                    order.eventIds.push(_.get(ticket, 'event_id'));
                                })
                                .catch(angular.noop);
                        }, orderPromise);

                        return orderPromise
                            .then(() => order.eventNames = _.chain(order.eventIds)
                                .uniq()
                                .map(eventId => _.get(_.find(events, {guid: eventId}), 'name'))
                                .filter()
                                .join(' ')
                                .value());
                    }, $q.resolve());

                    return ordersPromise.then(() => orders);
                })
                .then(orders => {
                    vm.totals = _.reduce(orders, (carry, order) => {
                        carry.amount += _.get(order, 'amount', 0);
                        carry.tickets += _.get(order, 'tickets.length', 0);

                        return carry;
                    }, {amount: 0, tickets: 0});

                    vm.loading = false;

                    return orders;
                })
                .then(orders => {
                    if (!vm.loaded) {
                        vm.loaded = true;
                        vm.page = vm.displayedPage - 1;
                        vm.changingPages = false;
                    }

                    return orders;
                })
                .catch(ErrorRejector.handle);
        }

        /**
         * Generate a C3 graph from given statistics
         * @param {Object} stats Aggregations object from ElasticSearch query
         */
        function generateGraph(stats) {
            /*
             * Calculate graph range
             */
            let timeUnit = (vm.timeUnit === 'autoscale') ? 'day' : vm.timeUnit;
            let elasticsearchDateFormat = (timeUnit === 'hour') ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
            let rangeDiff = Math.abs(vm.range.start.diff(vm.range.stop, timeUnit));

            /*
             * Prepare X axis
             */
            let dateColumn = ['dates'];
            let plotDateColumn = ['dates'];
            let tmpDate = vm.range.start.clone().startOf(timeUnit);

            dateColumn.push(tmpDate.format(elasticsearchDateFormat));
            plotDateColumn.push(tmpDate.format(elasticsearchDateFormat));

            for (let i = 0; i < rangeDiff; i++) {
                let tmpCount = tmpDate.add(1, timeUnit);

                dateColumn.push(tmpCount.format(elasticsearchDateFormat));
                plotDateColumn.push(tmpCount.format(elasticsearchDateFormat));
            }

            tmpDate.subtract(rangeDiff, timeUnit);

            /*
             * Prepare chart data
             */
            let columns = {};
            let revenue = {};
            let groups = [];

            _.forEach(_.get(stats, 'eventCounts', []), (statsCounts, eventId) => {
                let event = _.find(events, {guid: eventId});

                if (_.isNil(event)) {
                    return; // Will also discard 'doc_count'
                }

                let statsRevenue = _.get(stats, ['eventRevenues', eventId], {});

                let calculatedData = prepareChartEvent(event, rangeDiff, statsCounts, statsRevenue, dateColumn);

                if (!_.isNil(calculatedData)) {
                    groups.push(event.name);
                    columns[event.guid] = calculatedData.column;
                    revenue[event.name] = calculatedData.revenue;
                }
            });

            if (!vm.stacked) {
                groups = [];
            }

            /*
             * Initialize chart
             */
            columns = _.values(columns);
            columns.unshift(plotDateColumn);

            if (vm.chart) {
                vm.chart.destroy();
            }

            vm.chart = c3.generate({
                bindto: '#statsEvents',
                data: {
                    x: 'dates',
                    columns: columns,
                    type: 'bar',
                    groups: [
                        groups
                    ]
                },
                size: {
                    width: $('#statsEvents').width() * 0.95,
                    height: 250
                },
                legend: {
                    show: false
                },
                tooltip: {
                    format: {
                        title: function(x) {
                            switch (timeUnit) {
                            case 'month':
                                return 'Month ' + moment(x).format('MM-YYYY');
                            case 'week':
                                return 'Week ' + moment(x).format('w-YYYY');
                            case 'hour':
                                return moment(x).format('DD-MM HH:mm');
                            case 'day':
                            default:
                                return moment(x).format('DD-MM-YYYY');
                            }
                        }
                    },
                    contents: function(d, defaultTitleFormat, defaultValueFormat, color) {
                        var $$ = this,
                            config = $$.config,
                            nameFormat = config.tooltip_format_name || function(name) {
                                return name;
                            },
                            valueFormat = config.tooltip_format_value || defaultValueFormat,
                            dateFormat = elasticsearchDateFormat;

                        return c3TooltipFn({
                            rows: d,
                            $$: $$,
                            valueFormat: valueFormat,
                            revenue: revenue,
                            nameFormat: nameFormat,
                            dateFormat: dateFormat,
                            color: color,
                            dayCount: 0,
                            dayRev: 0,
                            formatCurrency: $filter('formatCurrency'),
                            currency: vm.currency
                        });
                    }
                },
                color: {pattern: ['#00bef6', '#0079bf', '#00a650', '#f46b49', '#bb2321']},
                axis: {
                    y: {
                        min: 0,
                        padding: {bottom: 0},
                        tick: {
                            format: function(d) {
                                if (d % 1 === 0) {
                                    return d;
                                }
                                return '';
                            }
                        }
                    },
                    x: {
                        type: 'timeseries',
                        tick: {
                            culling: true,
                            format: function(x) {
                                switch (timeUnit) {
                                case 'month':
                                    return 'Month ' + moment(x).format('MM-YYYY');
                                case 'week':
                                    return 'Week ' + moment(x).format('w-YYYY');
                                case 'hour':
                                    return moment(x).format('DD-MM HH:mm');
                                case 'day':
                                default:
                                    return moment(x).format('DD-MM-YYYY');
                                }
                            }
                        }
                    }
                }
            });
        }

        function prepareChartEvent(event, rangeDiff, statsCounts, statsRevenue, dateColumn) {
            let data = {
                column: [event.name],
                revenue: [event.name]
            };

            _.times(rangeDiff + 1, () => {
                data.column.push(0);
                data.revenue.push(0);
            });

            let dayCounts = _.keyBy(_.get(statsCounts, 'statistics.buckets', {}), 'key_as_string');
            let dayRevenues = _.keyBy(_.get(statsRevenue, 'statistics.buckets', {}), 'key_as_string');

            if (_.isEmpty(dayCounts)) {
                return null;
            }

            _.forEach(dateColumn, (date, index) => {
                if (index === 0) {
                    return; // Skip first item (it is the event name due to c3js)
                }

                data.column[index] += _.get(_.get(dayCounts, date, {}), 'doc_count', 0);
                data.revenue[index] += _.get(_.get(dayRevenues, date, {}), 'statistics.value', 0);
            });

            return data;
        }
    }).name;

