export default angular.module('eventix.dashboard.wizard.common.shop.collapses.demo', [])
    .component('shopCollapseDemo', {
        bindings: {
            collapse: '<',
            tickets: '<?',
            event: '<?',
            isOpen: '=?',
            disabled: '@'
        },
        controller: function($scope, $attrs, $q) {
            const $ctrl = this;
            $ctrl.$onInit = function() {
                $scope.$watch('$ctrl.collapse', c => {
                    if(!c)
                        return;
                    $ctrl.isOpen = $attrs.isOpen === undefined ? c.start_open : $ctrl.isOpen;
                });
                $scope.$watch('$ctrl.tickets', t => {
                    _.forEach(t, t=> t.calculateMinimumPrice(false));
                }, true);
            };
            $ctrl.shouldOpen = function() {
                if($ctrl.disabled)
                    return $q.reject();
                return $q.resolve();
            };
        },
        template: `<button class="btn btn-default btn-lg btn-block" type="button" disabled ng-hide="$ctrl.collapse.pre_tickets" translate>
    models.collapse.other_tickets
</button>
        <h4 ng-bind-html="$ctrl.collapse.pre_title | toHtml"></h4>
<p ng-bind-html="$ctrl.collapse.pre_content | toHtml"></p>
<div class="panel panel-default" ng-class="{ open: $ctrl.isOpen }">
    <div class="panel-heading">
        <h4 class="panel-title">
            <a class="accordion-toggle"
               ng-click="$ctrl.isOpen = !$ctrl.isOpen"
               ng-bind-html="$ctrl.collapse.title | toHtml"></a>
        </h4>
    </div>
    <div class="panel-body"
            uib-collapse="!$ctrl.isOpen"
            expanding="$ctrl.shouldOpen()"
            collapsing="$ctrl.shouldOpen()">
        <h4 ng-bind-html="$ctrl.collapse.inner_title | toHtml"></h4>
        <p ng-bind-html="$ctrl.collapse.inner_content | toHtml"></p>
        <ul class="tickets">
            <li class="row" ng-repeat="ticket in $ctrl.tickets">
                <div class="col-xs-6 ticket-name">
                    <h4>{{ ticket.name }}</h4>
                    <p class="small">Service cost -</p>
                </div>
                <div class="col-xs-3 amount-control">
                    <button class="btn btn-xs btn-round btn-danger" type="button">
                        <i class="fa fa-minus"></i>
                    </button>
                    <span class="reservation-count">1</span>
                    <button class="btn btn-xs btn-round btn-danger" type="button">
                        <i class="fa fa-plus"></i>
                    </button>
                </div>
                <div class="col-xs-3 reservation-summary">
                    <p class="small reservations">
                        1 x {{ ticket.minimumPrice | formatCurrency:$ctrl.event.currency }}
                    </p>
                    <p class="small price">{{ ticket.minimumPrice | formatCurrency:$ctrl.event.currency }}</p>
                </div>
            </li>
        </ul>
    </div>
</div>
<p ng-bind-html="$ctrl.collapse.post_content | toHtml"></p>
<button class="btn btn-default btn-lg btn-block" type="button" disabled ng-show="$ctrl.collapse.pre_tickets" translate>
    models.collapse.other_tickets
</button>`
    }).name;
