import style from './login.less';
import template from './login.html';

export default angular.module('eventix.login', [])
    .config(function($stateProvider) {

        $stateProvider
            .state('eventix.login', {
                url: '/login',
                views: {
                    '@': {
                        controller: 'LoginController',
                        controllerAs: 'vm',
                        templateUrl: template,
                    },
                },
            })
            .state('eventix.logout', {
                url: '/logout',
                views: {
                    main: {
                        template: '',
                        controller: /*@ngInject*/ function($analytics, $window) {

                            $analytics.setUsername(null);

                            if ($window.auth.isLoggedIn) {
                                $window.auth.logout().then(() => {
                                    $window.location.href = LOGIN_PAGE;
                                });
                            }
                            else {
                                $window.location.href = LOGIN_PAGE;
                            }
                        },
                    },
                },
            });

    })
    .controller('LoginController', function($scope, $window, $translate, UIMessages, Locales) {

        // Redirect the user to the dashboard home if already authenticated
        if ($window.auth.isLoggedIn) {
            $window.location.href = DASHBOARD_ROOT + 'home';
        }

        var vm = this;

        vm.locales = Locales;
        vm.setLocale = l => $translate.use(l);
        vm.messages = UIMessages;
        vm.rememberMe = true;

        vm.login = function() {

            if (vm.busy) {
                return;
            }

            vm.busy = true;

            const email = vm.username;
            const password = vm.password;

            $window.auth.login(email, password).then(() => {

                $window.location.href = DASHBOARD_ROOT + 'home';

            // ApiError
            }, (err) => {

                vm.busy = false;
                vm.password = '';

                let message = 'Error logging in';
                console.log(err);

                if (err.status_code === 401 || err.status_code === 400) {

                    message = 'Invalid credentials';

                }

                UIMessages.push({
                    message: message,
                    type: 'error'
                });

                $scope.$apply();

            });

        };

    }).name;

