import template from './scanactions.html';

export default angular.module('eventix.dashboard.scanactions', [])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.dashboard.scanactions', {
            url: '/scanactions?eventGuid',
            params: {
                eventGuid: {dynamic: true}
            },
            views: {
                'dashboard@eventix.dashboard': {
                    controller: 'EventScanActionsController as vm',
                    templateUrl: template
                }
            }
        });
    })
    .controller('EventScanActionsController', function($scope, $q, $state, $stateParams, $transitions, $http, $timeout, $filter, ErrorRejector, UIMessages, user, isAdmin, events) {
        const vm = this;

        let currentState = $state.current.name;

        vm.isAdmin = isAdmin;
        vm.events = events;
        vm.totalRows = 0;
        vm.pagingSize = 10;
        vm.totals = {};
        vm.scanStatii = ['scan', 'scan_invalid'];
        vm.selectedEvent = _.find(events, {guid: $stateParams.eventGuid});
        vm.showEventSelector = /scanactions$/.test(currentState);

        vm.updateTable = updateTable;
        vm.loadScanActions = loadScanActions;
        vm.getBackgroundColor = getBackgroundColor;

        $scope.$watch('vm.selectedEvent', updateTable);

        let deregisterParamWatcher = $transitions.onSuccess({to: currentState}, function(trans) {
            if (!vm.selectedEvent || vm.selectedEvent.guid !== trans.params().eventGuid) {
                vm.selectedEvent = _.find(events, {guid: trans.params().eventGuid});
            }
        });

        let deregisterExitWatcher = $transitions.onStart({exiting: currentState}, function(trans) {
            deregisterParamWatcher();
            deregisterExitWatcher();
        });

        function updateTable(event) {
            if (!event) {
                return;
            }

            $state.go($state.current.name, {eventGuid: event.guid}, {notify: false});

            $scope.$broadcast('table-view-refresh');
        }

        function loadScanActions(offset, limit, sorting, filters) {
            if (!_.get(vm.selectedEvent, 'guid')) {
                return $q.resolve([]);
            }

            vm.loading = true;

            let query = {
                offset: offset,
                limit: limit,
                sorting: sorting,
                filters: filters
            };

            return $http.post(`/event/${vm.selectedEvent.guid}/scanner/actions`, query)
                .then(response => {
                    vm.loading = false;

                    vm.totalRows = _.get(response, 'data.total', 0);

                    return _.get(response, 'data.scan_actions');
                }, error => {
                    vm.loading = false;

                    return ErrorRejector.handle(error);
                });
        }

        function getBackgroundColor(action) {
            switch (action) {
            case 'scan':
                return 'green';

            case 'scan_invalid':
                return 'green';

            default:
                return 'orange';
            }
        }
    }).name;
