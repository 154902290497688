import template from './scanners.html';
import { copyToClipboard } from '../../../dashboard';

export default angular.module('eventix.dashboard.wizard.advanced.scanners', [])
    .config(ModuleConfig)
    .component('wizardAdvancedScanners', {
        controller: WizardAdvancedScannersController,
        templateUrl: template,
        bindings: {
            event: '<',
            company: '<'
        }
    }).name;

function ModuleConfig($stateProvider) {
    $stateProvider.state('eventix.dashboard.wizard.advanced.scanners', {
        url: '/scanners',
        component: 'wizardAdvancedScanners',
        bindings: {
            event: 'currentEvent',
            company: 'company'
        }
    });
}

function WizardAdvancedScannersController($q, $uibModal, $filter, $translate, CrudCtrl, ErrorRejector, scannerLoginModal, ScannerType, Role) {
    const $ctrl = this;
    $ctrl.isAdmin = Role.isAuthorizedAs('Admin');
    $ctrl.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');

    if ($ctrl.isAdminOrWLAdmin) {
        $ctrl.formOptionsAvailable = [
            {
                key: 'expires_at',
                label: $translate.instant('models.scanner_type.expires_at'),
            },
            {
                key: 'scan_types',
                label: $translate.instant('models.scanner_type.scan_types'),
            },
        ];
    }

    $ctrl.showQR = (scannerType) => scannerLoginModal(scannerType);
    $ctrl.save = save;
    $ctrl.copyToClipboard = copyToClipboard;

    $ctrl.$postLink = function() {
        $ctrl.crud = new CrudCtrl(
            () => $ctrl.event.$queryOrCreateScannerType(() => ScannerType.newDefault([$ctrl.event.name, $translate.instant('models.models.scannerType', {count: 1})].join(' - '))),
            () => ScannerType.newDefault()
        );
        $ctrl.crud.addBelongsToMany('products', 'Product');
    };

    function save() {
        return $ctrl.event.$crudCreateOrSaveChild($ctrl.crud, 'ScannerType')
            .catch(error => {
                if (_.get(error, 'data.error_description.username.0') === 'The username has already been taken.') {
                    UIMessages.push({
                        type: 'danger',
                        message: 'The username has already been taken.'
                    });
                }

                if (_.get(error, 'data.error_description.password.0') === 'The password must be at least 4 characters.') {
                    UIMessages.push({
                        type: 'danger',
                        message: 'The password must be at least 4 characters.'
                    });
                }

                return error;
            });
    }
}
